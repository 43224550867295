export class Config {
	//100% static
	static all = 'all'

	static ecommerce = 'ecommerce'

	static login = '/login'

	//static firstLogin = "/login/first";  // 14.03.2022 renamed for clarity
	static activation = '/activation'
	static agreement = '/agreement' // 26.05.2022

	//static verifyPuk = "/login/verifyPuk";
	static verifyPuk = '/verifyPuk' // 15.03.2022

	static recoverFromPuk = '/login/recoverFromPuk'

	static profile = '/profile' // 25.01.2021

	static subscription = '/subscription' //03-05-23

	static dashboard = '/dashboard'
	static locations = '/locations'

	static dealers = '/dealers'
	static doctors = '/doctors'
	static operators = '/operators'
	static patients = '/patients'
	static colleagues = '/colleagues' // sempre lista doctors, stesso controller per miniB  04.02.2021

	// static visits = '/visits'
	//static exams = "/exams";
	//static exam = "/exam";
	//static pictures = "/pictures";
	static devices = '/devices' // 08.03.2021
	static updates = '/updates' // 18.03.2021

	static createUser = '/createUser'
	static relations = '/relations'
	static distributor = '/distributor'
	static distributors = '/distributors'
	static clinics = '/clinics'
	static statistics = '/statistics' // 31.01.2018
	static admins = '/admins' // 08.08.2018
	static relationsList = '/relationsList' // 09.08.2018

	static graders = '/graders'

	static telerefract = '/telerefract' // 29.04.2022

	static working = '/working' // 01.06.2022

	static notfound = '/not-found'

	static trial = '/trial'
	//static dicomservers = "/dicomservers";   // 18.10.2019
	//static templates = "/templates";   // 02.07.2019

	//from config.json
	static isProductionMode: boolean
	static isStagingMode: boolean // 27.10.2022 serve per Telerefract

	static cryptographyMode: String

	static telerefractUrl: String // 02.11.2022
	static apiEndpoint: String
	static bridgeEndpoint: String

	static autoregUrl: String

	//built from json data
	static tokenEndpoint
	static pingEndpoint
	static sapEndpoint // 07.12.2022

	static usersEndpoint
	static doctorsEndpoint
	static distributorsEndpoint
	static relatedClinicsEndpoint
	static availableDistributorsEndpoint
	static profilesEndpoint
	static addressesEndpoint
	static patientsEndpoint
	static pendingPatientsEndpoint
	static visitsEndpoint
	static examsEndpoint
	static relationsEndpoint
	static nearestRelationsEndpoint
	static adminsEndpoint
	static reportsEndpoint
	static statisticsEndpoint // 26.01.2021
	static countriesEndPoint
	static userscountries
	static devicesEndpoint
	static updatesEndpoint
	static salePlansEndpoint // 17.08.2021
	static pairingEndpoint
	static servicesEndpoint

	static agreementsEndpoint
	// static utilsEndpoint
	static aiReportsEndpoint // 24.08.2022

	static additionsEndpoint // 22.11.2022
	static logsEndpoint

	static anamnesisEndpoint // 22.02.2023
	static impactAnamnesisEndpoint

	static vaEndpoint

	static supportTaskEndpoint
	static surveysEndpoint

	static userEventsEndpoint: string

	static patientUUIDEndpoint: string

	static amplifyConfigEndpoint

	static vatCheckerEndpoint: string
	static irisEndpoint: string
	static vacanciesEndpoint: string

	static impactEndpoint: string

	static QRExpireTimer: number = 15 // minuti
	static QRCodeEnabled: boolean

	// 27.02.19 aggiunto per brand
	static brand: String

	// ATT a non confondere brand del FE con brand utente. Ok ad usare stesse costanti
	//static BR_NS = "nextsight";
	static BR_DEFAULT = 'Default'
	static BR_CLALIT = 'Clalit' // 12.10.2021
	static BR_RDS = 'RDS' // 14.10.2021
	static BR_ESSILOR = 'Essilor'
	static BR_ZEISS = 'Zeiss'

	static BUILD = '3.8.17'

	// 04.02.2021 corrispondo alla enum sul DB, campo users.user_type
	static PR_OPTICIAN = 'Optician'
	static PR_DOCTOR = 'Doctor'
	static PR_SPECIALIST = 'Specialist'
	static PR_DISTRIB = 'Distributor'
	static PR_ADMIN = 'Admin'
	static PR_VICE = 'Vice'
	static PR_MANAGER = 'Manager' // 25.05.2021
	static PR_STATS = 'Statistician' // 20.07.2021
	static PR_SERVICE = 'Service' // 01.06.2022
	static PR_SUPPORT = 'Support' // 09.11.2022
	static PR_INSTALLER = 'Installer' // 24.10.2023
	static PR_CLINIC = 'ClinicAdmin'

	static ALL_LEV1 = 'lev1' // usato nelle statistiche

	// 08.02.2021
	static SUB_STD = 'Std'
	static SUB_SUPER = 'Super'
	static SUB_MINI = 'Mini'
	static SUB_PRIVATE = 'Private' // per refertatori scelti da optician, non visionix_partner
	static SUB_COMPANY = 'Company' // 21.06.2023 graders che lavorano per l'azienda VX
	static SUB_GLOBAL = 'Global'
	static SUB_COUNTRY = 'Country'
	static SUB_INTERNAL = 'Internal'

	static SUB_UNKNOWN = '' // 18.01.2023 se non arriva la info

	static SUB_DEMO = 'Demo'
	static SUB_TEST = 'Test' // 28.07.2021 (ex 'device' su Nexy)

	//Agreement doc_type

	static AGR_TERMS = 'terms'
	static AGR_PRIVACY = 'privacy'

	// 16.09.2021 meglio qui, non su session
	static SUBTYPES = [Config.SUB_STD, Config.SUB_SUPER, Config.SUB_MINI]
	// tolto Config.SUB_TEST
	// tolto Config.SUB_DEMO, usiamo solo ip NS   13.10.2021

	static INSTALLER_SUBTYPES = [Config.SUB_GLOBAL, Config.SUB_COUNTRY, Config.SUB_INTERNAL]

	static EXM_TOPO = 'topo'
	static EXM_EXT = 'extphoto'
	static EXM_DRYEYE = 'dryeye'
	static EXM_PACHY = 'pachy' // su glaucoma
	static EXM_PACHYMULT = 'pachymult' // su cornea
	static EXM_TONO = 'tono'
	static EXM_FUNDUS = 'fundus'
	static EXM_LM = 'lensmeter'
	static EXM_PUPIL = 'pupil'
	static EXM_RETRO = 'retro'
	static EXM_SBJ = 'subjective'
	static EXM_WF = 'wf'

	static EXAM_TYPES = [
		Config.EXM_DRYEYE,
		Config.EXM_EXT,
		Config.EXM_FUNDUS,
		Config.EXM_LM,
		Config.EXM_PACHY,
		Config.EXM_PACHYMULT,
		Config.EXM_PUPIL,
		Config.EXM_RETRO,
		Config.EXM_SBJ,
		Config.EXM_TONO,
		Config.EXM_TOPO,
		Config.EXM_WF,
	]

	// NB attenzione al case
	static CAT_REFRACTION = 'refraction'
	static CAT_ANAMNESIS = 'anamnesis'
	static CAT_ANTERIOR = 'anterior'
	static CAT_CORNEA = 'cornea'
	static CAT_GLC = 'glaucoma'
	static CAT_FUNDUS = 'fundus'

	static CATEG_NAMES = [Config.CAT_ANAMNESIS, Config.CAT_REFRACTION, Config.CAT_ANTERIOR, Config.CAT_CORNEA, Config.CAT_GLC, Config.CAT_FUNDUS]

	// ESAMI PER OGNI CATEGORIA
	static CAT_REFRACTION_EXAMS = [Config.EXM_LM, Config.EXM_SBJ, Config.EXM_TOPO, Config.EXM_WF]
	static CAT_ANTERIOR_EXAMS = [Config.EXM_DRYEYE, Config.EXM_EXT]
	static CAT_CORNEA_EXAMS = [Config.EXM_PACHYMULT, Config.EXM_TOPO]
	static CAT_GLC_EXAMS = [Config.EXM_PACHY, Config.EXM_RETRO, Config.EXM_TONO, Config.EXM_WF]
	static CAT_FUNDUS_EXAMS = [Config.EXM_FUNDUS]

	static CATEGORIES_EXAMS = {
		[Config.CAT_ANAMNESIS]: [],
		[Config.CAT_REFRACTION]: Config.CAT_REFRACTION_EXAMS,
		[Config.CAT_ANTERIOR]: Config.CAT_ANTERIOR_EXAMS,
		[Config.CAT_CORNEA]: Config.CAT_CORNEA_EXAMS,
		[Config.CAT_GLC]: Config.CAT_GLC_EXAMS,
		[Config.CAT_FUNDUS]: Config.CAT_FUNDUS_EXAMS,
	}

	static CATEGORIES_EXAMS_WITH_iMAGES = {
		[Config.CAT_ANTERIOR]: [Config.EXM_DRYEYE, Config.EXM_EXT],
		[Config.CAT_CORNEA]: [Config.EXM_PACHYMULT, Config.EXM_TOPO],
		[Config.CAT_GLC]: [Config.EXM_PACHY, Config.EXM_RETRO, Config.EXM_WF],
		[Config.CAT_FUNDUS]: [Config.EXM_FUNDUS],
	}

	// 17.08.2021

	static CHOICE_ALL = 'All'
	static CHOICE_MAN = 'Manual'

	static PDF_FORMATS = ['A4', 'LETTER']
	static DATE_FORMATS = ['MM/DD/YYYY', 'DD/MM/YYYY']

	static EXM_ADDITION = 'addition' // 25.11.2022

	// 19.10.2021 diagnosis groups
	static DIAGN_GRP_0 = '0 - No Codes'
	static DIAGN_GRP_1 = '1 - ICD10'
	static DIAGN_GRP_2 = '2 - ICD hidden'
	static DIAGN_GRP_3 = '3 - test'

	static DIAGN_GROUPS = [Config.DIAGN_GRP_0, Config.DIAGN_GRP_1, Config.DIAGN_GRP_2]

	// valori usati per le currentAction
	static ACT_CREATE = 'create'
	static ACT_EDIT = 'modify'
	static ACT_DELETE = 'delete'
	static ACT_RESTORE = 'restore'
	static ACT_SHOW = 'show' // come edit ma readonly

	static DELETE_ME = 'DELETE_ME' // 09.05.2022

	// 20.12.2021 per le tabelle con lista elementi
	// partiamo con stessa config. per tutte
	// verificare il sort...

	// AI REPORTs

	static AIDEVICES = ['VX610']

	//static totPerPage = 6;   // lasciamo basso per cambiare man mano le chiamate a questo, usare il serv.
	static externalImgNames = [
		{ db: 'image_auto', label: 'automatic' },
		{ db: 'image_man_1', label: 'manual 1' },
		{ db: 'image_man_2', label: 'manual 2' },
		{ db: 'image_man_3', label: 'manual 3' },
		{ db: 'image_man_4', label: 'manual 4' },
		{ db: 'image_man_5', label: 'manual 5' },
		{ db: 'image_man_6', label: 'manual 6' },
	]

	static topoImgNames = [
		{ db: 'axial_map', label: 'axial map' },
		{ db: 'elevation_map', label: 'elevation map' },
		{ db: 'tangential_map', label: 'tangential map' },
	]

	static retroImgNames = [
		{ db: 'image_high', label: 'retro high' },
		{ db: 'image_low', label: 'retro low' },
	]

	static fundusImgNames = [
		{ db: 'image_central', label: 'central' },
		{ db: 'image_nasal', label: 'nasal' },
		{ db: 'supero_nasal', label: 'supero nasal' },
		{ db: 'supero_temporal', label: 'supero temporal' },
		{ db: 'image_temporal', label: 'temporal' },
		{ db: 'image_inferior', label: 'inferior' },
		{ db: 'image_external', label: 'external' },
		{ db: 'central_nasal', label: 'nasal' },
	]
	// 30.12.2021 spostate da categs
	static descrImgTopo = ['axial map', 'elevation map', 'tangential map']
	static descrImgPachyMult = ['pachy multi']

	static descrImgExternal = ['automatic', 'manual 1', 'manual 2', 'manual 3', 'manual 4', 'manual 5', 'manual 6']
	static descrImgDryEye = ['dry eye']
	static descrImgPachyS = ['pachy', 'pachy with data'] // 20.07.2020
	static descrImgRetro = ['retro high', 'retro low'] // 25.08.2020
	static descrImgWf = ['wf grid', 'wf meso'] // poi ignoro meso

	// 07.10.2022 fix nomi delle fissazioni, con _ non - !! [ls]
	// stessa sequenza che era nel nexy e che e' sul DB, viene usata per la visualizzazione
	//static descrImgFundus = ['central', 'nasal', 'supero-nasal', 'supero-temporal', 'temporal', 'inferior', 'external', 'central-nasal']; // 28.09.2020
	static descrImgFundus = ['central', 'nasal', 'supero_nasal', 'supero_temporal', 'temporal', 'inferior', 'external', 'central_nasal'] // 28.09.2020

	static followUpMonths = [1, 3, 6, 12]

	// vd anche su Ai_reports, per il TL, TODO unico...
	static RED = 'red'
	static GREEN = 'green'
	static YELLOW = 'yellow'
	static GRAY = 'gray'

	//field to decrypt on user.model Specialist - opt
	static fieldToDecrypt = ['firstname', 'lastname', 'display_name', 'signature', 'signature_name', 'logo', 'licence_num']
	static fieldToDecryptAddr = ['address_line1', 'city', 'province', 'zip', 'country', 'phone1', 'ref_email', 'organization', 'vat']
	static fieldClearAddr = ['id', 'address_label', 'latitude', 'longitude', 'role', 'label', 'gmt_offset_sec', 'timezone']

	//address roles
	static SHOP = 'shop'
	static CLINIC = 'clinic'
	static LEGAL = 'legal'

	// notifications
	static STORAGE = 'Storage'
	static CREDITS = 'Credits'
	static SUBSCRIPTION = 'Subscription'
	static RELATION = 'Relation'
	static REMINDER = 'Reminder'
	static AIUNLIMIT = 'AiUnlimited'
	static SERVICE_PURCHASED = 'ServicePurchased'
	static GMTOFFSET = 'GMTOffset'

	static load(jsonConfig) {
		// console.log(jsonConfig) // 10.03.2021 tolta trace

		//from config.json
		Config.apiEndpoint = jsonConfig.apiEndpoint
		Config.irisEndpoint = jsonConfig.irisEndpoint
		Config.bridgeEndpoint = jsonConfig.api_bridgeEndpoint
		//Config.phpEndpoint = jsonConfig.phpEndpoint;
		Config.isProductionMode = !!jsonConfig.production
		Config.isStagingMode = !!jsonConfig.staging

		Config.cryptographyMode = jsonConfig.cryptography ? jsonConfig.cryptography : 'standard'
		Config.brand = jsonConfig.brand

		Config.telerefractUrl = jsonConfig.telerefractUrl

		Config.autoregUrl = jsonConfig.autoregUrl

		Config.QRCodeEnabled = jsonConfig.qrCodeEnabled

		// 10.03.2021 nuova trace ridotta

		console.log('[init] B:' + jsonConfig.brand + ', Prod? ' + Config.isProductionMode + ', v:' + Config.BUILD)
		console.log('[init] API: ' + jsonConfig.apiEndpoint)

		var apiPath = '/api/v1' // 20.04.2021 tutte a parte la token-login

		//built from json data
		//Config.tokenEndpoint = Config.apiEndpoint + "/../../oauth/token";
		Config.tokenEndpoint = Config.apiEndpoint + '/oauth/token' // 25.10.2021
		Config.pingEndpoint = Config.apiEndpoint + '/ping' // 05.05.2022

		//Config.sapEndpoint = Config.apiEndpoint + '/sap'; // 07.12.2022 temporaneam. fuori

		// le due sopra sono non autenticate, le prox lo sono tutte **********

		Config.apiEndpoint = Config.apiEndpoint + apiPath // 20.04.2021

		Config.doctorsEndpoint = Config.apiEndpoint + '/doctors'
		Config.usersEndpoint = Config.apiEndpoint + '/users'
		Config.relationsEndpoint = Config.apiEndpoint + '/relations'
		Config.nearestRelationsEndpoint = Config.apiEndpoint + '/nearest_relations'
		Config.distributorsEndpoint = Config.apiEndpoint + '/distributors'
		Config.relatedClinicsEndpoint = Config.distributorsEndpoint + this.clinics
		Config.availableDistributorsEndpoint = Config.distributorsEndpoint + '/available?user='
		Config.adminsEndpoint = Config.apiEndpoint + '/admins'
		Config.profilesEndpoint = Config.apiEndpoint + '/profiles'
		Config.addressesEndpoint = Config.apiEndpoint + '/addresses'
		Config.patientsEndpoint = Config.apiEndpoint + '/patients'
		Config.pendingPatientsEndpoint = Config.apiEndpoint + '/pending_patients'
		Config.visitsEndpoint = Config.apiEndpoint + '/visits'
		Config.examsEndpoint = Config.apiEndpoint + '/exams'
		Config.reportsEndpoint = Config.apiEndpoint + '/reports'
		Config.statisticsEndpoint = Config.apiEndpoint + '/statistics' // 26.01.2021
		Config.devicesEndpoint = Config.apiEndpoint + '/devices' // 08.03.2021
		Config.servicesEndpoint = Config.apiEndpoint + '/services'
		Config.pairingEndpoint = Config.servicesEndpoint + '/pairing'
		Config.updatesEndpoint = Config.apiEndpoint + '/updates' // 18.03.2021
		Config.agreementsEndpoint = Config.apiEndpoint + '/agreements' // 25.05.2022
		// Config.utilsEndpoint = Config.apiEndpoint + '/utils' // 08.06.2022
		Config.countriesEndPoint = Config.apiEndpoint + '/countries'
		Config.userscountries = Config.countriesEndPoint + '/userscountries'
		Config.aiReportsEndpoint = Config.apiEndpoint + '/ai_reports'
		Config.salePlansEndpoint = Config.apiEndpoint + '/saleplans' // 17.08.2021
		Config.additionsEndpoint = Config.apiEndpoint + '/additions'
		Config.logsEndpoint = Config.apiEndpoint + '/logs'
		Config.sapEndpoint = Config.apiEndpoint + '/sap' // 02.01.2023 con api/v1 e autenticazione
		Config.anamnesisEndpoint = Config.apiEndpoint + '/anamnesis' // 22.02.2023
		Config.impactAnamnesisEndpoint = Config.apiEndpoint + '/impact_anamnesis'
		Config.vaEndpoint = Config.apiEndpoint + '/patient_va'
		Config.vatCheckerEndpoint = Config.bridgeEndpoint + '/user/vatchecker'
		Config.irisEndpoint = Config.irisEndpoint + '/notifications'
		Config.amplifyConfigEndpoint = Config.bridgeEndpoint + '/vbr/mapconfig'
		Config.vacanciesEndpoint = Config.apiEndpoint + '/vacancies'
		Config.surveysEndpoint = Config.apiEndpoint + '/surveys'
		Config.supportTaskEndpoint = Config.apiEndpoint + '/support_tasks'
		Config.userEventsEndpoint = Config.apiEndpoint + '/events'
		Config.patientUUIDEndpoint = Config.apiEndpoint + '/patient_uuids'
		Config.impactEndpoint = Config.apiEndpoint + '/impact'
		//Config.dicomSrvEndpoint = Config.apiEndpoint + "/dicom_servers";
	}
}
