import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injector, ErrorHandler, APP_INITIALIZER } from '@angular/core' // deve essere per primo
import { createCustomElement } from '@angular/elements'

import * as Sentry from '@sentry/angular-ivy'

import { Router } from '@angular/router'

import { NgbModule, NgbModal } from '@ng-bootstrap/ng-bootstrap'

import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms' // ReactiveFormsModule
import { DatePipe } from '@angular/common'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module'

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome' // 03.01.2022

// material
import { MatInputModule } from '@angular/material/input'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner' // 21.03.2022
import { MatSlideToggleModule } from '@angular/material/slide-toggle' //13.10.22 tomas
import { MatSliderModule } from '@angular/material/slider'
import { MatIconModule } from '@angular/material/icon'

import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core'

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'

import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'

import { InternationalizationModule } from './internationalization/internationalization.module'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateLoader, TranslateModule, TranslateStore } from '@ngx-translate/core'

import { NgxSliderModule } from '@angular-slider/ngx-slider'

// import { NoopAnimationsModule } from '@angular/platform-browser/animations'; //questo modulo elimina le animazioni material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations' //abilitando le animazioni levando NoopAnimationsModule va importanto questo per abilitare le animazioni

import { NgxImageZoomModule } from 'ngx-image-zoom'

import { NgApexchartsModule } from 'ng-apexcharts'

import { QRCodeModule } from 'angularx-qrcode'

// ********** locali
import { AppComponent } from './app.component'
import { LoginComponent } from './component/login/login.component'

import { AuthenticationLevelPipe } from './filters/authentication-level.pipe'
import { AgePipe } from './filters/age.pipe'
import { StringToDate } from './filters/stringToDate.pipe'

import { PatientListComponent } from './component/patients/patientList.component'
import { PatientModalContent } from './component/patients/patient.modal'
import { PendingPatientConflictModalContent } from './component/patients/pendingPatientConflict.modal'
import { PatientReportModal } from './component/patients/reports.modal'

import { VisitListComponent } from './component/visits/visitList.component'
import { VisitListModal } from './component/visits/visitList.modal'
import { addDeviceModal } from './component/visits/addDevice.modal'
import { aiReviewModal } from './component/visits/aiReview.modal'
//import { VisitsDirective } from './component/visits/visitList.directive'

import { ReportList } from './component/reports/reports-hg.modal'
// import { ReportsDirective } from './component/reports/reports.directive';
import { ReportAiList } from './component/reports/report-ai.component'

import { CategoriesController } from './component/categories/categories.controller'

import { DeleteModalContent } from '././component/delete/delete.modal'

import { ProfileComponent } from './component/profile/profile.component'
import { PwdModalContent } from './component/profile/pwd.modal'

import { DoctorListComponent } from './component/doctors/doctorList.component'
//import { DoctorsDirective } from './component/doctors/doctorList.directive'
import { DoctorModal } from './component/doctors/doctor.modal'
import { MiniModal } from './component/doctors/mini.modal'
import { BalanceModal } from './component/doctors/balance.modal' // 28.09.2022
import { RelationsModal } from './component/doctors/relations/relations.modal'
import { RelationInfoModal } from './component/relations/relationInfo.modal' // solo display info

import { CreateUserComponent } from './component/users/createUser.component'
import { AdminListComponent } from './component/admins/adminList.component'
// import { AdminsDirective } from './component/admins/adminList.directive';
import { AdminModal } from './component/admins/admin.modal'

import { AnamnesisModal } from './component/anamnesis/anamesis.modal'

import { WorkingComponent } from './component/working/working.component'
import { ActivationComponent } from './component/activation/activation.component'
import { VerifyPukComponent } from './component/verifyPuk/verifyPuk.component'

//import { RecoverPukComponent } from './component/login/recover-puk/recover-puk.component';
import { RecoverPukModal } from './component/login/recoverPuk.modal'
import { AboutModal } from './component/about/about.modal'
import { TelerefractComponent } from './component/telerefract/telerefract.component'

import { RemoteListComponent } from './component/remotes/remoteList.component'
//import { RemotesDirective } from './component/remotes/remoteList.directive'
import { SpecialistModal } from './component/remotes/specialist.modal' // 03.08.2022

import { RelationsComponent } from './component/relations/relations.component'
// import { RelationsDirective } from './component/relations/relations.directive';
import { RelationModalContent } from './component/relations/relation.modal'
import { AgreementComponent } from './component/agreement/agreement.component'
import { StatisticsComponent } from './component/statistics/statistics.component'

import { DevicesComponent } from './component/devices/devices.component'
import { DeviceModal } from './component/devices/device.modal' // 10.08.2022

import { UserDevicesModal } from './component/doctors/userDevices.modal'

import { SapComponent } from './component/sap/sap.component' // 06.12.2022

import { dbSettingComponent } from './component/dbsettings/dbsettings.component'

import { UpdatesComponent } from './component/updates/updates.component'
import { UpdatesModalContent } from './component/updates/updates.modal'
import { WizardModal } from './component/updates/wizard/wizard.modal'
import { PushUpdateModal } from './component/updates/push-update/push-update.modal'

// **** elements ****
import { ConfirmModal } from './elements/confirm/confirm.modal'
import { AgreementModal } from './elements/agreement-modal/agreement-modal.modal'
import { PatientComponent } from './elements/patient/patient.component'
import { FieldComponent } from './elements/field/field.component'
import { AlbumComponent } from './elements/album/album.component' // 27.12.2021
import { RefractionComponent } from './elements/refraction/refraction.component'
import { HeaderComponent } from './elements/header/header.component'
import { DiagnosiComponent } from './elements/diagnosi/diagnosi.component'
import { ScrollCheckDirective } from './elements/diagnosi/scroll.directive'
import { PrescriptionComponent } from './elements/prescription/prescription.component'
import { SearchdropdownComponent } from './elements/searchdropdown/searchdropdown.component'
import { BalanceComponent } from './elements/balance/balance.component'
import { CreditsComponent } from './elements/credits/credits.component'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { PatAgreementComponent } from './elements/pat-agreement/pat-agreement.component'
import { PatCredentialsComponent } from './elements/pat-credentials/pat-credentials.component'
import { ActivitiesList } from './elements/activities/activities.component'
import { PatAnamnesisComponent } from './elements/pat-anamnesis/pat-anamnesis.component'
import { PatVaComponent } from './elements/pat-anamnesis/pat-va/pat-va.component'
import { patternVisusDirective } from './elements/pat-anamnesis/pattern-visus.directive'
import { SubscriptionComponent } from './component/subscription/subscription.component'
import { ToastComponent } from './component/toast/toast.component'
import { OffcanvasComponent } from './component/offcanvas/offcanvas.component'
import { TrialComponent } from './component/trial/trial.component'
import { ClinicsComponent } from './component/clinics/clinics.component'
import { GraderModal } from './component/clinics/grader.modal'
import { DashboardComponent } from './component/dashboard/dashboard.component'
import { AvailabilityHandlerComponent } from './elements/activities/availability-handler/availability-handler.component'
import { LocationComponent } from './elements/location/location.component'
import { RequestChangeGraderModalContent } from './elements/credits/request-change-grader.modal'
import { CloserAutoRelationModalContent } from './component/relations/closer-auto-relation.modal'
import { UserEventsModal } from './elements/userEvents/userEvent.modal'
import { QRCode } from './elements/qrCode/qrCode.modal'
import { MessageModal } from './elements/message-modal/message.modal'
import { LoaderComponent } from './elements/loader/loader.component'
import { LoaderStatusComponent } from './elements/loader-status/loader-status.component'
import { MapComponent } from './elements/map/map.component'
import { ImpactSliderComponent } from './component/impact/impact-slider/impact-slider.component'
import { ImpactModal } from './component/impact/impact.modal'
import { TopicHistoryModal } from './component/impact/topic-history.modal/topic-history.modal'
import { ExternalDocumentComponent } from './elements/release-notes/release-notes.modal'

/**
 * The http loader factory : Loads the files from define path.
 * @param {HttpClient} http
 * @returns {TranslateHttpLoader}
 * @constructor
 */
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, '../assets/locales/', '.json')
}

/*
TranslateModule.forRoot({
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  }
})
*/

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		PatientListComponent,
		PatientComponent,
		PatientModalContent,
		PendingPatientConflictModalContent,
		PatientReportModal,
		VisitListComponent,
		VisitListModal,
		addDeviceModal,
		aiReviewModal,
		DoctorListComponent,
		DoctorModal,
		MiniModal,
		BalanceModal,
		RelationInfoModal,
		ReportList,
		RelationsModal,
		CategoriesController,
		DeleteModalContent,
		FieldComponent,
		AlbumComponent,
		RefractionComponent,
		HeaderComponent,
		ProfileComponent,
		PwdModalContent,
		CreateUserComponent,
		AdminListComponent,
		// AdminsDirective,
		AdminModal,
		AnamnesisModal,
		DiagnosiComponent,
		ScrollCheckDirective,
		patternVisusDirective,
		PrescriptionComponent,
		AuthenticationLevelPipe,
		AgePipe,
		StringToDate,
		WorkingComponent,
		ActivationComponent,
		VerifyPukComponent,
		RecoverPukModal,
		AboutModal,
		TelerefractComponent,
		RemoteListComponent,
		//RemotesDirective,
		RelationsComponent,
		// RelationsDirective,
		RelationModalContent,
		AgreementComponent,
		StatisticsComponent,
		SearchdropdownComponent,
		DevicesComponent,
		DeviceModal,
		SpecialistModal,
		ReportAiList,
		UserDevicesModal,
		BalanceComponent,
		ActivitiesList,
		SapComponent,
		dbSettingComponent,
		CreditsComponent,
		UpdatesComponent,
		UpdatesModalContent,
		WizardModal,
		PushUpdateModal,
		PageNotFoundComponent,
		ConfirmModal,
		PatAgreementComponent,
		PatCredentialsComponent,
		PatAnamnesisComponent,
		PatVaComponent,
		ToastComponent,
		OffcanvasComponent,
		SubscriptionComponent,
		TrialComponent,
		ClinicsComponent,
		AgreementModal,
		GraderModal,
		DashboardComponent,
		AvailabilityHandlerComponent,
		LocationComponent,
		RequestChangeGraderModalContent,
		CloserAutoRelationModalContent,
		UserEventsModal,
		QRCode,
		MessageModal,
		LoaderComponent,
		LoaderStatusComponent,
		MapComponent,
		ImpactModal,
		ImpactSliderComponent,
		TopicHistoryModal,
		ExternalDocumentComponent,
	],
	imports: [
		NgbModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserModule,
		FormsModule,
		QRCodeModule,
		ReactiveFormsModule,
		FontAwesomeModule,
		NgxImageZoomModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatAutocompleteModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatSliderModule,
		MatDatepickerModule,
		MatRippleModule,
		MatNativeDateModule,
		MatProgressSpinnerModule,
		InternationalizationModule.forRoot({ locale_id: 'en_EN' }),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		BrowserAnimationsModule,
		// NoopAnimationsModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		NgApexchartsModule,
		NgxSliderModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [
		DatePipe,
		NgbModal,
		TranslateStore,
		MatDatepickerModule,
		// // these providers are necessary for material datepicker
		{ provide: MAT_DATE_LOCALE, useValue: 'en_EN' },
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
	],
	bootstrap: [AppComponent], // poi  carica login
})
export class AppModule {
	constructor(private injector: Injector) {}

	ngDoBootstrap() {
		const elemField = createCustomElement(FieldComponent, {
			injector: this.injector,
		})

		const pat = createCustomElement(PatientComponent, {
			injector: this.injector,
		})

		const alb = createCustomElement(AlbumComponent, {
			injector: this.injector,
		})

		const refr = createCustomElement(RefractionComponent, {
			injector: this.injector,
		})

		const headr = createCustomElement(HeaderComponent, {
			injector: this.injector,
		})

		const diagn = createCustomElement(DiagnosiComponent, {
			injector: this.injector,
		})

		const prescr = createCustomElement(PrescriptionComponent, {
			injector: this.injector,
		})

		// 06.06.2022
		const dd = createCustomElement(SearchdropdownComponent, {
			injector: this.injector,
		})

		// 27.10.2022
		const bal = createCustomElement(BalanceComponent, {
			injector: this.injector,
		})

		const act = createCustomElement(ActivitiesList, {
			injector: this.injector,
		})

		customElements.define('field', elemField)
		customElements.define('patient', pat)
		customElements.define('album', alb)
		customElements.define('refraction', refr)
		customElements.define('header', headr)
		customElements.define('diagnosi', diagn) // 01.02.2022
		customElements.define('prescription', prescr) // 07.02.2022
		customElements.define('searchdropdown', dd) // 06.06.2022
		customElements.define('balance', bal) // 27.10.2022
	}
}
