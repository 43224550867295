import { Component, Input, OnInit } from '@angular/core'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexYAxis, ApexAnnotations, ApexGrid, ApexDataLabels } from 'ng-apexcharts'
import { ImpactHistoryEyeData } from 'src/app/models/impact.model'
import { ImpactService } from 'src/app/service/impact.service'
import { SessionService } from 'src/app/service/session.service'

export type ChartOptions = {
	chart: ApexChart
	xaxis: ApexXAxis
	yaxis: ApexYAxis
	series: ApexAxisChartSeries
	annotations: ApexAnnotations
	grid: ApexGrid
	dataLabels: ApexDataLabels
}

@Component({
	selector: 'topic-history-modal',
	templateUrl: './topic-history.modal.html',
	styleUrls: ['./topic-history.modal.scss'],
})
export class TopicHistoryModal implements OnInit {
	@Input() section: string
	@Input() topic: string
	@Input() normRange: { min: number; max: number }
	@Input() patientId: number
	@Input() day: string

	leftEyeHistoryData: { value: number; date: string }[]
	rightEyeHistoryData: { value: number; date: string }[]
	chartOptionsL: ChartOptions
	chartOptionsR: ChartOptions
	isLoading: boolean = true

	faEye = faEye

	constructor(public session: SessionService, public activeModal: NgbActiveModal, public impactService: ImpactService) {}

	ngOnInit() {
		this.impactService
			.getImpactTopicHistory(this.patientId, this.topic, this.day)
			.then((res) => {
				this.leftEyeHistoryData = this.processEyeData(res.historical_topic.eyes, 'left')
				this.rightEyeHistoryData = this.processEyeData(res.historical_topic.eyes, 'right')

				this.chartOptionsL = this.createChartOptions(this.leftEyeHistoryData, this.normRange)
				this.chartOptionsR = this.createChartOptions(this.rightEyeHistoryData, this.normRange)
			})
			.finally(() => {
				this.isLoading = false
			})
	}

	createChartOptions(data: { value: number; date: string }[], normRange: { min: number; max: number }): ChartOptions {
		const paddedData = this.addPadding(data)
		const categoriesArray = paddedData.map((item) => item.x)

		const normRangeSize = normRange.max - normRange.min
		const buffer = normRangeSize * 0.4
		const minY = normRange.min - buffer
		const maxY = normRange.max + buffer

		return {
			chart: { type: 'scatter', toolbar: { show: false } },
			xaxis: {
				categories: categoriesArray,
				labels: {
					formatter: (value: string) => {
						if (!value) return ''
						return ['start', 'end'].includes(value) ? '' : value.split(' ')[0]
					},
					style: { fontFamily: 'Montserrat' },
				},
				axisTicks: { show: false },
			},
			yaxis: {
				min: minY,
				max: maxY,
				labels: { style: { fontFamily: 'Montserrat' } },
				tickAmount: 9,
			},
			series: [{ name: 'Values', data: paddedData, color: '#e4491d' }],
			annotations: {
				yaxis: [
					{
						y: normRange.min,
						y2: normRange.max,
						borderColor: '#f2f0f7',
						fillColor: '#f2f0f7',
						opacity: 0.4,
					},
				],
			},
			grid: {
				show: true,
				xaxis: { lines: { show: true } },
				position: 'back',
			},
			dataLabels: {
				enabled: true,
				offsetY: -7.5,
				background: { enabled: false },
				style: {
					colors: ['#e4491d'],
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
					fontSize: '1.1rem',
				},
			},
		}
	}

	addPadding(data: { value: number; date: string }[]): { x: string; y: number | null }[] {
		const formattedData = data.map((el) => ({ x: el.date, y: el.value }))
		return [{ x: 'start', y: null }, ...formattedData, { x: 'end', y: null }]
	}

	processEyeData(examsData: ImpactHistoryEyeData[], eye: string) {
		return examsData
			.filter((exam) => exam.eye === eye && !Number.isNaN(exam.decrValue))
			.sort((a, b) => new Date(a.exam_date).getTime() - new Date(b.exam_date).getTime())
			.map((exam) => ({
				value: exam.decrValue,
				date: this.session.formatDateTime(new Date(exam.exam_date)),
			}))
	}

	close() {
		this.activeModal.dismiss()
	}
}
