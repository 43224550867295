import { Component, OnInit, Input, ViewChild, Output, SimpleChanges, OnDestroy } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { faSave } from '@fortawesome/free-regular-svg-icons'
import { faBan } from '@fortawesome/free-solid-svg-icons'

import { SessionService } from '../../service/session.service'
import { DataModelService } from '../../service/data-model.service'

import { Patient } from '../../models/patient.model'
import { Util } from '../../models/util.model'
import { FullReport, Report, ReportStatus } from '../../models/report.model'
import { ReportType } from '../../models/pdf.model'

import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { TablePrefs } from '../../models/settings.model'
import { ActivatedRoute } from '@angular/router'
import { AppToastService } from 'src/app/service/toast.service'
// import { AnamnesisService } from 'src/app/service/anamnesis.service'
import { reportsService } from 'src/app/service/reports.service'
import { ToastOptions } from 'src/app/models/toast.model'
import { Subscription } from 'rxjs'

@Component({
	selector: 'reports-hg',
	templateUrl: './reports-hg.modal.html',
	styleUrls: ['./reports-hg.modal.scss'],
})
export class ReportList implements OnInit, OnDestroy {
	reportListTable: MatTableDataSource<Report>

	@Input() reportList: Report[] // Arriva dal parent
	// @Input('replist-changed') refreshFlag: string

	@Input() targetPatient: Patient
	targetReport: number

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
	@ViewChild(MatSort, { static: true }) sort: MatSort
	// @ViewChild('filter') input: ElementRef
	displayedColumns: string[]
	sortStatus: Sort
	pageStatus: PageEvent
	// @Output() reportPref: TablePrefs
	localStorageName: string

	// currentPatient: Patient
	hgReportListSubscription: Subscription
	faSave = faSave // per usarla su html  13.01.2022
	faBan = faBan

	constructor(
		public session: SessionService,
		// public anamnesisService: AnamnesisService,
		private reportsService: reportsService,
		public activeModal: NgbActiveModal,
		public translator: TranslateService,
		public dataService: DataModelService,
		public myMatPagIntl: MatPaginatorIntl,
		private activatedRoute: ActivatedRoute,
		private toastService: AppToastService
	) {
		Util.debug('[ReportList] constructor')

		this.reportListTable = new MatTableDataSource<Report>()
		this.targetReport = 0
	}

	ngOnInit(): void {
		Util.debug('[ReportList] onInit')

		this.initColumns()

		this.loadUrlParameters()
		this.initTable()

		this.hgReportListSubscription = this.dataService.reportListChanged.subscribe((resp) => {
			Util.debug('reportListChanged for paId: ' + resp.patId + 'repList length: ' + resp.reports.length)

			if (resp.patId === this.targetPatient.id) {
				this.reportList = resp.reports
				if (!this.session.isSpecialist()) {
					for (let rep of this.reportList) {
						if (!rep.refDisplayName || rep.refDisplayName == '') {
							let refId = rep.created_by
							rep.refDisplayName = this.session.getSpecialistName(refId)
						}
					}
				}
				this.initTable()
			}
		})

		this.myMatPagIntl.itemsPerPageLabel = this.translator.instant('PAGING.ITEMS_PER_PG')
	}

	//Inizializzazione colonne tabella
	initColumns() {
		this.displayedColumns = ['report_id', 'creation_date']

		if (!this.session.isSpecialist()) {
			this.displayedColumns.push('created_by')
		}

		if (this.session.isLevel3()) {
			this.displayedColumns.push('status')
		}

		if (this.session.isLevel3() || this.session.isSupport()) {
			this.displayedColumns.push('diagn_group')
		}

		if (!this.session.isSupport()) {
			this.displayedColumns.push('download')
		}
	}

	private initTable() {
		if (this.reportList != null) {
			// console.log(this.reportList)
			this.reportListTable = new MatTableDataSource<Report>()
			this.reportListTable.data = this.reportList
			this.reportListTable.paginator = this.paginator
			this.reportListTable.sort = this.sort

			this.sort.active = 'creation_date'
			this.sort.direction = 'desc'
			this.sort.sortChange.emit()

			// automatic sort
			// this.ApplySettings(this.reportPref, this.reportListTable)
		} else {
			Util.debug('(Reports) null rep list!')
		}
	}

	loadUrlParameters() {
		if (this.activatedRoute != null) {
			let report = this.activatedRoute.snapshot.queryParams['report']
			if (report != null && parseInt(report) > 0) {
				this.targetReport = parseInt(report)

				let rep: Report = this.reportList.find((x) => x.id == this.targetReport)
				// console.log(rep)

				if (rep) {
					this.downloadReport(rep)
				}
			}
		}
	}

	downloadReport(report: Report) {
		if (!report.is_waiting_PDF) {
			Util.debug('[ReportList](downloadReport) inizio')
			report.is_waiting_PDF = true

			this.session
				.getReport(report.id)
				.then((myRep: FullReport) => {
					// console.log(myRep)

					this.reportsService
						.printPDF(myRep, ReportType.DIAGNOSIS)
						.then((resp) => {
							// console.log(resp)
							report.is_waiting_PDF = false
							this.updateReportStatus(report, ReportStatus.STATUS_READ)
						})
						.catch((err) => {
							report.is_waiting_PDF = false

							let header = this.translator.instant('TOAST.HEADER.WARNING')
							let body = this.session.parseErrorMessage(err)
							let options = new ToastOptions('error')

							this.toastService.show(header, body, false, options, 'center')
						})
				})
				.catch((error) => {
					console.log('(getFullReport) error!')
					console.log(error)
					let header = this.translator.instant('TOAST.HEADER.WARNING')
					let body = this.session.parseErrorMessage(error)
					let options = new ToastOptions('error')

					this.toastService.show(header, body, false, options, 'center')
				})
		} else {
			return
		}
	}

	passBack() {
		Util.debug('[ReportList] modal closed!')
		this.activeModal.close()
	}

	// 12.06.2020 aggiorno lo stato del report, se era "new" -> "downloaded"
	private updateReportStatus(report: Report, newStatus: ReportStatus) {
		let originalStatus: ReportStatus = report.status

		if (!this.session.isLevel1()) return

		this.session.updateReport(report, newStatus).then((response) => {
			//console.log(response);
			Util.debug('(updateReportStatus) OK done for rep ' + report.id)
			// this.updatedRepStatus = true
			if (originalStatus == ReportStatus.STATUS_NEW) {
				// aggiorno l'info sul paziente in modo da aggiornare lo stato dei nuovi report
				let obj: { prop: string; value: any }[] = []
				let value = this.targetPatient.tot_new_report
				if (value && value > 0) {
					value--
				}
				obj.push({ prop: 'tot_new_report', value: value })

				this.dataService.editPatientInfo(this.targetPatient.id, obj)
			}
		})
	}

	ngOnDestroy(): void {
		if (this.hgReportListSubscription) {
			Util.debug('unsubscribing hgReportListSubscription')
			this.hgReportListSubscription.unsubscribe()
		}
	}
}
