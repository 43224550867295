import { Component, OnInit, Input, SimpleChanges, OnDestroy } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { AppToastService } from 'src/app/service/toast.service'
import { canvasService } from 'src/app/service/offcanvas.service'
//import { ActivatedRoute } from '@angular/router';  // 03.01.2022

import {
	faUsers,
	faUserMd,
	faUserShield,
	faLaptopHouse,
	faUserPlus,
	faStethoscope,
	faUserTie,
	faShieldAlt,
	faAngleDoubleUp,
	faChartLine,
	faLink,
	faLaptopMedical,
	faUnlock,
	faCoins,
	faWallet,
	faRightFromBracket,
	faMap,
	faDatabase,
} from '@fortawesome/free-solid-svg-icons'

import { faBell, faEye, faArrowAltCircleDown, faUserCircle } from '@fortawesome/free-regular-svg-icons'
//import { amazon } from '@fortawesome/free-brands-svg-icons';

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'

import { UserType } from '../../models/user.model'
import { Language, Section } from '../../models/navbar.model'
import { Util } from 'src/app/models/util.model'
import { IsActiveMatchOptions } from '@angular/router'
import { reportsService } from 'src/app/service/reports.service'
import { Toast, ToastOptions } from 'src/app/models/toast.model'
import { Subscription } from 'rxjs'

@Component({
	selector: 'header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	profileStateSubscription: Subscription
	isProfileComplete: boolean

	languages: Language[]
	sections: Section[]

	currentSection: IsActiveMatchOptions = {
		fragment: 'ignored',
		matrixParams: 'subset',
		paths: 'subset',
		queryParams: 'subset',
	} //03.10.22

	@Input() logged: boolean

	counter: number
	serviceCounter: number

	userLoggedSubscription: Subscription
	//translateProvider: any;
	//location: any;
	currentUrl: string // 03.01.2022

	// elenco di tutte le icon usate ?!
	//faCoffee = faCoffee;
	faUnlock = faUnlock // per usarla su html
	faUserCircle = faUserCircle
	faUserShield = faUserShield
	faBell = faBell
	faCoins = faCoins
	faWallet = faWallet
	faRightFromBracket = faRightFromBracket
	faMap = faMap
	faDatabase = faDatabase

	constructor(
		public session: SessionService,
		private translator: TranslateService, //private activatedRoute: ActivatedRoute
		public canvasService: canvasService,
		private toastService: AppToastService,
		private reportsService: reportsService
	) {
		Util.debug('[Header] - constructor')

		this.languages = []
		//
		//this.initLanguages();  // 30.03.2022 spostato su session
		this.initSections()
		this.counter = 0
		this.serviceCounter = 0
		this.isProfileComplete = true
	}

	// pippo(){ //test per menu

	//   const elItem = document.querySelectorAll('.nav-item');

	//   console.log(elItem);
	// }

	ngOnInit(): void {
		Util.debug('[Header] - ngOnInit')

		// this.initSections()
		if (this.session.userLogged.value) {
			this.onceLogged()
		}

		this.userLoggedSubscription = this.session.userLogged.subscribe((resp: boolean) => {
			if (resp) {
				this.onceLogged()
			}
		})

		// 07-04-23 tomas-17737
		this.initCounter()

		// qui vuoto
		/*
		this.currentUrl = this.activatedRoute.snapshot['_routerState'].url;    
		this.currentUrl = window.location.href;
		this.currentUrl = window.location.pathname;
		console.log("[Header] - currUrl: "+this.currentUrl);  
		*/
		Util.debug('[Header] - url: ' + window.location.pathname)
		this.currentUrl = window.location.pathname

		this.session.setCreatedRelationAuxFunc((isSwitchingGrader) => {
			const header = this.translator.instant('TOAST.RELATION')
			const body = this.translator.instant(isSwitchingGrader ? 'TOAST.NOTIFICATIONS.RELATION_CREATED_SWITCHING' : 'TOAST.NOTIFICATIONS.RELATION_CREATED')
			const options = new ToastOptions('info_blue')

			this.toastService.show(header, body, false, options, 'center')
		})

		this.profileStateSubscription = this.session.checkProfileComplete.subscribe((val: boolean) => {
			// console.log('Profile updated' + val)

			this.isProfileComplete = val
		})
	}

	// 07-04-23 tomas-17737
	private initCounter() {
		this.canvasService.open.subscribe((res) => {
			if (res == 'add') {
				this.counter = this.canvasService.getNotificationNumber()
				// console.log(this.counter)
				if (this.session.isOptician()) {
					this.serviceCounter = this.session.user.hasPurchasedServices().tot
				}
			}

			if (this.session.isOptician() && res == 'update') {
				this.serviceCounter = this.session.user.hasPurchasedServices().tot
			}
		})
	}

	private initSections() {
		Util.debug('(header) - init section')
		//gq 22.05.2017 modificato section per prendere css selected

		this.sections = []

		this.sections.push(
			Section.createSection(
				'createUser',
				() => {
					this.onSectionClick('createUser')
				},
				'NAVBAR.CREATE',
				'icon-login_icon',
				[UserType.GOD, UserType.VICE],
				faUserPlus
			)
		)

		// 20.12.2022 patch temporanea
		//if (this.session.isDev()) {  // 12.01.2023 tolta limitazione
		// 06.12.2022 per carico info da files di SAP, sia import utenti che crediti e piani, etc.
		this.sections.push(
			Section.createSection(
				'sap',
				() => {
					this.onSectionClick('sap')
				},
				'NAVBAR.SAP',
				'',
				[UserType.GOD],
				faUserShield
			)
		)
		//}

		this.sections.push(
			Section.createSection(
				'dbsettings',
				() => {
					this.onSectionClick('dbsettings')
				},
				'NAVBAR.DATABASE_SETTINGS',
				'',
				[UserType.GOD],
				faDatabase
			)
		)

		//"data", () => {this.onSectionClick("createUser")}, "NAVBAR.CREATE", "icon-login_icon", [UserType.GOD]));

		this.sections.push(
			Section.createSection(
				'dashboard',
				() => {
					this.onSectionClick('dashboard')
				},
				'NAVBAR.DASHBOARD',
				'icon-list_icon',
				[UserType.CLINIC],
				faChartLine
			)
		) // alternativa: faUserShield

		this.sections.push(
			Section.createSection(
				'locations',
				() => {
					this.onSectionClick('locations')
				},
				'NAVBAR.LOCATIONS',
				'icon-list_icon',
				[UserType.CLINIC],
				faMap
			)
		) // alternativa: faUserShield

		this.sections.push(
			Section.createSection(
				'admins',
				() => {
					this.onSectionClick('admins')
				},
				'NAVBAR.ADMINS',
				'icon-list_icon',
				[UserType.GOD],
				faShieldAlt
			)
		) // alternativa: faUserShield

		// ex distributors
		this.sections.push(
			Section.createSection(
				'remotes',
				() => {
					this.onSectionClick('remotes')
				},
				'NAVBAR.GRADERS',
				'',
				[UserType.GOD, UserType.VICE, UserType.STATS, UserType.SUPPORT], // , UserType.SUPPORT TODO
				faStethoscope
			)
		) // faUserMd

		// da spostare once logged come per telerefract // non funziona
		this.sections.push(
			Section.createSection(
				'operators',
				() => {
					this.onSectionClick('operators')
				},
				'NAVBAR.DOCTORS',
				'',
				[UserType.GOD, UserType.VICE, UserType.STATS, UserType.SUPPORT, UserType.INSTALLER],
				faUserTie
			)
		) // AwesomeIcon  14.01.2022

		// 04.02.2021
		this.sections.push(
			Section.createSection(
				'colleagues',
				() => {
					this.onSectionClick('colleagues')
				},
				'NAVBAR.COLLEAGUES',
				'icon-list_icon',
				[UserType.SUB_SUPER] // 18.04.2023 rimossi i mini, UserType.SUB_MINI - il superSupport ?
			)
		)

		// 24.05.2023
		this.sections.push(
			Section.createSection(
				'graders',
				() => {
					this.onSectionClick('graders')
				},
				'NAVBAR.GRADERS',
				'icon-list_icon',
				[UserType.CLINIC],
				faStethoscope
			)
		)

		/*  04.05.2022 
   this.sections.push(Section.createSection(
      "relationsList", () => {this.onSectionClick("relationsList")}, "NAVBAR.RELATIONS", "", 
                        [UserType.GOD, , UserType.VICE],
                        faLink));
    */

		// 22.01.2021
		this.sections.push(
			Section.createSection(
				'statistics',
				() => {
					this.onSectionClick('statistics')
				},
				'NAVBAR.STATISTICS',
				'',
				[UserType.GOD, UserType.STATS, UserType.SUPPORT],
				faChartLine
			)
		)

		// 08.03.2021
		this.sections.push(
			Section.createSection(
				'devices',
				() => {
					this.onSectionClick('devices')
				},
				'NAVBAR.DEVICES',
				'',
				[UserType.GOD, UserType.MANAGER, UserType.SUPPORT], // , UserType.SUPPORT TODO, come li filtro ? gestito sotto solo il super
				faLaptopMedical
			)
		) // TODO UserType.STATS ?

		// 18.03.2021
		this.sections.push(
			Section.createSection(
				'updates',
				() => {
					this.onSectionClick('updates')
				},
				'NAVBAR.UPDATES',
				'',
				[UserType.GOD, UserType.MANAGER],
				faAngleDoubleUp
			)
		)

		this.sections.push(
			Section.createSection(
				'patients', // key
				() => {
					this.onSectionClick('patients')
				}, // callback
				'NAVBAR.PATIENTS', // name to be translated
				'', // glyphClass - old
				[UserType.OPERATOR, UserType.CLINIC, UserType.DEALER], // visibleBy
				faUsers // AwesomeIcon  03.01.2022
			)
		)

		// quando la crea e' troppo presto, ritorna false
		/*
      if(this.logged && this.session.isTelerefractEnabled()){  
        this.sections.push(Section.createSection(
          "telerefract",                               // key
          () => {this.onSectionClick("telerefract")},  // callback
          "NAVBAR.TELEREFRACT",                        // name to be translated 
          "",                         
          [UserType.OPERATOR, UserType.DEALER],     // visibleBy 
          faLaptopHouse                             // AwesomeIcon  03.01.2022
          )
        );
      }
      */
		// console.log(this.sections)
		// console.log(UserType.CLINIC)
		// this.sections.push(Section.createSection(
		//  "profile", () => {this.onSectionClick("profile")}, "NAVBAR.PROFILE", "icon-login_icon", [UserType.DEALER, UserType.OPERATOR]));
	}

	//(onLoggedChange)="onceLogged()"

	// ngOnChanges(change: SimpleChanges) {
	// 	if (change['logged']) {
	// 		let currValue = change['logged'].currentValue
	// 		let prevValue = change['logged'].previousValue

	// 		//Util.debug("(header) - onChange logged curr: "+currValue+ " prev: "+prevValue); // ok

	// 		if (currValue == true) {
	// 			this.onceLogged()
	// 		}
	// 	}
	// }

	// TODO, come richiamarla ?!
	onceLogged() {
		Util.debug('(Header) - once logged')

		if (this.session.isTelerefractEnabled()) {
			// sui settings utente

			let telerefrSect = Section.createSection(
				'telerefract', // key
				() => {
					this.onSectionClick('telerefract')
				}, // callback
				'NAVBAR.TELEREFRACT', // name to be translated
				'',
				[UserType.OPERATOR, UserType.DEALER, UserType.INSTALLER], // visibleBy
				faLaptopHouse // AwesomeIcon  03.01.2022
			)

			Util.debug('(header) adding telerefract ')
			this.addSection(telerefrSect)
		} else {
			// 30.09.2022 va tolta se ero loggato con un utente che l'aveva 'on'
			// capita solo a noi in test ??
			this.removeSection('telerefract')
		}

		// 20.04.2023 abilito DEVICES solo per il superSupport
		if (this.session.isSupport()) {
			if (!this.session.user.isSuper()) {
				//Util.debug('(header) hide devices...')
				this.removeSection('devices')
			} else {
				this.removeSection('statistics')
			}
		}

		// console.log(this.session.isMiniC())

		if (this.session.isMiniC()) {
			this.removeSection('doctors') // non so xk bisogna chiamare doctors e non operators
		}
	}

	// 29.04.2022
	removeSection(sectName: string) {
		if (this.sections != null) {
			for (let i = 0; i < this.sections.length; i++) {
				//let currSect :Section;
				let currSect = this.sections[i]

				if (currSect.key == sectName) {
					this.sections.splice(i, 1) // la rimuove
					break
				}
			}
		}
	}

	// 29.04.2022
	addSection(sect: Section) {
		if (this.sections != null) {
			let index = this.sections.findIndex((x) => x.key == sect.key)
			if (index < 0) {
				this.sections.push(sect)
			}
		} else {
			this.sections = []
			this.sections.push(sect)
		}
	}

	// TODO, forzare ricalcolo quando si cambia pg cliccando su tabella
	isCurrent(key: string) {
		let flag = false
		// 03.01.2022

		// qui tutti validi, su onInit no
		//this.currentUrl = this.activatedRoute.snapshot['_routerState'].url;
		//console.log("H [isCurrent] - snapshot: "+this.currentUrl);  // /patients

		// window.location.href;
		//  http://192.168.1.44:4200/patients

		this.currentUrl = window.location.pathname

		Util.debug('current url' + this.currentUrl)
		// /patients

		// per modale reportList, vale ancora visits (parent window)

		//flag = (this.location.path().indexOf(key) >= 0);
		flag = this.currentUrl.indexOf(key) >= 0
		return flag
	}

	getLanguage() {
		//console.log("H (getLanguage)");
		//return this.translateProvider.use();
		let lang = this.session.getLanguage()
		if (lang == null || lang == '') lang = 'en'
		return lang
	}

	onLanguageClick(language: string) {
		//console.log("clicked language: " + language);
		this.session.setLanguage(language)
	}

	onSectionClick(section: string) {
		Util.debug('(onSectionClick) clicked section: ' + section)

		// 08.09.2021 andrebbero gia' bene quasi tutti con il default,
		// lo switch dovrebbe servire solo per le eccezioni

		var route
		switch (section) {
			case 'doctors':
				route = Config.doctors
				break

			case 'patients':
				route = Config.patients
				break

			case 'statistics': // 25.01.2021
				route = Config.statistics
				break

			case 'colleagues': // 04.02.2021
				route = Config.colleagues
				break

			case 'graders': // 24.05.2023
				route = Config.graders
				break

			case 'devices': //08.03.2021
				route = Config.devices
				break

			case 'updates': //18.03.2021
				route = Config.updates
				break

			case 'dashboard':
				route = Config.dashboard
				break

			case 'locations':
				route = Config.locations
				break

			/*
      case "templates":   // 03.07.2019
        route = Config.templates;
        break;  
      
      */
			case 'subscription': //03-05-23
				route = Config.subscription
				break

			// 08.09.2021
			case 'profile':
				if (this.session.firstLoginRequired()) {
					//var msg = "Please complete activation (first login) procedure before any other activity";
					let msg = this.translator.instant('PROFILE.WARNING_ACTIVATION')
					alert(msg)
					return
				} else {
					route = Config.profile
				}
				break

			// 29.04.2022
			case 'telerefract':
				if (this.session.isTelerefractEnabled()) {
					route = Config.telerefract
					//return this.openTelerefract();   // open on another window
				} else {
					//let msg = "This option is not enabled for your profile. Please contact the support team for more info.";
					let msg = this.translator.instant('TELEREFRACT.WARNING_DISABLED') // 06.06.2022
					alert(msg)
				}
				break

			default:
				route = '/' + section
		}

		// 23.03.2022
		this.isCurrent(section) //per cambiare colore alle icone

		// TEMP TODO
		//this.location.url(route);
		//this.location.replace();
		this.session.loadMyPage(route) // 05.01.2022
	}

	private DISAB_test(num) {
		alert('(test) ' + num)
	}

	logout() {
		// console.log(this.session.isInitAfterLogin())

		if (!this.session.isInitAfterLogin()) {
			this.canvasService.clear() // delete all notifications on canvas
			this.toastService.init = false // setto la variabile a false, cosí alla prossima login se non chiudo la pagina, mi ricarica le notifiche
			this.counter = 0 // counter notifiche = 0
			this.toastService.clear() // se faccio logout con la notifica aperta, rimuovo tutto
			this.toastService.onlyCredits = false // prevent bug: notifications not popping out on logout and re-login (without refreshing)
			this.reportsService.clearAllFundusList() // cancello dalla memoria le fundus richieste
			this.initSections() // rimuovendo operators per i miniC, se io entro come miniC e dopo con un utente che vede operators, questo non si vede, xk la header é stat inizializzata solo 1 volta all'inizio, per cui ad ogni logout la reinizializzo nuova
			this.session.logout()
		}
	}

	ngOnDestroy(): void {
		this.profileStateSubscription.unsubscribe()
		this.userLoggedSubscription.unsubscribe()
	}
}
