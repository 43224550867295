<div class="panel panel-modal panel-default ng-cloak position-relative impact-container">
	<loader [activateSpinner]="isLoading"></loader>

	<div class="select-dates-container">
		<select (change)="onChangeDate($event)" name="selectedDate" id="selectedDate" #selectedDate [disabled]="isLoading">
			<option value="{{date}}" *ngFor="let date of availableDates">{{formatDateString(date)}}</option>
		</select>
	</div>

	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
	</div>

	<div *ngIf="!isLoading && impactReportData">
		<div class="row mt-3 mb-3">
			<div class="datafield col-auto">
				<span>{{ 'PATIENT.INFO' | translate }}</span>
			</div>
			<field class="field col-auto" name="{{ 'PATIENT.NAME' | translate }}" value="{{ patient.name }}"></field>
			<field class="field col-auto" name="{{ 'PATIENT.CODE' | translate }}" value="{{ patient.code }}"></field>
			<field class="field col-auto" name="{{ 'PATIENT.AGE' | translate }}" value="{{ patient.birthDate | age }}"> </field>
			<field class="field col-auto" name="{{ 'PATIENT.SEX' | translate }}" value="{{ 'SEX.' + patient.sex | translate }}"></field>
			<field class="field col-auto" name="{{ 'PATIENT.PERSONAL_ID' | translate }}" value="{{ patient.personal_id}}"></field>
		</div>

		<!-- REFRACTION SECTION -->
		<div class="section-container" *ngIf="leftRefractionEyeData || rightRefractionEyeData">
			<div class="section-header">
				<h5 class="section-header-title mb-0">{{"IMPACT.SECTION_TITLE_REFRACTION" | translate}}</h5>
				<fa-icon [icon]="faChevronDown" faChevronDown [ngClass]="showRefraction ? 'show' : '' " aria-hidden="true" (click)="showRefraction = !showRefraction"></fa-icon>
			</div>
			<div class="row mt-3" *ngIf="showRefraction">
				<div class="col-6">
					<table class="refraction-table">
						<thead>
							<tr *ngIf="leftRefractionEyeData">
								<th>{{ 'MISC.LEFT' | translate }}</th>
								<th>{{ 'REFRACTION.REAL_PUPIL_SIZE' | translate }}</th>
								<th>{{ 'REFRACTION.SPHERE' | translate }}</th>
								<th>{{ 'REFRACTION.CYLINDER' | translate }}</th>
								<th>{{ 'REFRACTION.AXIS' | translate }}</th>
							</tr>
						</thead>
						<tr *ngIf="leftRefractionEyeData">
							<td>{{ 'EXAMS.DAY_PUPIL' | translate }}</td>
							<td>{{ leftRefractionEyeData.pupil_real_size_day + ' mm' }}</td>
							<td>{{ leftRefractionEyeData.sphere_day + ' DS' }}</td>
							<td>{{ leftRefractionEyeData.cylinder_day + ' DC' }}</td>
							<td>{{ leftRefractionEyeData.axis_day + '°' }}</td>
						</tr>
						<tr *ngIf="leftRefractionEyeData">
							<td>{{ 'EXAMS.NIGHT_PUPIL' | translate }}</td>
							<td>{{ leftRefractionEyeData.pupil_real_size_night + ' mm' }}</td>
							<td>{{ leftRefractionEyeData.sphere_night + ' DS' }}</td>
							<td>{{ leftRefractionEyeData.cylinder_night + ' DC' }}</td>
							<td>{{ leftRefractionEyeData.axis_night + '°' }}</td>
						</tr>
					</table>
				</div>
				<div class="col-6">
					<table class="refraction-table refraction">
						<thead>
							<tr *ngIf="rightRefractionEyeData">
								<th>{{ 'MISC.RIGHT' | translate }}</th>
								<th>{{ 'REFRACTION.REAL_PUPIL_SIZE' | translate }}</th>
								<th>{{ 'REFRACTION.SPHERE' | translate }}</th>
								<th>{{ 'REFRACTION.CYLINDER' | translate }}</th>
								<th>{{ 'REFRACTION.AXIS' | translate }}</th>
							</tr>
						</thead>
						<tr *ngIf="rightRefractionEyeData">
							<td>{{ 'EXAMS.DAY_PUPIL' | translate }}</td>
							<td>{{ rightRefractionEyeData.pupil_real_size_day + ' mm' }}</td>
							<td>{{ rightRefractionEyeData.sphere_day + ' DS' }}</td>
							<td>{{ rightRefractionEyeData.cylinder_day + ' DC' }}</td>
							<td>{{ rightRefractionEyeData.axis_day + '°' }}</td>
						</tr>
						<tr *ngIf="rightRefractionEyeData">
							<td>{{ 'EXAMS.NIGHT_PUPIL' | translate }}</td>
							<td>{{ rightRefractionEyeData.pupil_real_size_night + ' mm' }}</td>
							<td>{{ rightRefractionEyeData.sphere_night + ' DS' }}</td>
							<td>{{ rightRefractionEyeData.cylinder_night + ' DC' }}</td>
							<td>{{ rightRefractionEyeData.axis_night + '°' }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>

		<h4 class="sections-head-title">{{"IMPACT.RESULTS_TITLE" | translate}}</h4>
		<div *ngFor="let section of impactReportData.impact.sections">
			<!-- SLIDERS SECTIONS -->
			<div class="section-container" *ngIf="!['refraction'].includes(section.title)">
				<div class="section-header">
					<h5 class="section-header-title">{{"IMPACT.SECTION_TITLE_" + section.title.toUpperCase() | translate}}</h5>
					<div class="col-2 section-header-buttons-container">
						<a [href]="section.read_more_url" target="_blank"><button class="btn btn-primary bl-bg">{{"IMPACT.READ_MORE_BTN" | translate}}</button> </a>
					</div>
				</div>

				<div class="slider-topic-container" *ngFor="let topic of section.topics">
					<div class="col-2">
						<p class="topic-title">{{"IMPACT.TOPIC_TITLE_" + topic.title.toUpperCase() | translate}}</p>
					</div>
					<div class="col-3" *ngFor="let eye of topic.eyes">
						<p class="eye-label">{{"IMPACT.EYE_LABEL_" + eye.eye.toUpperCase()| translate}}</p>
						<p class="eye-exam-date">{{formatDateString(eye.exam_date)}}</p>
						<app-impact-slider *ngIf="eye.decrValue" [rangeMin]="topic.range_low" [rangeMax]="topic.range_high" [sliderValue]="eye.decrValue" [decimalDigits]="getMaxDecimals(topic.range_low, topic.range_high, eye.decrValue)"></app-impact-slider>
					</div>
					<div class="col-2 topic-buttons-container">
						<button class="btn btn-primary gr-bg" [disabled]="availableDates.length <= 1 || selectedDate.value === oldestAvailableDate" (click)="openTopicHistory(section, topic)">{{"IMPACT.HISTORY_BTN" | translate}}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
