import { Injectable } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http'
import { DatePipe } from '@angular/common'

// 16.05.2022
//import { TranslateService} from '@ngx-translate/core';
import { LocalizationService } from '../internationalization/localization.service'

import { Config } from '../../config'
import { CryptoUtilsService } from './crypto-utils.service'
import { DataModelService, DataStatus } from './data-model.service'
//import { TableSettings } from './table.settings' // 14.06.2022

import * as forge from 'node-forge' // per le keybox
import * as b64images from '../models/b64images'
import * as Sentry from '@sentry/angular-ivy'

// MODELS:
import { User, TokenResponse, UserType, LoggedUser, SessionStatus, CookieUser, editsInfo, editAddress, editReport, UserDevice } from '../models/user.model'
import { ProfileJson, ProfileRequest, ProfilesResponse } from '../models/profile.model'
import { Doctor } from '../models/doctor.model'
import { Admin } from '../models/admin.model'
import { Patient, PatientJson, PatientResponse, PendingPatient, PendingPatientsResponse, privDataDecryptedT } from '../models/patient.model'
import { Address, userLocation } from '../models/address.model'
import { Settings, AdmSettings, Preferences } from '../models/settings.model'
import { Language } from '../models/navbar.model'
import { Visit } from '../models/visit.model'

import { Util } from '../models/util.model'
import { FullReport, Report, ReportStatus } from '../models/report.model'
import { Specialist, Relation, Distrib, relationsStatus } from '../models/specialist.model'
import { Prescription, SrvDiagnosis } from '../models/diagnosis.model'
import { Country, DBCountrySettings } from '../models/countries.models' // 08.06.2022
import { Exam } from '../models/exam.model'

import { AiImage, AiReport } from '../models/aiReport.model'

//import {BalanceModal} from '../models/balance.modal';
import { SalePlan, BalanceRec, SaleInfo, service, serviceMode, serviceType, skuArticles } from '../models/salePlan.model'
import {
	CandidateDevices,
	ComponentType,
	Device,
	DevicesType,
	OsType,
	SwUpdate,
	UserDevices,
	pairingDevices,
	deviceTargetUpdate,
	deviceUpdateStatus,
} from '../models/device.model'
import { AgrStatus, AgrType, Agreement, AgreementInfo, AgreementsStatus } from '../models/agreement.model'
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs'
import { environment } from 'src/environments/environment'
import { DateAdapter } from '@angular/material/core'
import { HubConnection } from '@microsoft/signalr'
import { levenshteinEditDistance } from 'levenshtein-edit-distance'
import { PendingPatientConflictModalContent } from '../component/patients/pendingPatientConflict.modal'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { DateParser } from '../models/dateParser.model'
import { buildResponse } from '../component/login/login.component'
import { supportTask } from '../models/changeGrader.model'
import { Events, UserEvents } from '../models/userEvents.model'
import { Statistics } from '../models/statistics.model'
import { purchasedService } from '../component/sap/sap.component'
import { Documents } from '../models/documents.model'

@Injectable({
	providedIn: 'root',
})
export class SessionService {
	// usato behaviopusSubject, in quanto quando viene creato il profileComponent, potrebbe essere che il next sia giá stato chiamato, con BS si puó recuperare l'ultimo valore emesso dall'observable
	public checkProfileComplete: BehaviorSubject<boolean>
	public userLogged: BehaviorSubject<boolean>
	private patientListStatusSubscribe: Subscription
	public servicesUpdatedStatus: Subject<service[]>

	public creditsUpdatedStatus: Subject<number>

	public nexusBuils: buildResponse[]

	private countryDBSettings: DBCountrySettings[]

	public testLocali = false // per rilasci intermedi su Luneau
	//public testLocali = true;   // per test sulla .42

	public user: User
	private loggedUser: LoggedUser // 22.02.2017
	private status: SessionStatus

	//private currentEye: string
	//private current_window: string
	private currentDoctorId: number // 14.01.2022
	private currentPatientId: number // 23.01.23

	private targetPatient: number // 31.05.2022 per accesso diretto
	private targetReport: number // 07.07.2022
	private targetAiReport: number // 24.08.2022
	private targetBalance: boolean
	private targetPlan: boolean //11.12.2023
	private targetPatientEdit: boolean
	private targetPatientEditAnam: boolean

	public languages: Language[] // 30.03.2022 spostato da headers
	public lang: string
	public lang_suffix: string

	//private filter // 11.06.2020 cambio nome

	//private location; //11.12.2019

	//private nsPassword = 'NextSight2017' // pwd operativa, per azioni "gravi", tipo delete

	//private progressBar // 24.02.2021 serve ?

	private tempToken: string //  30.04.2018
	//private isRedir: boolean;   // 11.12.2019

	private isLoading: boolean // 04.01.2020

	public initAL: boolean

	// TODO, tenere valori allineati con il DB
	public brands = [Config.BR_DEFAULT, Config.BR_CLALIT, Config.BR_RDS, Config.BR_ESSILOR, Config.BR_ZEISS]

	public usrSubtypes // ['Std','Super','Mini','Demo','Test'];  // 28.07.2021 sostituito Device con Test, piu' chiaro [ls]
	public usrSubtypesNoMini // per doctors
	public usrSubtypesOpts: string[] // per opts
	public usrSubtypesSpec: string[] // per specialists  30.08.2022
	public usrSubtypesInstaller: string[] // per installers  24.10.2023

	// public fixedModels = [DevicesType.VX650, DevicesType.VX610, DevicesType.VX120, DevicesType.VX65, DevicesType.ER, DevicesType.DNEye3]
	public userDevices: UserDevices[]
	public deviceModels: string[] = []

	public eyes = ['left', 'right', 'bino'] // 30.08.2021

	public yesNoOptions = ['Y', 'N'] // per tutte le tendine

	public freeTrialOptions = ['Y', 'N', 'U']

	// usare costanti su Config,
	//public examTypes = ['topo', 'pachymult', 'extphoto', 'dryeye', 'pachy', 'tono', 'wf', 'retro', 'fundus', 'subjective', 'lensmeter'];
	public examTypes = Config.EXAM_TYPES

	public KEYBOX_LEN = 88 // 30.04.2020 era 64, ora c'e' iv davanti
	public KEYBOX_PUK_MINIC_LEN = 2304 // 03.07.2023
	//public KEYBOX_VICE_LEN = 44; // 29.09.2021 len della keyBoxVice e' variabile
	public MIN_KEYBOX_VICE_LEN = 8 // 05.10.2021 lunghezza minima

	public ASYM_RSA_ALG = 'RSAES-PKCS1-V1_5' // per crittografia asimmetrica, con public e private key

	// 17.05.2021 campo val corrisponde alla enum sul DB
	//public dicomLevels = ["No", "Find&Store", "MWL"];
	public dicomLevels = [
		{ val: 'N', descr: 'No' },
		{ val: 'FS', descr: 'Find&Store' },
		{ val: 'MWL', descr: 'MWL' },
	]

	public aiTypes = [
		{ val: 'none', descr: 'none - disabled' },
		{ val: 'dss', descr: 'dss - diabetic' },
		{ val: 'mcs', descr: 'mcs - full' },
	]

	// 14.10.2021 diagnosis_group values
	public diagnosisGroups = [
		{ val: 0, descr: Config.DIAGN_GRP_0 },
		{ val: 1, descr: Config.DIAGN_GRP_1 },
		{ val: 2, descr: Config.DIAGN_GRP_2 },
	]

	// 17.08.2021 poi prende dal DB, questo base in caso di errori o lista vuota
	public basicSalePlan = { id: 1, name: SalePlan.SALE_BASIC, description: 'free platform usage' }
	public salePlans = []

	public countries: Country[]
	public infoBridgeAvailable: boolean //usata x skippare l'inserimento obbligatorio dell'indirizzo in caso di bridge non raggiungibile

	private userShouldGoToEcommerce: boolean
	//private timeout,
	//private rootScope,
	//private cookies: CookieService,  // usiamo sessionStorage

	skuArticles: Map<string, skuArticles>

	constructor(
		private http: HttpClient,
		private data: DataModelService,
		public cryptoUtils: CryptoUtilsService,
		private routing: Router,
		private activatedRoute: ActivatedRoute,
		public datepipe: DatePipe,
		private localizService: LocalizationService, //private tableSettings: TableSettings //private translator: TranslateService
		private dateAdapter: DateAdapter<Date>,
		private modalService: NgbModal // private signalR: SignalR
	) {
		window['SESSION'] = this

		this.checkProfileComplete = new BehaviorSubject<boolean>(null)
		this.userLogged = new BehaviorSubject<boolean>(null)
		this.servicesUpdatedStatus = new Subject<service[]>()
		this.creditsUpdatedStatus = new Subject<number>()

		this.nexusBuils = []

		this.countryDBSettings = []

		//this.filter = null // TODO $filter;  // 14.06.2017
		this.user = new User(null) // .createFakeUser(UserType.NONE);
		this.status = SessionStatus.NOT_LOGGED

		this.initAL = false // usata per capire quaando sta facendo la initAfterLogin

		this.loggedUser = new LoggedUser() // 18.11.2021 serve davvero?

		this.lang = 'en' // 19.06.2017 fix
		this.tempToken = '' // 30.04.2018

		//this.progressBar = 0 // 18.04.2019
		this.isLoading = false
		this.userDevices = []
		this.currentDoctorId = 0
		this.currentPatientId = 0 // 23.01.23

		this.targetPatient = 0 // 31.05.2022 per accesso diretto
		this.targetReport = 0
		this.targetAiReport = 0
		this.targetBalance = false
		//this.connection = null
		this.targetPlan = false
		this.targetPatientEdit = false
		this.targetPatientEditAnam = false

		Util.debug('S (constructor)')

		this.initGlobals() // 24.08.2022 verificare doppioni?

		// 16.05.2022
		let savedLang = this.localizService.getSavedLang()
		if (savedLang != null && savedLang.length >= 2) {
			this.lang = savedLang.substring(0, 2)
			Util.debug('S (constructor) lang: ' + this.lang)
		}

		// 28.04.2022 gestione token temp per recover con puk
		this.loadTempTokenFromUrlParameters()

		this.initLanguages() // 30.03.2022

		let lang = this.localizService.getSavedLang()
		this.setLanguage(lang)

		// on demand ?
		//this.initCountries();  // 30.05.2022

		// 16.09.2021
		this.initSubtypes()

		// 17.11.2021 uso sessionStorage al posto dei cookies [ls]
		// var savedUser: CookieUser; // = null;
		//var savedUser = this.cookies.getObject("user");
		if (window.sessionStorage) {
			this.restoreUserFromStorage()
		}

		this.infoBridgeAvailable = true

		this.userShouldGoToEcommerce = false

		this.skuArticles = new Map<string, skuArticles>()
	}

	// 31.01.2023 portata fuori, con decrypt della pwd
	private restoreUserFromStorage() {
		// : Promise<CookieUser>

		if (!window.sessionStorage) return

		let tmp = window.sessionStorage.getItem('user')
		if (!tmp) return

		let savedUser: CookieUser

		if (tmp != null && tmp.length > 1) {
			savedUser = JSON.parse(tmp)
			// console.log(savedUser)

			if (savedUser != null && savedUser.username.trim() != '') {
				Util.debug('(S savedUser) da sessionStorage: ' + savedUser.username)

				// 31.01.2023  //if(savedUser.encr){
				let myK = savedUser.token.substring(7) // tolgo Bearer
				let myDt = savedUser.password

				this.decryptDataShort(myK, myDt)
					.then((ris) => {
						Util.debug('S (savedUser) decrypted! ') // ok

						//in realtá c'é un errore, ris non ritorna string ma forge.util.ByteStringBuffer
						// console.log(ris)
						let ris2: any = ris
						// console.log(ris2.getBytes())

						if (ris) {
							savedUser.password = ris2.getBytes() // sostituisco la pwd de-crittata
							//return savedUser;
						} else {
							return
						}

						// 18.11.2021 validare il token, anziche' rifare tutta la login -> nuovo tok
						if (savedUser.token != null && savedUser.token != '') {
							Util.debug('(S savedUser) no need to ask for a new token, using the existing one...')
							//Util.debug(savedUser.token);  // 11.03.2022

							// TODO validate token, in ogni caso la recover lo usa
							this.restoreUser(savedUser)
						} else {
							// solo in questo caso fare di nuovo la login
							Util.debug('(S savedUser) hidden login...')
							this.login(savedUser.username, savedUser.password)
						}
					})
					.catch((err) => {
						Util.debug('S (savedUser) err!' + err)
						return
					})
			}
		}
	}

	// 16.09.2021
	private initSubtypes() {
		// 29.03.2022 fix clone array

		// parto con tutti per tutti
		//this.usrSubtypes = Config.SUBTYPES;
		//this.usrSubtypesNoMini = Config.SUBTYPES;
		//this.usrSubtypesOpts = Config.SUBTYPES;

		// ko con array di stringhe
		//this.usrSubtypes = Config.SUBTYPES.map(x => Object.assign({}, x));

		this.usrSubtypes = Config.SUBTYPES.slice()
		this.usrSubtypesNoMini = Config.SUBTYPES.slice()
		this.usrSubtypesOpts = Config.SUBTYPES.slice()
		this.usrSubtypesSpec = [] //Config.SUBTYPES.slice();
		this.usrSubtypesInstaller = Config.INSTALLER_SUBTYPES

		//console.log("(initSubtypes) len: "+this.usrSubtypesNoMini.length);

		// senza mini, per i doctor
		var index = this.usrSubtypesNoMini.indexOf(Config.SUB_MINI)
		if (index > -1) {
			this.usrSubtypesNoMini.splice(index, 1)
		}

		// senza super e mini, per optician
		index = this.usrSubtypesOpts.indexOf(Config.SUB_MINI)
		if (index > -1) {
			this.usrSubtypesOpts.splice(index, 1)
		}
		index = this.usrSubtypesOpts.indexOf(Config.SUB_SUPER)
		if (index > -1) {
			this.usrSubtypesOpts.splice(index, 1)
		}

		// 30.08.2022 solo std e private per specialists
		// 21.06.2023 anche mini e company
		this.usrSubtypesSpec.push(Config.SUB_STD)
		this.usrSubtypesSpec.push(Config.SUB_MINI)
		this.usrSubtypesSpec.push(Config.SUB_COMPANY)
		this.usrSubtypesSpec.push(Config.SUB_PRIVATE)

		//console.log("(initSubtypes) for opticians: "+this.usrSubtypesOpts);
		//console.log("(initSubtypes) for doctors: ");
		//console.log(this.usrSubtypesNoMini);
		//console.log(this.usrSubtypesOpts);
	}

	/*  
     var currUser = {
              'username': this.user.username,
              'password': this.user.password,
              'token': tokenData  // 18.11.2021
            };
    */

	// 18.11.2021 in caso di reload della pg, prende dalla sessionStorage
	private restoreUser(savedInfo: CookieUser) {
		this.status = SessionStatus.LOGGING

		let tokenResp: TokenResponse
		tokenResp = User.buildTokenResp(savedInfo.token)
		this.user = User.createUser(this.cryptoUtils, tokenResp, savedInfo.username, savedInfo.password)

		// 31.01.2023 patch ?!? perche' non usiamo sempre lo user ? [ls]
		if (savedInfo.myIp && savedInfo.myIp != '') {
			this.loggedUser.myIp = savedInfo.myIp
		}

		Util.debug('(S restoreUser) user created, now initProfile')
		this.loadProfile(tokenResp).then((result: ProfilesResponse) => {
			// console.log(result)
			return this.user
				.initProfile(result)
				.then(() => {
					Util.debug('(S) (restoreUser) saved status LOGGED')

					// removed because we want to keep the localStorage datum
					// if (savedInfo && savedInfo.lang) {
					// console.log('(S restoreUser) currLang: ' + this.lang + ' force new: ' + savedInfo.lang)
					// this.setLanguage(savedInfo.lang)
					// }

					this.getCountries()
						.then((list) => {
							// this.countries = list
							Util.debug('(getCountries) tot countries: ' + this.countries.length)
						})
						.catch((error) => {
							Util.debug('(getCountries) ERR on countries!')
							console.log(error)
						})

					if (this.isLevel1() || this.isAdmin() || this.isSupport()) {
						// chiedo gli sku
						this.getSKUArticles().then(() => {
							Util.debug('(getSKUArticles) done')
						})
					}

					this.initAfterLogin().then(() => {
						let val = this.isProfileComplete()
						this.checkProfileComplete.next(val)

						this.initAL = false

						this.status = SessionStatus.LOGGED
						this.userLogged.next(true)

						Util.debug('(S restoreUser) - initAfterLogin end ')
						return this.manageReloadPage().then(() => {
							// this.manageSignalRConnection()
						})
					})

					// ripristinare la pagina in cui ero prima del reload
				})
				.catch((err) => {
					console.log('(S) (restoreUser) ko')
					console.log(err)
					this.logout() // 17.03.2022
					this.checkRoute()
				})
		})
	}

	login(username: string, password: string) {
		// var lang = this.getLanguage(); // 22.01.2021
		var lang = 'en'

		var testMsg = '(session) login has been called for ' + username + ' lang: ' + lang
		Util.debug(testMsg)

		// this.initGlobals() // fix se mi loggo con specialist_1 (normale) e vado nella visitlist di un paziente, faccio logout, viene ripreso dalla url id doctor, per cui se poi mi loggo con un clinic grader ad esempio, viene fatta una chiamata sbagliata
		// 09.06.2022 forzo pulizia dati vecchi, eventuale user precedente
		this.data.resetAll()

		//var usrBrand = null; //Config.BR_NS; // default
		this.status = SessionStatus.LOGGING

		return this.obtainToken(username, password)
			.then((tokenData: TokenResponse) => {
				//var tokenData: TokenResponse = dataResp.data;
				//var tokenData: TokenResponse = dataResp;
				// console.log(tokenData);  // solo per test

				return (
					this.loadProfile(tokenData)
						//.subscribe((result) => {
						.then((profileData: ProfilesResponse) => {
							// console.log(profileData) // 16.11.2021 ok, e' gia' oggetto profile

							//var tokenData = result[0];
							//var profileData = result[1].data;
							//var profileData = result; // .data;

							Util.debug('(session) OK login, loading profile for ' + username)

							// eventuali test sul brand qui, prima di procedere oltre --ls
							// qui ok brand

							this.loggedUser.validBrand = true // 30.05.2019
							this.loggedUser.pwd = password
							this.loggedUser.usrname = username

							// 06.06.23 per capire che é la prima login nella profile e fargli salvare le info personali tomas-17753
							if (profileData.profile.access_counter == 0) {
								this.loggedUser.isFirstLogin = true
							}

							// 08.03.2021
							if (profileData.profile && profileData.profile.client_ip) {
								this.loggedUser.myIp = profileData.profile.client_ip

								Util.debug('(S) [login] current ip: ' + this.loggedUser.myIp)
							}

							this.user = User.createUser(this.cryptoUtils, tokenData, username, password)
							Util.debug('(S login) user created, now initProfile')

							return this.user
								.initProfile(profileData)
								.then(() => {
									Util.debug('(S login) OK init profile, name: ' + this.user.firstname + ' last: ' + this.user.lastname + ' country: ' + this.user.getCountry())
									// console.log(this.user)
									// 17.11.2021 salvo le info minime x login silente
									this.saveCookieUser().then(() => {
										// segnalo ai servizi che sono loggato
										// this.userLogged.next(true)
										// lo faccio giá alla getLandingPage
									})
									//this.rootScope.$broadcast("session.login");

									// chiedo le country per tutti
									this.getCountries()
										.then((list) => {
											// console.log(this.countries)
											// this.countries = list
											Util.debug('(getCountries) tot countries: ' + this.countries.length)
										})
										.catch((error) => {
											Util.debug('(getCountries) ERR on countries!')
											console.log(error)
										})
									// chiedo gli sku per tutti
									this.getSKUArticles().then(() => {
										Util.debug('(getSKUArticles) done')
									})

									Util.debug('(S) saved status LOGGED')

									// requests to do only once, after login  ******
									this.initAfterLogin().then(() => {
										this.status = SessionStatus.LOGGED
										// console.log(this.user)
										// console.log(Sentry.isInitialized())
										if (Sentry.isInitialized()) {
											Sentry.setUser({
												// email: this.user.mainAddress.ref_email,
												username: this.user.username,
											})
										}

										this.initAL = false

										Util.debug('(S login) - initAfterLogin end ')
										return this.getLandingPage()
									})

									//return true;
									// **********************************
								})
								.catch((myErr) => {
									// 16.11.2021 aggiunto
									// 11.02.2022 uniformata gestione errori
									let msg = this.parseErrorMessage(myErr, 'trace')
									console.log('(S) KO refreshProfile ' + msg)
									return false
								})
						})
						.catch((err) => {
							//  (err) =>  {

							var msg = this.parseErrorMessage(err, 'trace') // 28.03.2022
							//let msg = (err.data)? err.data.error : err.toString();  // 02.05.2018

							console.log('KO login ' + msg)
							//console.log("KO login "+err.message);  // 04.09.2017

							this.loggedUser.pwd = ''
							this.loggedUser.usrname = ''

							// 28.10.2020
							if (this.isExpired(err)) {
								// in realta' controlla anche ko connect
								this.status = SessionStatus.CONNECT_ERROR
							} else if (err.status == 401) {
								// 09.02.2017
								console.log('Ko login, Not Authorized')
								this.status = SessionStatus.LOG_FAILED
							} else {
								this.status = SessionStatus.LOG_FAILED
								//this.status = SessionStatus.ERROR;  // TODO, altro errore generico ?
							}

							console.log(err)
							return false
						})
				)

				// 17.11.2021 ci passa troppo presto...
				/*
          () => {
          //.finally(() =>  {   // 18.12.2017 test
            console.log("(S login) finally 2");   // ok, ci passa, se servisse  
            return true;          
          }*/

				//) // chiude subscribe di loadProfile
			})
			.catch((errTok) => {
				if (this.isExpired(errTok)) {
					// in realta' controlla anche ko connect
					this.status = SessionStatus.CONNECT_ERROR
				} else if (errTok.status == 400) {
					console.log('(S) Ko login, Not Authorized')
					this.status = SessionStatus.LOG_FAILED
				} else {
					let msg = errTok.data ? errTok.data.error : errTok.toString()
					console.log('(S) KO token ' + msg)
					this.status = SessionStatus.LOG_FAILED
					//this.status = SessionStatus.ERROR;  // TODO, altro errore generico ?
				}

				return false
			})

		//console.log("(S) end method");  // no, esce subito
		//return true;
	}

	// 03.05.2022 portata fuori x chiamate multiple
	private saveCookieUser() {
		let currUser = new CookieUser(this.user)

		// 31.01.2023 patch ?
		if (this.loggedUser != null && this.loggedUser.myIp) {
			currUser.myIp = this.loggedUser.myIp
		}

		// 31.01.2023 critto la pwd, anche se e' solo su sessionStorage
		Util.debug('S (saveCookieUser) step1')

		let myK = currUser.token.substring(7) // tolgo Bearer
		let myDt = currUser.password
		return this.encryptDataShort(myK, myDt)
			.then((ris) => {
				Util.debug('S (saveCookieUser) encrypted!')

				if (ris) {
					currUser.password = ris // sostituisco la pwd con valore crittato
					currUser.encr = true
				}
			})
			.catch((err) => {
				Util.debug('S (saveCookieUser) err: ' + err)
			})
			.finally(() => {
				// 17.11.2021
				// console.log(currUser)
				if (window.sessionStorage) {
					Util.debug('S (saveCookieUser) step2, encr? ' + currUser.encr)
					window.sessionStorage.setItem('user', JSON.stringify(currUser))
					this.userLogged.next(true)
					//console.log("(S) saved user-info on sessionStorage "+JSON.stringify(currUser));
				}
			})
	}

	relogin() {
		Util.debug('(S) [relogin]')
		this.login(this.user.username, this.user.password)
	}

	private obtainToken(username: string, password: string) {
		// 19.03.2020 test con forge, ok
		// pwd is salted and hashed before leaving the client
		// 04.01.2017 qui fare hash prima di postarlo --ls
		//var hash_pwd = cryptoJs.SHA256(username.toLowerCase()+password).toString();
		//var hash_pwd = this.cryptoUtils.getSHA256(username.toLowerCase()+password).toString();
		var hash_pwd = this.cryptoUtils.getSHA256(username.toLowerCase() + password).toString()

		//Util.debug('(obtainToken) hash_pwd ' + hash_pwd)

		//const headers = { 'caller': 'FEweb'};
		var message = {
			grant_type: 'password',
			username: username,
			password: hash_pwd,
		}

		Util.debug('(obtainToken) going to ask for ' + username)

		// 07.06.2022 moved on the caller
		//this.status = SessionStatus.LOGGING;

		// 18.11.2022 uso la myPost con la baseReq, cosi' forzo il caller
		//return this.http.post<any>(Config.tokenEndpoint, message)		//.map(response => response.json());
		let request: any = this.buildBaseRequest(null)
		request.url = Config.tokenEndpoint
		return this.myPost(request, message)
	}

	private loadProfile(tokenData?): Promise<ProfilesResponse> {
		let request: any = this.buildBaseRequest(tokenData)
		request.url = Config.profilesEndpoint

		Util.debug('(S) [loadProfile] going to do the request to the server...')
		//return this.http.get<any>(Config.profilesEndpoint, this.buildBaseRequest(tokenData));
		//return this.http.get<any>(Config.profilesEndpoint, {headers: request.headers});

		return (
			this.myGet(request)
				/*
      .then((ris) => {
        console.log("(S) [loadProfile] ok");
        return ris;
      })*/
				.catch((err) => {
					console.log('(S) [loadProfile] ko')
					this.status = SessionStatus.NOT_LOGGED
					throw err
				})
		)
	}

	public refreshProfile(tokenData?) {
		Util.debug('(S) [refreshProfile] A')

		return this.loadProfile(tokenData)
			.then((profile: ProfilesResponse) => {
				// console.log(profile)
				if (profile != null) {
					//return this.user.refreshProfile(this.cryptoUtils, profile).then(() => {
					return this.user.initProfile(profile).then(() => {
						//nothing to return?
						Util.debug('(S) [refreshProfile] end')

						let isProfileComplete = this.isProfileComplete()
						this.checkProfileComplete.next(isProfileComplete)

						this.saveCookieUser()
						return true
					})
				}
			})
			.catch((err) => {
				console.log('(S) [refreshProfile] ko')
				this.status = SessionStatus.NOT_LOGGED // 13.01.2022 ?
			})
	}

	// 18.11.2021 portato fuori
	public initAfterLogin(): Promise<boolean> {
		Util.debug('S (initAfterLogin) start')

		const promise = new Promise<boolean>((resolve, reject) => {
			this.initAL = true
			// 04.01.2022 forzata la lang dalle preferences utente  (spostata da destroy su login)
			// check if localstorage Language is the same as profile language
			if (this.user.settings.lang && this.user.settings.lang != this.lang) {
				Util.debug('S (initAfterLogin) going to change language from ' + this.lang + ' to ' + this.user.settings.lang)
				this.setLanguage(this.user.settings.lang)
			}

			// 22.01.2021 spostato qui dopo broadcast per avere lang giusta
			// la icd list serve a tutti: sia specialist
			// che al livello 1 per stampare il report pdf,
			// che agli admin per la preview...

			// 08.09.2021 escludere se e' firstLogin
			if (!this.firstLoginRequired()) {
				let grp = this.userDiagnosisGroup()

				Util.debug('S (initAfterLogin) loading ICDs, group: ' + grp)
				// 23.05.2022 gli admins devono caricarli man mano che guardano i report...
				if (this.isAdmin()) {
					this.loadIcdList(2)
				} else if (!this.isSynchro() && !this.isSupport() && grp > 0) {
					this.loadIcdList(grp)
				}

				// if (this.isManagement()) {
				// 	this.requireModels().then((ris) => {
				// 		// console.log(ris)

				// 		this.userDevices = ris.models
				// 		this.deviceModels = ris.models.map((a) => a.model)
				// 		resolve(true)
				// 	})
				// }

				this.requireModels().then((ris) => {
					// console.log(ris)

					this.userDevices = ris.models
					this.deviceModels = ris.models.map((a) => a.model)
					if (this.isManagement() || this.isGod()) {
						resolve(true)
					}
				})

				// 29.12.2022 per i level1, chiedo il piano
				if (this.isLevel1()) {
					// this.salePlanObserver = from(this.loadUserPlan(this.user.user_id)) trasforma una promise in observable, peró il vantaggio é che non la richiama ogni volta, lo svantaggio é che se ci serve rinferscare il dato non funziona.
					this.loadUserPlan(this.user.user_id).finally(() => {
						resolve(true)
					})
				}

				// 06.06.2023 patch per keypair mancanti su pregresso
				if (this.isLevel2() || this.isClinicAdmin()) {
					// se non ha la priv, non ha neanche la public
					if (this.user.privateKeybox) {
						Util.debug('(initAfterLogin) user already has the key-pair')
					} else {
						Util.debug('(initAfterLogin) user has not yet the keypair...')
						this.amendMyKeyPair()
					}

					let needToPatchRelations = false

					// 06.05.2024 licence_num encrypted -> impacts also the relations
					if (this.isSpecialist() && this.user.order_reg_num && !this.user.licence_num) {
						needToPatchRelations = true
						Util.debug('(initAfterLogin) going to encrypt order_num...')

						// 06.05.2024 meglio funzione dedicata sulle relazioni,
						// che fa amend anche della key_public, non mando la signature, a meno che non manchi

						// const profileDraft = {
						// 	order_reg_num: this.user.order_reg_num,
						// 	display_name: this.user.display_name,
						// 	signature: this.user.signature,
						// 	signature_name: this.user.signature_name,
						// }
						// this.updateReportInfo(profileDraft)

						this.updateMyLicence()
					}

					// 07.06.2023
					this.loadRelationsList(this.user.user_id).then((rels) => {
						this.decryptRelsAfterVerify(rels).then((rels) => {
							if (needToPatchRelations) {
								Util.debug('(initAfterLogin) going to amend licence_num on relations')
								let relsList = this.data.relsList
								// console.log(relsList, relsList[0])
								if (relsList) {
									for (let i = 0; i < relsList.length; i++) {
										let rel = relsList[i]
										this.patchRelationBySpecialist(rel)
									}
								}
								resolve(true)
							} else {
								resolve(true)
							}
						})
					})
				}
			} else {
				// first login
				resolve(true)
			}
		})

		return promise
	}

	private decryptRelsAfterVerify(rels: Relation[]): Promise<Relation[]> {
		const promise = new Promise<Relation[]>((resolve, reject) => {
			let promiseArray = []

			let encryptRels: Relation[] = rels.filter((rel) => rel.decrypted == false)

			if (encryptRels && encryptRels.length > 0) {
				for (let encryptRel of encryptRels) {
					let mySalt = encryptRel.username.toLowerCase()

					promiseArray.push(
						this.cryptoUtils.decryptDataWithPwdS(this.user.password, encryptRel.docKeyBoxPhoto, mySalt).then((doctKeyPhoto: forge.util.ByteStringBuffer) => {
							return Relation.decryptFields(encryptRel, this.cryptoUtils, doctKeyPhoto).then((decRel) => {
								// console.log(decRel)
								this.data.updateRelation(decRel, true)
							})
						})
					)
				}

				Promise.all(promiseArray).then(() => {
					Util.debug('(loadRelations - verifyNewRelations - decrypted all relations end)')
					// console.log(this.data.relsList)
					resolve(this.data.relsList)
				})
			} else {
				resolve(rels)
			}
		})

		return promise
	}

	isLogging(): boolean {
		return this.status == SessionStatus.LOGGING
	}

	isLogged(): boolean {
		return this.status == SessionStatus.LOGGED
	}

	isInitAfterLogin(): boolean {
		return this.initAL
	}

	isFirstLogin() {
		return this.loggedUser.isFirstLogin
	}

	endFirstLogin() {
		this.loggedUser.isFirstLogin = false
	}

	getStatus(): SessionStatus {
		return this.status
	}

	// ******* funzioni GET su utente loggato **************

	// richiamata da navbar.html, come filtro sui bottoni da esporre
	// ritorna il livello utente, vd anche filter authenticationLevel.ts
	getType(): UserType {
		return this.user.type
	}

	// 04.02.2021  distingue tra optician e doctor, tra specialist e distrib
	getUserProfile(): UserType {
		return this.user.subType
	}

	// 09.02.2021 per ora differenzio solo i superB, serve per gestire visibilita' bottone colleagues
	getSubProfile(): UserType {
		var ret: UserType

		if (this.user.isSuperB()) {
			ret = UserType.SUB_SUPER
		} else if (this.user.isMini()) {
			ret = UserType.SUB_MINI
		} else {
			ret = UserType.SUB_STD
		}

		return ret
	}

	// 28.10.2021 identifica entrambi i livelli 2, alias piu' chiaro
	isLevel2(): boolean {
		return this.user.type == UserType.DEALER
	}

	/*  21.01.2022
  // identifica entrambi i livelli 2
  isDealer(): boolean {
    return this.user.type == UserType.DEALER;
  }
  */

	// identifica entrambi i livelli 1
	// 22.01.2021 alias piu' chiaro, sostituito isDoctor [ls]
	isLevel1(): boolean {
		return this.user.type == UserType.OPERATOR // entrambi i livelli 1
	}

	// livello 1 specifico
	isOptician(): boolean {
		var ret = this.isLevel1() && this.user.subType == UserType.OPTICIAN
		return ret
	}

	// sulla 42 non funzionava piú, mi ritrovavo nello user il campo a undefined mentre nel model era ''
	hasEmail(): boolean {
		return this.user.mainAddress.ref_email && this.user.mainAddress.ref_email != ''
	}

	isMissingMandatoryVat(): boolean {
		return (this.user.mainAddress.vat === undefined || this.user.mainAddress.vat === '') && this.user.is_test === 'N' && this.user.settings.ecommerce === 'Y'
	}

	// hasAllReportInfo(): boolean {
	// 	if (!this.isSpecialist()) {
	// 		return true
	// 	}
	// 	var ret =
	// 		this.user.display_name &&
	// 		this.user.display_name != '' &&
	// 		this.user.signature &&
	// 		this.user.signature != '' &&
	// 		this.user.order_reg_num &&
	// 		this.user.order_reg_num != ''

	// 	return ret
	// }

	public addressLocationEnabled(): boolean {
		if ((this.isOptician() && !this.isMini()) || (this.isSpecialist() && !this.isMiniC()) || this.isClinicAdmin() || this.isSuperB()) {
			return true
		}

		return false
	}

	public isProfileComplete(): boolean {
		var ret = false

		// se il bridge non risponde, l'indirizzo se non  c'é non é piú obbligatorio
		if (!this.infoBridgeAvailable) {
			ret = true
			return ret
		}

		ret = this.generalInfoComplete() && this.reportInfoComplete() && this.addressInfoComplete()

		return ret
	}

	public addressInfoComplete(): boolean {
		var ret = true

		if (this.addressLocationEnabled()) {
			ret = this.user.getMainAddressCoordinates() != null
		}

		return ret
	}

	public generalInfoComplete(): boolean {
		var ret = false

		if (this.user.firstname && this.user.lastname && this.user.mainAddress.ref_email) {
			ret = this.user.firstname != '' && this.user.lastname != '' && this.user.mainAddress.ref_email != ''
		}

		if (this.isOptician() && this.user.mainAddress.organization && !this.isGroupB()) {
			ret = ret && !this.isMissingMandatoryVat() && this.user.mainAddress.organization != ''
		}

		if (this.isAdmin()) {
			ret = true
		}

		return ret
	}

	public reportInfoComplete(): boolean {
		var ret = false

		if (this.isSpecialist()) {
			if (this.user.display_name && this.user.signature && this.user.order_reg_num) {
				ret = this.user.display_name != '' && this.user.signature != '' && this.user.order_reg_num != ''
			}
		}

		if (this.isOptician() && !this.isGroupB()) {
			ret = true
			//aggiungere logo quando spostato
		}

		if (this.isGroupB() || this.isClinicAdmin() || this.isLevel3() || this.isDoctor() || this.isSupport()) {
			ret = true
		}

		return ret
	}

	/*
  // livello 1 specifico - old name 
  isPharmacist(): boolean {       
    return this.isOptician();    
  }
  */

	// vale true sia per i B classici, che superB che miniB
	// livello 1 specifico - ex isDoctorNoPharm, rinominato 12.10.2021
	isDoctor(): boolean {
		//var ret = this.isLevel1() && (this.user.subType != data.UserType.OPTICIAN);
		var ret = this.isLevel1() && this.user.subType == UserType.DOCTOR
		return ret
	}

	isSuperB() {
		return this.user.isSuperB()
	}

	/* 20.09.2022 anche miniA
  isMiniB(){
    return this.user.isMiniB();
  }
  */

	isMini() {
		return this.user.isMini()
	}

	isMiniC() {
		return this.user.isMiniC()
	}

	// sono esclusi i B classici
	isGroupB() {
		return this.user.isSuperB() || this.user.isMini()
	}

	// livello 2 specifico
	isSpecialist(): boolean {
		var ret = this.isLevel2() && this.user.subType == UserType.SPECIALIST
		//console.log("(isSpecialist) "+ret);
		return ret
	}

	// livello 2 specifico
	isDistributor(): boolean {
		var ret = this.isLevel2() && this.user.subType != UserType.SPECIALIST
		return ret
	}

	isDoctorFirstTime(): boolean {
		return this.user.type == UserType.USER_FIRST_TIME
	}

	isDoctorPukTime(): boolean {
		return this.user.type == UserType.USER_PUK_TIME
	}

	// 08.09.2021
	firstLoginRequired() {
		return this.isDoctorFirstTime() || this.isDoctorPukTime() || (this.isClinicAdmin() && this.getRoute() === 'agreement')
	}

	/**
	 * tutti gli admins compreso il primo, sono esclusi i vice
	 */
	isGod(): boolean {
		//console.log("(isGod) "+this.user.type);
		return this.user.type == UserType.GOD
	}

	isVice(): boolean {
		return this.user.type == UserType.VICE
	}

	// 08.08.2018 per differenziare il gruppo di admin interni dal primo, orig
	isAdmin(): boolean {
		return this.user.type == UserType.GOD && !this.user.isSuper()
	}
	isFirstGod(): boolean {
		return this.user.type == UserType.GOD && this.user.isSuper()
	}

	isLevel3(): boolean {
		return this.isGod() || this.isVice() // entrambi i livelli 3
	}

	// 21.11.2022 livelli 3 e altri amministrativi o gestione...
	isManagement() {
		let ret = this.isVice() || this.isAdmin() || this.isStats() || this.isSupport() || this.isInstaller() || this.isManager()
		return ret
	}

	isManager(): boolean {
		return this.user.type == UserType.MANAGER
	}

	// 20.07.2021
	isStats() {
		return this.user.type == UserType.STATS
	}

	// 01.06.2022
	isSynchro() {
		return this.user.type == UserType.SERVICE
	}

	// 09.11.2022
	isSupport() {
		return this.user.type == UserType.SUPPORT
	}

	isInstaller() {
		return this.user.type == UserType.INSTALLER
	}

	// 20.04.2023
	isSuperSupport() {
		return this.isSupport() && this.user.isSuper()
	}

	// 24.05.2023
	isClinicAdmin() {
		return this.user.type == UserType.CLINIC
	}

	/*
  // 17.04.2020 user_subtype e' trasversale al type, si applica a tutti i livelli
  private isDemoUser(){
    var flag = false;
    
    //13.10.2021 dismettiamo, serviva solo per i test fase1
    if(this.user.user_subtype){
      flag = ((this.user.user_subtype).toUpperCase() == "DEMO");  // Config.SUB_DEMO
    }

    // 08.03.2021 abilito temporaneamente per chiamate da ip interno NS
    if(!flag){           
     flag = this.isLocalNS();
    }

    return flag;
  }
  */

	// 01.06.2021
	public isLocalNS() {
		var ipNS = '91.81.40.162' // ip pubblico NS NextSight
		var ipNSFibra = '2.33.47.18' // ip pubblico NS NextSight con Fibra  26.07.2023
		var ipVPNFortinet = '2.33.47.22' // ip pubblico NS NextSight con VPN Fortinet
		var ipInterno = '192.168.1.' // srv locale
		var ipLocalhost = 'localhost' // srv locale
		// console.log(this.loggedUser)

		var flag =
			this.loggedUser != null &&
			(this.loggedUser.myIp == ipNS ||
				this.loggedUser.myIp == ipNSFibra ||
				this.loggedUser.myIp == ipVPNFortinet ||
				this.loggedUser.myIp.indexOf(ipInterno) == 0 ||
				this.loggedUser.myIp.indexOf(ipLocalhost) >= 0)

		return flag
	}

	/*
  // usato per far comparire build e nome utente
  private isLocalSrv(){   
    var ipInterno = "192.168.1.";
    var flag = (this.loggedUser.myIp.indexOf(ipInterno)==0);
    return flag;
  }
  */

	// 02-05-23 tomas-17734 when user is on profile, the menu item are hidden
	getRoute() {
		return this.activatedRoute.snapshot.children[0].routeConfig.path
	}

	getUserId() {
		return this.user.user_id
	}

	getUsername(): string {
		return this.user.username
	}

	getFirstName(): string {
		return this.user.firstname
	}

	getLastName(): string {
		return this.user.lastname
	}

	// 15.06.2017
	getFullName(): string {
		return this.user.firstname + ' ' + this.user.lastname
	}

	// 03.03.2021
	getUserInitials(): string {
		if (this.user != null && this.user.firstname != null && this.user.lastname != null) {
			var N = this.user.firstname.substring(0, 1)
			var S = this.user.lastname.substring(0, 1)
			return N + '.' + S + '.'
		} else {
			return ''
		}
	}

	// 14.06.2022
	getUserBrand(): string {
		let ret = ''
		if (this.user && this.user.settings) {
			ret = this.user.settings.brand
			Util.debug('(getUserBrand) ' + ret)
		}
		return ret
	}

	getCode(): string {
		return this.user.code
	}

	getEmail(): string {
		return this.user.mainAddress.ref_email
	}

	getPhone(): string {
		return this.user.mainAddress.phone1
	}

	// 14.02.2017
	getOrganization(): string {
		//return this.user.mainAddress.organization; // other_data;
		return this.user.getOrganization()
	}

	getVat(): string {
		return this.user.getVat()
	}

	// 26.05.2022
	getUserCountry(): string {
		return this.user.getCountry()
	}

	// 27.05.2022
	getUserTypeAgreementStatus(type: AgrType): AgrStatus {
		return this.user.getTypeAgreementStatus(type)
	}

	getUserAgreementStatus(status: AgrStatus): { res: boolean; agreements: AgreementsStatus[] } {
		return this.user.getAgreementsStatus(status)
	}

	getUserAvailableAgreements() {}

	getUserAgreementTHStatus() {
		// return this.user.getAgreementTHStatus()
	}

	getPatientAgreementStatus() {
		return this.user.getPatientAgreementStatus()
	}

	// 25.07.2017 immagine in base64 con logo farmacia o timbro del medico
	getLogo(): string {
		return this.user.logo
	}

	getVacancies() {
		return this.user.vacancies
	}

	// 31.05.2017
	getCurrentUser(): User {
		return this.user
	}

	// 31.03.2022 - 16.02.2022
	getPdfFormat() {
		let ret = Config.PDF_FORMATS[0] // default
		let usrPdf = this.user.settings.pdf_format

		for (let i = 0; i < Config.PDF_FORMATS.length; i++) {
			if (Config.PDF_FORMATS[i] == usrPdf) {
				ret = usrPdf
				break
			}
		}
		return ret
	}

	// ******** language **************

	getLanguage() {
		if (this.lang == null || this.lang == '') this.lang = 'en'
		return this.lang
	}

	// 19.05.2022 - 03.03.2022 aggiunto parametro flagManual
	setLanguage(language, flagManual?) {
		if (language != null) {
			// 14.04.2020 aggiunto test [ls]
			Util.debug('(setLanguage) got ' + language)

			// 03.05.2022
			let fullLang = ''
			let shortLang = ''
			if (language.length == 2) {
				fullLang = language + '_' + language.toUpperCase()
				shortLang = language
			} else {
				fullLang = language
				shortLang = language.substring(0, 2)
			}
			// Set locale for  material date picker
			this.dateAdapter.setLocale(fullLang)

			Util.debug('S (setLanguage) full: ' + fullLang)

			// 16.05.2022 usiamo il service
			//this.translator.use(fullLang.concat(localStorage.getItem('language_suffix')) );  // 03.05.2022 spostata qui
			// let suff = localStorage.getItem('language_suffix')
			//this.localizService.useLanguage(fullLang.concat(localStorage.getItem('language_suffix')));
			this.localizService.useLanguage(fullLang)

			//this.lang = language.substring(0,2);
			this.lang = shortLang

			// 19.05.2022 - 03.03.2022 aggiorno gli icd con le traduzioni corrette
			if (flagManual != null && flagManual == true && this.isLogged()) {
				this.loadIcdList()
			}

			// 03.05.2022 salvo anche su cookieUser x reload
			if (this.isLogged()) {
				this.user.settings.lang = this.lang
				this.saveCookieUser()
			}
		}
	}

	onLanguageClick(event: any, language?: string) {
		//onLanguageClick(event: any, lab?: any) {

		Util.debug('S (onLanguageClick) clicked language: ' + language)
		//console.log(event);

		let shortLang = language
		let fullLang = language + '_' + language.toUpperCase()

		Util.debug('S (onLanguageClick) ' + fullLang + ' short: ' + shortLang)

		let flagManual = true // 19.05.2022 - 03.03.2022
		//this.translator.use(fullLang);  // spostato dentro la setLang
		this.setLanguage(shortLang, flagManual)
	}

	// navbar elements for languages
	private createLanguagePush(lTitle, code) {
		this.languages.push(
			Language.createLanguage(
				lTitle,
				() => {
					this.onLanguageClick(code)
				},
				code
			)
		)
	}

	// creation languages for navbar dropdown
	private initLanguages() {
		this.languages = []

		this.createLanguagePush('ENGLISH', 'en_EN')
		this.createLanguagePush('ITALIANO', 'it_IT')
		this.createLanguagePush('FRANÇAIS', 'fr_FR')
		this.createLanguagePush('DEUTSCH', 'de_DE')

		this.createLanguagePush('PORTUGUÊS', 'pt_PT')

		this.createLanguagePush('ESPAÑOL', 'es_ES')
		this.createLanguagePush('中文语', 'zh_ZH')

		console.log('[Header] - tot languages: ' + this.languages.length)
	}

	/*
  getDealerId() {
    return this.user.dealerId;
  }

  getDoctorId() {
    return this.user.doctorId;
  }
  */

	// TODO 16.05.2018 numero di iscrizione all'albo degli oculisti, per currentUser loggato
	/*
  getOrderRegNumber(): string {
    return this.user.order_reg_num;
  }
  */

	// ******* basic protocol ****************************

	public buildBaseRequest(tokenData?: TokenResponse) {
		var token = !!tokenData ? User.buildToken(tokenData) : this.user.token

		// 02.05.2018 per test
		//console.log("(buildBaseRequest) tok ["+token+"]");

		// reference for !!   both null and undefined
		// https://stackoverflow.com/questions/28975896/is-there-a-way-to-check-for-both-null-and-undefined

		// 28.04.2022 patch
		if (token == null || token == undefined) {
			token = ''
		}

		return {
			headers: {
				Authorization: token,
				caller: 'feWeb2', // 20.04.2020 aggiunto caller [ls]
				Pragma: 'no-cache', // 18.03.2022 toglie la cache
				'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
				Expires: 0,
			},
		}
	}

	// 11.02.2022 uniformata per evitare alert enormi
	parseErrorMessage(myErr, level?) {
		let msg = ''

		// 09.05.2022 ATT a loop!
		// 05.05.2022 forzo controllo expired, qualche chiamata non ci passa ?!
		//this.isExpired(myErr);  // eventualmente forza logout e manda pop-up
		if (this.manageExpired(myErr)) {
			return
		}

		//if(!Config.isProductionMode){
		//console.log("(S) [ERR] "+myErr);  // object
		//}

		if (myErr.data && myErr.data.error && myErr.data.error.error) {
			// 31.05.2022
			msg = myErr.data.error.error
		} else if (myErr.data) {
			msg = myErr.data.error
		} else if (myErr.error && myErr.error.error) {
			// 20.07.2022
			msg = myErr.error.error // il msg scritto dalle api
		} else if (myErr.statusText) {
			// 20.07.2022
			msg = ' ' + myErr.statusText // decodifica 404 in forbidden
		}

		if (msg == null || msg.length == 0) {
			msg = JSON.stringify(myErr)
		}
		if (msg.length > 40 && level == 'alert') {
			console.log('(S) [ERR] for alert, ' + msg)
			//msg = myErr.toString();  // piu' corto ?!
		}
		return msg
	}

	// 21.01.2022 alias qui, per gestire il sessionExpired
	private myGet(request) {
		//console.log("(S) [myGet] calling: "+request.url);
		return this.data.myGet(request).catch((err) => {
			console.log('(S) [myGet] ko')
			this.isExpired(err) // eventualmente forza logout
			throw err
		})
	}

	// 21.01.2022
	private myPut(request, message) {
		//console.log("(S) [myPut] calling: "+request.url);
		return this.data.myPut(request, message).catch((err) => {
			console.log('(S) [myPut] ko')
			this.isExpired(err) // eventualmente forza logout
			throw err
		})
	}

	// 21.01.2022
	private myPost(request, message, timeout?: number) {
		Util.debug('(S) [myPost] calling: ' + request.url)
		return this.data.myPost(request, message, timeout).catch((err) => {
			console.log('(S) [myPost] ko')
			this.isExpired(err) // eventualmente forza logout
			throw err
		})
	}

	// 28.01.2022
	private myDelete(request) {
		Util.debug('(S) [myDelete] calling: ' + request.url)
		return this.data.myDelete(request).catch((err) => {
			console.log('(S) [myDel] ko')
			this.isExpired(err) // eventualmente forza logout
			throw err
		})
	}

	// 21.09.2022 per passare dati alla telerefract senza aprire nuova finestra
	public myExternalPost(myUrl, message): Observable<any> {
		console.log('S (myExternalPost) going to call ' + myUrl)
		//console.log("S (myExternalPost) params: "+message.toString()); // ko

		return this.http.post<any>(myUrl, message)
	}

	// 22.08.2018 funzione da richiamare in tutti i costruttori che fanno GET per verificare se sessione scaduta
	isExpired(err) {
		var isExp = false

		let msg = err.data ? err.data.error : err.toString()

		// 11.02.2022 a volte e' troppo lungo, mette tutta la request...
		let msg2 = err.data ? err.data.error : JSON.stringify(err)
		Util.debug('S (KO) ' + msg2)

		if (err.statusText) {
			msg += ' ' + err.statusText
		}

		Util.debug('(S ERROR) KO ' + msg)
		Util.debug(err)

		// {"data":null,"status":-1,"config":{"method":"GET","transformRequest":[null],"transformResponse":[null],"jsonpCallbackParam":"callback","headers":{"Authorization":"Bearer aOZ3damUtWzgqXkUYnicW57wZ2m3H9eIaIBJQnPePAM","caller":"feWeb","Accept":"application/json, text/plain, */*"},"url":"http://10.30.15.42:81/api/v1/reports?patient_id=13"},"statusText":"","xhrStatus":"error"}

		isExp = this.manageExpired(err)

		/*if(err.status == 401) {    

      isExp = true;
      console.log("S - session expired, 401"); //03.01.2022

      // 14.09.2021 per richieste multiple da una pagina, 
      // evitiamo che escano tanti pop-up con stesso msg expired.
      if(this.status == SessionStatus.NOT_LOGGED){
      // gia' fatto...
      } else {      
        alert("session expired"); // TODO, togliere alert e mettere msg su pg      
        this.logout();  // 14.04.2020 fix
      	// TODO, chiudere eventuali modal ?
				return isExp;
      }

      // 28.10.2020 gestire problemi di rete,
      // net::ERR_CONNECTION_TIMED_OUT
      // TODO esporre un warning sul FE, qls sia la pagina
      //} else if(msg.indexOf("net::ERR_CONNECTION")>=0){  
		*/

		if (isExp) {
			// alert gia' gestito dentro
		} else if (err.status == -1 && err.data == null && err.xhrStatus == 'error') {
			console.log('(session) ' + msg) // 14.06.2021

			if (msg.indexOf('net::ERR_CONNECTION') >= 0) {
				// 14.06.2021
				alert('NET Connection Problems')
			} else {
				// 11.02.2022 uniformata gestione errori
				let alertMsg = this.parseErrorMessage(err, 'alert')
				alert(alertMsg)
			}

			isExp = true // per disabilitare altro alert sulla chiamante, TODO sistemare
			//throw err;

			// 21.10.2022 api non raggiungibili ?
		} else if (err.status == 0) {
			console.log('(session) 2 ' + msg)
			alert('NET Connection Problems (2)')
			isExp = true // per disabilitare altro alert
		} else {
			// 26.08.2020 ri-commento x facilitare la gestione ai chiamanti
			//throw err;   // 03.09.2019 aggiunto
		}

		return isExp
	}

	// 09.05.2022 portata fuori
	private manageExpired(err) {
		let isExp = false
		if (err && err.status == 401) {
			isExp = true
			console.log('S - session expired, 401') //03.01.2022
			// 14.09.2021 per richieste multiple da una pagina,
			// evitiamo che escano tanti pop-up con stesso msg expired.
			if (this.status == SessionStatus.NOT_LOGGED) {
				// gia' fatto...
			} else {
				alert('session expired') // TODO, togliere alert e mettere msg su pg
				this.logout() // 14.04.2020 fix
				// TODO, chiudere eventuali modal ?
			}
		}
		return isExp
	}

	// *****************************************************

	public logout() {
		Util.debug('(S) logout has been called')

		// 04.10.2021 se sta fallendo la login, non ho userId da de-loggare
		let currId = this.getUserId()
		let request: any

		// if (this.isOptician() || this.isSupport()) {
		// this.signalR.stopConnection()
		this.userLogged.next(false)
		// }

		if (currId != null) {
			// 05.10.2020 invia una chiamata alle api per invalidare il token
			// intanto lo carico prima di annullare l'oggetto user
			request = this.buildBaseRequest()
			//request.method = "POST";
			request.url = Config.profilesEndpoint + '/' + currId + '/logout'
		}

		// *** annullo lato client ***********

		// 02.11.2022 poi devo inviare il logout alla piattaforma di teleRefract
		let currTok = ''
		if (this.user.isTelerefractEnabled()) {
			currTok = this.user.token.substring(7)
		}

		this.resetClientSide() // 21.01.2022 portata fuori
		//this.rootScope.$broadcast("session.logout");

		// 02.11.2022
		if (currTok != '') {
			this.logoutTelerefract(currTok)
		}

		if (currId != null) {
			// 05.10.2020 invia una chiamata alle api per invalidare il token
			this.http.post<any>(request.url, null, { headers: request.headers }).subscribe(
				(resp) => {
					//console.log("(S logout) resp ");  // ok
				},
				(err) => {
					console.log('(S logout) err ')
					console.log(err) // togliere dopo i test
					this.checkRoute()
				},
				() => {
					Util.debug('(S logout) done, checking routes...')
					this.checkRoute()
				}
			)
		} else {
			Util.debug('(S logout) no user_id')

			this.routing.navigateByUrl(Config.login)
		}
	}

	// 21.01.2022 portata fuori
	private resetClientSide() {
		this.status = SessionStatus.NOT_LOGGED

		this.user = new User(null) //User.createFakeUser(UserType.NONE);
		// 18.01.2022
		this.data.resetAll()

		//this.cookies.remove("user");
		if (window.sessionStorage) {
			// 07.06.2022 usiamo remove o clear all ?
			//window.sessionStorage.setItem("user", "");
			window.sessionStorage.removeItem('user')
			// 07.06.2022 Remove all saved data from sessionStorage
			window.sessionStorage.clear()
		}

		this.loggedUser.pwd = '' // 22.02.2017
		this.loggedUser.usrname = ''

		this.isLoading = false // 04.07.2023

		//this.setRedirect(false); // 12.12.2019
		this.setTempToken('') // 12.12.2019

		// 02.11.2022 non serve piu' con le material tables
		//this.tableSettings.resetAll() // 14.06.2022 bug 181 - non lo risolve!

		this.initGlobals() // 01.06.2023 Aggiunto per ripulire anche ad esempio il docId che mi filtra la lista di patient

		if (this.logoutAuxFunc) {
			this.logoutAuxFunc()
		}
	}

	// 02.11.2022
	private logoutTelerefract(myTok: string) {
		let teleRefrlogout = Config.telerefractUrl + '/Auth/Logout'
		let myForm = new FormData() // as form data
		myForm.append('externalToken', myTok)

		this.myExternalPost(teleRefrlogout, myForm).subscribe(
			(resp) => {
				Util.debug('(logoutTelerefract) ok logout teleRefract platform')
				//console.log(resp)
			},
			(err) => {
				let status = err.status
				let msg = this.parseErrorMessage(err, 'alert')
				//alert("ko syncr with teleRefract - status: "+status+" msg: "+msg);
				Util.debug('(logoutTelerefract) ko logout teleRefract - status: ' + status + ' msg: ' + msg)
			}
		)
	}

	initGlobals() {
		Util.debug('S (initGlobals)')

		//this.filter = null
		this.user = new User(null)
		this.status = SessionStatus.NOT_LOGGED

		this.loggedUser = new LoggedUser()

		//this.currentEye = ''

		// this.lang = 'en' // 19.06.2017 fix
		// avendo agginuto al logout la init glbal, la lingua veniva reimpostata a en anche se non doveva
		this.localizService.initService()
		let lang = this.localizService.getSavedLang()
		this.setLanguage(lang)

		this.tempToken = '' // 30.04.2018

		//this.progressBar = 0 // 18.04.2019
		this.isLoading = false
		this.deviceModels = []
		this.userDevices = []
		this.currentDoctorId = 0
		this.currentPatientId = 0

		this.targetPatient = 0 // 31.05.2022 per accesso diretto
		this.targetReport = 0
		this.targetAiReport = 0
		this.targetBalance = false
		this.targetPlan = false
		this.targetPatientEdit = false
		this.targetPatientEditAnam = false
	}

	// **************** recover ********************************

	// 30.04.2018
	setTempToken(myTok) {
		this.tempToken = myTok
		//console.log("(session.setTempToken) ok tok: "+myTok);
		// TODO verificare se scaduto ?
	}

	getTempToken() {
		return this.tempToken
	}

	// 28.04.2022
	loadTempTokenFromUrlParameters() {
		if (this.activatedRoute != null) {
			// 09.06.2022 added test
			this.activatedRoute.queryParams.subscribe((params) => {
				//console.log(params); // ko, object

				let tempTok = params['token']
				//let tempTok = params.token;

				//let tempTok = window.location.search().token;
				if (tempTok) {
					//Util.debug("(S) 1 tempToken: "+tempTok);
					this.setTempToken(tempTok)
				} else {
					//Util.debug("(S) 1 ko tempToken!");

					// 28.04.2022 ko, parse meccanico FIXME
					let myParams = window.location.search
					//console.log("(S) myParams: "+myParams);
					//let tempTok = myParams['token']; // ko

					let tempTok = ''
					let pattern = 'token='
					let index = myParams.indexOf(pattern)
					if (index > 0) {
						tempTok = myParams.substring(index + 6)
					}

					if (tempTok) {
						//Util.debug("(S) 2 tempToken: "+tempTok);
						this.setTempToken(tempTok)
					} else {
						//Util.debug("(S) 2 ko tempToken!");
					}
				}
			})
		}
	}

	// 18.11.2021 era su nexy, su nexus e' la users/show
	// 19.11.2018 verifica validita' del token arrivato dal link della mail, se expired -> pop-up
	verifyTempToken() {
		// 29.04.2019 ko, loadProfile ritorna troppe info [ls]
		//return this.loadProfile()
		return this.verifyProfileToken().then(() => {
			return true
		}) // il catch lo fa la chiamante --ls
	}

	// 29.04.2019 nuova piu' leggera di getProfile [ls]
	private verifyProfileToken() {
		// 29.04.2019 fix  --ls
		var tempToken = <TokenResponse>{}
		tempToken.access_token = this.getTempToken()
		tempToken.token_type = 'bearer'

		let request: any = this.buildBaseRequest(tempToken)
		request.method = 'GET'

		// i devices usano "/test" al posto di myTok
		request.url = Config.usersEndpoint + '/myTok'

		return this.myGet(request).then(function (myResponse) {
			//console.log(profileResponse);
			return [tempToken, myResponse]
		})
	}

	// 31.01.2023 uso il token della sessione corrente
	public verifyUserToken() {
		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		// i devices usano "/test" al posto di myTok
		request.url = Config.usersEndpoint + '/myTok'
		return this.myGet(request)
	}

	// 07.09.2017 richiede al server l'invio di una mail con link per cambio pwd (e token temporaneo)
	recoverPwd(username: string, privateEmail: string) {
		Util.debug('(recoverPwd) has been called for ' + username)
		// 18.05.2021 usiamo POST, non serve piu'
		//var docUsrName = this.parseEmail(username);
		return this.requireChangePwdMail(username, privateEmail)
	}

	// se ok, compare a video "check your mail for further details"
	// 18.05.2021 uso un POST con i dati sensibili
	//private requireChangePwdMail(usrname) {
	private requireChangePwdMail(usrname, privateEmail) {
		let request: any = this.buildBaseRequest()
		//request.method = "GET";
		request.method = 'POST'

		//request.url = Config.apiEndpoint + "/recover/" + usrname;
		request.url = Config.apiEndpoint + '/recover/'

		Util.debug('(S) going to request a temp token...')

		var dataReq = {
			data: {
				username: usrname,
				private_email: privateEmail,
			},
		}
		//request.data = dataReq;

		return this.myPost(request, dataReq)
	}

	// 17.03.2017
	/*
partendo dal puk, prova a decrittare i campi,  
- viene chiesto al server il contenuto di user_access.puk_data e user_access.puk_metadata
- dal lato client si tenta di decrittare questi due dati usando il PUK inserito.

*) Se la decrittazione fallisce, il medico riceve un messaggio di “PUK errato” e può riprovare fino a un massimo
di tre volte, oppure può scegliere di recuperare i dati con le password vecchie.

*) Se la decrittazione riesce, sul lato client si ottengono KEY_PHOTO e KEY_PRIVACY; 
 
- l’utente verrà indirizzato a una pagina nella quale si chiede di inserire la nuova password e la conferma della password .

- Una volta riempiti i campi, KEY_PRIVACY e KEY_PHOTO vengono crittati con la nuova password, e vengono
inviati al server per essere archiviati (stesse funz e api del cambio pwd normale)
- Il server archivia i valori precedenti di user_access.keybox_doctor e user_access.keybox_photo in
user_access_old_keyboxes, per mantenere uno storico delle keyboxes usate.
- La nuova password viene salata e hashata sul lato client, e inviata al server che provvederà a salvarla 
al posto di quella vecchia.

*/
	// recover con puk - recover with puk
	//recoverFromPuk(username: string, puk: string, newPwd: string, lastname: string): angular.IPromise<string> {
	recoverPwdFromPuk(username: string, puk: string, newPwd: string) {
		//lastname non viene piú usato
		var testMsg = '(recoverFromPuk) has been called for ' + username
		Util.debug(testMsg)

		var result: any = {}
		result.username = username

		return this.obtainPukBoxes(username)
			.then((userData) => {
				//console.log("(recoverFromPuk) rx: ");
				//console.log(userData);

				var boxesResp = userData.data // 16.06.2022 FIX
				// console.log(boxesResp) // OK

				// decrypt boxes con il puk
				var box1 = boxesResp.puk_data
				var box2 = boxesResp.puk_metadata
				var img = boxesResp.image // 30.04.2018
				let role = boxesResp.role // 03.07.2023

				//result.cognome = boxesResp.lastname;
				//result.user_id = boxesResp.user_id;  // serve ?

				if (!box1 || box1.length != this.KEYBOX_LEN) {
					// era 64, ora c'e' iv davanti
					Util.debug('(recoverFromPuk) ERRORE su keyBox ricevuta')
					throw 'Error: invalid keyBox rx'
				}

				// 03.07.2023 per i graders len_box2 e' maggiore ma se sto facendo il recover, non so che profilo e'.
				// uso nuovo campo "role"

				if (role == Config.PR_SPECIALIST) {
					// "Specialist"
					if (!box2 || box2.length != this.KEYBOX_PUK_MINIC_LEN) {
						Util.debug('(recoverFromPuk) ERRORE su keyBox2 ricevuta, ' + box2.length)
						throw 'missing field, cannot recover pwd with puk' // 04.07.2023
					}
				} else {
					// ok sia optician che doctor, level1
					if (!box2 || box2.length != this.KEYBOX_LEN) {
						Util.debug('(recoverFromPuk) ERRORE su keyBox2 ricevuta')
						throw 'Error: invalid keyBox2 rx'
					}
				}

				if (!img || img.length == 0) {
					Util.debug('(recoverFromPuk) ERRORE su img ricevuta')
					throw 'Error: invalid img rx'
				}

				return this.cryptoUtils.decryptDataWithPuk(puk, box1).then((keyPhoto) => {
					if (!keyPhoto) {
						//console.log("(recoverFromPuk) ERROR on len keyPhoto "+keyPhoto.length);
						throw 'Error: invalid keyPhoto len'
					}

					result.keyPhoto = keyPhoto

					// test decritt di una immagine (logo), verifica l'inizio...
					var bag = this.cryptoUtils.generateBag()
					bag['image'] = img
					this.cryptoUtils.purge(bag) // 18.05.2021

					return this.cryptoUtils
						.decryptImage(keyPhoto, bag)
						.then((myImg) => {
							// verifico se ok, se inizia con data/jpeg
							//var testArray = angular.copy(myImg);  // fa un clone
							var testArray = myImg // 15.03.2022 TOTEST !!!

							var iOff = 0
							if (testArray[iOff] == 'd' && testArray[iOff + 1] == 'a' && testArray[iOff + 2] == 't' && testArray[iOff + 3] == 'a') {
								Util.debug('(recoverFromPuk) ok decrypt img di test')

								return this.cryptoUtils.decryptDataWithPuk(puk, box2).then((metadataContent) => {
									Util.debug('(recoverFromPuk) ok decrypt box2')

									//if(keyPriv.length != 32){
									if (!metadataContent) {
										//console.log("(recoverFromPuk) ERROR on len keyPriv "+keyPriv.length);
										throw 'Error: invalid metadataContent'
									}

									// ko, qui scrive function...
									//console.log("(recoverFromPuk) len metadataContent "+metadataContent.length);

									if (role == Config.PR_SPECIALIST) {
										result.private_key = metadataContent // per asymm decrypt
										result.keyPriv = '' // cosi' poi encrypt non va in errore, e la ignoriamo
									} else {
										// opticians
										result.keyPriv = metadataContent // per critt pazienti
									}

									// ora qui result ha i campi ok per fare il cambio pwd
									//console.log("(recoverFromPuk) OK decrypt boxes from PUK"); // 06.09.2017 trace inutile --ls
									// critta le key con la nuovaPwd

									var usrSalt = result.username.toLowerCase() // 29.04.2020
									return this.cryptoUtils.encryptDataWithPwdS(newPwd, result.keyPhoto, usrSalt).then((myKeyboxPhoto) => {
										//console.log("keyBoxFoto : "+myKeyboxPhoto);
										result.keyboxPhoto = myKeyboxPhoto

										if (myKeyboxPhoto.length != this.KEYBOX_LEN) {
											Util.debug('(recoverFromPuk) ERROR on len myKeyboxPhoto ' + myKeyboxPhoto.length + ', forse puk errato? ')
											throw 'Error: invalid myKeyboxPhoto len, maybe wrong puk'
										}

										return this.cryptoUtils.encryptDataWithPwdS(newPwd, result.keyPriv, usrSalt).then((keyboxDoctor) => {
											//console.log("keyBoxDoctor: "+keyboxDoctor);
											result.keyboxDoctor = keyboxDoctor

											if (role != Config.PR_SPECIALIST) {
												// per gli specialist questa e' vuota, la ignoro
												if (keyboxDoctor.length != this.KEYBOX_LEN) {
													Util.debug('(recoverFromPuk) ERROR on len keyBoxPriv ' + keyboxDoctor.length)
													throw 'Error: invalid keyBoxPriv len'
												}
											}

											// ora invia al server le nuove keybox

											// 19.03.2020 uso forge [ls]
											var username = result.username
											//var nuova_hash_pwd = cryptoJs.SHA256(username.toLowerCase()+newPwd).toString();
											var nuova_hash_pwd = this.cryptoUtils.getSHA256(username.toLowerCase() + newPwd).toString()

											var nuoviDati = {
												username: result.username, // 04.07.2023 riabilitata ?
												password: nuova_hash_pwd,
												keybox_doctor: result.keyboxDoctor,
												keybox_photo: result.keyboxPhoto,
											}

											// 03.07.2023
											if (role == Config.PR_SPECIALIST) {
												Util.debug('(recoverFromPuk) for specialist')
												return this.cryptoUtils.encryptDataWithPwdS(newPwd, result.private_key, usrSalt).then((priv_keybox) => {
													Util.debug('(recoverFromPuk) adding private keybox, len: ' + priv_keybox.length)

													if (priv_keybox.length != this.KEYBOX_PUK_MINIC_LEN) {
														Util.debug('(recoverFromPuk) ERROR on len priv_keybox ' + priv_keybox.length)
														throw 'Error: invalid priv_keybox len'
													}

													nuoviDati['private_keybox'] = priv_keybox
													nuoviDati['keybox_doctor'] = null // 08.05.2024

													return this.changePwdInternal(nuoviDati).then((resp) => {
														// 04.07.2023 devo resettare tutte le keybox_distrib delle relazioni,
														// xche' sono chiuse con la vecchia pwd.
														// -> lo fanno le API, 2024-05-08

														// Poi per ciascuna rel, estrae la keyPhoto dalla keybox_public e rigenera la keybox_distrib
														// come fanno i miniC quando viene fatta una nuova rel.
														// -> lo fa qui, nella initPostLogin, come quando attiva le nuove relazioni, 2024-05-08

														// NB: non si deve cancellare la keybox_public!

														return resp
													})
												})
											} else {
												Util.debug('(recoverFromPuk) for optician')
												// 20.03.2020 uso la stessa funzione [ls]
												//return this.changePwdPostPuk(nuoviDati)
												return this.changePwdInternal(nuoviDati)
											}
										})
									})
								})
							} else {
								console.log('(recoverFromPuk)  invalid keyPhoto (1) ')
								throw 'Error: invalid keyPhoto (1)'
							}
						})
						.catch((err2) => {
							//var msg = (err2.data)? err2.data.error : err2.toString();  // 02.05.2018
							var msg = this.parseErrorMessage(err2, 'trace') // 28.03.2022

							console.log('(recoverFromPuk) (2) KO ' + msg)
							throw 'Error: invalid PUK, ' + msg
						})
				})
			})
			.catch((err) => {
				//var msg = (err.data)? err.data.error : err.toString();  // 02.05.2018
				var msg = this.parseErrorMessage(err, 'trace') // 28.03.2022

				console.log('(recoverFromPuk) KO ' + msg)
				//console.log(err);
				throw err
			})
	}

	// 20.03.2017
	private obtainPukBoxes(usrname) {
		let request: any

		//18.04.2018 impostare il token temporaneo arrivato dal link della mail, per ora fisso --ls

		if (!this.user.token || this.user.token.length == 0) {
			var tempToken = <TokenResponse>{}

			// 30.04.2018
			tempToken.access_token = this.getTempToken()
			tempToken.token_type = 'bearer'

			//console.log("(obtainPukBoxes) forzato token "); // ok
			request = this.buildBaseRequest(tempToken)
		} else {
			request = this.buildBaseRequest()
		}

		//let request:any = this.buildBaseRequest(tempToken);
		request.method = 'GET'

		// 21.03.2017 il replace ne sostituisce solo uno, uso parseEmail
		//var docUsrName = usrname.replace('.', '$');
		var docUsrName = this.parseEmail(usrname)

		//request.url = Config.apiEndpoint + "/user_puk/boxes/" + docUsrName;
		request.url = Config.apiEndpoint + '/recover/' + docUsrName + '/boxes/'

		return this.myGet(request)
		/*
  return this.http(request)
  .catch((err) =>  {    // 14.07.2021            
    if(!this.isExpired(err)){  // rilancia la exception
      throw err;
    }
  }); 
  */
	}

	// ***************************************************************

	// 05.05.2022
	getFeBuild() {
		let request: any = this.buildBaseRequest() // NB: richiesta non autenticata
		request.url = Config.pingEndpoint + '/buildFe/'
		return this.myGet(request)
	}

	getAllBuilds() {
		let request: any = this.buildBaseRequest() // NB: richiesta non autenticata
		request.url = Config.pingEndpoint + '/builds/'
		return this.myGet(request).then((resp) => {
			return resp.builds
		})
	}

	// ************ SAP sale plan *********************

	// 28.09.2022
	loadUserBalance(userId: number) {
		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.salePlansEndpoint + '/balance?user=' + userId

		return this.myGet(request)
	}

	// 17.10.2022 anche dell'utente loggato
	getUserBalance(userId: number): Promise<BalanceRec[]> {
		let records: BalanceRec[]
		records = []
		this.loadUserBalance(userId).then((resp) => {
			//Util.debug("(saleBalance) ");
			//console.log(resp);
			if (resp && resp.balance) {
				for (let i = 0; i < resp.balance.length; i++) {
					let rec = new BalanceRec(resp.balance[i])
					records.push(rec)
				}
			}
		})
		return Promise.all(records)
	}

	// 02.01.2023 ripristinata
	// 17.08.2021 load dal DB
	loadSalePlans(): Promise<SalePlan[]> {
		//console.log("(loadSalePlans) ");   // 09.11.2021 tolta trace

		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.salePlansEndpoint
		this.salePlans = [] // svuoto per ripartire

		return this.myGet(request).then((ris) => {
			//console.log("(S loadSalePlans) got: "); // 09.11.2021 tolta trace
			// console.log(ris) // 09.11.2021 tolta trace
			if (ris && ris.plans) {
				let list = ris.plans
				for (let i = 0; i < list.length; i++) {
					var plan = new SalePlan(list[i])
					this.salePlans.push(plan)
				}
			}
			return this.salePlans
		})

		/*
      if(ris && ris.data && ris.data.plans){
        var list = ris.data.plans;
        //console.log("(S loadSalePlans) list: "+list.length); // 09.11.2021 tolta trace
        this.salePlans = []; // svuoto per ripartire          
        for(let i=0; i<list.length; i++){ 
          var plan = new SalePlan(list[i]);                                  
          this.salePlans.push(plan);            
        }        
      }    
    })
    .catch((err) =>  {               
      if(!this.isExpired(err)){  // rilancia la exception
        throw err;
      }
    })
    .finally(() =>  {  
      // temporaneam. se DB vuoto o altro errore
      if(this.salePlans == null || this.salePlans.length==0){ 
        this.salePlans = [];
        var basicPlan = new SalePlan(this.basicSalePlan);
        this.salePlans.push(basicPlan); 
        //console.log("(S loadSalePlans) forced basic plan"); // 09.11.2021 tolta trace
      }
    });
    */
	}

	// richiamata da profile su se stesso e da admins sui doctors
	// qui non ci sono i record di balance, solo le info del piano e crediti residui
	// 28.09.2022 ask to the server for fresh values
	loadUserPlan(userId: number): Promise<SaleInfo> {
		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.salePlansEndpoint + '/' + userId

		Util.debug('S (loadUserPlan) asking for user' + userId)

		return this.myGet(request).then((ris) => {
			// console.log(ris)
			let infoPlan = null
			if (ris && ris.operator) {
				infoPlan = new SaleInfo(ris.operator)
			}

			// 29.12.2022 aggiorno anche su currentUser
			if (this.isLevel1() && userId == this.user.user_id) {
				this.user.setSaleInfo(infoPlan)
			}

			return infoPlan
		})
	}

	// 22.12.2022 data already retrieved, if asked once
	public getCurrUserPlan() {
		//return this.user.salePlan;

		if (this.user.saleInfo)
			// 11.01.2023 fix bug 296
			return this.user.saleInfo.salePlan
		else return null
	}

	public uploadCsvSap(formData) {
		let request: any = this.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.sapEndpoint
		return this.myPost(request, formData)
	}

	// 13.04.2023
	public updateUserPlan(planParams) {
		let request: any = this.buildBaseRequest()
		request.method = 'POST'

		request.url = Config.salePlansEndpoint + '/saleinfo'

		let dataReq = {
			data: {
				user_id: planParams.userId,
				plan_id: planParams.planId,
				credits: planParams.credits,
				months: planParams.months,
				kind: planParams.kind,
			},
		}

		return this.myPost(request, dataReq)
	}

	// SKU ARTICLES START
	public getSKUArticles(): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			if (this.isOptician() || this.isAdmin() || this.isSupport() || this.isSuperSupport()) {
				let request: any = this.buildBaseRequest()
				request.method = 'GET'
				request.url = Config.apiEndpoint + '/sku_articles'

				this.myGet(request)
					.then((ris) => {
						for (let sku of ris.sku_articles) {
							this.skuArticles.set(sku.sku, sku)
						}
						resolve(true)
					})
					.catch((err) => {
						reject(err)
					})
			} else {
				Util.debug('getSKUArticles: user not authorized')
				resolve(true)
			}
		})
		return promise
	}

	public getSKUArticlesList(): Promise<skuArticles[]> {
		const promise = new Promise<skuArticles[]>((resolve, reject) => {
			if (this.skuArticles.size > 0) {
				resolve(Array.from(this.skuArticles.values()))
			} else {
				this.getSKUArticles()
					.then(() => {
						resolve(Array.from(this.skuArticles.values()))
					})
					.catch((err) => {
						resolve([])
					})
			}
		})

		return promise
	}

	public getSkuDescription(sku: string): string {
		let ret: string = ''

		if (this.skuArticles.size > 0 && sku && sku.length > 0) {
			if (this.skuArticles.has(sku)) {
				ret = this.skuArticles.get(sku).description
			}
		}

		return ret
	}

	// SKU ARTICLES END

	public buyUserServices(service: purchasedService) {
		let request: any = this.buildBaseRequest()
		request.method = 'POST'

		request.url = Config.salePlansEndpoint + '/purchase'

		return this.myPost(request, service)
	}

	// ******* ROUTES ****************

	// 12.01.2022 se faccio reload, ripristino la pg dove ero...
	// 1) capire dove sono
	// 2) caricare elementi pre-requisiti -> demandato al controller specifico
	// 3) load alla pg giusta
	// questo serve anche per accesso alle pagine con link diretto (es RDS)
	manageReloadPage() {
		Util.debug('S (manageReloadPage) ')
		let origRoute = document.location.pathname
		let params = document.location.search

		//console.log("(manageReloadPage) curr path: "+origRoute);
		//console.log("(manageReloadPage) curr params: "+params);

		// estrae param specifico:
		//var patientId = origLocation.search().patientId;

		const queryString = document.location.search
		const urlParams = new URLSearchParams(queryString)

		/*
		let docId = urlParams.get('doctor')
		if (docId != null && docId != '') {
			let intVal = parseInt(docId)
			if (intVal > 0) {
				this.currentDoctorId = intVal
				Util.debug('(manageReloadPage) saved doct: ' + docId)

				// 06.05.2022 poi la pg patients lo ricarica di nuovo, capire se serve ad altre pg
				//this.loadDoctor(this.currentDoctorId);  // 18.01.2022
			} else {
				Util.debug('(manageReloadPage) invalid docId: ' + docId)
			}
		}*/
		this.initDoctorFromUrl()

		// 24.01.2023 recupero anche il patient
		let patId = urlParams.get('patient')
		if (patId != null && patId != '') {
			let intVal = parseInt(patId)
			if (intVal > 0) {
				this.currentPatientId = intVal
				Util.debug('(manageReloadPage) saved pat: ' + patId)
			} else {
				Util.debug('(manageReloadPage) invalid patId: ' + patId)
			}
		}

		var newRoute = ''
		if (params != null) {
			newRoute = origRoute + params
		} else {
			newRoute = origRoute
		}

		// 14.03.2022 test su invalid route
		if (newRoute.length < 3) {
			Util.debug('(manageReloadPage) invalid page: ' + newRoute)
			//riparto da capo
			return this.getLandingPage()
		} else {
			Util.debug('(manageReloadPage) restore pg: ' + newRoute)
			return this.loadMyPage(newRoute)
		}
	}

	private initDoctorFromUrl() {
		// estrae param specifico:
		// commentato old method to retrieve docId, al logout dalla visitList, viene mantenuto il path vecchio per quel tanto che basta che viene preso il docId
		const queryString = document.location.search
		const urlParams = new URLSearchParams(queryString)

		// console.log(queryString)
		// console.log(urlParams)

		// console.log(this.activatedRoute)
		// console.log(this.activatedRoute.snapshot.queryParams['doctor'])
		// let docId = this.activatedRoute.snapshot.queryParams['doctor'] // piú corretto

		let docId = urlParams.get('doctor')
		if (docId != null && docId != '') {
			let intVal = parseInt(docId)
			if (intVal > 0) {
				this.currentDoctorId = intVal
				Util.debug('(initDoctorFromUrl) saved doct: ' + docId)
			} else {
				Util.debug('(initDoctorFromUrl) invalid docId: ' + docId)
			}
		}
	}

	// 13.01.2022 richiamata anche da header
	public loadMyPage(myRoute) {
		console.log('S (loadMyPAge) ')
		// console.log(myRoute)
		let origRoute = document.location.pathname
		let params = document.location.search

		//console.log("(loadMyPage) curr path: "+origRoute);
		//console.log("(loadMyPage) curr params: "+params);

		this.initDoctorFromUrl() // 20.06.2023 patch per gestire la lista flat
		let flagReload = false

		// 24.03.2022 se sono un superB e passo da patient?con doctor a patients=all, forzo reload
		if (myRoute == origRoute && params != '') {
			// vanno resettati
			Util.debug('(loadMyPage) TODO reset old params: ' + params)
			//myRoute += "?doctor=all";
			//myRoute += "?reset";
			// forzare il reload
			flagReload = true

			// ko, diventa patients/%3Fdoctor%3Dall
			//return this.routing.navigate([myRoute, '?doctor=all']);  // test
		}
		// TODO, chiamare header.isCurrent(myRoute) per cambiare colore alle icone ?

		// esempio:
		// this.router.navigate(['product-list'], {queryParams: {page: this.page+1}});

		//capire quale delle due righe sotto e' meglio, con e senza parametri
		//return this.routing.navigate([myRoute]);
		if (flagReload) {
			//window.location.reload();  // ko
			// 24.03.2022 brutta patch ma funziona...
			return this.routing.navigateByUrl('working').then(() => this.routing.navigateByUrl(myRoute))
		} else {
			return this.routing.navigateByUrl(myRoute) // 13.01.2022 ok questo anche con params
		}
	}

	// 30.03.2021 aggiunto origLocation per passare parametri dalla url
	getLandingPage() {
		Util.debug('(getLandingPage) - inizio')

		let isProfileComplete = this.isProfileComplete()
		this.checkProfileComplete.next(isProfileComplete)

		// console.log(this.targetPatient)

		var route

		if (this.userShouldGoToEcommerce) {
			this.gotoEcomm()
		}

		if (this.getUserAgreementStatus(AgrStatus.AVAILABLE).res) {
			route = Config.agreement
		} else if (this.isDoctorFirstTime()) {
			//route = Config.activation;  // 14.03.2022 renamed, era firstLogin

			// 23.06.2022 attivato
			// TODO - attivare quando ci saranno gli agreement
			//25.05.2022 se esiste l'agreement per questo utente ok, altrimenti poi salta	su activation
			route = Config.activation // 25.05.2022
		} else if (this.isDoctorPukTime()) {
			// 02.02.2017
			route = Config.verifyPuk
			// check email and vat
		} else if (!isProfileComplete && (this.isLevel1() || this.isSupport())) {
			route = Config.profile
		} else if (this.isLevel1()) {
			// 04.12.2023 force transparent logo if null
			if (!this.user.logo) {
				this.updateLogo('', '')
			}
			// console.log(this.getUserBrand())

			if (this.getUserBrand() == Config.BR_RDS) {
				//if(this.getUserBrand() == Config.BR_DEFAULT){ // solo per TEST !

				// 31.05.2022 accesso diretto al paziente -> lista delle sue visite
				if (this.targetPatient > 0) {
					route = Config.patients + '?patient=' + this.targetPatient

					this.currentPatientId = this.targetPatient // 23.01.2023

					// 07.07.2022 apro anche il report pdf
					if (this.targetReport > 0) {
						route += '&report=' + this.targetReport
					}
					// oppure AI report pdf
					else if (this.targetAiReport > 0) {
						route += '&aireport=' + this.targetAiReport
					} else if (this.targetPatientEdit) {
						route = Config.patients + '?patient=' + this.targetPatient + '&edit=' + this.targetPatientEdit
						if (this.targetPatientEditAnam) {
							route += '&anamnesi=' + this.targetPatientEditAnam
						}
					}

					this.resetTargetPatient() // 14.06.2022 reset, lo uso solo una volta
					this.resetTargetReport()
					this.resetTargetAiReport()
					this.resetTargetPatientEdit()
					this.resetTargetPatientEditAnamnesis()
				} else if (this.targetBalance) {
					route = Config.subscription + '?balance=true'
					this.resetTargetBalancePage()
				} else if (this.targetPlan) {
					route = Config.subscription + '?plan=true'
					this.resetTargetPlanPage()
				} else {
					route = this.isTelerefractEnabled() ? Config.telerefract : Config.patients // lista pazienti
				}
			} else {
				route = this.isTelerefractEnabled() ? Config.telerefract : Config.patients // patients -> carica la lista pazienti
			}
		} else if (this.isLevel2() && !isProfileComplete) {
			route = Config.profile
		} else if (this.isLevel2()) {
			route = Config.patients

			// 18969 all go to patients
			// // 31.05.2023
			// if (this.isMiniC()) {
			// 	route = Config.patients
			// }
		} else if (!isProfileComplete && this.isClinicAdmin()) {
			route = Config.profile
		} else if (this.isClinicAdmin()) {
			// Clinic add 24.05.2023
			// route = Config.graders
			route = Config.dashboard
		} else if (this.isGod()) {
			// 11.01.2017

			route = Config.createUser // 08.06.2022
			//route = Config.operators;  // 14.01.2022 temp
		} else if (this.isVice()) {
			// 03.08.2018
			//route = Config.doctors;     // chiedere quale pg presentare per prima
			route = Config.operators
			//route = Config.createUser;
		} else if (this.isManager()) {
			// 27.05.2021
			route = Config.devices
		} else if (this.isStats() || this.isSupport() || this.isInstaller()) {
			// 09.11.2022
			// 20.07.2021
			//route = Config.statistics;
			route = Config.operators // 20.05.2022 temp
		} else if (this.isSynchro()) {
			// 01.06.2022
			route = Config.profile
		} else {
			// 03.08.2018 pg per profili nuovi, da gestire, sia service che altri, TODO
			//route = Config.generic;
			console.log('(getLandingPage) profile undefined!')
		}
		// console.log(route)
		//console.log("(getLandingPage) path: "+document.location.pathname);  // 30.03.2021

		Util.debug('(getLandingPage) going to: ' + route) // 11.03.2020 solo per test
		// return this.loadMyPage(route)
		// console.log(this.user)
		return this.loadMyPage(route).then(() => {
			this.userLogged.next(true)
		})
	}

	// 18.11.2021 valutare se qui e' il posto giusto
	checkRoute() {
		// TODO verificare di non esserci gia' ?

		if (this.isLogging()) {
			Util.debug('(checkRoute) is logging, curr path: ' + document.location.pathname)

			// if going to a pvt page and not logged then redirect to login
		} else if (!this.isLogged()) {
			Util.debug('(checkRoute) not logged! going to the login page.')
			return this.routing.navigateByUrl(Config.login)
		} else {
			Util.debug('(checkRoute) ok logged: ' + this.isLogged())
		}

		/*
    // TODO gestione token temporaneo per recover con puk
    var tempToken = $location.search().token;
    if(tempToken){
      //console.log("(main) tempToken: "+tempToken);
      session.setTempToken(tempToken);
    } 
    */
	}

	// 14.12.2021
	// loadVisitsPage(docId, patId: number) {
	// 	let route = Config.visits

	// 	// 23.01.2023
	// 	if (patId && patId > 0) {
	// 		this.currentPatientId = patId
	// 	} else {
	// 		//alert("invalid patient!");
	// 		console.log('S (loadVisitsPage) invalid patient!')
	// 		return
	// 	}

	// 	if (this.isLevel1()) {
	// 		route += '?patient=' + patId
	// 	} else {
	// 		if (docId && parseInt(docId) > 0) {
	// 			// 20.05.2022 ko da stats
	// 			route += '?doctor=' + docId + '&patient=' + patId

	// 			// 08.06.2023 patch per le flat list dei graders
	// 			if (this.currentDoctorId == 0) {
	// 				console.log('S (loadVisitsPage) forcing current doctor, ' + docId)
	// 				this.currentDoctorId = parseInt(docId)
	// 			}
	// 		} else {
	// 			//alert('(loadVisitsPage) invalid request!')
	// 			console.log('S (loadVisitsPage) invalid request')
	// 			return
	// 		}
	// 	}

	// 	Util.debug('(loadVisitsPage) :' + route + ' saved pat: ' + this.currentPatientId)
	// 	//console.log(this.routing);

	// 	return this.loadMyPage(route)
	// }

	loadTrialPage() {
		Util.debug('(loadTrialPage)')
		let route = Config.trial
		return this.loadMyPage(route)
	}

	isEcommerceEnabled(): boolean {
		return this.user.settings.ecommerce == 'Y'
	}

	isFreeTrialAvailable(): boolean {
		return this.user.settings.free_trial_available == 'Y'
	}

	gotoEcomm() {
		// console.log(this.user)
		// let agreements = this.getUserAgreementStatus(AgrStatus.AVAILABLE)
		// console.log(agreements)
		// let isTerms = agreements.agreements.find((t) => t.doc_type == AgrType.TERMS).doc_type == AgrType.TERMS
		// console.log(isTerms)
		// if (agreements.res && isTerms) {
		// 	this.userShouldGoToEcommerce = true
		// 	return this.loadMyPage(Config.agreement)
		// }

		if (this.isMissingMandatoryVat()) {
			if (this.profileRedirectAuxFunc) this.profileRedirectAuxFunc()
			return this.loadProfilePage()
		}

		this.getCountries()
			.then(() => {
				this.userShouldGoToEcommerce = false
				let c: Country = this.getSingleCountry(this.user.country)
				Util.debug('Country For E-Commerce: '.concat(c.alpha2))
				let cookieName: String = 'nexus'
				let cookieValue: String = sessionStorage.getItem('user')
				console.log(cookieValue)
				let myDate: Date = new Date()
				myDate.setMinutes(myDate.getMinutes() + 5)
				document.cookie = cookieName + '=' + cookieValue + ';expires=' + myDate + ';domain=.nexus.visionix.com;path=/;SameSite=Strict'
				window.open(environment.ecommerce.concat('/').concat(c.alpha2), 'ecommerce').focus()
			})
			.catch(() => {
				Util.debug('Error Retrieve Country Lists')
			})
	}

	loadPatientsPage(docId?) {
		let route = Config.patients

		if (!this.isLevel1()) {
			route += '?doctor=' + docId

			if (docId != this.currentDoctorId) {
				Util.debug('(loadPatientsPage) moving from oper ' + this.currentDoctorId + ' to ' + docId)
				this.currentDoctorId = docId
				this.data.changeDoctor() // 28.01.2022 ripulisce memoria
			} else {
				Util.debug('(loadPatientsPage) same lev1, ' + this.currentDoctorId)
			}

			// 06.05.2022 TEMP TODO
			if (this.isLevel3()) {
				route += '&status=active'
			}
		} else if (this.isGroupB() && docId != null) {
			// 23.03.2022 arriva qui anche da groupB
			route += '?doctor=' + docId
			Util.debug('(loadPatientsPage) filtering for doct ' + docId)
		}

		Util.debug('(loadPatientsPage) :' + route)
		//return this.routing.navigateByUrl(route);
		return this.loadMyPage(route)
	}

	// 25.03.2022
	loadDoctorsPage(refId?) {
		let route = Config.operators

		Util.debug('(loadDoctorsPage) :' + route)
		//console.log(this.routing);

		return this.loadMyPage(route)
	}

	// 04.05.2022
	// loadRelationsPage(refId: number) {
	// 	let route = Config.relations

	// 	// 20.04.2023 cambiato test
	// 	// 04.05.2022
	// 	//if (this.isLevel3() && refId) {
	// 	if (!this.isSpecialist() && refId) {
	// 		// lista doctors di questo refertatore
	// 		route += '?distrib=' + refId
	// 	}
	// 	return this.loadMyPage(route)
	// }

	// 15.09.2022 subito dopo la first login, finito ok puk
	loadProfilePage() {
		let route = Config.profile
		return this.loadMyPage(route)
	}

	// ************************************************

	// funzioni di get sugli oggetti, per evitare session.getData().xx

	// getDtExam() {
	// 	return this.data.exam
	// }

	// getDtExamList() {
	// 	return this.data.examList
	// }

	// ******* profile user pwd ********

	// 17.02.2021 rinominata
	// 13.02.2017
	//public isValidPwd(myPwd):boolean {
	public isCorrectPwd(myPwd): boolean {
		return this.user.password == myPwd
	}

	// 30.09.2021
	canChangePwd() {
		// 07.05.2024 ripristinato specialist --ls
		// 07.05.2024 tolto level3, e' stato mai testato ? --ls
		// 22.12.2022 TODO aggiungere support, specialist rotto non va
		var ret = (this.isLevel1() && !this.isGroupB()) || this.isSpecialist() // || this.isSupport()  || this.isLevel3()

		// this.isManager()  // non ha la key vice
		// isStats() // TODO

		// provvisorio 22.06.23
		if (this.isMiniC()) {
			ret = false
		}

		return ret
	}

	canAccessOptions() {
		var ret = false

		if (this.isLevel1()) {
			ret = true
		}

		if (this.isSpecialist()) {
			ret = true
		}

		if (this.isLevel3()) {
			ret = true
		}

		if (this.isClinicAdmin() && this.hasEmail()) {
			ret = true
		}

		if (this.isInstaller()) {
			ret = true
		}

		if (this.isSupport()) {
			ret = true
		}

		if (this.isAdmin()) {
			ret = true
		}

		return ret
	}

	// 14.03.2022 activation - first login
	// 14.12.2017 e' una funzione sicura? --ls
	getNuovoPuk() {
		var ret = ''
		if (this.loggedUser.nuovoPuk && this.loggedUser.nuovoPuk.length > 0) ret = this.loggedUser.nuovoPuk

		return ret
	}

	// 06.06.2023 estesa anche ai lev2
	// richiamata alla firstLogin, da activationComponent, forza il cambio pwd
	/**
    Will do in order:
     generate a keyDoctor,
     crypt the keyDoctor into a keybox with the new password,
     decrypt the keyBoxPhoto with the old password,
     crypt the keyPhoto into a keybox with the new password,

     generate a PUK,
     crypt the keyDoctor into a keybox with the PUK,
     crypt the keyPhoto into a keybox with the PUK,

     generate a couple private-pubblic key,   // 03.2020
     encrypt the private with the keyDoctor

     update the password/profile.
     refresh the local profile.

  */
	initializeUserAccount(newPassword) {
		// 03.07.2023 patch temporanea per sviluppo veloce, duplicate le funzioni.
		// TODO riunirle [ls]
		if (this.user.subType == UserType.SPECIALIST) {
			return this.initializeSpecialistAccount(newPassword)
		} else {
			// standard opticians
			return this.initializeOptAccount(newPassword)
		}
	}

	// 03.07.2023 portata fuori, temporaneamente duplicata
	private initializeOptAccount(newPassword) {
		var oldPassword = this.user.password
		var result: any = {}

		//var keyPrivacy = this.cryptoUtils.generateRandomKeyAES();

		return this.cryptoUtils
			.generateRandomKeyAES()
			.then((keyPrivacy) => {
				result.keyPrivacy = keyPrivacy // questa e' quella che usera' per crittare i nomi dei pazienti, nota solo a lui

				// 22.03.2017
				Util.debug('(initializeUsrAccount) key priv len:' + keyPrivacy.length)
				if (keyPrivacy.length != 32) {
					console.log('(initializeUsrAccount) ERROR on len key priv ' + keyPrivacy.length)
					// uscire
					throw 'Error: invalid keyPrivacy len' // 22.03.2017
				}

				// 29.04.2020 uso lo username per "salare" la pwd
				var mySalt = this.user.username.toLowerCase()
				//return this.cryptoUtils.encryptDataWithPwd(newPassword, result.keyPrivacy)
				return this.cryptoUtils.encryptDataWithPwdS(newPassword, result.keyPrivacy, mySalt).then((keyboxDoctor) => {
					//console.log("keyBoxDoctor: "+keyboxDoctor);
					result.keyboxDoctor = keyboxDoctor

					Util.debug('(initializeUsrAccount) keyBoxPriv len:' + keyboxDoctor.length)
					if (keyboxDoctor.length != this.KEYBOX_LEN) {
						// era 64, ora c'e' iv davanti
						console.log('(initializeUsrAccount) ERROR on len keyBoxPriv ' + keyboxDoctor.length)
						// uscire
						throw 'Error: invalid keyBoxPriv len'
					}

					return this.cryptoUtils.decryptDataWithPwdS(oldPassword, this.user.keyboxPhoto, mySalt).then((keyPhoto) => {
						//console.log("(initializeUsrAccount) key photo:"+keyPhoto);   // TOGLIERE in prod
						result.keyPhoto = keyPhoto // serve dopo per il puk

						return this.cryptoUtils
							.encryptDataWithPwdS(newPassword, keyPhoto, mySalt)
							.then((keyboxPhoto) => {
								result.keyboxPhoto = keyboxPhoto
								//console.log("keyboxPhoto:"+keyboxPhoto);

								Util.debug('(initializeUsrAccount) keyboxPhoto len:' + keyboxPhoto.length)
								if (keyboxPhoto.length != this.KEYBOX_LEN) {
									// era 64, ora c'e' iv davanti
									console.log('(initializeUsrAccount) ERROR on len keyboxPhoto ' + keyboxPhoto.length)
									//   uscire
									throw 'Error: invalid keyboxPhoto len'
								}

								return this.cryptoUtils.generatePUK()
							})
							.then((puk) => {
								result.puk = puk
								Util.debug('(initializeUsrAccount) nuovo puk ' + puk + ', len: ' + puk.length)

								// 03.07.2023 differenzio il contenuto della puk_metadata e len_keybox
								let expectedLen = this.KEYBOX_LEN

								//let metadataContent = null;
								let metadataContent = result.keyPrivacy // per crittare dati pazienti

								return this.cryptoUtils.encryptDataWithPuk(puk, metadataContent).then((keyboxDoctorPUK) => {
									result.keybox_metadata = keyboxDoctorPUK
									//console.log("puk_metadata: "+keyboxDoctorPUK);

									Util.debug('(initializeUsrAccount) keyboxDoctorPUK len:' + keyboxDoctorPUK.length)

									if (keyboxDoctorPUK.length != expectedLen) {
										// era 64, ora c'e' iv davanti
										console.log('(initializeUsrAccount) ERROR on len keyboxDoctorPUK ' + keyboxDoctorPUK.length)
										// 22.03.2017  uscire
										throw 'Error: invalid keyboxDoctorPUK len'
									}

									return puk
								})
							})
							.then(() => {
								return this.cryptoUtils.encryptDataWithPuk(result.puk, result.keyPhoto).then((keyboxPhotoPUK) => {
									result.keyboxPhotoPUK = keyboxPhotoPUK
									//console.log("puk_data: "+keyboxPhotoPUK);

									Util.debug('(initializeUsrAccount) keyboxPhotoPUK len:' + keyboxPhotoPUK.length)
									if (keyboxPhotoPUK.length != this.KEYBOX_LEN) {
										// era 64, ora c'e' iv davanti
										console.log('(initializeUsrAccount) ERROR on len keyboxPhotoPUK ' + keyboxPhotoPUK.length)
										// 22.03.2017  uscire
										throw 'Error: invalid keyboxPhotoPUK len'
									}

									return result.puk
								})
							})
					})
				})
			})
			.then(() => {
				var username = this.user.username

				// 19.03.2020 uso forge [ls]
				//var nuova_hash_pwd = cryptoJs.SHA256(username.toLowerCase()+newPassword).toString();
				var nuova_hash_pwd = this.cryptoUtils.getSHA256(username.toLowerCase() + newPassword).toString()

				//console.log("(initializeUsrAccount) "+username+" nuova hash_pwd: "+nuova_hash_pwd);

				var pem1 = ''
				var pem2 = ''

				// solo per lev1 users

				// synchronously
				//console.log("keyPair - inizio");
				var keypair = this.cryptoUtils.getKeyPair()
				//console.log("keyPair - fine");  // esame tempo ? 5 secondi...

				//asynchronously - 03.04.2020 - KO
				//this.cryptoUtils.genKeyPair()
				//.then((keypair) => {
				//}

				if (!keypair) {
					Util.debug('(initializeUsrAccount) ko keyPair!')
					throw 'Error: invalid keyPair for level1 user'
				}

				// convert a Forge public key to PEM-format (which is binary encoded as base64)
				//pem1 = this.cryptoUtils.forge.pki.publicKeyToPem(keypair.publicKey);
				pem1 = this.cryptoUtils.publicKeyToPem(keypair.publicKey) // 14.03.2022

				//Util.debug("(initializeDoctorAccount) public: "+pem1); // then save on DB

				// 19.03.2020 TODO-FIXME il box viene troppo grande, viene fatto 2 volte il base64...
				//privKey = keypair.privateKey;
				//console.log("(initializeDoctorAccount) priv len: "+privKey.length); // undefined

				//pem2 = this.cryptoUtils.forge.pki.privateKeyToPem(keypair.privateKey);
				pem2 = this.cryptoUtils.privateKeyToPem(keypair.privateKey) // 14.03.2022

				//console.log("(initializeDoctorAccount) private: "+pem2);  // SOLO PER TEST ****

				// ************************************
				// 23.03.2020 cambio importante: critto con la keyPrivacy del doctor, non con la pwd,
				// cosi' non serve aggiornare il campo con i cambi pwd
				//return this.cryptoUtils.encryptDataWithPwd(newPassword, pem2)  // 11.03.2020
				// **************************************
				var myDoctKey = result.keyPrivacy
				return this.cryptoUtils
					.encryptDataWithMyKey(myDoctKey, pem2) // 11.03.2020
					.then((priv_keybox) => {
						Util.debug('(initializeUsrAccount) len priv-keybox: ' + priv_keybox.length) // 24

						var nuoviDati = {
							//action: "first_login",        // 11.03.2020
							password: nuova_hash_pwd,
							keybox_doctor: result.keyboxDoctor,
							keybox_photo: result.keyboxPhoto,
							puk_metadata: result.keybox_metadata,
							puk_data: result.keyboxPhotoPUK,
							public_key: pem1,
							private_keybox: priv_keybox,
						}

						return this.initializeAccountInternal(nuoviDati)
					})
					.then(() => {
						Util.debug('(initializeUsrAccount) Req sent to server, now refresh profile...')
						this.user.password = newPassword //otherwise profile decryption won't work, better to do a full logout to fix also the token
						return this.refreshProfile().then(() => {
							// meglio ritornarlo in base64, cosi' l'utente se lo salva --ls
							return result.puk
						})
					})
			})
	}

	// 03.07.2023 temporaneamente duplicata da initializeOptAccount
	private initializeSpecialistAccount(newPassword) {
		var oldPassword = this.user.password
		var result: any = {}

		// 29.04.2020 uso lo username per "salare" la pwd
		var mySalt = this.user.username.toLowerCase()

		// estraggo la private_key
		return this.cryptoUtils
			.decryptDataWithPwdS(oldPassword, this.user.privateKeybox, mySalt)
			.then((keyPrivate) => {
				result.private_key = keyPrivate
				//Util.debug('(initializeSpecAccount) private key');
				//Util.debug(result.private_key);

				return this.cryptoUtils.decryptDataWithPwdS(oldPassword, this.user.keyboxPhoto, mySalt).then((keyPhoto) => {
					//console.log("(initializeUsrAccount) key photo:"+keyPhoto);   // TOGLIERE in prod
					result.keyPhoto = keyPhoto // serve dopo per il puk

					return this.cryptoUtils
						.encryptDataWithPwdS(newPassword, keyPhoto, mySalt)
						.then((keyboxPhoto) => {
							result.keyboxPhoto = keyboxPhoto
							//console.log("keyboxPhoto:"+keyboxPhoto);

							Util.debug('(initializeSpecAccount) keyboxPhoto len:' + keyboxPhoto.length)
							if (keyboxPhoto.length != this.KEYBOX_LEN) {
								// era 64, ora c'e' iv davanti
								console.log('(initializeSpecAccount) ERROR on len keyboxPhoto ' + keyboxPhoto.length)
								//   uscire
								throw 'Error: invalid keyboxPhoto len'
							}

							return this.cryptoUtils.generatePUK()
						})
						.then((puk) => {
							result.puk = puk
							Util.debug('(initializeSpecAccount) nuovo puk ' + puk + ', len: ' + puk.length)

							// 03.07.2023 differenzio il contenuto della puk_metadata e len_keybox
							let expectedLen = this.KEYBOX_LEN

							let metadataContent = null

							// 03.07.2023 salvo la Private_key anche chiusa con il puk, per eventuale recover
							metadataContent = result.private_key // per crittografia asimmetrica
							expectedLen = this.KEYBOX_PUK_MINIC_LEN // 2304 // FIXME - sempre ?? 03.07.2023

							return this.cryptoUtils.encryptDataWithPuk(puk, metadataContent).then((keyboxDoctorPUK) => {
								result.keybox_metadata = keyboxDoctorPUK
								//console.log("puk_metadata: "+keyboxDoctorPUK);

								Util.debug('(initializeSpecAccount) keyboxDoctorPUK len:' + keyboxDoctorPUK.length)

								if (keyboxDoctorPUK.length != expectedLen) {
									// era 64, ora c'e' iv davanti
									console.log('(initializeSpecAccount) ERROR on len keyboxDoctorPUK ' + keyboxDoctorPUK.length)
									// 22.03.2017  uscire
									throw 'Error: invalid keyboxDoctorPUK len'
								}

								return puk
							})
						})
						.then(() => {
							return this.cryptoUtils.encryptDataWithPuk(result.puk, result.keyPhoto).then((keyboxPhotoPUK) => {
								result.keyboxPhotoPUK = keyboxPhotoPUK
								//console.log("puk_data: "+keyboxPhotoPUK);

								Util.debug('(initializeSpecAccount) keyboxPhotoPUK len:' + keyboxPhotoPUK.length)
								if (keyboxPhotoPUK.length != this.KEYBOX_LEN) {
									// era 64, ora c'e' iv davanti
									console.log('(initializeSpecAccount) ERROR on len keyboxPhotoPUK ' + keyboxPhotoPUK.length)
									// 22.03.2017  uscire
									throw 'Error: invalid keyboxPhotoPUK len'
								}

								return result.puk
							})
						})
				})
			})
			.then(() => {
				var username = this.user.username

				// 19.03.2020 uso forge [ls]
				//var nuova_hash_pwd = cryptoJs.SHA256(username.toLowerCase()+newPassword).toString();
				var nuova_hash_pwd = this.cryptoUtils.getSHA256(username.toLowerCase() + newPassword).toString()

				//console.log("(initializeDoctorAccount) "+username+" nuova hash_pwd: "+nuova_hash_pwd);

				var pem1 = ''
				var pem2 = ''
				//var privKey = "";

				// uso lo username per "salare" la pwd
				var usrSalt = this.user.username.toLowerCase()

				return this.cryptoUtils.decryptDataWithPwdS(oldPassword, this.user.privateKeybox, usrSalt).then((keyPrivate) => {
					//console.log('(initializeSpecAccount) key private:' + keyPrivate) // TOGLIERE in prod!!!
					result.private_key = keyPrivate // 03.07.2023 serve prima !! ?

					return this.cryptoUtils
						.encryptDataWithPwdS(newPassword, keyPrivate, usrSalt)
						.then((priv_keybox) => {
							result.private_keybox = priv_keybox
							//console.log("keyboxPhoto:"+keyboxPhoto);

							Util.debug('(initializeSpecAccount) privateKeyBox len:' + priv_keybox.length)
							// if (privateKeyBox.length != this.KEYBOX_LEN) {
							// 	// era 64, ora c'e' iv davanti
							// 	console.log('(initializeSpecAccount) ERROR on len keyboxPhoto ' + keyboxPhoto.length)
							// 	//   uscire
							// 	throw 'Error: invalid keyboxPhoto len'
							// }

							var nuoviDati = {
								//action: "first_login",        // 11.03.2020
								password: nuova_hash_pwd,
								//keybox_doctor: result.keyboxDoctor,   // per i graders non serve
								keybox_photo: result.keyboxPhoto,
								puk_metadata: result.keybox_metadata, // 03.07.2023 per i graders uso lo stesso campo, cambio il contenuto
								puk_data: result.keyboxPhotoPUK,
								public_key: pem1,
								private_keybox: priv_keybox,
							}

							return this.initializeAccountInternal(nuoviDati)
						})
						.then(() => {
							Util.debug('(initializeSpecAccount) Req sent to server, now refresh profile...')
							this.user.password = newPassword //otherwise profile decryption won't work, better to do a full logout to fix also the token
							return this.refreshProfile().then(() => {
								// meglio ritornarlo in base64, cosi' l'utente se lo salva --ls
								return result.puk
							})
						})
				})
			})
	}

	// renamed, used for both opticians and specialists
	private initializeAccountInternal(rawAccessData) {
		let request: any = this.buildBaseRequest()
		request.method = 'PUT' // e' un update del record

		var usrId = this.user.user_id // 11.03.2020
		//request.url = Config.doctorsInitializeEndpoint;  // doctors/initialize
		request.url = Config.usersEndpoint + '/' + usrId // users, firstLogin

		//request.data = {user: rawAccessData};
		//request.data = {action: "first_login", user: rawAccessData};

		let reqData = { user: rawAccessData }
		return this.myPut(request, reqData)

		/*
      return this.http(request)
      .catch((err) =>  {    // 14.07.2021            
        if(!this.isExpired(err)){  // rilancia la exception
          throw err;
        }
      }); 
      */
	}

	// alias comodo per il grader loggato
	private amendMyKeyPair(): Promise<any> {
		return this.amendKeyPair(this.user.user_id, this.user.username, this.user.password)
	}

	// 06.06.2023 generate keyPair
	// used both for logged grader or after create a grader
	// non aspetto la risposta, va in bg
	private amendKeyPair(userId, username, userPwd): Promise<any> {
		if (this.isLevel2()) {
			Util.debug('(generateKeyPi) I am lev2')
		} else if (this.isAdmin() || this.isClinicAdmin()) {
			Util.debug('(generateKeyPi) I am admin')
		} else {
			return Promise.reject('forbidden!')
		}

		// this.setLoading(true) // attivo un flag per attivita' in BG

		// NB: chiamare questa solo se davvero va fatto! non deve sovrascrivere esistente
		Util.debug('(generateKeyPi) start generating the keypair for ' + username)

		return this.getKeyPairCouple(username, userPwd)
			.then((nuoviDati) => {
				return this.updateUserKeyPair(userId, nuoviDati)
			})
			.catch((err) => {
				// this.setLoading(false)
				throw err
			})
	}

	// 15.06.2023 genero coppia di chiavi e keybox per quella privata.
	// SOLO per i GRADERS, perche' gli optician chiudono la keybox con la keyDoctor.
	// Ritorna json da usare come body per le chiamate al server
	private getKeyPairCouple(username: string, userPwd: string): Promise<any> {
		var pem1 = ''
		var pem2 = ''

		var keypair = this.cryptoUtils.getKeyPair()
		//console.log("keyPair - fine");  // esame tempo ? 5 secondi...

		if (!keypair) {
			return Promise.reject('ko keypair generation!')
		}

		// convert a Forge public key to PEM-format (which is binary encoded as base64)
		pem1 = this.cryptoUtils.publicKeyToPem(keypair.publicKey)

		//Util.debug("(generateKeyPi) public: "+pem1); // then save on DB

		//pem2 = this.cryptoUtils.forge.pki.privateKeyToPem(keypair.privateKey);
		pem2 = this.cryptoUtils.privateKeyToPem(keypair.privateKey) // 14.03.2022

		//Util.debug("(generateKeyPi) private: "+pem2);  // SOLO PER TEST ****

		// ************************************
		// NB: per i lev1 critto con la keyDoctor dello user, non con la sua pwd, cosi' non serve aggiornare il campo con i cambi pwd
		// this.cryptoUtils.encryptDataWithMyKey(myDoctKey, pem2)
		// 06.06.2023 i lev2 non ce l'hanno
		// **************************************
		let mySalt = username.toLowerCase()

		return this.cryptoUtils.encryptDataWithPwdS(userPwd, pem2, mySalt).then((priv_keybox) => {
			Util.debug('(generateKeyPi) len priv-keybox: ' + priv_keybox.length) // 24

			var nuoviDati = {
				public_key: pem1,
				private_keybox: priv_keybox,
			}

			return nuoviDati
		})
	}

	// *********** PUK *****************************

	// 02.02.2017 usa il puk per decrittare
	/*
Per verificare che il PUK sia corretto basta avere user_access.keybox_puk_data. 
Se il PUK inserito è corretto, si usa per decrittare
keybox_puk_data, e per confrontare i dati così decrittati con KEY_PHOTO. 
Se la decrittazione e il confronto hanno successo, significa che il PUK è corretto.
*/
	// se ok manda al server un update dell'access_counter
	verifyDoctorPuk(usrPuk) {
		var result: any = {}

		if (this.user.keyPhoto != null) {
			//console.log("(verifyDoctorPuk) OK keyPhoto, len: "+this.user.keyPhoto.length ); // ko
			Util.debug('S (verifyDoctorPuk) OK keyPhoto')
		} else {
			console.log('(verifyDoctorPuk) KO keyPhoto')
			//return false;
			throw 'Error: invalid keyPhoto' // 23.02.2017
		}

		return this.cryptoUtils
			.decryptDataWithPuk(usrPuk, this.user.pukData) // 13.02.2017
			.then((keyPhotoFromPuk) => {
				// ko ==, sono ByteStringBuffer

				// 15.03.2022
				let areEquals = this.cryptoUtils.compareKeys(this.user.keyPhoto, keyPhotoFromPuk)
				if (areEquals == true) {
					//if(angular.equals(this.user.keyPhoto, keyPhotoFromPuk)) {

					Util.debug('(verifyDoctorPuk) OK, valid')

					// invia al server update del counter
					var data1 = {
						access_counter: 2,
						last_update: new Date().toISOString(),
					} //  toLocaleString ko con opera

					return this.updatePukDoctor(data1)
				} else {
					console.log('(verifyDoctorPuk) KO')
					throw 'Error: invalid Puk' // 23.02.2017
					//return false;
				}
			})
			.then(() => {
				return this.refreshProfile().then(() => {
					return result.user_id
				})
			})
	}

	// 06.06.2023  [PUT] /users/<id>/keypair
	private updateUserKeyPair(userId, rawAccessData) {
		let request: any = this.buildBaseRequest()
		request.method = 'PUT'
		request.url = Config.usersEndpoint + '/' + userId + '/keypair'
		let myData = { user: rawAccessData }
		return this.myPut(request, myData)
	}

	private updatePukDoctor(rawAccessData) {
		let request: any = this.buildBaseRequest()
		request.method = 'PUT'
		request.url = Config.profilesEndpoint + '/' + this.getUserId() + '/puk'
		let myData = { data: rawAccessData }
		return this.myPut(request, myData)
	}

	// richiamato da verifyPuk, se utente durante la first login non lo ha salvato
	// 04.03.2021 NB: sulla nexus mancano le api
	// 14.12.2017  come parte finale di initializeDoctorAccount --ls
	DISAB_resetDoctorsPuk() {
		var result: any = {}
		var currUser: User

		currUser = this.getCurrentUser() // prendo dall'utente gia' loggato

		// 14.03.2022
		//result.keyPrivacy = angular.copy(currUser.keyDoctor);
		//result.keyPhoto = angular.copy(currUser.keyPhoto);
		result.keyPrivacy = currUser.keyDoctor
		result.keyPhoto = currUser.keyPhoto

		return this.cryptoUtils
			.generatePUK()
			.then((puk) => {
				result.puk = puk
				Util.debug('(resetDoctPuk) nuovo puk ' + puk + ', len: ' + puk.length)
				return this.cryptoUtils.encryptDataWithPuk(puk, result.keyPrivacy).then((keyboxDoctorPUK) => {
					result.keybox_metadata = keyboxDoctorPUK
					//console.log("(resetDoctPuk) keyboxDoctorPUK len:"+keyboxDoctorPUK.length);
					if (keyboxDoctorPUK.length != this.KEYBOX_LEN) {
						// era 64, ora c'e' iv davanti
						console.log('(resetDoctPuk) ERROR on len keyboxDoctorPUK ' + keyboxDoctorPUK.length)
						throw 'Error: invalid keyboxDoctorPUK len'
					}
					return puk
				})
			})
			.then(() => {
				return this.cryptoUtils.encryptDataWithPuk(result.puk, result.keyPhoto).then((keyboxPhotoPUK) => {
					result.keyboxPhotoPUK = keyboxPhotoPUK
					//console.log("(resetDoctPuk) keyboxPhotoPUK len:"+keyboxPhotoPUK.length);
					if (keyboxPhotoPUK.length != this.KEYBOX_LEN) {
						// era 64, ora c'e' iv davanti
						console.log('(resetDoctPuk) ERROR on len keyboxPhotoPUK ' + keyboxPhotoPUK.length)
						throw 'Error: invalid keyboxPhotoPUK len'
					}
					return result.puk // 19.01.2017
				})
			})
			.then(() => {
				var nuoviDati = {
					puk_metadata: result.keybox_metadata,
					puk_data: result.keyboxPhotoPUK,
				}
				return this.initializeDoctorPuk(nuoviDati)
			})
			.then(() => {
				Util.debug('(resetDoctPuk) inviata Req al server, ora refresh dati')

				this.loggedUser.nuovoPuk = result.puk // 14.12.2017 per poterlo poi esporre

				return this.refreshProfile().then(() => {
					return result.puk
				})
			})
	}

	// 14.12.2017 salva keybox con nuovo puk
	private initializeDoctorPuk(rawAccessData) {
		let request: any = this.buildBaseRequest()
		request.method = 'PUT' // e' un update del record
		request.url = Config.doctorsEndpoint + '/resetPuk'

		//request.data = {data: rawAccessData};
		let reqData = { data: rawAccessData }

		return this.myPut(request, reqData)

		/*
    return this.http(request)
    .catch((err) =>  {    // 14.07.2021            
      if(!this.isExpired(err)){  // rilancia la exception
        throw err;
      }
    });
    */
	}

	// 13.02.2017
	private changePwdInternal(rawAccessData) {
		let request: any //  = this.buildBaseRequest();

		var usrId = 0 // 07.06.2021 se arrivo dal recover con puk, non ce l'ho

		//30.04.2018 imposta il token temporaneo arrivato dal link della mail --ls
		if (!this.user.token || this.user.token.length == 0) {
			var tempToken = <TokenResponse>{}
			tempToken.access_token = this.getTempToken()
			tempToken.token_type = 'bearer'
			request = this.buildBaseRequest(tempToken)
			Util.debug('(changePwdP-Puk) forzato token ')
		} else {
			request = this.buildBaseRequest()
			usrId = this.user.user_id // 11.03.2020
		}

		request.method = 'PUT' // e' un update del record, con post su old_boxes

		// spostato sopra
		//var usrId = this.user.user_id;   // 11.03.2020

		request.url = Config.profilesEndpoint + '/' + usrId + '/changepwd'

		//request.data  // 05.01.2022
		let dataReq = { data: rawAccessData }

		// return this.http(request)
		return this.myPut(request, dataReq)
	}

	// 13.02.2017 change pwd
	/**
Servono i campi user_access.keybox_doctor e user_access.keybox_photo; // gia' ricevuti alla profile
questi campi vengono decrittati con la vecchia password, 
ri-crittati con la nuova e inviati al server.
Il server archivia i valori precedenti di user_access.keybox_doctor e user_access.keybox_photo in
user_access_old_keyboxes, per mantenere uno storico delle keyboxes usate (in caso il medico dimentichi l’ultima password scelta).
La nuova password viene salata e hashata sul lato client, e inviata al server che provvederà a salvarla al posto di quella vecchia.

    Will do in order:

     decrypt the keyBoxPhoto with the old password,
     decrypt the keyBoxPriv with the old password,
     crypt the keyDoctor with the new password,
     crypt the keyPhoto with the new password,

  */
	//changeDoctorsPwd(newPassword) {
	//	var oldPassword = this.user.password
	//	var result: any = {}
	private changeDoctorsPwd(newPassword) {
		var oldPassword = this.user.password
		var result: any = {}

		result.privateKeybox = '' // 11.03.2020

		Util.debug('S (changeDoctorsPwd) inizio')

		// 29.04.2020 uso lo username per "salare" la pwd
		var docSalt = this.user.username.toLowerCase()

		var keyPhoto = this.cryptoUtils.decryptBoxWithPwdS(oldPassword, this.user.keyboxPhoto, docSalt)

		var keyPrivacy = keyPhoto

		if (this.isLevel1()) {
			keyPrivacy = this.cryptoUtils.decryptBoxWithPwdS(oldPassword, this.user.keyboxDoctor, docSalt)
		}

		// console.log(this.user.privateKeybox)

		// 07.05.2024 uso stessa var di appoggio per estrarre una key diversa, per i graders
		if (this.isSpecialist()) {
			keyPrivacy = this.cryptoUtils.decryptBoxWithPwdS(oldPassword, this.user.privateKeybox, docSalt)
		}

		return this.cryptoUtils.encryptDataWithPwdS(newPassword, keyPrivacy, docSalt).then((keyboxDoctor) => {
			result.keyboxDoctor = keyboxDoctor

			Util.debug('(changeDoctorsPwd) keyboxDoctor len:' + keyboxDoctor.length)
			if (this.isLevel1() && keyboxDoctor.length != this.KEYBOX_LEN) {
				// era 64, ora c'e' iv davanti
				console.log('(changeDoctorsPwd) ERRORE su len keyboxDoctor ' + keyboxDoctor.length)

				throw 'Error: invalid keyboxDoctor len' // esce
			} else if (this.isSpecialist()) {
				// 09.05.2024
				if (keyboxDoctor.length != this.KEYBOX_PUK_MINIC_LEN) {
					// per ora solo trace
					console.log('(changeDoctorsPwd) len keyboxDoctor ' + keyboxDoctor.length) // 2304
				}
			}

			return this.cryptoUtils.encryptDataWithPwdS(newPassword, keyPhoto, docSalt).then((keyboxPhoto) => {
				result.keyboxPhoto = keyboxPhoto

				//console.log("keyboxPhoto:"+keyboxPhoto);

				Util.debug('(changeDoctorsPwd) keyboxPhoto len:' + keyboxPhoto.length) // 88

				if (keyboxPhoto.length != this.KEYBOX_LEN) {
					// era 64, ora c'e' iv davanti
					console.log('(changeDoctorsPwd) ERRORE su len keyboxPhoto ' + keyboxPhoto.length)
					throw 'Error: invalid keyboxPhoto len'
				}

				var username = this.user.username

				// 19.03.2020 uso forge [ls]
				//var nuova_hash_pwd = cryptoJs.SHA256(username.toLowerCase()+newPassword).toString();
				var nuova_hash_pwd = this.cryptoUtils.getSHA256(username.toLowerCase() + newPassword).toString()

				var nuoviDati = {
					password: nuova_hash_pwd,
					keybox_doctor: result.keyboxDoctor,
					keybox_photo: result.keyboxPhoto,
					//private_keybox: result.privateKeybox   // 23.03.2020 ora crittata con la key_doctor, non serve piu' [ls]
				}

				// 07.05.2024
				if (this.isSpecialist()) {
					nuoviDati['private_keybox'] = result.keyboxDoctor // riutilizzo delle variabili con sign diverso!
					nuoviDati['keybox_doctor'] = null
				}

				return this.changePwdInternal(nuoviDati).then((res) => {
					Util.debug('(changePwd) inviata Req al server, ora refresh dati')
					this.user.password = newPassword //otherwise profile decryption won't work,
					this.saveCookieUser()

					if (this.isSpecialist()) {
						this.refreshProfile().then(() => {
							return this.reactivateAllRelations()
						})
					} else {
						return this.refreshProfile()
					}
				})
			})
		})
	}

	// 28.09.2021 change pwd for level3 users
	/**
    La nuova password viene salata e hashata sul lato client, e inviata al server 
    che provvederà a salvarla al posto di quella vecchia.
    Will do in order:
    - decrypt the keyBoxes with the old password,
    - crypt the keyBoxes with the new password,    
  */
	//changeAdminsPwd(newPassword) {
	//	var oldPassword = this.user.password
	//	var result: any = {}
	private changeAdminsPwd(newPassword) {
		var oldPassword = this.user.password
		var result: any = {}

		result.privateKeybox = '' // 11.03.2020

		Util.debug('(changeAdminsPwd) inizio')

		// 29.04.2020 uso lo username per "salare" la pwd
		var mySalt = this.user.username.toLowerCase()

		var keyPhoto = this.cryptoUtils.decryptBoxWithPwdS(oldPassword, this.user.keyboxPhoto, mySalt)
		var keyVice = this.cryptoUtils.decryptBoxWithPwdS(oldPassword, this.user.keyboxVice, mySalt)

		return this.cryptoUtils
			.encryptDataWithPwdS(newPassword, keyPhoto, mySalt)
			.then((keyboxPhoto) => {
				result.keyboxPhoto = keyboxPhoto
				Util.debug('(changeAdminsPwd) keyboxPhoto len:' + keyboxPhoto.length)
				if (keyboxPhoto.length != this.KEYBOX_LEN) {
					// 88 era 64, ora c'e' iv davanti
					console.log('(changeAdminsPwd) ERRORE su len keyboxPhoto ' + keyboxPhoto.length)
					throw 'Error: invalid keyboxPhoto len'
				}

				// 28.09.2021
				return this.cryptoUtils.encryptDataWithPwdS(newPassword, keyVice, mySalt).then((keyboxVice) => {
					result.keyboxVice = keyboxVice
					Util.debug('(changeAdminsPwd) keyboxVice len:' + keyboxVice.length)
					// 05.10.2021 la len della keyboxVice e' variabile, almeno 8 di certo
					//if(keyboxVice.length != this.KEYBOX_VICE_LEN){
					if (keyboxVice.length < this.MIN_KEYBOX_VICE_LEN) {
						console.log('(changeAdminsPwd) ERRORE su len keyboxVice ' + keyboxVice.length)
						throw 'Error: invalid keyboxVice len'
					}
				})
			})
			.then(() => {
				var username = this.user.username

				// 19.03.2020 uso forge [ls]
				var nuova_hash_pwd = this.cryptoUtils.getSHA256(username.toLowerCase() + newPassword).toString()

				var nuoviDati = {
					password: nuova_hash_pwd,
					keybox_photo: result.keyboxPhoto,
					keybox_vice: result.keyboxVice,
				}

				return this.changePwdInternal(nuoviDati)
			})
			.then(() => {
				//console.log("(initializeDoctorAccount) inviata Req, il puk era "+result.puk); // ko, dati binari
				Util.debug('(changeAdminsPwd) inviata Req al server, ora refresh dati')
				this.user.password = newPassword //otherwise profile decryption won't work, better to do a full logout to fix also the token

				// 03.03.2021 Serve anche su loggedUser ?
				//this.loggedUser.pwd = newPassword;

				// 07.06.2022 usiamo la funzione dedicata
				this.saveCookieUser()

				return this.refreshProfile()
			})
	} // chiude changeAdminsPwd

	// 28.09.2021 generalizzata
	changeUserPassword(newPassword) {
		Util.debug('(S) changeUserPassword')

		if (this.isLevel1() || this.isSpecialist()) return this.changeDoctorsPwd(newPassword)
		//else if (this.isLevel3() || this.isSupport()) return this.changeAdminsPwd(newPassword)  // da testare
		else {
			throw new Error('not available for your profile')
			//return false;
		}
	}

	public updateReportInfo(profileDraft: editReport) {
		let request: any = this.buildBaseRequest()

		request.method = 'PUT'
		request.url = Config.profilesEndpoint + '/' + this.getUserId()
		var myBag = this.cryptoUtils.generateBag()

		var dataReq: ProfileRequest
		var myProfile: ProfileJson = new ProfileJson()

		if (profileDraft.order_reg_num) {
			myProfile.order_reg_num = profileDraft.order_reg_num
		}

		// if (this.isSpecialist() || this.isDoctor()) {
		myBag['signature'] = profileDraft.signature
		myBag['display_name'] = profileDraft.display_name
		myBag['signature_name'] = profileDraft.signature_name
		myBag['license_num'] = profileDraft.order_reg_num
		// myProfile.addresses = []
		// }
		this.cryptoUtils.purge(myBag)

		let goodKey = this.user.getKeyPhoto()
		return this.cryptoUtils.encryptFromStringToBase64Content(goodKey, myBag).then((bagCri) => {
			// if (this.isSpecialist() || this.isDoctor()) {
			myProfile.signature = bagCri['signature']
			myProfile.display_name = bagCri['display_name']
			myProfile.signature_name = bagCri['signature_name']
			myProfile.licence_num = bagCri['license_num']
			myProfile.licence_hashed = this.cryptoUtils.getSHA256(this.user.order_reg_num).toString()
			// }

			// var myAddr = new Address(bagCri)
			// myProfile.addresses.push(myAddr)

			dataReq = {
				data: myProfile,
			}

			return this.myPut(request, dataReq)
				.then(() => {
					Util.debug('S (updateReportInfo) ok, done')

					// 20.06.2023 bisogna aggiornare le relazioni
					if (this.isLevel2()) {
						let relsList = this.data.relsList
						if (relsList) {
							Util.debug('S (updateReportInfo) post login std, dopo get Rels list, tot: ' + relsList.length)
							// ora mi aggiorno i display_name e la firma
							for (let i = 0; i < relsList.length; i++) {
								let myRel = relsList[i]
								this.editRelation(myRel, profileDraft.display_name, profileDraft.signature, profileDraft.order_reg_num)
							}
						} else {
							Util.debug('S (updateReportInfo) post login std, no relations!')
						}
					}

					this.refreshProfile()
					return true // non serve aspettare il refresh, intanto proseguo
				})
				.catch((err) => {
					throw err // rilancia la exception
				})
		})
	}

	// richiamata dall'utente loggato
	public updateProfileInfo(profileDraft: editsInfo) {
		let request: any = this.buildBaseRequest()

		request.method = 'PUT'
		//request.url = Config.profilesEndpoint + "/myId";  // fake id, lo prende dal token
		request.url = Config.profilesEndpoint + '/' + this.getUserId() // 10.03.2022

		// 09.02.2017 alcuni campi vanno crittati, prima dell'invio: nome, cognome, phone
		//console.log("(updateProfile) critt alcuni campi");
		var myBag = this.cryptoUtils.generateBag()

		var dataReq: ProfileRequest
		var myProfile: ProfileJson
		myProfile = new ProfileJson()
		myProfile.addresses = [] // lo costruisco dopo con i dati crittati

		// 15.01.2024 salvo per capire poi se e' stata cambiata o no
		let myPrevOrg = this.getOrganization()
		let address = this.user.getMainAddress()

		// tutti gli altri campi devono essere crittati

		myBag['firstname'] = profileDraft.firstName
		myBag['lastname'] = profileDraft.lastName

		let fieldToDecryptAddr = Config.fieldToDecryptAddr
		for (let field of fieldToDecryptAddr) {
			if (address[field] != null) {
				myBag[field] = address[field]
			}
		}

		for (let field of fieldToDecryptAddr) {
			if (profileDraft[field] != null) {
				myBag[field] = profileDraft[field]
			}
		}

		let fieldClearAddr = Config.fieldClearAddr
		for (let field of fieldClearAddr) {
			if (address[field] != null) {
				myBag[field] = address[field]
			}
		}
		// myBag['phone'] = profileDraft.phone
		// myBag['ref_email'] = profileDraft.email
		// myBag['organization'] = profileDraft.organization
		// myBag['vat'] = profileDraft.vat

		// // devo mandare anche l'indirizzo leggendolo da quello che ho, in quanto organization, email e vat sono all'intenrno dell'address
		// // quindi devo creare l'oggetto address, ma non posso lasciare gli altri campi vuoti altrimenti gli sovrescrive
		// myBag['address_line1'] = address.address_line1
		// myBag['city'] = address.city
		// myBag['province'] = address.province
		// myBag['country'] = address.country
		// myBag['zip'] = address.zip

		this.cryptoUtils.purge(myBag)

		//console.log("(updateProfile) costruita bag toCritt "+bag.firstname+" by user "+this.getUsername());
		let goodKey = this.user.getKeyPhoto()

		return this.cryptoUtils.encryptFromStringToBase64Content(goodKey, myBag).then((bagCri) => {
			myProfile.firstname = bagCri['firstname']
			myProfile.lastname = bagCri['lastname']

			var myAddr = new Address(bagCri)
			myProfile.addresses.push(myAddr)

			dataReq = {
				data: myProfile,
			}

			return this.myPut(request, dataReq)
				.then(() => {
					Util.debug('S (updateProfileInfo) ok, done')

					// 15.01.2024 se il clinicAdmin cambia l'organization
					// bisogna aggiornare tutti i display_name delle sue relazioni (azure #19748)
					if (this.isClinicAdmin() && profileDraft.organization != myPrevOrg) {
						let relsList = this.data.relsList
						if (relsList) {
							let myNewDisplayName = profileDraft.organization
							Util.debug('S (updateProfileInfo) going to spread the new value -' + myNewDisplayName + '- on all ' + relsList.length + ' relations')
							// console.log(relsList)
							for (let i = 0; i < relsList.length; i++) {
								let myRel = relsList[i]
								// questa funzione e' per i lev2, va adeguata o duplicata per il clinicAdmin
								//this.editRelation(myRel, myNewDisplayName, null)
								this.editClinicAdminRelation(myRel, myNewDisplayName)
							}
						}
					}

					this.refreshProfile() // 11.01.2022 serve xche' se riapro, compaiono i valori vecchi
					return true // non serve aspettare il refresh, intanto proseguo
				})
				.catch((err) => {
					throw err // rilancia la exception
				})
		})
	}

	// 10.03.2022 portata da oldAngular
	// 16.02.2022
	updateUsrPrefs(myPreferences: Preferences) {
		let request: any = this.buildBaseRequest()
		request.method = 'PUT'
		request.url = Config.profilesEndpoint + '/' + this.getUserId() + '/settings'

		//request.data = {
		let myData = {
			settings: myPreferences,
		}
		Util.debug('(updateUsrPrefs) sending request... ')

		//17.02.2022 salvo cmq per sessione corrente, evito di fare la getProfile di nuovo
		this.user.updatePreferences(myPreferences)

		return this.myPut(request, myData)
		//.then(()=> {
		//return this.refreshProfile(); // per aggiornarsi il suo oggetto user
		//});
	}

	updateLogo(logo: string, logo_name: string) {
		if (logo === '' && logo_name === '') {
			logo = b64images.logoTransparent
			logo_name = 'transparent.png'
		}
		let request: any = this.buildBaseRequest()
		request.method = 'PUT'
		request.url = Config.profilesEndpoint + '/' + this.getUserId() + '/logo'

		const myBag = this.cryptoUtils.generateBag()
		myBag['logo'] = logo
		this.cryptoUtils.purge(myBag)
		let goodKey = this.user.getKeyPhoto()

		return this.cryptoUtils.encryptFromStringToBase64Content(goodKey, myBag).then((bagCri) => {
			const logoCri = bagCri['logo']

			const dataReq = {
				data: { logo: logoCri, logo_name },
			}

			return this.myPut(request, dataReq)
				.then(() => {
					Util.debug('S (updateLogo) ok, done')

					this.user.updateLogo(logo, logo_name)
					return true
				})
				.catch((err) => {
					throw err
				})
		})
	}

	// 08.05.2024 richiamata dallo specialist alla login, per convertire valore in chiaro
	private updateMyLicence() {
		let request: any = this.buildBaseRequest()

		request.method = 'PUT'
		request.url = Config.distributorsEndpoint + '/' + this.getUserId() + '/licence'

		let goodKey = this.user.getKeyPhoto()

		// ho solo un campo da crittare, non serve la bag
		return this.cryptoUtils.encryptDataWithKey(goodKey, this.user.order_reg_num).then((myLicenceCritt) => {
			let myLicenceHashed = this.cryptoUtils.getSHA256(this.user.order_reg_num).toString()

			let dataReq = {
				distributor: {
					licence_num: myLicenceCritt,
					licence_hashed: myLicenceHashed,
				},
			}

			return this.myPut(request, dataReq)
		})
	}

	async updateVacancies(vacancy: { from_date: string; to_date: string; id?: number }, update: boolean) {
		const request: any = this.buildBaseRequest()
		request.method = update ? 'PUT' : 'POST'
		request.url = Config.vacanciesEndpoint + (update ? `/${vacancy.id}` : '')

		const myData = {
			vacancy,
		}
		Util.debug('(updateVacancies) sending request... ')

		const myReq = update ? this.myPut(request, myData) : this.myPost(request, myData)
		const res = await myReq

		if (update) {
			this.user.updateVacancies(vacancy)
		} else {
			this.user.updateVacancies({ ...vacancy, id: res.vacancy })
		}
	}

	async deleteVacancy(vacancyId: number) {
		let request: any = this.buildBaseRequest()
		request.url = Config.vacanciesEndpoint + '/' + vacancyId

		const res = await this.myDelete(request).then(() => this.user.deleteVacancies())
		return res
	}

	//  ******* DOCTORS **********

	public getCurrentDoctorId() {
		if (this.currentDoctorId > 0) {
			return this.currentDoctorId
		} else {
			this.currentDoctorId = this.activatedRoute.snapshot.queryParams['doctor']
			return this.currentDoctorId
		}
	}

	// 25.01.2022
	public hasLoadedDoctor(doctorId) {
		return this.data.hasLoadedDoctor(doctorId)
	}

	// 21.03.2022
	isLoadingDoctor() {
		return this.data.isLoadingDoctor()
	}

	// 18.03.2022
	isLoadingDoctors() {
		let ret = this.data.isLoadingDoctors()
		//Util.debug("(S isLoadingDoctors) "+ret);
		return ret
	}

	public hasLoadedDoctors() {
		return this.data.hasLoadedDoctors()
	}

	public canSeeDoctors(): boolean {
		return (
			this.getType() == UserType.DEALER || // entrambi i levels
			this.getType() == UserType.GOD || // anche admin group
			this.getType() == UserType.VICE || // 06.08.2018 aggiunto
			this.getType() == UserType.STATS || // 20.07.2021
			this.getType() == UserType.SUPPORT || // 09.11.2022
			this.getType() == UserType.INSTALLER ||
			this.getType() == UserType.MANAGER ||
			this.user.isGroupB()
		) // 04.02.2021, 11.02 esteso anche ai mini
	}

	// 23.11.2021 per la smart-table nuova
	loadDoctorList(ignoreCritt: boolean): Promise<Doctor[]> {
		return this.loadDoctors(ignoreCritt).then((flagDone) => {
			let dList = this.data.doctorList

			if (dList != null) {
				// 27.05.2022
				Util.debug('S (loadDoctorList) tot: ' + dList.length)
			} else {
				Util.debug('S (loadDoctorList) null list, flag: ' + flagDone)
			}

			return dList
		})
	}

	// 06.06.2022  per la createRel
	// doctors/available?specialist=<specialist_id>
	loadAvailableOpticians(specId: number): Promise<Doctor[]> {
		return this.data.loadAvailableOpts(this.buildBaseRequest(), specId).then((partialList) => {
			if (partialList != null) {
				Util.debug('S (loadAvailableOpticians) tot: ' + partialList.length)
			}
			return partialList
		})
	}

	// 03.10.2022 aggiunto parametri x estrazione statistiche con crediti
	loadDoctors(ignoreCritt?, mode?, toDate?): Promise<boolean> {
		if (this.canSeeDoctors()) {
			// distributor and specialist devono usare la key dalla tabella distrib_doctor
			// uso la funzione che gestisce i vice e gli admin, ok anche per i mini
			var goodPwd = this.getPwdForKeyPhoto() // senza promise

			//console.log("(loadDoctors) loading doctors list, param critt: "+ignoreCritt);
			return this.data.loadDoctors(this.buildBaseRequest(), goodPwd, ignoreCritt, mode, toDate) // 07.02.2017 estesa per campi crittati
		}
		//return null;
		else return Promise.reject('user cannot see doctor list, forbidden')
	}

	// non controlla se c'e' gia', carica ogni volta nuovo
	loadDoctor(doctorId: number): Promise<boolean> {
		// console.log('eccomi')

		// 07.06.2023 patch per evitare troppi loop
		// if (!iterations) {
		// 	iterations = 1
		// } else {
		// 	iterations++
		// }
		// console.log(iterations)
		// if (iterations >= 4) {
		// 	Util.debug('(loadDoctor) too many iterations, aborting... opt: ' + doctorId)
		// 	return Promise.reject('problems with decryption keys')
		// }

		if (!doctorId || doctorId == 0) {
			return Promise.reject('problems with doctorId')
		}

		if (this.canSeeDoctors()) {
			// 06.08.2018 per i Vice devo usare la keybox_vice per ottenere la "vera" distribKey
			// ok anche per i mini
			//var distribPwd = this.user.password;
			var goodPwd = this.getPwdForKeyPhoto() // senza promise

			// 07.02.2017 serve poi per i pazienti --ls
			// 25.10.2019 NB: deve ancora caricare il dott singolo, lo prende dalla lista
			if (this.data.doctorList != null && this.data.doctorList.length > 0) {
				this.loadDoctorKey('' + doctorId) // serve poi per decrypt delle foto
			}

			Util.debug('(loadDoctor) loading doctor ' + doctorId + ' by ' + this.user.username)

			// 08.02.2021
			var superBKey = null
			//if(this.isSuperB()){  // 24.02.2021 esteso anche ai miniB
			if (this.isGroupB()) {
				superBKey = this.user.keyPhoto
			}
			//return this.data.loadDoctor(this.buildBaseRequest(), goodPwd, doctorId);
			return this.data.loadDoctor(this.buildBaseRequest(), goodPwd, '' + doctorId, superBKey)
		} else {
			//return false;
			return Promise.reject('user cannot see doctor, forbidden')
		}
	}

	// 02.03.2021 per i miniB e superB, il numero dei "colleghi",
	// per un admin o vice, quelli che puo' vedere
	getDoctorsNumber() {
		var tot = 0
		tot = this.data.getDoctorsNumber()
		return tot
	}

	// 07.09.2022 aggiunto parametro per escludere da lista
	// l'ultimo richiesto con get diretta
	// 16.09.2021 aggiunto parametro id per controllo
	getDtDoctor(docId?, forceFull?) {
		Util.debug('getDtDoctor')
		var currDoc: Doctor

		if (docId == null) {
			// 20.01.2022
			docId = this.currentDoctorId

			// 20.06.2023 patch
			if (docId == 0) {
				this.initDoctorFromUrl()
				docId = this.currentDoctorId
				Util.debug('(getDtDoctor) inferred: ' + docId)
			}
		}

		//currDoc = this.data.doctor;
		currDoc = this.data.getDoctor(docId) // 18.01.2022 miglioria

		// puó essere eliminato, viene usato nella relation list ma anche quella verrá abbandonata
		// 07.09.2022
		if (forceFull && forceFull == true) {
			// non provo dalla lista, voglio solo da get singola
			return currDoc
		}

		// 16.09.2021
		if (docId != null && (currDoc == null || (currDoc != null && currDoc.id != docId))) {
			// provo dalla lista

			currDoc = this.data.getDoctorFromList(docId)

			//Util.debug("S (getDtDoctor) from list: "+docId+" valid? "+(currDoc != null));

			// se ricarico la visitList, non ho piu' il doctor, lo devo ricaricare, necessario per avere la country del doctor nel pdf se lo apre uno specialist o lev.3 (caso deu che va nascosta la firma)
			if (!currDoc) {
				this.loadDoctor(docId).then(() => {
					currDoc = this.data.getDoctor(docId)
					return currDoc
				})
			} else {
				return currDoc
			}
		} else {
			return currDoc
		}
	}

	// ritorna l'ultima lista richiesta
	getDtDoctorList() {
		return this.data.doctorList
	}

	// 16.09.2021 aggiunto parametro di verifica
	// 22.06.2021 sulla directive doctor, usare questa per nascondere fullName agli specialisti
	getDoctorName(docId?): string {
		Util.debug('getDoctorName')
		//Util.debug("S (getDoctorName) id: "+docId);
		return this.getDocName(this.getDtDoctor(docId))
	}

	getDocName(doc: Doctor): string {
		var ret = 'Doctor'

		if (!doc) return ret

		if (this.isSpecialist()) {
			//ret = "Doctor "+doc.id; // per questioni di privacy
			ret = 'Oper_' + doc.code // 24.02.2017, richiesta NS
		} else {
			// admins, groupB, distrib ?

			if (doc.isDecrypted) {
				ret = doc.getFullName()
			} else {
				ret = doc.username
			}

			//ret = doc.name;  // ok per distrib e God
			//ret = doc.getFullName();  // Oper_XX se non e' stato decrittato

			if (ret == '- -') {
				// 23.09.2021
				ret = 'Oper_' + doc.code
			}
		}

		return ret
	}

	// 26.07.2023
	public getDoctorByUsername(optUserName: string) {
		let list = this.data.doctorList

		let myDoc = null
		if (list != null) {
			for (let i = 0; i < list.length; i++) {
				if (list[i].username == optUserName) {
					myDoc = list[i]
					break
				}
			}
		}
		return myDoc
	}

	getDoctorUsername(docId?): string {
		Util.debug('getDoctorUsername')
		return this.getDocUserName(this.getDtDoctor(docId))
	}

	getDocUserName(doc: Doctor): string {
		var ret = 'Operator'

		if (!doc) {
			return ret
		}

		ret = doc.username

		// if (this.isSpecialist()) {
		// 	ret = 'Oper_' + doc.code
		// }

		return ret
	}

	// 06.09.2022 aggiunta country
	// 27.01.2022 edit doctor
	// 01.06.2021 aggiunto logoName
	updateDoctor(doctorDraft: Doctor, logoImage, deleteExistingLogo?, logo_name?): Promise<any> {
		Util.debug('updateDoctor')
		let request: any = this.buildBaseRequest()
		request.method = 'PUT'
		var doctorId = doctorDraft.id
		request.url = Config.doctorsEndpoint + '/' + doctorId

		const bag = this.cryptoUtils.generateBag()

		// 24.07.2017
		let logoCritt: string

		if (logoImage != null) {
			Util.debug('S (updateDoctor) ok logo ' + logo_name)
			bag['logo'] = logoImage

			// 06.11.2018
		} else if (deleteExistingLogo != null && deleteExistingLogo != '') {
			Util.debug('S (updateDoctor) deleteExistingLogo  ' + deleteExistingLogo)
		} else {
			Util.debug('S (updateDoctor) missing logo ')
		}

		this.cryptoUtils.purge(bag) // eventualm. rimane vuota

		// dovrebbe averla gia' xche' prima ha letto gli specialist
		var currDoctor = this.getDtDoctor(doctorId)
		if (currDoctor == null) {
			console.log('(updateDoctor) ko current doctor! ' + doctorId)
			//alert('(updateDoctor) KO 1')
			return Promise.reject('ko doctor!')
		}

		var localKey = currDoctor.key_distrib
		if (localKey == null) {
			console.log('(updateDoctor) invalid key! ')
			//return false;
			return Promise.reject('ko key doctor!')
			// 27.01.2022 TEMP TODO
			//localKey = this.getDoctorKeyPhoto(currDoctor.username);
		}

		return Promise.all([this.cryptoUtils.encryptImage(localKey, bag), this.cryptoUtils.encryptDataWithKey(localKey, doctorDraft.order_reg_num)])
			.then(([photoBag, orderRegNumCritt]) => {
				if (photoBag != null) {
					//console.log("(updateDoctor) ok photoBag"); // qui anche se era nullo
					logoCritt = photoBag['logo']
				} else {
					console.log('(updateDoctor) ko photoBag ')
				}

				// 06.11.2018
				if (deleteExistingLogo != null && deleteExistingLogo == Config.DELETE_ME) {
					logoCritt = deleteExistingLogo // in chiaro
					Util.debug('S (updateDoctor) going to deleteExistingLogo.')
				}

				// serve doctorDraft per gestire la delete dal controller
				//var nuoviDicomSrv = doctorDraft.dicomServers;

				// TODO Doctor(s)Request

				let myParams = {
					order_reg_num: doctorDraft.order_reg_num,
					user_subtype: doctorDraft.user_subtype,
					is_test: doctorDraft.is_test, // 14.10.2021
					country: doctorDraft.country, // 06.09.2022  resta in chiaro
					logo: logoCritt,
					logo_name: logo_name, // 01.06.2021
					settings: doctorDraft.settings,
					created_by: doctorDraft.created_by,
				}

				if (doctorDraft.order_reg_num) {
					myParams['licence_num'] = orderRegNumCritt
				}

				let dataReq = {
					doctor: myParams,
				}

				//console.log("S (updateDoctor) dataReq: ");

				Util.debug('S (updateDoctor) sending request...')
				return this.myPut(request, dataReq)
			})
			.catch((exc) => {
				console.log('S (updateDoctor) ko encrypt bag ' + exc.message)
				return Promise.reject(exc)
				//throw exc;
			})
	}

	// 04.02.2021 TODO, riunire in unica funzione con createNewUser
	createNewMiniB(draftForm: User, docSettings: Settings) {
		// 04.02.2021 solo per i superB
		if (!this.isSuperB()) {
			console.log('(createMiniB) KO, solo se superB, curr user: ' + this.user.username)
			return
		}

		//var userType = draftForm.type;
		var userType = draftForm.role // 20.09.2022

		if (userType != Config.PR_OPTICIAN && userType != Config.PR_DOCTOR) {
			// 21.09.2022
			console.log('(createMiniB) KO, solo lev1, curr user: ' + userSubType + ' ' + userType)
			return
		}

		var userSubType = draftForm.user_subtype // 04.02.2021

		//if(userType != UserType.OPERATOR || userSubType != Config.SUB_MINI) {
		//if(userType != Config.PR_OPTICIAN || userSubType != Config.SUB_MINI) {
		if (userSubType != Config.SUB_MINI) {
			// 21.09.2022
			console.log('(createMiniB) KO, solo nuovi mini, curr user: ' + userSubType + ' ' + userType)
			return
		}

		Util.debug('(createMiniB) by user: ' + this.user.username)

		var docPwd = draftForm.password
		var usrname = draftForm.username.toLowerCase() // per salare la pwd usata nel fare le keybox

		Util.debug('(createMiniB) inizio ' + draftForm.username + ' type:' + userType + ' subT: ' + userSubType)

		var hash_pwd = this.cryptoUtils.getSHA256(usrname + docPwd).toString()

		// questo oggetto conterra' le varie keybox,
		// fatte in modo diverso se stiamo creando un miniB o un utente "standard"
		var result: any = {}

		// la keyPhoto e la keyDoctor sono le stesse dell'utente superB
		var superB = this.getCurrentUser()

		//result.keyPhoto  =  angular.copy(superB.keyPhoto);
		//result.keyDoctor =  angular.copy(superB.keyDoctor);

		//var keySuperPhoto =  angular.copy(superB.keyPhoto);
		//var keySuperDoctor =  angular.copy(superB.keyDoctor);

		// 22.03.2022
		var keySuperPhoto = superB.keyPhoto
		var keySuperDoctor = superB.keyDoctor

		return this.cryptoUtils
			.encryptDataWithPwdS(docPwd, keySuperPhoto, usrname)
			.then((myKeyboxPhoto) => {
				result.keyboxPhoto = myKeyboxPhoto // per immagini

				if (myKeyboxPhoto.length != this.KEYBOX_LEN) {
					console.log('(createMiniB) ERROR on len myKeyboxPhoto ' + myKeyboxPhoto.length + ', forse ?! ')
					throw 'Error: invalid myKeyboxPhoto len, maybe wrong pwd ? '
				}

				return this.cryptoUtils.encryptDataWithPwdS(docPwd, keySuperDoctor, usrname).then((myKeyboxDoct) => {
					result.keyboxDoctor = myKeyboxDoct // per dati sensibili paziente

					if (myKeyboxDoct.length != this.KEYBOX_LEN) {
						console.log('(createMiniB) ERROR on len myKeyboxDoct ' + myKeyboxDoct.length + ', forse ?! ')
						throw 'Error: invalid myKeyboxDoct len, maybe wrong pwd ? '
					}

					// uso la bag, al posto delle chiamate singole
					var bag = this.cryptoUtils.generateBag()

					bag['firstname'] = draftForm.firstname
					bag['lastname'] = draftForm.lastname

					Util.debug('(createMiniB) bag: ' + bag['firstname'] + ' ' + bag['lastname']) // ok

					bag['phone'] = draftForm.mainAddress.phone1
					bag['ref_email'] = draftForm.mainAddress.ref_email
					bag['organization'] = draftForm.mainAddress.organization
					bag['address'] = draftForm.mainAddress.address_line1
					bag['city'] = draftForm.mainAddress.city
					bag['province'] = draftForm.mainAddress.province
					bag['country'] = draftForm.mainAddress.country
					bag['licence_num'] = draftForm.order_reg_num

					this.cryptoUtils.purge(bag)

					//var goodKey = angular.copy(superB.keyPhoto);
					var goodKey = superB.keyPhoto // 22.03.22

					//console.log("(createMiniB) prima di encrypt");

					return this.cryptoUtils.encryptFromStringToBase64Content(goodKey, bag).then((bagCri) => {
						var addresses = []
						var addr = {
							address_line1: bagCri['address'],
							city: bagCri['city'],
							province: bagCri['province'],
							ref_email: bagCri['ref_email'],
							phone1: bagCri['phone'],
							organization: bagCri['organization'],
							country: bagCri['country'],
							//country: draftForm.country,  // 18.03.2020 in chiaro ?
						}

						addresses.push(addr)

						var datiMini = {
							username: draftForm.username,

							firstname: bagCri['firstname'],
							lastname: bagCri['lastname'],
							password: hash_pwd,

							keybox_photo: result.keyboxPhoto,
							keybox_doctor: result.keyboxDoctor, // solo se utenti mini

							user_type: userType,
							user_subtype: userSubType, // 06.05.2020 tolto // 04.02.2021 ripristinato
							addresses: addresses,
							country: draftForm.mainAddress.country, // 15.06.2023 in chiaro
							settings: docSettings,
							licence_num: bagCri['licence_num'],
							licence_hashed: this.cryptoUtils.getSHA256(draftForm.order_reg_num).toString(),
						}

						return this.createNewUserInternal(datiMini)
					})
				})
			})
			.then((ris) => {
				return ris.user_id
			})
	} // end createMiniB

	/** 26.05.2023 create new miniC */

	public createNewMiniC(draftForm: User, docSettings: Settings) {
		// 04.02.2021 solo per i clinicAdmins
		if (!this.isClinicAdmin()) {
			console.log('(createMiniC) KO, solo se clinicAdmin, curr user: ' + this.user.username)
			return Promise.reject('forbidden')
		}

		//var userType = draftForm.type;
		var userType = draftForm.role // 20.09.2022

		// if (userType != Config.PR_OPTICIAN && userType != Config.PR_DOCTOR) {
		// 	// 21.09.2022
		// 	console.log('(createMiniC) KO, solo lev1, curr user: ' + userSubType + ' ' + userType)
		// 	return
		// }

		var userSubType = draftForm.user_subtype // 04.02.2021

		//if(userType != UserType.OPERATOR || userSubType != Config.SUB_MINI) {
		//if(userType != Config.PR_OPTICIAN || userSubType != Config.SUB_MINI) {
		if (userSubType != Config.SUB_MINI) {
			// 21.09.2022
			console.log('(createMiniC) KO, solo nuovi mini, curr user: ' + userSubType + ' ' + userType)
			return Promise.reject('wrong params')
		}

		Util.debug('(createMiniC) by user: ' + this.user.username)

		var docPwd = draftForm.password
		var usrname = draftForm.username.toLowerCase() // per salare la pwd usata nel fare le keybox

		Util.debug('(createMiniC) inizio ' + draftForm.username + ' type:' + userType + ' subT: ' + userSubType)

		var hash_pwd = this.cryptoUtils.getSHA256(usrname + docPwd).toString()

		// questo oggetto conterra' le varie keybox,
		// fatte in modo diverso se stiamo creando un miniB o un utente "standard"
		var result: any = {}

		// la keyPhoto e la keyDoctor sono le stesse dell'utente super, owner
		var superOwner = this.getCurrentUser()
		var keySuperPhoto = superOwner.keyPhoto

		return this.cryptoUtils.encryptDataWithPwdS(docPwd, keySuperPhoto, usrname).then((myKeyboxPhoto) => {
			result.keyboxPhoto = myKeyboxPhoto // per le immagini

			if (myKeyboxPhoto.length != this.KEYBOX_LEN) {
				console.log('(createMiniC) ERROR on len myKeyboxPhoto ' + myKeyboxPhoto.length + ', forse ?! ')
				throw 'Error: invalid myKeyboxPhoto len, maybe wrong pwd ? '
			}

			// return this.cryptoUtils.encryptDataWithPwdS(docPwd, keySuperDoctor, usrname).then((myKeyboxDoct) => {
			// 	result.keyboxDoctor = myKeyboxDoct // per dati sensibili paziente

			// uso la bag, al posto delle chiamate singole
			var bag = this.cryptoUtils.generateBag()

			bag['firstname'] = draftForm.firstname
			bag['lastname'] = draftForm.lastname

			Util.debug('(createMiniC) bag: ' + bag['firstname'] + ' ' + bag['lastname']) // ok

			bag['phone'] = draftForm.mainAddress.phone1
			bag['ref_email'] = draftForm.mainAddress.ref_email
			bag['organization'] = draftForm.mainAddress.organization
			bag['address'] = draftForm.mainAddress.address_line1
			bag['city'] = draftForm.mainAddress.city
			bag['province'] = draftForm.mainAddress.province
			bag['country'] = draftForm.mainAddress.country

			this.cryptoUtils.purge(bag)

			//var goodKey = angular.copy(superB.keyPhoto);
			var goodKey = superOwner.keyPhoto // 22.03.22
			result.keyPhoto = superOwner.keyPhoto // 19.06.2023

			//console.log("(createMiniC) prima di encrypt");

			return this.cryptoUtils.encryptFromStringToBase64Content(goodKey, bag).then((bagCri) => {
				var addresses = []
				var addr = {
					address_line1: bagCri['address'],
					city: bagCri['city'],
					province: bagCri['province'],
					ref_email: bagCri['ref_email'],
					phone1: bagCri['phone'],
					organization: bagCri['organization'],
					country: bagCri['country'],
					//country: draftForm.country,  // 18.03.2020 in chiaro ?
				}

				addresses.push(addr)

				// 15.06.2023 createMiniC - genero subito le keypair, senza fare edit dopo
				return this.getKeyPairCouple(draftForm.username, docPwd).then((keysObj) => {
					let datiMiniC = {
						username: draftForm.username,

						firstname: bagCri['firstname'],
						lastname: bagCri['lastname'],
						password: hash_pwd,

						keybox_photo: result.keyboxPhoto,
						//keybox_doctor: result.keyboxDoctor, // ai miniC non serve

						public_key: keysObj['public_key'],
						private_keybox: keysObj['private_keybox'],

						user_type: userType,
						user_subtype: userSubType,
						addresses: addresses,
						country: draftForm.mainAddress.country, // 15.06.2023 in chiaro
						settings: docSettings,
						order_reg_num: draftForm.order_reg_num, // 13.04.2023
					}

					return this.createNewUserInternal(datiMiniC)
				})
			})
			// })
		})
		// .then((ris) => {
		// 	let newId = 0
		// 	if (ris && ris.user_id) {
		// 		newId = parseInt(ris.user_id)

		// 		// 19.06.2023 per poter fare il recover con puk
		// 		// Util.debug('(createUsr) - lev2, carico il logo per id ' + newId)
		// 		// var docKeyPhoto = result.keyPhoto
		// 		// this.addLogo(newId, docKeyPhoto) //alla create di un miniC 'wrong profile' from api

		// 		// 15.06.2023 applico tutte le (mie) relazioni a questo nuovo grader della clinica
		// 		this.applyRelationsToNewGrader(newId)
		// 	} else {
		// 		console.log('(createMiniC) ko create, ' + ris)
		// 	}

		// 	return newId
		// })
	} //   end createMiniC

	// 15.06.2023 applico tutte le (mie) relazioni a questo nuovo grader della clinica
	// devo prima richiedere l'elemento aggiornato
	// private applyRelationsToNewGrader(graderId: number) {
	// 	this.loadDistrib('' + graderId).then((myDistrib) => {
	// 		if (myDistrib && myDistrib.hasPublicKey()) {
	// 			Util.debug('(applyRelationsToNewGrader) got full distrib, going to apply relations...')
	// 			this.applyMyRelsToClinic(myDistrib)
	// 		}
	// 	})
	// }

	// echostudio, richiamata dal controller createUser, per tutti i tipi di utenti, non solo doctors
	/**
    Sequenza step:
     - hash della username + pwd 
     - genera KEY_PHOTO in modo random, 
     - critta la KEY_PHOTO con la nuova pwd del medico - > salva su keybox_photo
     - critta la KEY_PHOTO con la pwd di NS - > salva su keyboxNS
     - critta NOME e COGNOME (dal form) con la KEY_PHOTO
  */
	// 18.01.207 con libreria forge

	// 25.10.2021 anche admSett
	// estesa con i settings, lista server dicom
	// 27.05.2021 settings possono essere anche AdmSettings
	//createNewUser(draftForm, newSettings: data.Settings, dicomSrvIds?) {
	//createNewUser(draftForm, newSettings, dicomSrvIds?) {
	createNewUser(draftForm: User, adminSettings: AdmSettings, dicomSrvIds?) {
		if (!this.isGod() && !this.isVice()) {
			Util.debug('(createUsr) ko, user non abilitato: ' + this.user.username)
			return
		}
		Util.debug('(createUsr) by user: ' + this.user.username)

		// **********************************************
		// se il vice crea un doctor, deve usare la pwd di admin, non la sua privata
		var admPwd = this.getPwdForKeyPhoto()

		// **********************************************

		var docPwd = draftForm.password

		var userType = draftForm.role // 25.03.2022
		var userSubType = draftForm.user_subtype // 04.02.2021

		var usrSalt = draftForm.username.toLowerCase() // 29.04.2020 per salare la pwd usata nel fare le keybox

		Util.debug('(createUsr) inizio ' + draftForm.username + ' type:' + userType + ' subT: ' + userSubType)

		// 19.03.2020 uso forge [ls]
		// fare lowercase della username
		//var hash_pwd = cryptoJs.SHA256(draftForm.username.toLowerCase()+docPwd).toString();
		var hash_pwd = this.cryptoUtils.getSHA256(draftForm.username.toLowerCase() + docPwd).toString()

		// questo oggetto conterra' le varie keybox,
		// fatte in modo diverso se stiamo creando un miniB o un utente "standard"
		var result: any = {}

		// 23.01.2017
		//return this.cryptoUtils.generateRandomString()
		return this.cryptoUtils
			.generateRandomKeyAES()
			.then((keyPhoto) => {
				Util.debug('(createUsr) random keyPhoto len: ' + keyPhoto.length) // len: 32
				result.keyPhoto = keyPhoto

				return this.cryptoUtils.encryptDataWithPwdS(docPwd, keyPhoto, usrSalt).then((myKeyboxPhoto) => {
					result.keyboxPhoto = myKeyboxPhoto

					return this.cryptoUtils.encryptDataWithPwdS(admPwd, result.keyPhoto, usrSalt).then((myKeyboxNS) => {
						result.keyboxAdmin = myKeyboxNS

						// **********************************************
						// 03.08.2018 al vice serve la keyboxVice, e a tutti gli admins oltre al n.1
						var pwdV, dataV: string
						var mySalt = ''

						// 09.11.2022 aggiunto support
						// 29.09.2021 fix, aggiunto lo statistico
						//if(userType == Config.PR_VICE || userType == Config.PR_ADMIN){
						if (
							userType == Config.PR_VICE ||
							userType == Config.PR_ADMIN ||
							userType == Config.PR_STATS ||
							userType == Config.PR_SUPPORT ||
							userType == Config.PR_INSTALLER ||
							userType == Config.PR_MANAGER
						) {
							pwdV = docPwd
							dataV = admPwd
							mySalt = usrSalt // draftForm.username.toLowerCase();
						} else {
							// TODO per gli altri, valutare se serve
							pwdV = '-'
							dataV = '-'
						}

						// 04.09.2018 aggiunto parametro, per ora solo utenti vice e admin
						return this.cryptoUtils.encryptDataWithPwdS(pwdV, dataV, mySalt).then((myKeyboxVice) => {
							result.keyboxVice = myKeyboxVice

							// 29.09.2021 solo per test
							//console.log("(createUsr) myKeyboxVice len: "+myKeyboxVice.length);  // e' da 44, non 88 come le altre

							// **********************************************

							// 06.05.2020 tolta data di nascita per gli utenti, rimane solo sui pazienti

							// uso la bag, al posto delle chiamate singole
							var bag = this.cryptoUtils.generateBag()

							bag['firstname'] = draftForm.firstname
							bag['lastname'] = draftForm.lastname

							let addr = draftForm.mainAddress

							bag['phone'] = addr.phone1
							bag['ref_email'] = addr.ref_email
							bag['organization'] = addr.organization
							bag['address'] = addr.address_line1
							bag['city'] = addr.city
							bag['province'] = addr.province
							bag['country'] = addr.country
							bag['vat'] = addr.vat

							this.cryptoUtils.purge(bag)

							//var goodKey = keyPhoto; // 25.03.2022
							var goodKey = new forge.util.ByteStringBuffer(keyPhoto)

							// var docSettings = {} // vuoto
							//var docSettings :data.Settings;

							// if (newSettings != null) {
							// 	docSettings = newSettings

							// 	if (newSettings.offline_days) Util.debug('(createUsr) offline days: ' + newSettings.offline_days)
							// }

							// 25.10.2021
							// if (adminSettings != null) {
							// 	docSettings['models'] = adminSettings.models // ok anche se nulli
							// 	Util.debug('(createUsr) adm models: ' + docSettings['models'])

							// 	// 26.10.2021 working
							// 	//docSettings['categories'] = adminSettings.categories;
							// 	//console.log("(createUsr) adm categs: "+docSettings['categories'] );

							// 	// 09.05.2023 FIX, lintel e diagonal
							// 	docSettings['lintel'] = adminSettings.lintel
							// 	docSettings['diagonal'] = adminSettings.diagonal

							// 	Util.debug('(createUsr) diagonal: ' + docSettings['diagonal'] + ' lintel: ' + docSettings['lintel'])
							// }

							//console.log("(createUsr) prima di encrypt");

							return this.cryptoUtils.encryptFromStringToBase64Content(goodKey, bag).then((bagCri) => {
								//campo hash_pwd: hash_pwd, per ora tralasciamo, bug sicurezza, sarebbe in chiaro sul db

								if (
									userType == Config.PR_DOCTOR ||
									userType == Config.PR_OPTICIAN ||
									userType == Config.PR_DISTRIB ||
									userType == Config.PR_SPECIALIST ||
									userType == Config.PR_CLINIC ||
									userType == Config.PR_VICE ||
									userType == Config.PR_ADMIN ||
									userType == Config.PR_MANAGER ||
									userType == Config.PR_STATS ||
									userType == Config.PR_SUPPORT ||
									userType == Config.PR_INSTALLER
								) {
									var addresses = []
									var addr = {
										address_line1: bagCri['address'],
										city: bagCri['city'],
										province: bagCri['province'],
										ref_email: bagCri['ref_email'],
										phone1: bagCri['phone'],
										organization: bagCri['organization'],
										country: bagCri['country'],
										vat: bagCri['vat'],
										//country: draftForm.country,  // 18.03.2020 in chiaro ?
									}

									addresses.push(addr) // 10.03.2020

									var data1 = {
										username: draftForm.username,
										firstname: bagCri['firstname'],
										lastname: bagCri['lastname'],
										password: hash_pwd,
										keybox_photo: result.keyboxPhoto,
										keybox_admin: result.keyboxAdmin,
										keybox_vice: result.keyboxVice,
										user_type: userType,
										user_subtype: userSubType, // 04.02.2021
										is_test: draftForm.is_test, // 28.10.2021
										country: draftForm.mainAddress.country, // 06.09.2022 in chiaro
										order_reg_num: draftForm.order_reg_num, // 13.04.2023
										addresses: addresses,
										// settings: docSettings, // uso oggetto valorizzato prima
										//sale_plan_id: draftForm.sale_plan_id   // 17.08.2021   // 31.08.2021
										//dicom_servers: dicomSrvIds, // array con gli id
									}

									return this.createNewUserInternal(data1)
								} else {
									console.log('(createUsr) ko, type ' + userType + '.')
									//return false;  // TODO gestire la risposta
									throw 'Error: invalid user type ' + userType // 03.08.2018
								}
							})
						})
					})
				})
			})
			.then((ris) => {
				//console.log('(createUsr) ris: '); // 17.05.2021 solo per test
				//console.log(ris);

				//var newDocId = ris.data.user_id;
				var newDocId = ris.user_id

				// 17.05.2021 aggiungo logo trasparente per i livelli1
				var isLev1 = userType == Config.PR_DOCTOR || userType == Config.PR_OPTICIAN
				if (isLev1) {
					Util.debug('(createUsr) - lev1, carico il logo per id ' + newDocId)
					var docKeyPhoto = result.keyPhoto
					this.addLogo(newDocId, docKeyPhoto)
				}
				// 07.06.2023 aggiungo keyPair per i graders
				else if (userType == Config.PR_SPECIALIST || userType == Config.PR_CLINIC) {
					let username = draftForm.username
					Util.debug('(createUsr) genero keyPair per grader:' + newDocId + ' ' + username)
					// fix keypair as edit, run in paralel
					this.amendKeyPair(newDocId, username, docPwd)

					// 19.06.2023
					Util.debug('(createUsr) - lev2, carico il logo per id ' + newDocId)
					var docKeyPhoto = result.keyPhoto
					this.addLogo(newDocId, docKeyPhoto)
				}

				return newDocId
			})
	}

	// 17.05.2021
	private addLogo(newDocId, docKeyPhoto) {
		var logoImage = b64images.logoTransparent // per tutti i brand, solo bg trasparente

		// non metto return qui, la create va bene anche se ko upload logo
		this.updateLogoDoctor(newDocId, logoImage, docKeyPhoto).catch((err) => {
			let msgErr = this.parseErrorMessage(err, 'trace')
			console.log('(updtLogo) error: ' + msgErr)
			//console.log(err);
			//var msg = (err.data)? err.data.error : err.toString();
			//console.log(msg);
		})
	}

	// richiesta di create nuovo utente sul server
	private createNewUserInternal(rawAccessData) {
		let request: any = this.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.usersEndpoint // "users"

		let myData = { user: rawAccessData }

		// 03.08.2018 per test --ls
		Util.debug('(createNewUserInternal) sending POST ...')

		return this.myPost(request, myData)
	}

	// 28.03.2022
	// 17.05.2021 only for transparent logo, during create user procedure
	private updateLogoDoctor(doctorId, logoImage, keyPhoto) {
		if (logoImage != null) {
			Util.debug('(updtLogoDoctor) ok logo')
		} else {
			Util.debug('(updtLogoDoctor) missing logo ')
			return
		}

		let request: any = this.buildBaseRequest()
		request.method = 'PUT'
		request.url = Config.doctorsEndpoint + '/' + doctorId

		var logoCritt: string
		var bag = this.cryptoUtils.generateBag()
		bag['logo'] = logoImage
		this.cryptoUtils.purge(bag)

		return this.cryptoUtils.encryptImage(keyPhoto, bag).then((bagCritt) => {
			if (bagCritt != null) {
				logoCritt = bagCritt['logo']
			} else {
				console.log('(updtLogoDoctor) ko bagCritt ')
			}

			var dataReq = {
				doctor: {
					logo: logoCritt,
					logo_name: 'transparent.png', // 17.05.2021 fisso
				},
			}

			//request.data = dataReq;
			return this.myPut(request, dataReq)
		})
	}

	// abilitata solo per utente NS e superB
	deleteDoctor(doctorId) {
		let request: any = this.buildBaseRequest()
		request.method = 'DELETE'
		request.url = Config.doctorsEndpoint + '/' + doctorId

		return this.myDelete(request)
	}

	// ********* admins ***************

	// 30.09.2021
	getAdminUsername(usrId) {
		var ret = '' + usrId
		var myUsr = this.data.getAdminFromList(usrId)
		if (myUsr != null) {
			ret = myUsr.username
		}
		return ret
	}

	// 27.01.2022 per la smart-table nuova
	loadAdminList(ignoreCritt: boolean): Promise<Admin[]> {
		return this.loadAdmins(ignoreCritt).then(() => {
			let dList = this.data.adminList
			return dList
		})
	}

	// 08.08.2018
	loadAdmins(ignoreCritt?): Promise<boolean> {
		if (this.isGod()) {
			var distribPwd = this.getPwdForKeyPhoto() // serve ?
			return this.data.loadAdmins(this.buildBaseRequest(), distribPwd, ignoreCritt)
		} else {
			Util.debug('S (loadAdmins) forbidden!')
			return null
		}
	}

	loadAdmin(adminId: string): Promise<boolean> {
		//if (this.isGod()) {   // fatto dalla chiamante
		var distribPwd = this.getPwdForKeyPhoto()
		Util.debug('S (loadAdmin) loading adm ' + adminId + ' by ' + this.user.username)
		return this.data.loadAdmin(this.buildBaseRequest(), distribPwd, adminId)
	}

	deleteAdmin(adminId) {
		if (this.isGod()) {
			let request: any = this.buildBaseRequest()
			request.method = 'DELETE'
			request.url = Config.adminsEndpoint + '/' + adminId
			Util.debug('(deleteAdmin) going to delete id ' + adminId)
			return this.myDelete(request)
		} else {
			Util.debug('(deleteAdmin) user not authorized!')
		}
	}

	// 15.06.2021 pe ora solo models per i manager
	updateAdmin(adminId, admSetting: AdmSettings) {
		if (!this.isGod()) {
			Util.debug('(updtAdmin) user not authorized!')
			return null
		}

		let request: any = this.buildBaseRequest()
		request.method = 'PUT'
		request.url = Config.adminsEndpoint + '/' + adminId
		Util.debug('(updtAdmin) going to update id ' + adminId)

		var dataReq = {
			admin: {
				//id:  adminId,
				models: admSetting.models, // per ora solo un parametro
			},
		}
		//request.data = dataReq;

		return this.myPut(request, dataReq)
	}

	// 28.01.2022 l'ultimo richiesto con get diretta - con parametro id per controllo
	getDtAdmin(adminId) {
		var currAdm: Admin
		// a dispetto del nome, prima prova con il current, se no, prende dalla lista
		currAdm = this.data.getAdminFromList(adminId)
		return currAdm
	}

	//  ******* PATIENTS **********

	// 31.05.2022 salvo il target patient dalla pg di login, in modo che poi venga gestito nella landing pg
	public setTargetPatient(patId: number) {
		if (patId && patId > 0) {
			this.targetPatient = patId
		}
	}

	// 31.05.2022 richiamata dalla visits se ko patient, non dovrebbe piu' servire
	public resetTargetPatient() {
		this.targetPatient = 0
	}

	// EDIT PATIENT DIRECTLY
	public setTargetPatientEdit() {
		this.targetPatientEdit = true
	}

	public resetTargetPatientEdit() {
		this.targetPatientEdit = false
	}
	// END EDIT

	// EDIT PATIENT ANAMNESI DIRECTLY
	public setTargetPatientEditAnamnesis() {
		this.targetPatientEditAnam = true
	}

	public resetTargetPatientEditAnamnesis() {
		this.targetPatientEditAnam = false
	}
	// END EDIT

	// 23.01.2023
	public getCurrentPatientId() {
		return this.currentPatientId
	}

	private canSeePatients(): boolean {
		return (
			this.getType() == UserType.DEALER || // specialist e' un sotto-tipo
			this.getType() == UserType.OPERATOR || // farmacist e' sotto-tipo
			this.getType() == UserType.GOD ||
			this.getType() == UserType.VICE || // 06.08.2018 aggiunto
			this.getType() == UserType.STATS || // 20.05.2022
			this.getType() == UserType.SUPPORT || // 14.11.2022
			this.getType() == UserType.CLINIC // 01.06.2023
		)
	}

	// 06.05.2022 added status, for admins, to load only the active or the deleted
	// il parametro doctorId non serve per i livelli1
	private loadPatients(doctorId?: string): Promise<boolean> {
		// : Subscription

		if (!this.canSeePatients()) {
			console.log('S (loadPatients) cannot see patients...')
			return Promise.reject('forbidden')
		}

		var goodKey = this.user.keyDoctor

		let doctorIdNum = parseInt(doctorId, 10)

		if (this.isClinicAdmin() || this.isMiniC()) {
			if (doctorIdNum == 0) {
				goodKey = null
			}
		}

		// 14.11.2017 se nella lista aveva usato ignoreCritt, qui non ce l'ha ? --ls

		if (this.isLevel2() && doctorIdNum > 0) {
			// 07.02.2017 valorizzata prima nel caricare il doctor --ls
			// 15.11.2017 la richiedo di nuovo, se lista senza critt?
			this.loadDoctorKey(doctorId)
			goodKey = this.user.keyDistrib
		}

		return this.data.loadPatients(this.buildBaseRequest(), goodKey, doctorId)
	}

	// 12.05.2022 spostata dal patient.service
	// 23.11.2021 per la smart-table nuova
	// 06.05.2022 aggiunto status, per chiedere solo i non deleted
	loadPatientList(docId?): Promise<Patient[]> {
		Util.debug('S (loadPatList) - inizio')

		// 14.01.2022
		if (!this.isLevel1() && !docId) {
			docId = this.getCurrentDoctorId() // 14.01.2022
			Util.debug('S (loadPatList) by lev2 or lev3 - docId:' + docId)
		} else if (docId != null && this.isGroupB()) {
			// 23.03.2022
			// filtro su uno dei colleghi
			Util.debug('S (loadPatList) by B-Group - docId:' + docId)

			//Per uscita release 10/01/2024 patch provvisoria gruppi
			// se miniA o miniB, con refresh list, veniva passato il docId e quindi non vedevano piú tutti i paz
			docId = 0
		} //else if(this.isLevel1() && !docId){  // 27.12.2022
		//docId = this.getUserId(); // se stesso
		//}

		// il parametro docId non serve per i livelli1
		if (!this.isLevel1()) Util.debug('S (loadPatList) - docId:' + docId)

		return this.loadPatients(docId)
			.then((flagDone) => {
				// e' un boolean
				Util.debug('S (loadPatList) done? ' + flagDone)
				return this.data.patientList // appena caricata
			})
			.catch((err) => {
				// 30.05.2022 per evitare errore in console ?! no, non passa qui
				console.log('S (loadPatList) KO!')
				console.log(err)
				throw err // la gestisce poi la chiamante --ls
			})
	}

	/*
  loadPatient(doctorId?: string) : Promise<boolean>  {  // : Subscription  

    if (!this.canSeePatients()) {
      console.log("S (loadPatients) cannot see patients...");
      return Promise.reject("forbidden");
    }
    
    var goodKey  = this.user.keyDoctor;

    // 14.11.2017 se nella lista aveva usato ignoreCritt, qui non ce l'ha ? --ls
  
    if(this.isLevel2()){  // 07.02.2017 valorizzata prima nel caricare il doctor --ls        
      // 15.11.2017 la richiedo di nuovo, se lista senza critt?
      this.loadDoctorKey(doctorId);        
      goodKey  = this.user.keyDistrib;        
    }
  
    return this.data.loadPatients(this.buildBaseRequest(), goodKey, doctorId);            
  }*/

	loadPatient(patientId: number): Promise<boolean> {
		var goodKey = this.user.keyDoctor
		Util.debug('S (loadPatient) loading pat ' + patientId)

		return this.data.loadPatient(this.buildBaseRequest(), goodKey, patientId)
	}

	// alias piu' comodo
	createPatient(patientDraft: Patient) {
		Util.debug('(createPatient) inizio...')
		return this.insertOrUpdatePatient(patientDraft)
	}

	// alias piu' comodo
	updatePatient(patientDraft: Patient) {
		var currId = patientDraft.id
		Util.debug('(UpdatePatient) id ' + currId)
		return this.insertOrUpdatePatient(patientDraft, currId)
	}

	private insertOrUpdatePatient(patientDraft: Patient, patientId?): Promise<any> {
		//se POST torna {patient_id:number}

		const promise = new Promise<any>((resolve, reject) => {
			let request: any = this.buildBaseRequest()

			// console.log(patientDraft)

			if (patientId) {
				request.method = 'PUT' // aggiorna esistente
				request.url = Config.patientsEndpoint + '/' + patientId
				/*
      // 02.03.2020 FE web non ha QuickEntry
      var eType = patientDraft.entry_type;
      if(eType.indexOf("Quick")==0){  // inizia per
        patientDraft.entry_type = eType.replace("Quick", "Full");          
      }*/
			} else {
				request.method = 'POST' // lo crea nuovo
				request.url = Config.patientsEndpoint
				//patientDraft.entry_type = "FullEntry"  // 02.03.2020 FE web non ha QuickEntry
			}

			//console.log("(insertOrUpdatePatient) going to call "+request.url);

			//var dateAndYear = DateParser.stringify(patientDraft.birthDate); // formato AAAA-MM-GG
			//var onlyYear = dateAndYear.substring(0,4);

			Util.debug('(insertOrUpdatePatient) bDay orig ' + patientDraft.birthDate + ' year: ' + patientDraft.birthYear)

			// 05.06.2017 prove
			//var onlyYear = data.DateParser.getFullYear(patientDraft.birthDate); // formato AAAA
			//var onlyYear = data.DateParser.getYearOnly(patientDraft.birthYear); // formato AAAA
			//var dateAndYear = data.DateParser.stringify(patientDraft.birthYear);
			//console.log("(insertOrUpdatePatient) year "+onlyYear+" date: "+dateAndYear);

			var dataReq: PatientResponse
			var myPat: PatientJson

			myPat = new PatientJson()

			myPat.sex = patientDraft.sex
			myPat.race = patientDraft.race
			myPat.pending_id = patientDraft.pending_id

			myPat.birthday_year = patientDraft.birthYear //  onlyYear,
			myPat.addresses = [] // lo costruisco dopo con i dati crittati

			if (!patientId) {
				// solo su createPat
				var today = new Date()
				myPat.subscription_time = today.toUTCString() // toISOString();
			}

			// ******** 16.02.2023 ***************************

			//let owner = this.user.username;  // TODO per i mini, devono usare il superSalt ?
			let ownerId = this.user.isGroup() ? this.user.groupId : this.user.user_id

			let myString = (patientDraft.firstName + patientDraft.lastName + patientDraft.birthDate + ownerId).toLowerCase()
			Util.debug('(insertOrUpdatePatient) before_hash: ' + myString)
			let hased = this.cryptoUtils.getSHA256(myString).toString()
			Util.debug('(insertOrUpdatePatient) unique_hash: ' + hased)
			myPat.unique_hash = hased
			// ***************************************

			var myBag = this.cryptoUtils.generateBag()

			// forziamo alcuni campi upperCase ? non serve

			myBag['firstname'] = patientDraft.firstName // .toUpperCase();
			myBag['lastname'] = patientDraft.lastName // .toUpperCase();
			myBag['birthday_date'] = patientDraft.birthDate
			myBag['personal_id'] = patientDraft.personal_id
			//myBag['personal_data'] = patientDraft.personal_data ;

			var patAddr = patientDraft.getMainAddress()
			if (patAddr) {
				if (patAddr.ref_email) myBag['ref_email'] = patAddr.ref_email
				//bag.email = patAddr.ref_email;

				if (patAddr.address_line1 != null) myBag['address_line1'] = patAddr.address_line1

				if (patAddr.phone1 != null) myBag['phone1'] = patAddr.phone1

				if (patAddr.city != null) myBag['city'] = patAddr.city // .toUpperCase();

				if (patAddr.country != null) myBag['country'] = patAddr.country // .toUpperCase();

				if (patAddr.zip != null) myBag['zip'] = patAddr.zip // .toUpperCase();

				if (patAddr.province != null) myBag['province'] = patAddr.province // .toUpperCase();

				// 31.05.2017 non usati
				//bag.address_line2 = patientDraft.addressLine2;
				//bag.phone2 = patientDraft.phone2;
			} else {
				Util.debug('(insertOrUpdatePatient) address is missing!')
			}

			if (patientDraft.internal_code != null) myBag['internal_code'] = patientDraft.internal_code // .toUpperCase();

			this.cryptoUtils.purge(myBag)

			//console.log("(insertOrUpdatePatient) costruita bag toCritt "+bag.firstname+" anno: "+onlyYear);

			var goodKey = this.user.keyDoctor // key per dati sensibili del paziente

			// console.log(myBag)

			this.cryptoUtils.encryptFromStringToBase64Content(goodKey, myBag).then((bagCri) => {
				myPat.firstname = bagCri['firstname']
				myPat.lastname = bagCri['lastname']
				myPat.birthday_date = bagCri['birthday_date']
				myPat.internal_code = bagCri['internal_code']
				myPat.personal_id = bagCri['personal_id']

				//dataReq.patient.personal_data = bagCri['personal_data'];  // 02.03.2018

				//var myAddr = Address.initFromBag(bagCri);
				var myAddr = new Address(bagCri) // TODO valutare quale e' meglio ?

				myPat.addresses.push(myAddr)

				// 12.03.2018 FIX se il campo e' vuoto e prima c'era -> forzare cancellazione
				// per tutti i campi opzionali. come fare cfr con precedente?
				//
				if (patientId > 0) {
					// e' un update
					/*
          if(patientDraft.personal_data == ""){  // viene ripulita dalla bag
            //console.log("(insertOrUpdatePatient) elimino personal_data per "+patientId);
            dataReq.patient.personal_data = "DELETE_ME"; // forzo pulizia campo sul DB
          }
          */

					if (patientDraft.internal_code == '') {
						myPat.internal_code = Config.DELETE_ME // forzo pulizia campo sul DB
					}
				}

				dataReq = {
					patient: myPat,
				}

				// console.log(dataReq)

				//request.data = dataReq;

				if (patientId) {
					this.myPut(request, dataReq)
						.then((ris) => {
							resolve(ris)
						})
						.catch((err) => {
							reject(err)
						})
				} else {
					this.myPost(request, dataReq)
						.then((ris) => {
							resolve(ris)
						})
						.catch((err) => {
							reject(err)
						})
				}
			})
		})

		return promise
	}

	deletePatient(patientId: number) {
		let request: any = this.buildBaseRequest()
		//request.method = "DELETE";
		request.url = Config.patientsEndpoint + '/' + patientId

		return this.myDelete(request).then((ris) => {
			// 21.09.2022 delete andata bene, tolgo anche  dalla treeMap su DT
			this.data.removeMapPatient(patientId)
			return ris
		})
	}

	// di norma ho sempre il paziente, xk dove viene chiamata sono passato per la patientsList,
	// ma nel caso in cui non ci sia il patient in lista lo carico, questo il motivo della promise
	getDtPatient(id: number, full?: boolean): Promise<Patient> {
		const promise = new Promise<any>((resolve, reject) => {
			let res = this.data.hasLoadedPatient(id, full)

			if (res.resp) {
				resolve(res.pat)
				return
			} else {
				this.loadPatient(id)
					.then(() => {
						resolve(this.data.hasLoadedPatient(id).pat)
						return
					})
					.catch((err) => {
						reject(err)
					})
			}
		})
		return promise
	}

	// the patList is related to an optician
	// da chiamare solo quando la lista viene chimata da altri
	getDtPatientList(): Promise<Patient[]> {
		const promise = new Promise<Patient[]>((resolve, reject) => {
			let patLisStatus = this.data.patientListStatusObservable.value

			if (patLisStatus == DataStatus.LOADED) {
				resolve(this.data.patientList)
			} else {
				this.patientListStatusSubscribe = this.data.patientListStatusObservable.subscribe((status) => {
					// console.log(status)

					if (status == DataStatus.LOADED) {
						resolve(this.data.patientList)
					}
				})
			}
		})

		return promise
	}

	getDtPatientListUnsubscribe() {
		if (this.patientListStatusSubscribe) {
			this.patientListStatusSubscribe.unsubscribe()
		}
	}

	// getDtPatientList() {
	// 	return this.data.patientList
	// }

	// 08.09.2022
	findPatientByCode(patCode: string) {
		let request: any = this.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.patientsEndpoint + '/search'
		Util.debug('(findPatientByCode) code: ' + patCode)
		let dataReq = { code: patCode }
		return this.myPost(request, dataReq)
	}

	// ******* visits **************

	getDtVisit(visitId): Visit {
		return this.data.getVisit(visitId)
	}

	getDtVisitList() {
		return this.data.visitList
	}

	getVisitsNumber() {
		var tot = 0
		tot = this.data.getVisitsNumber()
		return tot
	}

	// 23.11.2021 per la smart-table nuova
	loadVisitList(patId, docId?): Promise<Visit[]> {
		//console.log("(VisitsService) - loadList, refresh ? "+flagRefr+" canSee: "+this.canSeeStatus);
		return this.loadVisits(patId, docId).then(() => {
			let visitList = this.getDtVisitList()
			return visitList
		})
	}

	// 24.03.2020 asks for patient's visits
	loadVisits(patientId: string, doctorId?: string): Promise<boolean> {
		// per i medici e farmacisti docId e' sempre undefined --ls
		Util.debug('S (loadVisits) for patient ' + patientId + ' optician: ' + doctorId)

		// la carico per dopo, per le retine
		if (doctorId) {
			// non e' un liv. 1
			Util.debug('(loadVisits) load doctor key for ' + doctorId)

			this.loadDoctorKey(doctorId)

			// 15.06.2023 patch per la patients flat list
			let optId = parseInt(doctorId)
			if (optId > 0 && optId != this.currentDoctorId) {
				this.currentDoctorId = optId
				//this.data.changeDoctor() // ripulisce memoria, serve qui ?
			}
		}
		// su dataModel
		return this.data.loadVisits(this.buildBaseRequest(), patientId)
		/*
   .catch((err) =>  {    
     if(!this.isExpired(err)){  // rilancia la exception
       throw err;
     }
   }); 
   */
	}

	// 28.09.2022
	undoReviewReq(myVisit: Visit): Promise<boolean> {
		Util.debug('S (undoReviewReq) for visit ' + myVisit.id)
		return this.editVisit(myVisit.id, 'N')
	}

	// 27.06.2023 alternativa comoda
	updateVisitById(myVisitId: number): Promise<any> {
		// cambiato in any, non risponde un boolean, ma un oggetto {visit : '1234'}
		Util.debug('S (GradeVisit) for visit ' + myVisitId)
		return this.editVisit(myVisitId, 'Y')
	}

	// 28.09.2022 cambio nome, piu' chiaro, era updateVisit
	// 14.04.2020 request review to the specialist
	updateVisit(myVisit: Visit): Promise<any> {
		// cambiato in any, non risponde un boolean, ma un oggetto {visit : '1234'}
		Util.debug('S (GradeVisit) for visit ' + myVisit.id)
		return this.editVisit(myVisit.id, 'Y')
	}

	// 28.09.2022 generalizzata
	private editVisit(myVisitId: number, flag: string): Promise<boolean> {
		let request: any = this.buildBaseRequest()
		request.method = 'PUT'
		request.url = Config.visitsEndpoint + '/' + myVisitId
		Util.debug('S (editVisit) for visit ' + myVisitId + ' flag: ' + flag)

		var dataReq = {
			visit: {
				is_visible: flag, // per ora solo un parametro, fisso, requestReview
			},
		}
		return this.myPut(request, dataReq)
	}

	// richiede al server l'xml di una vista, ritorna una promise
	loadVisitXml(visitId: string): Promise<Visit> {
		Util.debug('S (loadVisitXml) visit ' + visitId)

		// 10.09.2020 deciso che saranno solo dati, no patient
		var goodKey = this.getExamKey() // se ci sono solo dati tecnici
		//var goodKey = angular.copy(this.user.keyDoctor);  // se ci sono anche dati paziente

		return this.data.loadVisitXml(this.buildBaseRequest(), goodKey, visitId)

		/*
    .catch((err) =>  {    
      if(!this.isExpired(err)){  // rilancia la exception
        throw err;
      }
    });
    */
	}

	createVisit(visDraft) {
		console.log('(createVisit) inizio, pat id:' + visDraft.patient_id)

		var request: any = this.buildBaseRequest()
		request.method = 'POST' // lo crea nuovo
		request.url = Config.visitsEndpoint

		var dataReq = {
			visit: visDraft,
		}

		// request.data = dataReq;

		console.log('(createVisit) costruita request, ora post url: ' + request.url)
		return this.myPost(request, dataReq)
	}

	// 02.09.2020 upload dell'xml, tecnicamente una PUT di update, con parametro dedicato [ls]
	uploadXmlVisit(myVisit: Visit) {
		var request: any = this.buildBaseRequest()
		request.method = 'PUT'
		request.url = Config.visitsEndpoint + '/' + myVisit.id
		Util.debug('(uploadXmlVisit) for visit ' + myVisit.id)

		// critta il blob xml
		var bag: any = this.cryptoUtils.generateBag()
		bag.xml_blob = myVisit.xml_blob
		this.cryptoUtils.purge(bag)

		if (Util.isEmptyObj(bag)) {
			Util.debug('(uplXml) empty bag toCritt')
			alert('ERR: missing required xml!')
			return null
		}

		// 10.09.2020 confermato che ci saranno solo dati esami
		var goodKey = this.user.keyPhoto // se sono solo dati degli esami
		//var goodKey = this.user.keyDoctor;  // se ci sono i dati paziente

		return this.cryptoUtils.encryptImage(goodKey, bag).then((bagCri: any) => {
			var visitParams = {
				xml_blob: bagCri.xml_blob, // crittato
			}

			request.data = {
				visit: visitParams,
			}

			return this.myPut(request, myVisit).catch((err) => {
				// 14.07.2021
				if (!this.isExpired(err)) {
					// rilancia la exception
					throw err
				}
			})
		})
	}

	// ************************ New Exams demo ******************************

	createExam(examDraft /*: data.Exam*/) {
		console.log('(createExam) inizio, visit id:' + examDraft.visit_id + ' type: ' + examDraft.exam_type)
		// console.log(examDraft);
		// console.log(examDraft.image_central);

		var request: any = this.buildBaseRequest()
		request.method = 'POST' // lo crea nuovo
		request.url = Config.examsEndpoint

		var dataReq = {
			exam: examDraft,
		}

		// console.log(dataReq.exam.image_central);

		// dataReq.exam.image_central = 'pippo';

		// console.log(examDraft.image_central);

		// svuoto i campi immagine e gli altri da crittare

		// if (examDraft.exam_type == Config.EXM_PACHYMULT) {
		// 	dataReq.exam.image = null;
		// } else if (examDraft.exam_type == Config.EXM_TOPO) {
		// 	// 24.04.2020 anche i campi numerici
		// 	dataReq.exam.eccentricity = null;
		// 	dataReq.exam.KPI = null;
		// 	dataReq.exam.SI = null;

		// 	dataReq.exam.K1_radius = null;
		// 	dataReq.exam.K1_axis = null;
		// 	dataReq.exam.K2_radius = null;
		// 	dataReq.exam.K2_axis = null;
		// 	dataReq.exam.CYL_power = null;
		// 	dataReq.exam.CYL_axis = null;

		// 	dataReq.exam.image = null;
		// 	dataReq.exam.axial_map = null;
		// 	dataReq.exam.elevation_map = null;
		// 	dataReq.exam.tangential_map = null;
		// } else if (examDraft.exam_type == Config.EXM_EXT) {
		// 	dataReq.exam.image_auto = null;
		// 	dataReq.exam.image_man_1 = null;
		// 	dataReq.exam.image_man_2 = null;
		// 	dataReq.exam.image_man_3 = null;
		// 	dataReq.exam.image_man_4 = null;
		// 	dataReq.exam.image_man_5 = null;
		// 	dataReq.exam.image_man_6 = null;
		// } else if (examDraft.exam_type == Config.EXM_DRYEYE) {
		// 	dataReq.exam.image = null;

		// 	dataReq.exam.first_breakup = null;
		// 	dataReq.exam.average_breakup = null;
		// 	dataReq.exam.percentage = null;
		// 	dataReq.exam.tear_meniscus = null;
		// } else if (examDraft.exam_type == Config.EXM_PACHY) {
		// 	// 21.07.2020
		// 	dataReq.exam.image = null;
		// 	dataReq.exam.image_with_data = null;

		// 	dataReq.exam.central = null;
		// 	dataReq.exam.nasal_angle = null;
		// 	dataReq.exam.temporal_angle = null;
		// } else if (examDraft.exam_type == Config.EXM_TONO) {
		// 	// tono non ha immagini
		// 	dataReq.exam.IOP = null;
		// 	dataReq.exam.IOPc = null;
		// } else if (examDraft.exam_type == Config.EXM_WF) {
		// 	// 31.07.2020
		// 	dataReq.exam.image_grid = null;
		// 	dataReq.exam.image_meso = null;

		// 	dataReq.exam.pupil_size_day = null;
		// 	dataReq.exam.sphere_day = null;
		// 	dataReq.exam.cylinder_day = null;
		// 	dataReq.exam.axis_day = null;

		// 	dataReq.exam.pupil_size_night = null;
		// 	dataReq.exam.sphere_night = null;
		// 	dataReq.exam.cylinder_night = null;
		// 	dataReq.exam.axis_night = null;
		// } else if (examDraft.exam_type == Config.EXM_RETRO) {
		// 	// 21.08.2020
		// 	dataReq.exam.image_low = null;
		// 	dataReq.exam.image_high = null;

		// 	dataReq.exam.cortical = null;
		// 	dataReq.exam.nuclear = null;
		// 	dataReq.exam.posterior = null;
		// } else if (examDraft.exam_type == Config.EXM_FUNDUS) {
		// 	// 17.09.2020

		// 	dataReq.exam.CDR = null;
		// 	dataReq.exam.vCDR = null;
		// 	//dataReq.exam.optic_disk_area = null;
		// 	//dataReq.exam.artery_vein = null;

		// 	dataReq.exam.image_central = null;
		// 	dataReq.exam.image_nasal = null;
		// 	dataReq.exam.image_supero_nasal = null;
		// 	dataReq.exam.image_supero_temporal = null;
		// 	dataReq.exam.image_temporal = null;
		// 	dataReq.exam.image_inferior = null;
		// 	dataReq.exam.image_external = null;
		// 	dataReq.exam.image_central_nasal = null;
		// } else if (examDraft.exam_type == Config.EXM_SBJ) {
		// 	// 05.10.2020

		// 	dataReq.exam.sphere = null;
		// 	dataReq.exam.cylinder = null;
		// 	dataReq.exam.axis = null;
		// 	dataReq.exam.prism_h = null;
		// 	dataReq.exam.base_h = null;
		// 	dataReq.exam.prism_v = null;
		// 	dataReq.exam.base_v = null;
		// 	dataReq.exam.add = null;
		// 	dataReq.exam.DVA_LM = null;
		// 	dataReq.exam.NVA_LM = null;
		// 	dataReq.exam.DVA_WF = null;
		// 	dataReq.exam.NVA_WF = null;
		// 	dataReq.exam.DVA_REF = null;
		// 	dataReq.exam.NVA_REF = null;
		// 	dataReq.exam.PDm = null;
		// } else if (examDraft.exam_type == Config.EXM_LM) {
		// 	// 08.10.2020

		// 	dataReq.exam.sphere = null;
		// 	dataReq.exam.cylinder = null;
		// 	dataReq.exam.axis = null;
		// 	dataReq.exam.prism_h = null;
		// 	dataReq.exam.base_h = null;
		// 	dataReq.exam.prism_v = null;
		// 	dataReq.exam.base_v = null;
		// 	dataReq.exam.add = null;
		// 	dataReq.exam.PDm = null;
		// }

		// 23.06.2020 test
		dataReq.exam.details = '' // 11.10.2024 anche basta (ls)  'Details test, whatever values here ' + new Date().toISOString()

		request.data = dataReq

		// 30.04.2020 attivo solo su questa funzione
		//this.cryptoUtils.setCBC(true);

		var bag: any = this.cryptoUtils.generateBag()

		if (examDraft.exam_type == Config.EXM_TOPO) {
			bag.axial_map = examDraft.axial_map
			bag.elevation_map = examDraft.elevation_map
			bag.tangential_map = examDraft.tangential_map

			bag.eccentricity = examDraft.eccentricity
			bag.KPI = examDraft.KPI
			bag.SI = examDraft.SI

			bag.K1_radius = examDraft.K1_radius
			bag.K1_axis = examDraft.K1_axis
			bag.K2_radius = examDraft.K2_radius
			bag.K2_axis = examDraft.K2_axis
			bag.CYL_power = examDraft.CYL_power
			bag.CYL_axis = examDraft.CYL_axis
		} else if (examDraft.exam_type == Config.EXM_PACHYMULT) {
			bag.image = examDraft.image
		} else if (examDraft.exam_type == Config.EXM_EXT) {
			bag.image_auto = examDraft.image_auto
			bag.image_man_1 = examDraft.image_man_1
			bag.image_man_2 = examDraft.image_man_2
			bag.image_man_3 = examDraft.image_man_3
			bag.image_man_4 = examDraft.image_man_4
			bag.image_man_5 = examDraft.image_man_5
			bag.image_man_6 = examDraft.image_man_6
		} else if (examDraft.exam_type == Config.EXM_DRYEYE) {
			bag.image = examDraft.image

			bag.first_breakup = examDraft.first_breakup
			bag.average_breakup = examDraft.average_breakup
			bag.percentage = examDraft.percentage
			bag.tear_meniscus = examDraft.tear_meniscus
		} else if (examDraft.exam_type == Config.EXM_PACHY) {
			bag.image = examDraft.image
			bag.image_with_data = examDraft.image_with_data

			bag.central = examDraft.central
			bag.nasal_angle = examDraft.nasal_angle
			bag.temporal_angle = examDraft.temporal_angle

			// 14.10.2024 aggiunti campi
			bag.acd = examDraft.acd
			bag.pupil_center_x_array = examDraft.pupil_center_x_array
			bag.pupil_center_y_array = examDraft.pupil_center_y_array
		} else if (examDraft.exam_type == Config.EXM_TONO) {
			bag.IOP = examDraft.IOP
			bag.IOPc = examDraft.IOPc
		} else if (examDraft.exam_type == Config.EXM_WF) {
			bag.image_grid = examDraft.image_grid
			bag.image_meso = examDraft.image_meso

			bag.pupil_size_day = examDraft.pupil_size_day
			bag.sphere_day = examDraft.sphere_day
			bag.cylinder_day = examDraft.cylinder_day
			bag.axis_day = examDraft.axis_day

			bag.pupil_size_night = examDraft.pupil_size_night
			bag.sphere_night = examDraft.sphere_night
			bag.cylinder_night = examDraft.cylinder_night
			bag.axis_night = examDraft.axis_night

			// 11.10.2024 FIX, mancavano i nuovi campi (bug #23755)
			bag.pupil_real_size_day = examDraft.pupil_real_size_day
			bag.pupil_real_size_night = examDraft.pupil_real_size_night
			bag.vertex_distance = examDraft.vertex_distance
		} else if (examDraft.exam_type == Config.EXM_RETRO) {
			bag.image_low = examDraft.image_low
			bag.image_high = examDraft.image_high

			bag.cortical = examDraft.cortical
			bag.nuclear = examDraft.nuclear
			bag.posterior = examDraft.posterior
		} else if (examDraft.exam_type == Config.EXM_FUNDUS) {
			bag.CDR = examDraft.CDR
			bag.vCDR = examDraft.vCDR
			//bag.optic_disk_area	     = examDraft.optic_disk_area	   ;
			//bag.artery_vein          = examDraft.artery_vein           ;
			bag.image_central = examDraft.image_central
			bag.image_nasal = examDraft.image_nasal
			bag.image_supero_nasal = examDraft.image_supero_nasal
			bag.image_supero_temporal = examDraft.image_supero_temporal
			bag.image_temporal = examDraft.image_temporal
			bag.image_inferior = examDraft.image_inferior
			bag.image_external = examDraft.image_external
			bag.image_central_nasal = examDraft.image_central_nasal
		} else if (examDraft.exam_type == Config.EXM_SBJ) {
			bag.sphere = examDraft.sphere
			bag.cylinder = examDraft.cylinder
			bag.axis = examDraft.axis
			bag.prism_h = examDraft.prism_h
			bag.base_h = examDraft.base_h
			bag.prism_v = examDraft.prism_v
			bag.base_v = examDraft.base_v
			bag.add = examDraft.add
			bag.DVA_LM = examDraft.DVA_LM
			bag.NVA_LM = examDraft.NVA_LM
			bag.DVA_WF = examDraft.DVA_WF
			bag.NVA_WF = examDraft.NVA_WF
			bag.DVA_REF = examDraft.DVA_REF
			bag.NVA_REF = examDraft.NVA_REF
			bag.PDm = examDraft.PDm
		} else if (examDraft.exam_type == Config.EXM_LM) {
			bag.sphere = examDraft.sphere
			bag.cylinder = examDraft.cylinder
			bag.axis = examDraft.axis
			bag.prism_h = examDraft.prism_h
			bag.base_h = examDraft.base_h
			bag.prism_v = examDraft.prism_v
			bag.base_v = examDraft.base_v
			bag.add = examDraft.add
			bag.PDm = examDraft.PDm
		} else if (examDraft.exam_type == Config.EXM_PUPIL) {
			bag.graph = examDraft.graph

			bag.scotopic_pupil = examDraft.scotopic_pupil
			bag.scotopic_dec_x = examDraft.scotopic_dec_x
			bag.scotopic_dec_y = examDraft.scotopic_dec_y
			bag.scotopic_k = examDraft.scotopic_k
			bag.low_pupil = examDraft.low_pupil
			bag.low_dec_x = examDraft.low_dec_x
			bag.low_dec_y = examDraft.low_dec_y
			bag.low_k = examDraft.low_k
			bag.mesopic_pupil = examDraft.mesopic_pupil
			bag.mesopic_dec_x = examDraft.mesopic_dec_x
			bag.mesopic_dec_y = examDraft.mesopic_dec_y
			bag.mesopic_k = examDraft.mesopic_k
			bag.photopic_pupil = examDraft.photopic_pupil
			bag.photopic_dec_x = examDraft.photopic_dec_x
			bag.photopic_dec_y = examDraft.photopic_dec_y
			bag.photopic_k = examDraft.photopic_k
		}

		this.cryptoUtils.purge(bag)
		//console.log("(createExam) costruita bag toCritt ");

		if (Util.isEmptyObj(bag)) {
			console.log('(createExam) empty bag toCritt')
			alert('ERR: missing required image!')
			return null
		} else {
			var mySize = Util.bagSize(bag)
			console.log('(createExam) bag with ' + mySize + ' elements.')
		}

		var goodKey = this.user.keyPhoto

		// 09.04.2020
		return this.cryptoUtils.encryptImage(goodKey, bag).then((bagCri: any) => {
			if (
				examDraft.exam_type == Config.EXM_PACHYMULT ||
				examDraft.exam_type == Config.EXM_TOPO ||
				examDraft.exam_type == Config.EXM_EXT ||
				examDraft.exam_type == Config.EXM_DRYEYE ||
				examDraft.exam_type == Config.EXM_PACHY || // 21.07.2020
				examDraft.exam_type == Config.EXM_TONO || // 28.07.2020
				examDraft.exam_type == Config.EXM_WF || // 31.07.2020
				examDraft.exam_type == Config.EXM_RETRO || // 21.08.2020
				examDraft.exam_type == Config.EXM_FUNDUS || // 17.09.2020
				examDraft.exam_type == Config.EXM_SBJ || // 05.10.2020
				examDraft.exam_type == Config.EXM_LM || // 07.10.2020
				examDraft.exam_type == Config.EXM_PUPIL
			) {
				// 07.10.2020 ciclo senza enumerarle tutte [ls]
				for (var prop in bagCri) {
					if (bagCri.hasOwnProperty(prop)) {
						request.data.exam[prop] = bagCri[prop]
					}
				}
			}

			// 30.04.2020 ripristino
			//this.cryptoUtils.setCBC(false);
			console.log('(createExam) costruita request per ' + examDraft.exam_type + ', ora post url: ' + request.url)
			return this.myPost(request, dataReq)
		})
	}

	// ********** reports *************

	// 07.07.2022 salvo il target report dalla pg di login, in modo che poi venga gestito nella landing pg
	public setTargetReport(repId: number) {
		if (repId && repId > 0) {
			this.targetReport = repId
		}
	}

	// richiamata se ko id, e anche dopo averlo usato la prima volta
	public resetTargetReport() {
		this.targetReport = 0
	}

	// 16.09.2022 ritorna una lista sommaria per evidenziare se ci sono nuovi reports, per qls patient
	public checkNewReports() {
		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.reportsEndpoint + '/allpatients?status=new'
		return this.myGet(request)
	}

	// carica solo la lista, non ci sono dati crittati
	// se ho giá report per quel paziente, chiedo i report dopo quella data con cui sono stati salvati
	// altrimenti passo dateTime null e viene fatta dall'inizio
	// ad ogni apertura dei report prenderó quello che ho in memoria e se ne arrivano altri mi arriveranno con signalR
	loadReports(patientId: number): Promise<Report[]> {
		const promise = new Promise<Report[]>((resolve, reject) => {
			Util.debug('(loadReports) for patient ' + patientId)

			const reportList = this.data.getCustomerReportList(patientId)
			let datetime: string | null = null

			if (reportList.reports.length > 0) {
				Util.debug('(loadReports) found ' + reportList.reports.length + ' reports')
				datetime = reportList.date
			}
			let request: any = this.buildBaseRequest()

			this.data
				.loadReportList(request, patientId, datetime)
				.then((resp) => {
					resolve(resp)
				})
				.catch((err) => {
					reject(err)
				})
		})
		return promise
	}

	// 06.02.2023 per lista attivita' dei refertatori, su tutti i patients
	// non ci sono dati crittati
	// TODO 2 params opzionali: ?last_created=2022-03-01&status=new
	loadAllReports(): Promise<Report[]> {
		if (!this.isSpecialist() && !this.isClinicAdmin()) return Promise.reject(null)
		Util.debug('(loadAllReports) by specialist')
		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.reportsEndpoint + '/allpatients'
		return this.myGet(request).then((resp) => {
			if (resp != null && resp.reports != null) {
				let list = resp.reports
				let reportList = []
				for (let i = 0; i < list.length; i++) {
					let rep = new Report(list[i])
					reportList.push(rep)
				}
				return reportList
			} else {
				console.log(resp)
				return null
			}
		})
	}

	// carica un report singolo
	// se gia' caricato, lo ritorno subito, altrimenti lo chiede
	getReport(reportId: number): Promise<FullReport> {
		var myReport = this.data.getReportById(reportId)
		if (myReport != null) {
			Util.debug('S (getReport) already loaded id ' + reportId)

			// finta promise ?
			//return this.cryptoUtils.q.when(myReport);
			return Promise.resolve(myReport)
		} else {
			return this.loadReport('' + reportId)
		}
	}

	// questo ritorna una promise
	loadReport(reportId: string): Promise<FullReport> {
		Util.debug('S (loadReport) id ' + reportId)

		var goodKey = this.getExamKey() // keyPhoto del medico

		return this.data.loadReport(this.buildBaseRequest(), goodKey, reportId)
		/*    
    .catch((err) =>  {    
      if(!this.isExpired(err)){  // rilancia la exception
        throw err;
      }
    });
    */
	}

	// 28.04.2022 per ora aggiorna solo lo status
	updateReport(myReport: Report, status: ReportStatus) {
		let request: any = this.buildBaseRequest()
		request.method = 'PUT'
		request.url = Config.reportsEndpoint + '/' + myReport.id
		Util.debug('(updateReport) nuovo status per report ' + myReport.id)

		var dataReq = {
			report: {
				status: myReport.status, // per ora solo un parametro, fisso
			},
		}

		this.data.changeHgReportstatus(myReport, status)

		return this.myPut(request, dataReq)
	}

	// 18.04.2023 aggiunti mesi di follow-Up
	// 26.10.2020 aggiunte final prescription da refraction
	// 28.05.2020 att alla differenza tra SrvDiagnosis e CategDiagnosis
	//postReport(patientId: number, ctgList: data.CategDiagnosis[], examList: data.CategExam[]){
	postReport(patientId: number, ctgList: SrvDiagnosis[], finalRx: Prescription[], followUpMonths: number, followUpNotes: string) {
		// i campi freetext vanno crittati

		var arrayPromise = []

		arrayPromise.push(this.crittaDiagnosi(ctgList))
		arrayPromise.push(this.crittGeneralTexts([followUpNotes], ['follow_up_notes']))

		return Promise.all(arrayPromise).then((dataArray) => {
			// console.log(dataArray)
			// })

			// return this.crittaDiagnosi(ctgList).then((dataArray) => {
			Util.debug('(postReport) post critt, ' + dataArray[0].length)
			//console.log(dataArray);

			//Process data here
			let request: any = this.buildBaseRequest()
			request.method = 'POST'
			request.url = Config.reportsEndpoint

			let grp = this.userDiagnosisGroup() // 07.04.2022

			Util.debug('(postReport) followUpMonths: ' + followUpMonths) // 18.04.2023

			let postData = {
				report: {
					patient_id: patientId,
					categ_diagnosis: dataArray[0],
					prescriptions: finalRx,
					diagnosis_group: grp, // 07.04.2022
					follow_up_months: followUpMonths, // 18.04.2023
					follow_up_notes: dataArray[1][0],
				},
			}

			// console.log(postData)

			Util.debug('(postReport) sending for patient ' + patientId)
			return this.myPost(request, postData)
		})
	}

	// 08.07.2020 con promise
	// 28.05.2020 att alla differenza tra SrvDiagnosis e CategDiagnosis
	private async crittaDiagnosi(ctgList: SrvDiagnosis[]): Promise<SrvDiagnosis[]> {
		var categoriesList: SrvDiagnosis[]
		//var categ: SrvDiagnosis;

		categoriesList = []

		var keyPhoto = this.getExamKey() // keyPhoto del medico

		for (let i = 0; i < ctgList.length; i++) {
			//categ = angular.copy(ctgList[i]);
			let categ = ctgList[i]
			if (categ != null) {
				// 07.07.2020
				Util.debug('(crittaDiagnosi) ctg ' + (i + 1) + ' ' + categ.category) // ok, arrivano tutte
				// 08.07.2020 portata fuori per gestire la promise [ls]
				//var localK = angular.copy(keyPhoto);

				// 08.02.2022
				//categoriesList.push(this.crittaDiagnSingola(categ, keyPhoto));
				const mySrvD = await this.crittaDiagnSingola(categ, keyPhoto) // let the Promise resolve
				categoriesList.push(mySrvD)
			}
		}

		return Promise.all(categoriesList)
		//return angular.q.all(categoriesList);  // 09.07.2020 ko compilazione
	}

	// 08.07.2020 con promise
	private crittaDiagnSingola(categ: SrvDiagnosis, goodKey): Promise<SrvDiagnosis> {
		if (categ.details != null || categ.treatment != null) {
			var bag = this.cryptoUtils.generateBag()

			bag['details'] = categ.details
			bag['treatment'] = categ.treatment

			//console.log("(postReport) ctg "+i+" details "+bag.details);

			categ.details = '' // svuoto per poi sovrascrivere con valori crittati
			categ.treatment = ''

			this.cryptoUtils.purge(bag)

			// come quando critta i campi paziente
			//this.cryptoUtils.encryptDataWithKey(goodKey, bag)
			return this.cryptoUtils.encryptFromStringToBase64Content(goodKey, bag).then((bagCri) => {
				categ.details = bagCri['details']
				categ.treatment = bagCri['treatment']
				Util.debug('(crittaDiagnSingola) ok crypt categ ' + categ.category)
				//console.log(categ); // ok
				return categ
			})
		} else {
			// niente da crittare
			return Promise.resolve(categ)
		}
	}

	// creata una generale e non specifica in tutti i casi nel qualse serve crittare stringhe
	private crittGeneralTexts(texts: string[], fields: string[]): Promise<string[]> {
		var bag = this.cryptoUtils.generateBag()

		var keyPhoto = this.getExamKey()

		const promise = new Promise<string[]>((resolve, reject) => {
			var critted: string[] = []

			if (texts != null && texts.length > 0 && fields != null && fields.length > 0 && texts.length == fields.length) {
				for (let i = 0; i < fields.length; i++) {
					let field = fields[i]
					let text = texts[i]
					bag[field] = text
				}

				this.cryptoUtils.encryptFromStringToBase64Content(keyPhoto, bag).then((bagCri) => {
					// console.log(bagCri)
					Util.debug('(crittGeneralTexts) ok ')

					for (let field of fields) {
						critted.push(bagCri[field])
					}
					resolve(critted)
				})
			} else {
				resolve(texts)
			}
		})

		return promise
	}

	// ***********************************************

	// 14.04.2020
	// richiede gli esami di una categoria.
	loadCategoryExams(examSelection, catName: string, force?: boolean) {
		// console.log(examSelection)
		// DA SPOSTARE E/O ELIMINARE QUANDO SI RIFÁ LA CETEGORIES
		let goodKey = this.getExamKey()

		// 20.05.2022 per stats arriva qui null, patch ?!
		// if (!goodKey || goodKey == null) {
		// 	//let currDoc : Doctor;
		// 	//let docId = this.getCurrentDoctorId();

		// 	/* 27.05.2022  ko
		// 	if(this.data.hasLoadedDoctor(""+docId))  {
		// 		goodKey = this.getKeyForImages(currDoc);
		// 	}	*/

		// 	// 01.06.2022
		// 	if (this.isStats()) {
		// 		if (this.loggedUser.keyPhoto != null) {
		// 			Util.debug('S (loadCategoryExams) ok keyPhoto')
		// 			goodKey = this.loggedUser.keyPhoto
		// 		}
		// 		/*
		// if(this.user.keyDistrib != null){
		//   Util.debug("S (loadCategoryExams) ok keyDistrib");
		//   goodKey = this.user.keyDistrib;
		// }
		// */
		// 	}
		// }

		if (!goodKey || goodKey == null) {
			// per admin, qualche volta ?!  18.01.2022
			Util.debug('S (loadCategoryExams) null key!!!')
			//alert('S (loadCategoryExams) null key!!!')
			return Promise.reject('ko key')
		}

		Util.debug('S (loadCategoryExams) inizio: ' + catName)
		return this.data.loadCategoryExams(this.buildBaseRequest(), goodKey, examSelection, catName, force)
	}

	// svuota precedenti array e prepara per nuova visualizzazione
	// da richiamare solo la prima volta, dopo che e' stata fatta
	// una selezione della visita o dei moduli (per i refertatori)
	initCategories() {
		//console.log("(initCategories) inizio");
		this.data.initCategories()
	}

	// 18.11.2022 returns a list
	loadAdditions(examType: string, examId: number) {
		let goodKey = this.getExamKey()

		// quando ?!
		if (!goodKey || goodKey == null) {
			Util.debug('S (loadAdditions) null key!!!')
			return Promise.reject('additions - ko key')
		}

		return this.data.loadAdditions(this.buildBaseRequest(), examType, examId, goodKey)
	}

	// single element, the image field needs to be decrypted
	loadSingleAddition(elemId: number) {
		let goodKey = this.getExamKey()

		// quando ?!
		if (!goodKey || goodKey == null) {
			Util.debug('S (loadAddition) null key!!!')
			return Promise.reject('addition - ko key')
		}

		return this.data.loadAddition(this.buildBaseRequest(), elemId, goodKey)
	}

	// ********** ICDS ***************************************

	// 14.05.2020 per ora unico gruppo
	loadIcdList(groupId?) {
		// 03.03.2022 gestito gruppo da qui
		if (groupId == null) {
			groupId = this.getUserDiagnGroup()
		}

		var lang = this.getLanguage() // 20.01.2021
		Util.debug('(loadIcdList) for group ' + groupId + ' lang: ' + lang)

		return this.data.loadIcdList(this.buildBaseRequest(), groupId, lang)
		/*
    .catch((err) =>  {    
      if(!this.isExpired(err)){  // rilancia la exception
        throw err;
      }
    });*/
	}

	// 30.11.2020 aggiunto parametro opzionale, per filtrare in base alle categorie
	// 14.05.2020
	// 14.04.2022 aggiungo gruppo
	getIcdList(catName?, group?) {
		if (group == null) {
			// 14.04.2022 prendo quello dell'utente loggato
			group = this.getUserDiagnGroup()
		}

		var myList = this.data.getIcdList(catName, group, this.lang)

		if (myList != null) Util.debug('S (getIcdList) categ: ' + catName + ' group: ' + group + ' tot: ' + myList.length)
		else Util.debug('S (getIcdList) categ: ' + catName + ' group: ' + group + ' null list!')

		return myList
	}

	// 15.04.2022
	checkIcdsAreLoaded(group?, lang?) {
		if (lang == null) {
			lang = this.lang
		}
		if (group == null) {
			group = this.getUserDiagnGroup()
		}
		return this.data.hasLoadedIcds(group, lang)
	}

	// 18.10.2021 basato sul diagnosisGroup
	// 11.10.2021 true solo per utenti B, non per A e C
	// per gli admins devo passare il refId
	userIcdsEnabled(operator?) {
		// 12.10.2021
		if (this.isClalit()) {
			return true // ok per tutti
		}

		var ret = false

		// 18.10.2021
		var grp = this.userDiagnosisGroup(operator)
		ret = grp != 0

		//console.log("(userIcdsEnabled) "+ret);
		return ret
	}

	// 08.04.2022 centralizzata funzione per future modifiche
	hideIcdsCodes(grp) {
		let ret = false
		if (grp == 2 || grp == 3) {
			ret = true
		}
		return ret
	}

	// 18.10.2021
	userDiagnosisGroup(operator?: Doctor) {
		if (this.isClalit()) {
			return 1 // ok ICD per tutti
		}

		var grp = 0
		if (this.isSpecialist() || this.isLevel1()) {
			grp = this.getUserDiagnGroup()
		} else if (this.isLevel3() && operator != null) {
			// prendo i settings dell'operator
			if (operator.settings != null) {
				grp = operator.settings.diagnosis_group
			}
		}

		return grp
	}

	// 03.03.2022
	private getUserDiagnGroup(): number {
		let icdGroup = 1 // default ?	meglio 0 ?
		if (this.user.settings != null) {
			icdGroup = this.user.settings.diagnosis_group
		}
		return icdGroup
	}

	// for current logged user
	public isTelerefractEnabled() {
		let ret = false

		if (this.isLogged() && this.user != null) {
			ret = this.user.isTelerefractEnabled()
		}
		// Util.debug('(isTelerefractEnabled) user: ' + this.user.user_id + ' flag: ' + ret)
		return ret
	}

	// ********** specialist *************

	// 27.06.2017 ritorna array di refertatori associati al farmacista
	getDtSpecialists(optId?) {
		Util.debug('getDtSpecialists')
		var specs: Specialist[]
		specs = [] // vuoto

		var currDoc = this.getDtDoctor(optId)
		if (currDoc) {
			specs = currDoc.specialists
		}

		return specs
	}

	// 05.06.2020 in base all'utente loggato
	public getSpecialistName(specId) {
		var name = ''

		//22.01.2021
		// 16.05.2022
		//var myself = this.translator.instant('REPORT.MYSELF');
		let myself = this.localizService.translate('REPORT.MYSELF')

		// 04.04.2023 cambiato ordine delle cascade-if
		if (this.isSpecialist() && this.user.user_id == specId) {
			name = myself
		} else if (this.isDoctor() && this.user.user_id == specId) {
			// 22.01.2021
			name = myself

			// 04.04.2023 anticipata qui
			// 20.09.2022 patch
		} else if (this.user.isGroupB()) {
			//  && name == ''
			let doct = this.getDtDoctor(specId)
			if (doct != null) {
				//name = doct.getFullName()  // 04.04.2023 no, viene Oper_D38 ...
				name = doct.getUsername()
			} else {
				Util.debug('(getSpecialistName) did not loaded the colleagues list!')
				name = 'ID ' + specId
			}
		} else if (this.isOptician()) {
			name = this.user.getSpecialistName(specId)
		} else {
			name = 'ID ' + specId
		}

		return name
	}

	/* TODO 
  // ex getSpecialist, richiamata da diagnosisReport.ts
  // 22.02.2021 esteso per gestire doctors che refertano, anche groupB  
	// 13.05.2022 cambio oggetto, uso Referrer
	getReferrer(specId, optId?, forceLoad?) : Referrer{

		let referrer : Referrer;
		
		if(this.isLevel3()){ 
			
			let currRel = this.getDtRelation(specId, optId);
			let currSpec = this.getDtSpecialist(specId);
			referrer = currSpec.getMeAsReferrer(currRel);

		} else if(this.isSpecialist()){

			if(specId == this.getUserId()){  // e' lui  	
				let currRel = this.getDtRelation(optId, forceLoad); 			
				referrer = this.user.getMeAsReferrer(currRel);
			} else {  
				// un altro specialist, non deve vedere nulla ?!

			}

		} else if (this.isLevel1()){

			if(this.isOptician()){
				let currSpec = this.user.getSpecialist(specId);
				let currRel = this.getDtRelation(specId, forceLoad); 
				referrer = currSpec.getMeAsReferrer(currRel);  

			} else if(this.isDoctor()){  
							
				if(specId == this.getUserId()){  // e' lui  					
					referrer = this.user.getMeAsReferrer();					
				} else if(this.isGroupB()) {  
					// puo' essere uno degli altri colleghi, TODO             
				}         
			}

		} else {
			referrer = null;  
		}

		return referrer;
	}
	*/

	// richiamato da pdf.model.ts
	// per fare il report pdf della diagnosi
	getSpecialist(specId, optId?, forceLoad?) {
		let spec: Specialist
		spec = null

		// 04.08.2022
		let currUsr = this.getCurrentUser()

		if (this.isOptician()) {
			var usrSpec = this.user.getSpecialist(specId)
			// console.log(usrSpec)
			//if(usrSpec != null){  // se e' nullo, lo gestisce la chiamante
			spec = usrSpec
			//}
		} else if (this.isDoctor()) {
			if (specId == this.getUserId()) {
				// dovrebbe essere solo lui
				spec = new Specialist(specId)
				spec.display_name = this.getFullName() // utente loggato  //"Myself";

				// 04.08.2022
				if (currUsr.order_reg_num && currUsr.order_reg_num != '') {
					spec.order_reg_num = currUsr.order_reg_num
				}
			} else if (this.isGroupB()) {
				// puo' essere uno degli altri colleghi, lo richiede la chiamante
			}
		} else if (this.isSpecialist() && specId == this.getUserId() && optId) {
			Util.debug('S (getSpecialist) by me as spec ' + specId + ' for opt ' + optId)
			var myRel = this.getDtRelation(optId, forceLoad) // se false, prende dai dati in memoria

			if (myRel != null) {
				// console.log(myRel)
				spec = new Specialist(specId)
				spec.display_name = myRel.display_name

				// 08.04.2022
				if (myRel.has_signature) {
					spec.signature = myRel.signature
				} else {
					Util.debug('S (getSpecialist) by me asSpec no signature ?! rel with ' + myRel.username)
				}

				// 04.08.2022
				if (currUsr.order_reg_num && currUsr.order_reg_num != '') {
					spec.order_reg_num = currUsr.order_reg_num
				}

				// 28.10.2021
				Util.debug('S (getSpecialist) by me, dispName: ' + spec.display_name + ' sign ? ' + myRel.has_signature)
			}
		} else if (this.isGod()) {
			// 20.09.2021 gli admin possono vedere il display_name
			// prendere dalla lista specialists del doctor
			if (optId != null) {
				var currDoc = this.data.getDoctorFromList(optId)
				if (currDoc != null) {
					spec = currDoc.getSpecialist(specId)
				} else {
					let specs = this.getDtSpecialists(optId) // da dati in memoria
					if (specs) {
						let tot = specs.length
						Util.debug('S (getSpecialist) tot specialist for this optician: ' + tot)
						for (let i = 0; i < tot; i++) {
							if (specs[i].distributor_id == specId) {
								spec = specs[i]
								break
							}
						}
					} else {
						// TODO, chiederli al server
					}
				}

				// 02.03.2023
				if (!spec) {
					// richiesta al server
					this.loadReferrer(optId, specId).then((resp: Specialist) => {
						if (resp) {
							spec = resp
						} else {
							Util.debug('S (getSpecialist) referrer not found !')
						}

						if (!spec) {
							// 05.08.2022
							Util.debug('S (getSpecialist) not found ?!')
							spec = new Specialist(specId)
						} else {
							if (spec.order_reg_num == '') {
								// 04.08.2022
								Util.debug('S (getSpecialist) manca licence num...')
								let mySpec = this.data.getDistribFromList(+specId)
								if (mySpec) {
									if (mySpec.order_reg_num && mySpec.order_reg_num != '') {
										spec.order_reg_num = mySpec.order_reg_num
									}
								}
							}
						}
					})
				}

				/*
        // 04.08.2022
        let mySpec = this.data.getDistribFromList(specId);

        if(mySpec){
          if(mySpec.order_reg_num && mySpec.order_reg_num != ""){
            spec.order_reg_num = mySpec.order_reg_num;  
          }
        } else {
          console.log("S (getSpecialist) ko info specialist by god");
        }
        */
			}
		}

		/*  08.04.2022 meglio toglierlo da qui x forzare reload della rel ? [ls]
		if(spec == null){
		console.log("(getSpecialist) creating an empty one"); // 24.01.2022
		spec = new Specialist(specId); 
		spec.display_name = "Doctor_"+specId;
		}
		*/

		return spec
	}

	// 16.11.2017 aggiunto parametro per non fare decrytt
	loadDistribs(ignoreCritt) {
		// 28.03.2017
		//if (this.isGod() || this.isVice() || this.isStats() || this.isSupport()) {
		if (this.isManagement() || this.isClinicAdmin()) {
			// 08.08.2018
			//var goodPwd = this.user.password; // per aprire poi la keybox_ns
			var goodPwd = this.getPwdForKeyPhoto() // senza promise

			// 30.05.2023
			let superKeyBox = null
			if (this.isClinicAdmin()) {
				superKeyBox = this.user.keyboxPhoto
				goodPwd = this.user.password
				Util.debug('(loadDistribs) by clinicAdmin ... valid box ? ' + (superKeyBox != null))
			}

			//console.log("loading distrib list");
			return this.data.loadDistributors(this.buildBaseRequest(), goodPwd, ignoreCritt, superKeyBox)
		} else {
			//return Promise.all('') // meglio che null?
			return Promise.reject('') // 17.11.2022
		}
	}

	// 03.05.2022 per la smart-table nuova
	loadRemotes(ignoreCritt: boolean): Promise<Distrib[]> {
		// console.log(this.getRoute())
		return this.loadDistribs(ignoreCritt)
			.then(() => {
				// da sistemare con observables con signalR
				let dList = this.data.distribList
				return dList
			})
			.catch((err) => {
				let msg = this.parseErrorMessage(err, 'alert')
				Util.debug('(loadRemotes) ko, err: ' + msg)
				throw err
			})
	}

	// 06.05.2022
	// 03.05.2017
	loadDistrib(distribId: string): Promise<Distrib> {
		if (!this.isGod() && !this.isVice() && !this.isSupport() && !this.isClinicAdmin()) {
			Util.debug('(loadDistrib) user not enabled')
			Promise.reject('forbidden')
		}

		// 17.11.2022 succede con il support ?! FIXME
		if (distribId == '0') {
			//alert('(loadSpecialist) invalid parameter')
			Promise.reject('invalid param')
		}

		// ok per entrambe le tipologie
		var distribKey = this.getPwdForKeyPhoto()
		Util.debug('(loadDistrib) loading distrib ' + distribId + ' by user ' + this.getUserId())

		// 30.05.2023
		let superKeyBox = null
		if (this.isClinicAdmin()) {
			superKeyBox = this.user.keyboxPhoto
			distribKey = this.user.password
			Util.debug('(loadDistribs) by clinicAdmin ... valid box ? ' + (superKeyBox != null))
		}

		// prende dall'array ricevuto appena prima, invece che chiedere di nuovo al server --ls
		// this.data.setCurrentDistrib(distribId);
		// 06.06.2017 se ho appena fatto una nuova relazione, non la vedo! meglio richiedere --ls
		return this.data.loadDistrib(this.buildBaseRequest(), distribKey, distribId, superKeyBox)
	}

	// 19.10.2021 aggiunto parametro, ultimo singolo caricato
	getDtDistrib(usrId?): Promise<Distrib> {
		Util.debug('(getDtDistrib) get distrib ')

		const promise = new Promise<Distrib>((resolve, reject) => {
			var dtUsr = this.data.distrib
			if (dtUsr != null && usrId != null && usrId != dtUsr.id) {
				dtUsr = null
			}

			if (usrId != null && (dtUsr == null || (dtUsr != null && dtUsr.id != usrId))) {
				console.log('geDistribFromList')
				dtUsr = this.geDistribFromList(usrId)
			}

			if (dtUsr == null) {
				this.loadDistrib(usrId)
					.then((distrib) => {
						dtUsr = distrib
						resolve(dtUsr)
					})
					.catch((err) => {
						console.log(err)
						reject(err)
					})
			} else {
				resolve(dtUsr)
			}
		})

		return promise
	}

	getDtDistribList() {
		let list = this.data.distribList
		return list
	}

	// 20.04.2023 rifatta
	// 04.08.2022 ????????????????????
	// 06.05.2022
	geDistribFromList(usrId: number) {
		let dtUsr = this.data.getDistribFromList(usrId)
		return dtUsr
	}

	// richiamato da specialist.modal.ts
	// 15.05.2018 aggiunto order_reg_num
	// 07.03.2019 aggiunti settings per il brand
	// 21.02.2022 aggiunti flag diagonal e lintel
	updateDistrib(userDraft: Distrib) {
		let request: any = this.buildBaseRequest()
		request.method = 'PUT'
		request.url = Config.distributorsEndpoint + '/' + userDraft.id

		// la mail non si edita xche' la cambia l'utente dal suo profile pg

		// Contracts.DistributorRequest
		var dataReq = {
			distributor: {
				order_reg_num: userDraft.order_reg_num, // solo per specialist
				created_by: userDraft.created_by, // 22.08.2018 owner
				is_test: userDraft.is_test, // 21.02.2022
				user_subtype: userDraft.user_subtype, // 31.08.2022
				settings: userDraft.settings, // poi le API accettano solo brand e diagnosis_group
				settings_admin: userDraft.admSettings, // 21.02.2022
			},
		}
		request.data = dataReq

		return this.myPut(request, dataReq)
	}

	// 04.08.2022
	public hasLoadedSpecialist(myId) {
		return this.data.hasLoadedDistributor(myId)
	}

	// 04.08.2022
	isLoadingSpecialist() {
		return this.data.isLoadingDistributor()
	}

	// 04.08.2022
	isLoadingRemotes() {
		let ret = this.data.isLoadingDistribList()
		return ret
	}

	isLoadingRels() {
		let ret = this.data.isLoadingRels()
		return ret
	}

	// ************ relations *************

	getDtDeletedRelations(doctorId: number): Promise<relationsStatus[]> {
		Util.debug('S (getDtDeletedRelations) doctorId: ' + doctorId)

		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.relationsEndpoint + '/search?optician=' + doctorId + '&status=deleted'

		const promise = new Promise<relationsStatus[]>((resolve, reject) => {
			this.myGet(request)
				.then((res) => {
					resolve(res.relations)
				})
				.catch((err) => {
					reject(err)
				})
		})

		return promise
	}

	// 24.01.2022 abilitata
	// 28.10.2021 era su nexy, non ancora implementata su nexus
	// 13.09.2019 richiede la currentRelation dello specialist loggato con il doctor in parametro
	getCurrentRelation(doctorId): Promise<Relation> {
		if (!this.isSpecialist()) {
			return Promise.reject(null)
		}

		//con la keyPhoto del farmacista apro la relation
		//if(!this.user.keyDistrib)
		//  this.loadDoctorKey(doctorId);  // 16.09.2019
		this.validateDoctKey(doctorId)

		let refId = this.getUserId()
		let myRel: Relation
		//return this.data.loadRelation(this.buildBaseRequest(), doctorId, goodKey);
		myRel = this.data.getRelation(refId, doctorId) // 21.01.2022 da lista in memoria

		if (myRel != null) {
			Util.debug('S (getCurrentRelation) OK, id: ' + myRel.id)
			//console.log(myRel); // ok, valida ma con i campi crittati

			if (!myRel.decrypted) {
				// 24.01.2022 e' una promise
				Util.debug('S (getCurrentRelation) going to decrypt...')
				this.decryptRelation(myRel).then((rel) => {
					return rel
				})
			} else {
				Util.debug('S (getCurrentRelation) already decrypted, displ: ' + myRel.display_name)
				return Promise.resolve(myRel)
			}
		} else {
			let goodPwd = null // 24.01.2022 tengo tutto crittato, decritta dopo
			Util.debug('S (getCurrentRelation) req all from the server...')

			// 22.05.2023 patch per evitare problemi con redirect_api e https in DEV
			//return this.data.loadRelations(this.buildBaseRequest(), goodPwd)
			let ignoreCritt = false
			return this.data.loadRelations(this.buildBaseRequest(), goodPwd, '' + refId, ignoreCritt).then(() => {
				Util.debug('S (getCurrentRelation) got the list, re-ask...')
				return this.getCurrentRelation(doctorId) // ATT: ricorsiva !!
			})
		}

		//return myRel;
	}

	// 21.01.2022 by specialist
	private decryptRelation(relCritt: Relation) {
		// 05.07.2023 FIXME, con la flatList, potrebbe essere dal patient precedente, di un altro optician
		//let goodKey = this.user.keyDistrib
		let goodKey = null

		// 08.06.2023 con le nuove relazioni, potrei gia' avere la keyPhoto
		if (!goodKey) {
			Util.debug('S (decryptRelation) taking the key from the rel...')
			goodKey = relCritt.docKeyPhoto
			if (goodKey) {
				this.user.keyDistrib = goodKey // solo per questo medico!
			} else {
				let docId = parseInt(relCritt.doctor_id)
				goodKey = this.getImageKeyFromDtRelation(docId)
				console.log('S (decryptRelation) got key from relation, ' + (goodKey != null))
			}
		}

		//console.log("S (decryptRelation):");
		//console.log(relCritt); // ok, valida ma con i campi crittati

		Util.debug('S (decryptRelation) starting...')

		return Relation.createFullRelation(relCritt, this.cryptoUtils, goodKey).then((fullRel: Relation) => {
			let forceUpdate = false
			this.data.updateRelation(fullRel, forceUpdate)
			Util.debug('S (decryptRelation) got full rel, has sign ? ' + fullRel.has_signature)
			return fullRel // 31.01.2022
		})
	}

	// 13.09.2019 ritorna la currentRelation dello specialist loggato con il doctor in parametro
	// prende dai dati in memoria, non fa nuova request
	getDtRelation(doctorId, forceLoad?): Relation {
		if (!this.isSpecialist() && !this.isDistributor()) {
			return null
		}

		var currRel: Relation
		var distId = this.user.user_id

		// prende dai dati in memoria, non fa nuova request
		currRel = this.data.getRelation(distId, doctorId)

		if (currRel != null) {
			Util.debug('(getDtRelation) dist: ' + distId + ' and doc ' + doctorId + ' displName: ' + currRel.display_name)
		} else {
			Util.debug('(getDtRelation) not loaded yet, between dist: ' + distId + ' and doc ' + doctorId)
			if (forceLoad) {
				Util.debug('(getDtRelation) FORCED, TODO')
			}
		}

		return currRel
	}

	// by admins
	// carica le relazioni (con i doctors) valide per questo distrib
	// 	loadRelationsList(distribId, forceRefresh?: boolean) {
	// 		let relsList: Relation[]
	// 		relsList = null

	// 		//let relRichList; // 10.08.2022 aggiungo info inactive accanto alla username dei liv1

	// 		return this.loadDistribDoctors(distribId, forceRefresh).then(() => {
	// 			relsList = this.data.relsList

	// 			let totRel = 0

	// 			if (relsList) {
	// 				totRel = relsList.length
	// 				// Util.debug('S (relList) list of ' + totRel + ' elements');
	// 				console.log('S (relList) list of ' + totRel + ' elements')
	// 			}
	// 			// console.log('Has loaded '+ this.data.hasLoadedDoctors());
	// 			// console.log('Is loading '+ this.data.isLoadingDoctors());

	// 			// 10.08.2022 aggiungo info inactive accanto alla username dei liv1
	// 			// TODO se mancano info doctors ?
	// 			if (relsList && this.data.hasLoadedDoctors()) {
	// 				for (let i = 0; i < totRel; i++) {
	// 					let optId = relsList[i].doctor_id
	// 					let opt = this.data.getDoctorFromList(optId)

	// 					if (opt && opt.isActive) {
	// 						relsList[i].isOperActive = true
	// 					}

	// 					// console.log(opt)
	// 				}
	// 			} else {
	// 				Util.debug('S (relList) cannot add inactive info on opticians!')
	// 			}

	// 			return relsList
	// 		})
	// 		/*
	//   .catch((err) =>  {
	//     if(!this.isExpired(err)){   //  e' un altro errore, va segnalato
	//       var msg = (err.data)? err.data.error : err.toString();
	//       alert(msg);
	//     }
	//   });
	//   */
	// 	}

	getRelationsList() {
		return this.data.relsList
	}

	// 02.05.2017 carica le relazioni (con i doctors) di questo distrib
	loadRelationsList(distribId, ignoreCritt?: boolean): Promise<Relation[]> {
		const promise = new Promise<Relation[]>((resolve, reject) => {
			if (this.isGod() || this.isVice() || this.isAdmin() || this.isSupport() || this.isSpecialist() || this.isClinicAdmin()) {
				//var goodPwd = this.user.password; // per aprire poi la keybox_ns
				var goodPwd = this.getPwdForKeyPhoto() // 07.08.2018 ok per entrambe le tipologie

				// 03.09.2019 miglioria [ls]
				// viene chiamata con 0 apposta da funzione resetBtnsRow1 su relationsList.ts
				if (distribId == 0) {
					Util.debug('(loadRelationsList) reset distribDoc list')
					this.data.resetRelationList()
					resolve([])
				}

				// Util.debug('S (loadRelationsList) inizio, distribId: ' + distribId + ' forceRefresh ? ' + forceRefresh)

				// 07.06.2023 se ho gia' una lista buona e non c'e' il force, ok questa
				// if (!forceRefresh) {
				// 	if (this.data.hasLoadedRels(distribId)) {
				// 		// aggiunto test per questo grader
				// 		let tot = this.data.relsList.length
				// 		Util.debug('(loadRelationsList) already loaded, tot: ' + tot)
				// 		return Promise.resolve(this.data.relsList)
				// 	}
				// }

				this.data.loadRelations(this.buildBaseRequest(), goodPwd, distribId, ignoreCritt).then((myList) => {
					if (myList && myList.length > 0) {
						// prima volta che la chiedo, verifico se ce ne sono di nuove
						Util.debug('(loadRelations) tot: ' + myList.length)
						if (this.isSpecialist() || this.isClinicAdmin()) {
							this.verifyNewRelations(myList).then(() => {
								Util.debug(' (loadRelations - verifyNewRelations) end')
								resolve(myList)
							})
						} else {
							resolve(myList)
						}
					} else {
						resolve(myList)
					}
				})
			} else {
				Util.debug('(loadRelations) forbidden!')
				reject('forbidden!')
			}
		})

		return promise
	}

	// 09.05.2024 da richiamare sia quando il grader cambia pwd, che dal recover con puk
	public reactivateAllRelations() {
		// 09.05.2024 ricarico le rels, sono tutte da ri-attivare - rifare le keybox
		// questo giro e' piu' leggero di chiamare le loadDistribDoct che rifa' tutto, con le firme etc.
		if (this.isSpecialist()) {
			let distribId = this.user.user_id
			let goodPwd = this.getPwdForKeyPhoto()

			let relList = this.getRelationsList()

			if (relList && relList.length > 0) {
				Util.debug('(changePwd) tot rels to reactivate: ' + relList.length)
				this.reactivateRelations(relList).then(() => {
					let ignoreCritt = false
					return this.data.loadRelations(this.buildBaseRequest(), goodPwd, '' + distribId, ignoreCritt)
				})
			}

			// let ignoreCritt = false
			// return this.data.loadRelations(this.buildBaseRequest(), goodPwd, '' + distribId, ignoreCritt).then((relsList) => {

			// })
		}
	}

	// called by specialist after changePwd or recoverWithPuk
	private async reactivateRelations(relsList) {
		if (!this.isSpecialist()) return Promise.reject('forbidden')

		let promiseslist: Relation[] // serve per sapere quando sono finite tutte
		promiseslist = []

		for (let i = 0; i < relsList.length; i++) {
			let rel = relsList[i]

			// FIX dovuto al fatto che la libreria di crittografia non e' tread-safe !!!
			// aggiunto await
			await this.reactivateSingleRel(rel).then((resp) => {
				Util.debug('S (reactivateRelations) ok done n. ' + i + ' with ' + rel.username)
				promiseslist.push(rel) // ok, done
			})
		} // chiude il for
		return Promise.all(promiseslist)
	}

	// 08.05.2024 arriva qui dopo reset pwd con puk o cambio pwd dalla profile
	// basta ripristinare la keybox, in teoria signature ecc sono gia' ok
	private reactivateSingleRel(rel: Relation): Promise<any> {
		// bisogna aprire la box con le keypriv, estarre la keyPhoto e crearsi la docKeyBoxPhoto
		// poi fare un PUT per aggiornare il record
		console.log('S (reactivateSingleRel) with opt: ' + rel.username)

		//apre la box con la keypriv, estrae la keyPhoto
		return this.extractAsymmetric(rel.keybox_public).then((keyphoto) => {
			// aggiorno in memoria, con questa posso gia' vedere le immagini
			rel.docKeyPhoto = keyphoto // nuovo campo, per comodita'
			//this.user.keyDistrib = keyphoto // solo per questo medico!

			// crea la keybox_distrib per la relazione
			let mySalt = rel.username.toLowerCase() // e' quella dell'optician
			console.log('(reactivateSingleRel) salt: ' + mySalt)
			return this.cryptoUtils.encryptDataWithPwdS(this.user.password, keyphoto, mySalt).then((keybox_distrib) => {
				if (keybox_distrib.length != this.KEYBOX_LEN) {
					console.log('(reactivateSingleRel) ko creazione KeyBox distributor! len ' + keybox_distrib.length)
					alert(' ** ko activation of new relation with optician ** ')
					return Promise.reject('ko KeyBox creation') // 09.05.2024 fix, mancava
				} else {
					// ok, aggiorna la rel con la key e keybox in memoria
					rel.docKeyBoxPhoto = keybox_distrib

					//this.data.updateRelation(rel, true) // in memoria

					// aggiorna sul server la relazione con la keybox e la signature
					console.log('(reactivateSingleRel) ok creazione KeyBox distributor, going to reactivate the relation... ')

					// ok sia per clinicAdmin che per Specialist, poi per Specialist aggiungo
					let dataR = {
						id: rel.id,
						keybox_distributor: rel.docKeyBoxPhoto, // questa ri-attiva dopo cambio pwd del grader
					}

					return this.updateRelInternal(dataR)
				}
			})
		})
	}

	// PER OPTICIAN
	public getRelationsByOpticians(): Promise<Specialist[]> {
		Util.debug('S (getRelationsByOpticians) ')

		const promise = new Promise<Specialist[]>((resolve, reject) => {
			let request: any = this.buildBaseRequest()
			request.method = 'GET'
			request.url = Config.relationsEndpoint

			this.myGet(request)
				.then((res) => {
					// console.log(res)
					resolve(res.specialists)
				})
				.catch((err) => {
					reject(err)
				})
		})

		return promise
	}
	// END
	public getAvailableGraders(userId: number): Promise<Distrib[]> {
		Util.debug('(getAvailableGraders) for: ' + userId)
		const promise = new Promise<Distrib[]>((resolve, reject) => {
			let request: any = this.buildBaseRequest()
			request.method = 'GET'
			request.url = Config.availableDistributorsEndpoint + userId

			var goodPwd = this.getPwdForKeyPhoto()
			this.data
				.loadAvailableGraders(request, goodPwd)
				.then((myList: Distrib[]) => {
					// console.log(myList)
					resolve(myList)
				})
				.catch((err) => {
					reject(err)
				})
		})

		return promise
	}

	//not used
	public getNearestRelations(userId: number): Promise<any> {
		Util.debug('(getNearestRelations) for: ' + userId)
		const promise = new Promise((resolve, reject) => {
			let request: any = this.buildBaseRequest()
			request.method = 'GET'
			request.url = Config.nearestRelationsEndpoint + '?user=' + userId

			this.myGet(request)
				.then((relationList) => {
					// console.log(relationList)
					resolve(relationList.nearest_relations)
					// ho una lista di relazioni, mi devo ricavare il distributor ma voglio mantenere lo status
				})
				.catch((err) => {
					reject(err)
				})
		})

		return promise
	}

	// 08.06.2023 solo da parte dello specialist, per le nuove relazioni, salva la keybox + firma, displ_name, licence, ecc.
	private verifyNewRelations(relsList: Relation[]): Promise<Relation[]> {
		const promise = new Promise<Relation[]>(async (resolve, reject) => {
			// ha senso solo se e' lui stesso perche' serve la sua pw

			if (!this.isSpecialist() && !this.isClinicAdmin()) {
				reject('not Specialist or ClinicAdmin')
			}

			// this.setLoading(true) // 04.07.2023
			// let promiseslist: Relation[] // serve per sapere quando sono finite tutte
			let promiseslist = []

			// 07.06.2023
			for (let i = 0; i < relsList.length; i++) {
				let rel = relsList[i]
				if (rel.keybox_public != null && !rel.docKeyBoxPhoto) {
					Util.debug('S (verifyNewRelations)')
					await promiseslist.push(
						this.updateSingleRel(rel).then((resp) => {
							Util.debug('S (verifyNewRelations) ok done n. ' + i + ' with ' + rel.username)
						})
					)
					// this.updateSingleRel(rel).then((resp) => {
					// 	Util.debug('S (verifyNewRelations) ok done n. ' + i + ' with ' + rel.username)
					// 	// promiseslist.push(rel) // ok, done
					// })
				}
			} // chiude il for

			Promise.all(promiseslist).then((done) => {
				Util.debug('S (verifyNewRelations) ok, aggiornate tutte le nuove relazioni, tot: ' + promiseslist.length)
				resolve(relsList)
			})
		})

		return promise
	}

	// 08.05.2024 arriva qui anche dopo reset pwd con puk, basterebbe ripristinare la keybox, in teoria signature ecc sono gia' ok, poco male se lo rifa'...
	// 05.07.2023 FIX dovuto al fatto che la libreria di crittografia non e' tread-safe !!!
	// aggiunto await nella chiamante
	private updateSingleRel(rel: Relation): Promise<any> {
		// TODO per nuova relazione,
		// bisogna aprire la box con le keypriv, estarre la keyPhoto e crearsi la docKeyBoxPhoto
		// poi fare un PUT per aggiornare il record
		console.log('S (verifyNewRel) new with opt: ' + rel.username)

		//apre la box con la keypriv, estrae la keyPhoto
		return this.extractAsymmetric(rel.keybox_public).then((keyphoto) => {
			// aggiorno in memoria, con questa posso gia' vedere le immagini
			rel.docKeyPhoto = keyphoto // nuovo campo, per comodita'
			this.user.keyDistrib = keyphoto // solo per questo medico!

			// crea la keybox_distrib per la relazione
			let mySalt = rel.username.toLowerCase() // e' quella dell'optician
			console.log('(verifyNewRel) salt: ' + mySalt)
			return this.cryptoUtils.encryptDataWithPwdS(this.user.password, keyphoto, mySalt).then((keybox_distrib) => {
				if (keybox_distrib.length != this.KEYBOX_LEN) {
					console.log('(verifyNewRel) ko creazione KeyBox distributor! len ' + keybox_distrib.length)
					alert(' ** ko activation of new relation with optician ** ')
					return Promise.reject('ko KeyBox creation') // 09.05.2024 fix, mancava
				} else {
					// ok, aggiorna la rel con la key e keybox in memoria
					rel.docKeyBoxPhoto = keybox_distrib

					// TODO ottimizzazione: solo se manca ?
					// 29.06.2023 FIXME per lo specialist, deve salvare anche il display_name e la firma! bug #18713

					let mySign = this.user.signature
					let myDisplName = this.user.display_name
					let licenceNum = this.user.order_reg_num // 08.05.2024 verificare nome del campo in chiaro

					if (this.isClinicAdmin()) {
						myDisplName = this.user.getOrganization()
						rel.display_name = myDisplName
					}

					// uso i valori in chiaro per il dato in memoria, poi invio al server con i campi crittati
					// non per il clinicAdmin
					if (this.isSpecialist()) {
						rel.signature = mySign
						rel.display_name = myDisplName
						rel.has_signature = true
					}

					this.data.updateRelation(rel, true) // in memoria

					var bag = this.cryptoUtils.generateBag()
					bag['signature'] = mySign
					bag['display_name'] = myDisplName
					bag['licence_num'] = licenceNum // 08.05.2024

					this.cryptoUtils.purge(bag)

					// questa e' un encryptAll
					return this.cryptoUtils.encryptFromStringToBase64Content(keyphoto, bag).then((bagCritt) => {
						rel.display_name = bagCritt['display_name']
						rel.signature = bagCritt['signature']
						rel.licence_num = bagCritt['licence_num'] // 08.05.2024 verificare nome campo

						// aggiorna sul server la relazione con la keybox e la signature
						console.log('(verifyNewRel) ok creazione KeyBox distributor, going to save the rich relation... ')

						// ok sia per clinicAdmin che per Specialist, poi per Specialist aggiungo
						let dataR = {
							id: rel.id,
							keybox_distributor: rel.docKeyBoxPhoto, // questa attiva la relazione nuova e/o ri-attiva dopo cambio pwd del grader
						}

						// aggiungo i campi
						if (this.isSpecialist() || this.isClinicAdmin()) {
							dataR['display_name'] = rel.display_name
						}

						if (this.isSpecialist()) {
							dataR['signature'] = rel.signature
							dataR['licence_num'] = rel.licence_num // 08.05.2024
						}

						// console.log(dataR)
						return this.updateRelInternal(dataR)
					})
				}
			})
		})
	}

	// 08.06.2023 prendo la keyPhoto dall'array di relazioni, anziche' da quello dei doctors
	// nella modalita' flat e' piu' leggero
	public getImageKeyFromDtRelation(opticianId: number) {
		if (!this.isSpecialist()) {
			return null
		}

		let currGraderId = this.user.user_id
		let myRel = this.data.getRelation(currGraderId, opticianId)

		let currKeyPhoto = null
		if (myRel) {
			currKeyPhoto = myRel.docKeyPhoto

			if (!currKeyPhoto) {
				if (myRel.docKeyBoxPhoto) {
					Util.debug('(getImageKeyFromRelation) TODO decrittarla dalla box!!')

					let mySalt = myRel.username.toLowerCase()

					this.cryptoUtils.decryptDataWithPwdS(this.user.password, myRel.docKeyBoxPhoto, mySalt).then((doctKeyPhoto: forge.util.ByteStringBuffer) => {
						myRel.docKeyPhoto = doctKeyPhoto

						currKeyPhoto = doctKeyPhoto

						return currKeyPhoto
					})
				} else {
					Util.debug('(getImageKeyFromRelation) ko relazione!')
					return currKeyPhoto
				}
			} else {
				return currKeyPhoto
				// 05.07.2023 togliere in PROD - solo per test ***************
				//Util.debug('(getImageKeyFromRelation) keyPhoto ok, optId: '+opticianId);
				//console.log(currKeyPhoto.toHex());
			}
		} else {
			Util.debug('(getImageKeyFromRelation) ko relation!')

			return currKeyPhoto
		}

		// Util.debug('(getImageKeyFromRelation) ok key ?' + (currKeyPhoto != null))

		// return currKeyPhoto
	}

	// 09.06.2023 l'admin, quando fa una nuova relazione per il clinicAdmin
	// deve distribuirla a tutti i miniC della clinica
	// private applyRelToClinics(clinicAdminId: number, optUsername: string, currKeyPhoto) {
	// 	Util.debug('(applyRelToClins) adminId: ' + clinicAdminId + ' with ' + optUsername)

	// 	let remotesList = this.getDtDistribList()
	// 	// l'ho caricata di certo perche' il clinicAdmin viene da li'
	// 	if (!remotesList) {
	// 		console.log('(applyRelToClins) empty remotes list!!!! *****************')
	// 		return
	// 	}

	// 	let totGraders = remotesList.length
	// 	Util.debug('(applyRelToClins) tot remotes: ' + totGraders)

	// 	// 14.06.2023
	// 	for (let i = 0; i < totGraders; i++) {
	// 		let myDistrib = remotesList[i]
	// 		if (myDistrib.created_by == clinicAdminId && !myDistrib.isDeleted()) {
	// 			Util.debug('(applyRelToClins) miniC: ' + myDistrib.username + ' with ' + optUsername)
	// 			// NB: senza return altrimenti fa solo il primo!
	// 			this.createRelWithPublicKey(optUsername, myDistrib, currKeyPhoto).catch((err) => {
	// 				console.log(err)
	// 			})
	// 			// TODO mettere uno sleep ?
	// 		} else {
	// 			//Util.debug('(applyRelToClins) scarto: '+myDistrib.username+" by: "+myDistrib.created_by)
	// 		}
	// 	}
	// }

	// 15.06.2023 quando un clinicAdmin fa un miniC, deve dargli tutte le sue relazioni
	// public applyMyRelsToClinic(myGrader: Distrib) {
	// 	if (!myGrader) {
	// 		console.log('(applyRelsToGrader) ko nuovo grader!!!! *****************')
	// 		return
	// 	}

	// 	//let clinicRels = this.getDtRelations()
	// 	let clinicRels = this.data.relsList
	// 	// l'ho caricata dopo la login...
	// 	if (!clinicRels) {
	// 		console.log('(applyRelsToGrader) empty relations list!!!! *****************')
	// 		return
	// 	}

	// 	let totGraders = clinicRels.length

	// 	// 15.06.2023
	// 	for (let i = 0; i < totGraders; i++) {
	// 		let myRel = clinicRels[i]
	// 		if (myRel.isValid()) {
	// 			let currKeyPhoto = myRel.docKeyPhoto
	// 			if (!currKeyPhoto && myRel.docKeyBoxPhoto) {
	// 				this.decryptRelation(myRel)
	// 				//currKeyPhoto = ....  // TODO
	// 			}
	// 			let optUsername = myRel.username

	// 			Util.debug('(applyRelsToGrader) ' + i + ' miniC: ' + myGrader.username + ' with ' + optUsername)
	// 			// NB: senza return altrimenti fa solo la prima
	// 			this.createRelWithPublicKey(optUsername, myGrader, currKeyPhoto).catch((err) => {
	// 				console.log(err)
	// 			})
	// 			// TODO mettere uno sleep ?
	// 		}
	// 	}
	// }

	// 21.01.2022 all the relations for this level2
	private OLDloadRelations(specId?) {
		var goodPwd = null

		if (this.isLevel2()) {
			//goodPwd = this.loadDoctorKey(); // user.keyDistrib; //  keyPhoto; // 21.01.2022
			//goodPwd = this.getPwdForKeyPhoto(); // 07.08.2018 solo per admins
			goodPwd = this.getExamKey()
			specId = this.getUserId() // sovrascrivo
		}

		// TEMP TODO
		//return this.data.loadRelations(this.buildBaseRequest(), specId, goodPwd);
	}

	// 06.12.2022 - 23.06.2020
	deleteRelation(relId) {
		var request: any = this.buildBaseRequest()
		request.method = 'DELETE'

		request.url = Config.relationsEndpoint + '/' + relId

		Util.debug('(deleteRelation) id ' + relId)

		return this.myDelete(request)
		//lo fa giá nella mydelete
		// .catch((err) => {
		// if (!this.isExpired(err)) {
		// 	// rilancia la exception
		// 	throw err
		// }
		// })
	}

	restoreRelation(relationId) {
		Util.debug('(restoreRelation) id: ' + relationId)

		var request: any = this.buildBaseRequest()
		request.method = 'PUT'

		request.url = Config.relationsEndpoint + '/' + relationId

		return this.myPut(request, null)
	}

	//OLD
	// restoreRelation(relationId) {
	// 	// e' un update del solo campo is_valid
	// 	var dataR = {
	// 		id: relationId, // 23.06.2020
	// 		is_valid: 'Y',
	// 	}
	// 	return this.updateRelInternal(dataR).catch((err) => {
	// 		// 14.07.2021
	// 		if (!this.isExpired(err)) {
	// 			// rilancia la exception
	// 			throw err
	// 		}
	// 	})
	// }

	// 30.05.2023 Add from old FE
	deleteDistrib(usrId) {
		var request: any = this.buildBaseRequest()
		request.method = 'DELETE'
		request.url = Config.distributorsEndpoint + '/' + usrId
		return this.myDelete(request).catch((err) => {
			// 14.07.2021
			if (!this.isExpired(err)) {
				// rilancia la exception
				throw err
			}
		})
	}

	// 16.06.2023 flag temporaneo impostato dal clinicAdmin
	public disableGrader(graderId: number) {
		return this.updateActiveGraderFlag(graderId, false)
	}

	// 16.06.2023
	public restoreGrader(graderId: number) {
		return this.updateActiveGraderFlag(graderId, true)
	}

	private updateActiveGraderFlag(graderId: number, flagActive: boolean) {
		if (!this.isClinicAdmin()) {
			return //forbidden;
		}

		let newStatus = flagActive ? 'active' : 'disabled' // NB: valori sulla enum del DB
		Util.debug('S (updateActiveGraderFlag) grader: ' + graderId + ' flagActive: ' + flagActive + ' new status:' + newStatus)

		//FIXME, estendere la updateDistrib che c'e' gia' ?
		let request: any = this.buildBaseRequest()
		request.method = 'PUT'
		request.url = Config.distributorsEndpoint + '/' + graderId

		var dataReq = {
			distributor: {
				status: newStatus,
			},
		}
		request.data = dataReq

		return this.myPut(request, dataReq)
	}

	// 02.03.2023
	// 20.01.2022 TODO
	loadReferrer(operId: number, specId: number): Promise<Specialist> {
		let goodKey = null
		if (this.isLevel2()) {
			specId = this.getUserId() // sovrascrivo
		}
		Util.debug('S (loadReferrer) opt: ' + operId + ' ref: ' + specId)

		if (this.isLevel1()) {
			goodKey = this.user.getKeyPhoto()
		} else if (this.isLevel3()) {
			Util.debug('S (loadReferrer) for admin')
			if (this.loadDoctorKey('' + operId)) {
				goodKey = this.loggedUser.keyPhoto
			}
		}

		return this.data.loadRelationReferrer(this.buildBaseRequest(), specId, operId, goodKey)
	}

	// 18.05.2018 distribId e' gia' dentro relation --ls
	// 22.05.2017 ***************************************************************
	// aggiunge display_name del distributore che sia visibile al medico
	// 11.07.2017 aggiunta immagine con la firma del refertatore
	private editRelation(relation: Relation, displayName: string, signatureImage, orderRegNum: string) {
		var result: any = {}

		//var doctorId = relation.doctor_id; // uso da relation --ls
		var docUsername = relation.username
		var signatureCritt
		//var enableNote = ''; // 18.05.2018

		// 06.08.2018 per i Vice devo usare la keybox_vice per ottenere la "vera" pwd
		//var nsPwd = this.user.password;
		//var nsPwd = this.getPwdForKeyPhoto(); // senza promise, ok per entrambe le tipologie di adm

		//console.log("(editRelation) last user: "+this.user.username); // NS

		Util.debug('(editRelation) with ' + relation.doctor_id + ' displayName: ' + displayName)

		//console.log("(editRelation) ok signature, len: "+signatureImage.byteLength);
		var flagDelSignature = false
		if (signatureImage != null) {
			if (signatureImage == Config.DELETE_ME) {
				flagDelSignature = true
				Util.debug('(editRelation) DELETE old signature, ' + signatureImage)
				signatureImage = null // altrimenti poi la critta
			} else {
				Util.debug('(editRelation) ok signature')
			}
		} else {
			console.log('(editRelation) no signature ')
		}

		// 20.06.2023 FIXME quando lo fa il distrib stesso, la keyPhoto e' gia' nella rel che sta aggiornando!!!
		let goodKeyPhoto = null

		if (this.isSpecialist()) {
			goodKeyPhoto = relation.docKeyPhoto
			if (!relation.docKeyPhoto) {
				goodKeyPhoto = this.getImageKeyFromDtRelation(parseInt(relation.doctor_id))
			}
		} else {
			// come admin chiamo 	return this.getDoctorKeyPhoto(docUsername)
			this.getDoctorKeyPhoto(docUsername).then((nsKeyPhoto) => {
				//console.log("(editRelation) ok KeyPhoto! ");
				goodKeyPhoto = nsKeyPhoto
			})
		}

		// questa dovrebbe aspettare di avere la var buona, sia che venga dalla promise dell'admin che come specialist
		// TODO testare come admin
		return Promise.resolve(goodKeyPhoto)
			.then(() => {
				// critta il displayName con la keyPhoto del medico
				// aprendo la keybox_admin del medico stesso, che ora richiede al server
				//return this.getDoctorKeyPhoto(docUsername)
				//.then((nsKeyPhoto) => {
				console.log('(editRelation) ok KeyPhoto! ')

				//goodKeyPhoto = nsKeyPhoto;

				// 30.04.2020 MIGLIORIA: si potrebbe usare encryptAll unica [ls]

				// 11.07.2017 serve la bag  --ls
				var bag = this.cryptoUtils.generateBag()
				bag['signature'] = signatureImage
				return this.cryptoUtils.encryptImage(goodKeyPhoto, bag).then((bagCritt) => {
					if (bagCritt != null) {
						Util.debug('(editRelation) ok bagCritt')
					} else {
						console.log('(editRelation) ko bagCritt ')
					}

					signatureCritt = bagCritt['signature']
					// ko trace
					//console.log("(editRelation) len sign critt "+signatureCritt.length);

					const myBag = this.cryptoUtils.generateBag()
					myBag['order_reg_num'] = orderRegNum
					myBag['display_name'] = displayName

					return this.cryptoUtils.encryptFromStringToBase64Content(goodKeyPhoto, myBag).then((dataCritt) => {
						var dataR = {
							id: relation.id, // 23.06.2020
							doctor_id: relation.doctor_id,
							distributor_id: relation.distrib_id,
							display_name: dataCritt['display_name'],
							licence_num: dataCritt['order_reg_num'],
							signature: signatureCritt,
							//enable_note: relation.enable_note,
						}

						if (flagDelSignature == true) {
							dataR.signature = 'DELETE_ME' // sovrascrivo il null
						}

						return this.updateRelInternal(dataR).then((resp) => {
							// 20.06.2023 salvo anche in memoria, uso i dati gia' in chiaro
							if (this.isSpecialist()) {
								relation.display_name = displayName
								relation.signature = signatureImage
								relation.has_signature = true
								this.data.updateRelation(relation, true)
							}

							return resp
						})
					})
				})
			})
			.catch((err) => {
				var msg = err.data ? err.data.error : err.toString() // 02.05.2018
				console.log('(editRelation) 2- KO ' + msg)
				this.status = SessionStatus.LOGGED // 03.04.2017 fix, superuser e' cmq loggato --ls
				throw 'Error: update relation rejected, please retry'
			})
	}

	// *********************************************************
	// 08.05.2024 patch relazione, aggiorno solo licence_num e public_key
	private patchRelationBySpecialist(relation: Relation) {
		if (!this.isSpecialist()) {
			return false
		}

		//Util.debug('(patchRelationBySpec) with ' + relation.doctor_id + ' displayName: ' + relation.display_name)

		// quando lo fa il distrib stesso, la keyPhoto e' gia' nella rel che sta aggiornando
		let goodKeyPhoto = relation.docKeyPhoto

		if (!relation.docKeyPhoto) {
			// qui devo gia' averla, non dovrei mai passarci...
			console.log('(patchRelationBySpec) ko KeyPhoto! rel with ' + relation.doctor_id)
			return false
		}

		//Util.debug('(patchRelationBySpec) ok KeyPhoto!')
		let my_keybox_public = null

		// controllo se relaz. vecchia, se manca la keybox_public, la faccio ora
		if (!relation.keybox_public) {
			console.log('(patchRelationBySpec) old relation with ' + relation.doctor_id + ', creating keybox_public now!')
			my_keybox_public = this.buildKeyboxPhoto(this.user.username, this.user.public_key, goodKeyPhoto)
			// opticians' keyPhoto closed with the graders public key
		}

		// ho solo un campo da crittare, non serve la bag
		return this.cryptoUtils
			.encryptDataWithKey(goodKeyPhoto, this.user.order_reg_num)
			.then((licenceNumCritt) => {
				var dataR = {
					id: relation.id,
					licence_num: licenceNumCritt,
				}

				if (my_keybox_public) {
					dataR['keybox_public'] = my_keybox_public
				}

				return this.updateRelInternal(dataR).then((resp) => {
					// TODO update rel in memoria, uso i dati gia' in chiaro
					relation.licence_num = this.user.order_reg_num
					if (my_keybox_public) {
						relation.keybox_public = my_keybox_public
					}

					this.data.mergeRelation(relation) // aggiorna nella lista, prende solo i campi nuovi, che mancavano

					return resp
				})
			})
			.catch((err) => {
				var msg = err.data ? err.data.error : err.toString() // 02.05.2018
				console.log('(patchRelationBySpec) 2- KO ' + msg)
				//this.status = SessionStatus.LOGGED // non dovrebbe servire
				throw 'Error: update relation rejected, please retry'
			})
	}

	// 15.01.2024 edit relation da clinicAdmin, ha solo il display_name, non la firma
	private editClinicAdminRelation(relation: Relation, displayName: string) {
		var docUsername = relation.username

		Util.debug('(editCliAdmRelation) with ' + relation.doctor_id + ' displayName: ' + displayName)

		// quando la funzione e' chiamata dal distrib stesso, la keyPhoto e' gia' nella rel che sta aggiornando
		let goodKeyPhoto = null

		if (this.isClinicAdmin()) {
			goodKeyPhoto = relation.docKeyPhoto
			if (!relation.docKeyPhoto) {
				console.log('(editCliAdmRelation) ko KeyPhoto, retrieve it!') // quando succede ??
				goodKeyPhoto = this.getImageKeyFromDtRelation(parseInt(relation.doctor_id))
			}
		} else {
			// come admin ?! per ora mai qui
			this.getDoctorKeyPhoto(docUsername).then((nsKeyPhoto) => {
				goodKeyPhoto = nsKeyPhoto
			})
		}

		// questa dovrebbe aspettare di avere la var buona, sia che venga dalla promise dell'admin che come specialist
		// TODO testare come admin
		return Promise.resolve(goodKeyPhoto)
			.then(() => {
				// critta il displayName con la keyPhoto dell'optician
				console.log('(editCliAdmRelation) ok KeyPhoto! ')

				return this.cryptoUtils.encryptDataWithKey(goodKeyPhoto, displayName).then((displayCritt) => {
					let dataR = {
						id: relation.id, // 23.06.2020
						doctor_id: relation.doctor_id,
						distributor_id: relation.distrib_id,
						display_name: displayCritt,
					}

					// console.log(dataR)

					return this.updateRelInternal(dataR).then((resp) => {
						// salvo anche in memoria, uso i dati gia' in chiaro
						relation.display_name = displayName
						this.data.updateRelation(relation, true)
						return resp
					})
				})
			})
			.catch((err) => {
				let msg = this.parseErrorMessage(err, 'trace')
				console.log('(editCliAdmRelation) KO ' + msg)
				msg = this.parseErrorMessage(err, 'alert')
				throw 'Error: update relation rejected ' + msg
			})
	}

	// 07.06.2022 converte una observable in promise
	// chiede un token come per una login
	private validatePwd(distrib, distrib_pwd) {
		// 18.11.2022
		return this.obtainToken(distrib, distrib_pwd)
		/*
		let promise = new Promise((resolve, reject) => {
			this.obtainToken(distrib, distrib_pwd)
				.toPromise()
				.then((res) => {
					// Success
					resolve(res);
				})
				.catch((err) => {
					reject(err)
				})
		})
		return promise
    */
	}

	// USATA PER CREARE RELAZIONI PARTENDO DALL'OPTICIAN
	public createOpticianRelation(docUsername: string, newDistrib: Distrib): Promise<any> {
		Util.debug('(S) - CreateOpticianRelation')

		const promise = new Promise<any>((resolve, reject) => {
			if (newDistrib.hasPublicKey()) {
				this.getDoctorKeyPhoto(docUsername).then((KeyPhoto) => {
					Util.debug('(createRelationWithPK) ok lev1 KeyPhoto!')
					let currKeyPhoto = this.cryptoUtils.copyKey(KeyPhoto)

					let doctorsUsername: string[] = []
					doctorsUsername.push(docUsername)

					// if (newDistrib.isClinicAdmin()) {
					// 	this.getRelatedClinics(newDistrib, true)
					// 		.then((distribs: Distrib[]) => {
					// 			// console.log(distribs)

					// 			// //prendo tutti quelli di quella clinica, usata nella chiamata loadDistrib
					// 			// let allClinics: Distrib[] = distribs.filter((d) => (d.isMini() && d.groupId == newDistrib.user_id && !d.isDeleted()) || d.user_id == newDistrib.user_id)
					// 			// console.log(allClinics)

					// 			//a aggiungo il clinicAdmin
					// 			distribs.push(newDistrib)
					// 			let promises: Promise<any>[] = []

					// 			for (let distrb of distribs) {
					// 				// if (!distrb.isDeleted()) {// non serve vengono giá ritornati solo gli active
					// 				promises.push(this.createRelWithPublicKey(docUsername, distrb, currKeyPhoto))
					// 				// }
					// 			}

					// 			Promise.all(promises).then((res) => {
					// 				// console.log(res)
					// 				resolve(res)
					// 			})
					// 		})
					// 		.catch((err) => {
					// 			console.log(err)
					// 			reject(err)
					// 		})
					// } else {
					this.createRelWithPublicKey(docUsername, newDistrib, currKeyPhoto).then((res) => {
						// console.log(res)
						resolve(res)
					})
					// }
				})
			} else {
				Util.debug('distrib.hasPublicKey() - false')
				alert('distrib has no public key')
				reject('distrib has no public key')
			}
		})

		return promise
	}
	//function that returns a list of miniC of a given clinicAdmin
	public getRelatedClinics(ClinicAdmin: Distrib, ignoreCritt: boolean): Promise<Distrib[]> {
		const promise = new Promise<Distrib[]>((resolve, reject) => {
			let request: any = this.buildBaseRequest()
			request.method = 'GET'
			request.url = Config.relatedClinicsEndpoint + '?id='

			if (ClinicAdmin && ClinicAdmin.isClinicAdmin()) {
				request.url += ClinicAdmin.user_id

				let superKeyBox = null
				if (this.isClinicAdmin()) {
					superKeyBox = this.user.keyboxPhoto
					Util.debug('(loadClinics) by clinicAdmin ... valid box ? ' + (superKeyBox != null))
				}

				this.data
					.getRelatedClinics(request, ignoreCritt, superKeyBox)
					.then((distribs: Distrib[]) => {
						// console.log(distribs)

						resolve(distribs)
					})
					.catch((err) => {
						console.log(err)
						reject(err)
					})
			} else {
				console.log(ClinicAdmin.username + ' User is not a clinicAdmin')
				reject([])
			}
		})

		return promise
	}

	// 07.06.2023 passo il distrib per intero, non solo la username
	// 07.06.2022 tolgo ultimo param flag_enable_note, non presente su nexus
	// 03.02.2017 echostudio, richiamata dal controller relations
	public createRelation(doctorUsrname: string, distrib: Distrib, distrib_pwd: string, display_name: string, signatureImage?): Promise<any> {
		// 14.06.2023 prende la KEY_PHOTO dell'optician, aprendo la keybox_admin dello user stesso
		// e poi la uso per le relazioni.
		// anticipata qui la chiamata perche' uso la keyPhoto per tutti quelli della clinica

		return this.getDoctorKeyPhoto(doctorUsrname).then((nsKeyPhoto) => {
			Util.debug('(createRelationWithPK) ok lev1 KeyPhoto!') // 03.06.2021
			let currKeyPhoto = this.cryptoUtils.copyKey(nsKeyPhoto)

			if (distrib.hasPublicKey()) {
				// new way

				Util.debug('(S createRelation) ok public key, new way...')
				// 09.06.2023 duplica questa nuova a tutti i suoi colleghi miniC
				// if (this.isAdmin() && distrib.isClinicAdmin()) {
				// 	this.applyRelToClinics(distrib.user_id, doctorUsrname, currKeyPhoto) // loop in parallelo
				// }

				return this.createRelWithPublicKey(doctorUsrname, distrib, currKeyPhoto) // relazione sul singolo
			} else {
				// old way
				Util.debug('(S createRelation) old way...')
				return this.oldCreateRel(doctorUsrname, distrib.username, distrib_pwd, display_name, signatureImage, currKeyPhoto)
			}
		})
	}

	// 06.06.2023
	private oldCreateRel(
		doctorUsrname: string,
		distribUsername: string,
		distrib_pwd: string,
		display_name: string,
		signatureImage,
		currKeyPhoto: forge.util.ByteStringBuffer
	) {
		//let signatureCritt // 19.07.2017

		// 30.03.2017 controlla che pwdDistrib sia giusta
		return this.validatePwd(distribUsername, distrib_pwd)
			.then((res) => {
				// Success
				Util.debug('(S createRelation) ok pwd distrib ' + distribUsername + ', doctor: ' + doctorUsrname)
				this.status = SessionStatus.LOGGED // 03.04.2017 fix, admin lo e' sempre e cmq --ls

				// costruire le keybox con la distrib_pwd, critta display_name e signature

				let localKey = this.cryptoUtils.copyKey(currKeyPhoto)
				let docKeyPhoto = this.cryptoUtils.copyKey(currKeyPhoto)

				var mySalt = doctorUsrname.toLowerCase()
				return this.cryptoUtils.encryptDataWithPwdS(distrib_pwd, docKeyPhoto, mySalt).then((keybox_distrib) => {
					// 30.04.2020 tolto test sul finale, tutte finiscono cosi'...
					// 22.02.2017 se finisce con ==, non va bene, rifare ?
					//if(keybox_distrib.endsWith("==") || keybox_distrib.length != this.KEYBOX_LEN ){
					if (keybox_distrib.length != this.KEYBOX_LEN) {
						//console.log("(createRelation) ko creazione KeyBox distributor! ");
						console.log('(createRelation) ko creazione KeyBox distributor! len ' + keybox_distrib.length)
						throw 'Error: invalid keybox, please retry' // 07.08.2018 arriva qui con Vice, FIXME
					}

					// 15.06.2023 metto tutto in una bag
					let myBag = this.cryptoUtils.generateBag()
					myBag['display_name'] = display_name
					myBag['signature'] = signatureImage
					this.cryptoUtils.purge(myBag)

					// questa e' un encryptAll
					return this.cryptoUtils.encryptFromStringToBase64Content(localKey, myBag).then((bagCritt) => {
						let display_name_critt = bagCritt['display_name']
						let signatureCritt = bagCritt['signature']

						/*
          // 01.06.2017
          return this.cryptoUtils.encryptDataWithKey(currKeyPhoto, display_name)
          .then((display_name_critt) => {
            // 19.07.2017
            if (signatureImage != null) {
              // ko trace con length
              //console.log("(createRelation) ok signature, len key: "+localKey.length);
              Util.debug('(createRelation) ok signature')
            } else {
              Util.debug('(createRelation) missing signature ')
            }

            var bag = this.cryptoUtils.generateBag()
            bag['signature'] = signatureImage
            this.cryptoUtils.purge(bag)

            return this.cryptoUtils.encryptImage(localKey, bag)
            .then((bagCritt) => {
              if (bagCritt != null) {
                Util.debug('(createRelation) ok bagCritt signature')
                signatureCritt = bagCritt['signature']
              } else {
                console.log('(createRelation) ko bagCritt ')
              }

              */

						var dataR = {
							doctor_usrname: doctorUsrname,
							distrib_usrname: distribUsername,
							keybox_distributor: keybox_distrib,
							display_name: display_name_critt,
							signature: signatureCritt,
							is_valid: 'Y',
						}

						return this.createRelInternal(dataR)
					})
					//})
				})
			})
			.catch((err) => {
				var msg = err.data ? err.data.error : err.toString() // 02.05.2018
				//var msg = err.data.error;  // 20.10.2017 fix, non err.message  --ls
				console.log('(createRelation) 2- KO obtain token on create Relation ' + msg)
				this.status = SessionStatus.LOGGED // 03.04.2017 fix, superuser e' cmq loggato --ls
				throw err // 03.06.2021, vice su nurse, passa qui, invalid key parameter
			})
	}

	// 06.06.2023
	// param currKeyPhoto e' la keyPhoto dell'optician (estratta dalla funzione chiamante, una volta per tutta la clinica)
	private createRelWithPublicKey(doctorUsrname: string, grader: Distrib, currKeyPhoto: forge.util.ByteStringBuffer): Promise<any> {
		let localKey = this.cryptoUtils.copyKey(currKeyPhoto)
		const keybox_public = this.buildKeyboxPhoto(grader.username, grader.public_key, currKeyPhoto)

		//Util.debug('(createRelationWithPK) grader id '+grader.user_id+' KeyBox len ' + keybox_public.length) // 512

		// il grader se lo sceglie, poi viene duplicato sulla relazione
		let display_name = grader.display_name
		let licence_num = grader.licence_num
		if (!display_name || display_name == '') {
			display_name = 'Dr. ' + grader.getName() // solo per test
		}

		Util.debug('(createRelationWithPK) grader id ' + grader.user_id + ' display_name ' + display_name)

		let signatureImage = grader.signature
		//if(!signatureImage) {
		//  signatureImage = b64images.logo // solo per test, e' il logo LTG vecchio
		//}

		const myBag = this.cryptoUtils.generateBag()
		myBag['licence_num'] = licence_num
		myBag['display_name'] = display_name

		// 01.06.2017
		return this.cryptoUtils.encryptFromStringToBase64Content(currKeyPhoto, myBag).then((data_critt) => {
			// 19.07.2017
			if (signatureImage != null) {
				// ko trace con length
				//console.log("(createRelation) ok signature, len key: "+localKey.length);
				Util.debug('(createRelationWithPK) ok signature')
			} else {
				Util.debug('(createRelationWithPK) grader ' + grader.user_id + ' without signature')
			}

			let signatureCritt: any
			var bag = this.cryptoUtils.generateBag()
			bag['signature'] = signatureImage
			this.cryptoUtils.purge(bag)

			return this.cryptoUtils.encryptImage(localKey, bag).then((bagCritt) => {
				if (bagCritt != null) {
					Util.debug('(createRelationWithPK) ok bagCritt signature')
					signatureCritt = bagCritt['signature']
				} else {
					console.log('(createRelationWithPK) ko bagCritt ')
				}

				var dataR = {
					doctor_usrname: doctorUsrname,
					distrib_usrname: grader.username,
					//keybox_distributor: keybox_distrib,   // 07.06.2023 tolta, la fara' il grader alla sua prox login
					keybox_public: keybox_public, // opticians' keyPhoto closed with the graders public key
					display_name: data_critt['display_name'],
					signature: signatureCritt,
					licence_num: data_critt['licence_num'],
					is_valid: 'Y',
				}

				return this.createRelInternal(dataR)
			})
		})
		//})
	}

	private createRelInternal(rawAccessData) {
		let request: any = this.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.relationsEndpoint
		let myData = { relation: rawAccessData }
		//console.log("(createRelInternal) POST "); // per test
		return this.myPost(request, myData)
	}

	private updateRelInternal(rawAccessData) {
		let request: any = this.buildBaseRequest()
		request.method = 'PUT'

		var relId = rawAccessData.id // 23.06.2020
		if (!relId) {
			relId = 'xx'
		}

		request.url = Config.relationsEndpoint + '/' + relId
		let myData = { relation: rawAccessData }
		//console.log("(updateRelInternal) PUT "); // per test
		return this.myPut(request, myData)
	}

	private getDoctorInternal(usrname) {
		let request: any = this.buildBaseRequest()
		request.method = 'GET'

		//06.02.2017 ko request per il punto su username   --ls
		var docUsrName = this.parseEmail(usrname)

		// 16.03.2020
		//request.url = Config.doctorsEndpoint + "/box/" +  docUsrName;
		request.url = Config.doctorsEndpoint + '/' + docUsrName + '/box/'
		return this.myGet(request)
	}

	// serve per la create relation
	// 01.06.2017 porto fuori parte comune, solo per user NS
	private getDoctorKeyPhoto(docUsername: string) {
		if (!this.isGod() && !this.isVice() && !this.isSupport()) {
			console.log('(getDoctorKeyPhoto) user not authorized')
			throw 'Error: invalid user'
		}
		var docKeyPhoto = null

		/*    
				// 13.09.2017 FIX, potrebbe non essere lo stesso doctor
				// 24.07.2017 se gia' caricata, va bene --ls       
				var docKeyPhoto = angular.copy(this.data.doctor.key_distrib); 
				if(docKeyPhoto != null){      
					return docKeyPhoto;
				}
	*/

		var nsPwd = '' //this.loggedUser.pwd;

		if (this.isVice() || this.isGod() || this.isSupport()) {
			// isGod = sia admin che god1
			nsPwd = this.getPwdForKeyPhoto()
		}

		Util.debug('(getDoctorKeyPhoto) going to ask...')

		return this.getDoctorInternal(docUsername).then((boxResponse) => {
			//var nsKeyBoxPhoto = boxResponse.data.box ; // e' la keybox_admin sul DB
			//var superSalt = (boxResponse.data.salt? boxResponse.data.salt : "" );

			// 09.05.2022 senza "data"
			//console.log(boxResponse); // solo per test

			var nsKeyBoxPhoto = boxResponse.box // e' la keybox_admin sul DB
			var superSalt = boxResponse.salt ? boxResponse.salt : ''

			if (nsKeyBoxPhoto && nsKeyBoxPhoto.length > 0) {
				// ok
			} else {
				console.log('(getDoctorKeyPhoto) KO keybox doctor')
				throw 'Error: invalid keybox'
			}

			// 03.06.2021 per i miniB serve username del superB, come sale
			// vd funzione getKeyForImages

			// devo usare la keybox_vice per ottenere la "vera" distribKey
			//if(this.isVice() || this.isAdmin()){

			var mySalt = docUsername.toLowerCase() // 29.04.2020

			// 10.02.2021 sui miniB, devo usare come sale la username di superB
			if (superSalt != null && superSalt.length > 0) {
				mySalt = superSalt
				Util.debug('(getDoctorKeyPhoto) miniB salt: ' + mySalt)
			}
			//}

			docKeyPhoto = this.cryptoUtils.decryptBoxWithPwdS(nsPwd, nsKeyBoxPhoto, mySalt)
			return docKeyPhoto

			/* orig: 
					var usrname = docUsername.toLowerCase(); // 29.04.2020
					return this.cryptoUtils.decryptDataWithPwdS(nsPwd, nsKeyBoxPhoto, usrname)                  
					.then((nsKeyPhoto) => {
							docKeyPhoto = nsKeyPhoto;
							//this.data.doctor.keyPhoto = docKeyPhoto; // 24.07.2017 la salvo se servira' altre volte
							return docKeyPhoto;
					});  
					*/
		})
	}

	private buildKeyboxPhoto(graderUsername: string, graderPublicKey: string, currKeyPhoto: forge.util.ByteStringBuffer) {
		let docKeyPhoto = this.cryptoUtils.copyKey(currKeyPhoto)

		//console.log(docKeyPhoto);  // e' un ByteStringBuffer
		//console.log('(createRelationWithPK) KeyPhoto len ' + docKeyPhoto.length);  // ko!!

		let pKey = graderPublicKey
		let graderPubKey: forge.pki.PublicKey

		// 14.06.2023 patch
		if (!pKey || pKey == '') {
			console.log('(createRelationWithPK) missing public key for user ' + graderUsername)
			return Promise.reject('missing public key for user ' + graderUsername)
		}

		graderPubKey = this.cryptoUtils.publicKeyFromPem(pKey)

		let keybox_public = ''
		// encrypt data with the public key (defaults to RSAES PKCS#1 v1.5)
		try {
			let input = this.cryptoUtils.applyableToString(docKeyPhoto)

			//let input = docKeyPhoto.toString();
			//let input = forge.util.encodeUtf8(docKeyPhoto.toString());

			let encryptedData = graderPubKey.encrypt(input, 'RSAES-PKCS1-V1_5')

			// e' la keybox chiusa con la publicKey contenente la keyPhoto
			keybox_public = forge.util.createBuffer(encryptedData).toHex()
		} catch (ex) {
			console.log(ex)
		}

		return keybox_public
	}

	// ************ keys *************

	// 07.08.2018 ritorna la pwd per aprire la keyDistrib x aprire le foto
	// solo per utenti Vice e AdminGroup
	// 29.09.2021 aggiunto statistico
	public getPwdForKeyPhoto(): string {
		var myPwd = ''
		var mySalt = ''

		// 21.11.2022 aggiunto support
		// 29.09.2021 aggiunto statistico
		//if (this.isVice() || this.isAdmin() || this.isStats() || this.isSupport()) {
		if (this.isManagement()) {
			// console.log(this.user)
			mySalt = this.user.username.toLowerCase()

			if (this.user.keyboxVice != null) {
				// 04.09.2018 aggiunto parametro
				//var keyAdm = this.cryptoUtils.decryptBoxWithPwd(this.user.password, this.user.keyboxVice);
				var keyAdm = this.cryptoUtils.decryptBoxWithPwdS(this.user.password, this.user.keyboxVice, mySalt)
				if (keyAdm != null) {
					//myPwd = angular.copy(keyAdm);
					myPwd = keyAdm.toString('utf8')
				}
			} else {
				Util.debug('(getPwdForKeyPhoto) missing keyBoxVice for user ' + this.user.username)
			}
		} else {
			// ok per tutti gli altri: God1, distrib, spec, superB
			myPwd = this.user.password
		}

		return myPwd
	}

	// 21.11.2022 aggiunto support
	// 07.08.2018 per utenti viceAdm, tutti gli admins oltre al primo
	// 29.09.2021 aggiunto statistician
	private getKeyForImages(currDoctor: Doctor): string {
		//if (!this.isVice() && !this.isAdmin() && !this.isStats() && !this.isSupport()) {
		if (!this.isManagement()) {
			console.log('(getKeyForImages) invalid profile')
			return ''
		}

		var currKeyPhoto = ''
		var admPwd = this.getPwdForKeyPhoto()
		var mySalt = currDoctor.username.toLowerCase() // 29.04.2020

		// 10.02.2021 sui miniB, devo usare come sale la username di superB
		if (currDoctor.isMini()) {
			mySalt = currDoctor.superSalt
			Util.debug('(getKeyForImages) miniB salt: ' + mySalt)
		}

		currKeyPhoto = this.cryptoUtils.decryptBoxWithPwdS(admPwd, currDoctor.keybox_distrib, mySalt)

		Util.debug('(getKeyForImages) valid ? ' + (currKeyPhoto != null))

		return currKeyPhoto
	}

	// portata fuori funzione da per richiamarla anche dopo --ls
	//levato iterations in quanto non veniva mai passato a questa func, per cui non funzionava mai
	public loadDoctorKey(doctorId: string): boolean {
		Util.debug('(loadDoctorKey)')
		let okKey = false

		if (!this.canSeeDoctors()) {
			// solo per utente NS e livelli2
			return false
		}

		// 08.06.2023 se grader, prendo dalla relazione, patch per quelle nuove, piu' veloce
		if (this.isSpecialist()) {
			let currKey = this.getImageKeyFromDtRelation(parseInt(doctorId))
			okKey = currKey != null

			if (okKey) {
				this.user.keyDistrib = currKey
				return okKey
			}
		}

		// 07.06.2023 patch per evitare troppi loop
		// if (!iterations) {
		// 	iterations = 1
		// } else {
		// 	iterations++
		// }
		// if (iterations >= 4) {
		// 	Util.debug('(loadDoctorKey) abort to avoid loop!!')
		// 	//alert('ferma loop')
		// 	return false
		// }

		let currDoc = null
		if (this.data.hasLoadedDoctor(doctorId)) {
			currDoc = this.data.getDoctor(doctorId)
		}

		if (currDoc == null) {
			Util.debug('(loadDoctorKey) get from list...')
			currDoc = this.data.getDoctorFromList(doctorId)
		}

		if (currDoc == null) {
			Util.debug('(loadDoctorKey) doct not loaded yet !')

			if (!this.isGroupB()) {
				// 15.02.2023 patch per evitare loop ??
				this.loadDoctor(parseInt(doctorId))
			}
			// 07.06.2023 va in loop anche con le nuove relazioni dei graders

			return false // deve aspettare che lo carichi
		}

		// qui sicuramente valido
		//if(currDoc != null) {

		// 17.02.2021 patch per i miniB, nella lista non viene valorizzato il superSalt
		if (currDoc.isMini()) {
			let creatorId = currDoc.created_by
			let mySuperB = this.data.getDoctorFromList(creatorId)

			// 06.10.2022 patch
			//currDoc.superSalt = mySuperB.username;
			if (mySuperB) {
				currDoc.superSalt = mySuperB.username
			} else if (this.isSuperB()) {
				currDoc.superSalt = this.getUsername().toLowerCase()
			}
		} else if (currDoc.isSuperB()) {
			// 21.10.2022 patch per superB come grader (fix bug 219)
			currDoc.superSalt = currDoc.getUsername().toLowerCase()
		}

		// 21.11.2022 aggiunto support
		// 20.05.2022 aggiunto statistico
		// devo usare la keybox_vice per ottenere la "vera" distribKey
		//if (this.isVice() || this.isAdmin() || this.isStats() || this.isSupport()) {
		if (this.isManagement()) {
			this.loggedUser.keyPhoto = this.getKeyForImages(currDoc) // senza promise
			Util.debug('(loadDoctorKey) got valid key ? ' + (this.loggedUser.keyPhoto != null))

			// 21.11.2022  perche' sempre?
			//okKey = true
			okKey = this.loggedUser.keyPhoto != null
		} else if (this.isLevel2()) {
			//this.user.keyDistrib = angular.copy(currDoc.key_distrib);
			this.user.keyDistrib = currDoc.key_distrib

			// 25.10.2019 aggiunto test
			if (this.user.keyDistrib != null) {
				okKey = true
			}
		} else if (this.isGroupB()) {
			// 04.04.2023 utente di gruppo, per esempio serve al miniA per info su un miniB grader...
			Util.debug('(loadDoctorKey) usr group, ' + this.user.firstname)
			okKey = true // ok gia' la sua
		} else {
			Util.debug('(loadDoctorKey) usr ' + this.user.firstname + ' ko profile ?! ')
		}

		// 25.10.2019 FIX trace! [ls]
		if (okKey) {
			//console.log("(loadDoctorKey) usr "+this.user.firstname+" ok KEY per doc "+this.data.doctor.id);
			Util.debug('(loadDoctorKey) usr ' + this.user.firstname + ' ok KEY per doc ' + currDoc.id)
		} else {
			Util.debug('(loadDoctorKey) usr ' + this.user.firstname + ' KO KEY per doc ' + currDoc.id)
		}

		// 08.06.2023 se grader, prendo dalla relazione, TODO anticipare prima!!
		if (!okKey && this.isSpecialist()) {
			let currKey = this.getImageKeyFromDtRelation(parseInt(doctorId))
			okKey = currKey != null
		}

		return okKey
	}

	// 15.04.2020 uniformata
	public getExamKey() {
		var goodKey = null
		let docId: number

		if (this.isLevel1()) {
			goodKey = this.user.keyPhoto
		} else {
			//var docId = this.getDtDoctor().id;
			docId = this.getCurrentDoctorId()

			let rc = this.validateDoctKey('' + docId) // potrebbe averla gia' caricata o non ancora

			if (!rc) {
				// 18.01.2022
				console.log('(getExamKey) missing key for doct ' + docId)
				//return null   // 22.11.2022 FIX ?
			}

			if (this.isLevel2() || this.isFirstGod()) {
				goodKey = this.user.keyDistrib

				// 08.06.2023 modalita' nuova, tengo la parte sopra per compatibilita' storica
				if (this.isSpecialist() && !goodKey) {
					goodKey = this.getImageKeyFromDtRelation(docId)
					console.log('(getExamKey) got key from relation, ' + (goodKey != null))
				}

				// 20.05.2022 aggiunto stats
				//} else if (this.isVice() || this.isAdmin() || this.isStats() || this.isSupport()) {
			} else if (this.isManagement()) {
				// console.log(this.loggedUser)

				goodKey = this.loggedUser.keyPhoto
			} else if (this.isStats()) {
				if (this.loggedUser.keyPhoto != null) {
					Util.debug('S (loadCategoryExams) ok keyPhoto')
					goodKey = this.loggedUser.keyPhoto
				}
			}
		}

		return goodKey
	}

	// 11.04.2019 verifica se gia' caricata, altrimenti la richiede [ls]
	private validateDoctKey(doctorId: string): boolean {
		var okKey = false
		if (!this.canSeeDoctors())
			// solo per utente NS e livelli2
			return okKey

		// 27.05.2022 aggiunto test
		if (this.currentDoctorId != parseInt(doctorId)) {
			Util.debug('(validateDoctKey) doctor id changed ! from ' + this.currentDoctorId + ' to ' + doctorId)
		}

		// 08.06.2023
		if (parseInt(doctorId) == 0) {
			Util.debug('(validateDoctKey) ko doctor id nullo ')
			alert('sorry, some problems occured')
			return false
		}

		//let myLen = 0;
		if (this.isManagement()) {
			//if (this.isVice() || this.isAdmin() || this.isStats() || this.isSupport()) {
			// 27.05.2022 aggiunto stats
			if (this.loggedUser.keyPhoto != null) {
				okKey = true
				//myLen =  this.loggedUser.keyPhoto.length;
			}
		}
		// 05.07.2023 con la flat-pat-List non e' detto che sia per lo stesso doctor!
		/*else if (this.user.keyDistrib != null) {  
			okKey = true
			//myLen =  this.user.keyDistrib.length();
		}*/

		// 08.06.2023 patch per nuova gestione graders, piu' veloce
		if (!okKey && this.isSpecialist()) {
			let currKey = this.getImageKeyFromDtRelation(parseInt(doctorId))
			okKey = currKey != null
			Util.debug('(validateDoctKey) for graders, is already ok ? ' + okKey)
		}

		if (!okKey) {
			Util.debug('(validateDoctKey) loading... for ' + doctorId)
			okKey = this.loadDoctorKey(doctorId)
		} else {
			//Util.debug("(validateDoctKey) already ok, len: "+myLen); // ko len
			Util.debug('(validateDoctKey) already ok for ' + doctorId)
		}

		// 01.06.2022
		if (this.isStats()) {
			Util.debug('(validateDoctKey) doc: ' + doctorId + ' valid ? ' + okKey)
		}

		return okKey
	}

	// ********** VARIOUS *************

	// not used
	//resetProgressBar() {
	//	this.progressBar = 0
	//}

	// 04.07.2023 riutilizzate per task in bg durante attivazione delle relazioni
	private setLoading(val) {
		// console.log('set ' + val)
		this.isLoading = val
	}

	public isWorkingInBg() {
		return this.isLoading
	}

	// ********** permissions *************

	// 20.08.2019
	userCanCreatePatient() {
		// 13.07.2021 legato alla certificazione ?
		return this.isLevel1()
	}

	// 20.08.2019
	userCanEditPatient() {
		// 13.07.2021 senza certificazione, sempre false
		//return false;
		return this.isLevel1()
	}

	// per i livelli 1 si; per i miniB solo sui propri
	userCanDeletePatient(currPatient) {
		var ret = false
		if (this.user.isLevel1()) {
			//ret = true;
			ret = !currPatient.isReadOnly() // se dicom
			if (this.user.isMini()) {
				if (currPatient.created_by != this.user.user_id) {
					ret = false
				}
			}
		}
		return ret
	}

	updateUserCredits(credits: number) {
		this.user.setCredits(credits)
		this.creditsUpdatedStatus.next(credits)
	}

	userCanSeeReportStatus(): boolean {
		//praticamente tutti
		// var canSeeStatus = this.isLevel1() || this.isSpecialist() || this.isLevel3() || this.isStats() || this.isSupport() || this.isClinicAdmin()
		let canSeeStatus = true
		return canSeeStatus
	}

	// in teoria potrebbe, per cui mostro i checkboxes, poi si vede se ha crediti
	userCouldRequestReview() {
		var ret = false

		// 20.11.2022 escludo anche i miniA
		if (this.isOptician() && this.user.hasSpecialists() && !this.isMini()) {
			ret = true
		}

		// 28.12.2022 deve avere almeno un piano middle (with private specialist, ok also without credits)
		if (ret) {
			let salePlan = this.getCurrUserPlan() // ricevuto alla login

			if (!salePlan) {
				// 11.01.2023 fix bug 296
				ret = false
			} else if (salePlan.level == SalePlan.SALE_BASIC) {
				Util.debug('(userCouldRequestReview) sale plan level: FREE, cannot request grading.')
				ret = false
			} else if (this.userHasPrivateGrader()) {
				Util.debug('(userCouldRequestReview) private grader, plan level: ' + salePlan.level)
			}
		}

		return ret
	}

	public canVisitDeviceRequestHGReview(): { resp: boolean; checkCredits: boolean } {
		let ret: { resp: boolean; checkCredits: boolean } = { resp: false, checkCredits: true }
		let saleInfo = this.user.saleInfo

		if (this.userHasPrivateGrader()) {
			ret.resp = true
			ret.checkCredits = false
			return ret
		}

		let service = saleInfo.getActiveHGService()

		// non serve in quanto non ci sarebbe nemmeno la checkbox sulla visita
		// if (!service) {
		// 	ret.resp = false
		// 	return ret
		// }

		if (service.service_mode == serviceMode.FLAT) {
			ret.resp = true
			ret.checkCredits = false
		} else {
			let repCost = saleInfo.salePlan.getMinHgReportCost() // prende dal plan, prende il max, da metter min per fixare ma va gestito lato api

			if (repCost <= 0 || saleInfo.available_credits >= repCost) {
				Util.debug('(verifyHgCredits) ok - available credits: ' + saleInfo.available_credits)
				ret.resp = true // solo ora abilito la funzionalita'
			} else {
				console.log('(verifyHgCredits) ko - not enought credits: ' + saleInfo.available_credits)
			}
		}

		return ret
	}

	public canVisitDeviceRequestAIReview(sn: string): boolean {
		let ret: boolean = false
		let saleInfo = this.user.saleInfo

		let service = saleInfo.getActiveAIService(sn)

		if (!service) {
			ret = false
			return ret
		}

		if (service.service_mode == serviceMode.FLAT) {
			ret = true
		} else {
			let cost

			if (this.user.settings.ai_type == Settings.MCS) {
				cost = saleInfo.salePlan.getMCSAiReportCost()
			} else if (this.user.settings.ai_type == Settings.DSS) {
				cost = saleInfo.salePlan.getDSSAiReportCost()
			}
			// ATTENZIONE, QUANDO AI SARÁ A COSTO  CREDITI FORSE CI SARÁ UN MONTE CREDITI DIVERSO
			if (cost <= saleInfo.available_credits) {
				Util.debug('(verifyAiCreditPlan) ok - available credits: ' + saleInfo.available_credits)
				ret = true
			} else {
				console.log('(verifyAiCreditPlan) ko - not enought credits: ' + saleInfo.available_credits)
			}
		}

		return ret
	}

	// 18.08.2021 dipende da varie cose:
	// - e' un optician che ha un refertatore
	// - non e' un miniA (i suoi colleghi refertano tutto, non deve richiederlo)
	// - ha un piano tariffario che gli abilita la funzione
	// userCanRequestReview(): Promise<boolean> {
	// 	Util.debug('userCanRequestReview ?')
	// 	if (!this.userCouldRequestReview()) {
	// 		return Promise.resolve(false)
	// 	}
	// 	//fa la richiesta al server
	// 	return this.loadUserPlan(this.getUserId()).then((plan: SaleInfo) => {
	// 		if (!plan) {
	// 			return Promise.reject(false)
	// 		} else {
	// 			return this.verifyHgCreditPlan(plan)
	// 		}
	// 	})
	// }

	// 21.12.2022 serve per abilitare il bottone di request grading
	// TODO, vd funzione molto simile su categories, uniformare!!
	public verifyHgCreditPlan(): boolean {
		let ret = false
		let plan = this.user.saleInfo

		// console.log(this.user.saleInfo)

		if (!plan.salePlan) {
			Util.debug('(verifyHgCredits) invalid sale plan info')
			return false
		}

		Util.debug('(verifyHgCredits) sale plan level: ' + plan.salePlan.level)

		// 13.01.2023 patch, ma non dovrebbe neanche arrivare qui
		if (plan.salePlan.isFree()) {
			Util.debug('(verifyHgCredits) basic sale plan level, no credits')
			return false
		}

		if (this.userHasPrivateGrader()) {
			Util.debug('(verifyHgCredits) private grader, plan level: ' + plan.salePlan.level)
			return !plan.salePlan.isFree() // almeno middle basta
		}

		// grader esterno, servono crediti
		if (plan.salePlan.isAdvanced()) {
			let repCost = plan.salePlan.getMinHgReportCost() // prende dal plan, prende il max, da metter min per fixare ma va gestito lato api

			if (repCost <= 0 || plan.available_credits >= repCost) {
				Util.debug('(verifyHgCredits) ok - available credits: ' + plan.available_credits)
				ret = true // solo ora abilito la funzionalita'
			} else {
				console.log('(verifyHgCredits) ko - not enought credits: ' + plan.available_credits)
			}
		} else {
			Util.debug('(verifyHgCredits) (2) invalid sale plan level: ' + plan.salePlan.level)
		}

		return ret
	}

	// 21.12.2022 true se almeno uno
	public userHasPrivateGrader() {
		return this.user.hasPrivateSpecialist()
	}

	// *********** AI reports *****************************************

	// per ora solo servizio AI unlimited
	private verifyAiCreditPlan(): boolean {
		let ret = false
		let plan = this.user.saleInfo

		if (!plan.salePlan) {
			Util.debug('(verifyAICredits) invalid sale plan info')
			return ret
		}

		Util.debug('(verifyAICredits) sale plan level: ' + plan.salePlan.level + ' ' + plan.salePlan.name)

		// if (plan.salePlan.isUnlimitedAi()) {
		// 	//se unlimitedAi, non servono crediti

		// 	ret = true
		// } else {
		let cost

		if (this.user.settings.ai_type == Settings.MCS) {
			cost = plan.salePlan.getMCSAiReportCost()
		} else if (this.user.settings.ai_type == Settings.DSS) {
			cost = plan.salePlan.getDSSAiReportCost()
		}

		if (cost <= plan.available_credits) {
			Util.debug('(verifyAiCreditPlan) ok - available credits: ' + plan.available_credits)
			ret = true
		} else {
			console.log('(verifyAiCreditPlan) ko - not enought credits: ' + plan.available_credits)
		}
		// }

		return ret
	}

	//# RDS users opens directly balace page
	public setTargetBalancePage() {
		this.targetBalance = true
	}

	public resetTargetBalancePage() {
		this.targetBalance = false
	}
	// ends

	//# RDS users opens directly plan page
	public setTargetPlanPage() {
		this.targetPlan = true
	}

	public resetTargetPlanPage() {
		this.targetPlan = false
	}
	// ends

	// 24.08.2022 salvo il target report dalla pg di login, in modo che poi venga gestito nella landing pg
	public setTargetAiReport(repId: number) {
		if (repId && repId > 0) {
			this.targetAiReport = repId
		}
	}

	// richiamata se ko id, e anche dopo averlo usato la prima volta
	public resetTargetAiReport() {
		this.targetAiReport = 0
	}

	// 30.08.2023
	// userCanRequestAIReport() {
	// 	Util.debug('userCanRequestAIReview ?')
	// 	if (!this.userCouldRequestAIReport()) {
	// 		return Promise.resolve(false)
	// 	}
	// 	//fa la richiesta al server
	// 	return this.loadUserPlan(this.getUserId()).then((plan: SaleInfo) => {
	// 		// console.log(plan)
	// 		if (!plan) {
	// 			return Promise.reject(false)
	// 		} else {
	// 			return this.verifyAiCreditPlan(plan)
	// 		}
	// 	})
	// }
	// 30.08.2023
	userCouldRequestAIReport() {
		var ret = false

		// 23.08.2022 TEMP patch
		// if (Config.isProductionMode) {
		// 	return false
		// }
		if (this.isOptician()) {
			ret = this.user.settings && this.user.settings.ai_type != Settings.NONE
		}

		return ret
	}

	// 23.01.2023 anche utente support, sui suoi
	// 23.08.2022 abilita il pallino di status per report AI
	userCanSeeAiReportStatus() {
		// aggiunto test se lev1 abilitato AI
		let lev1Enabled = this.userCouldRequestAIReport()
		let canSeeStatus = lev1Enabled || this.isLevel3() || this.isSupport()

		return canSeeStatus
	}

	// *********** Diagonal reports *****************************************

	// 13.09.2022 abilita il pallino di status per report Diagonal plus
	// userCanSeeDiagReportStatus() {
	// 	let canSeeStatus = this.isClalit()
	// 	return canSeeStatus
	// }

	public isClalit() {
		// su environment c'e' brand "Clalit", oppure la url dl fe ?
		let ret = Config.brand == Config.BR_CLALIT
		return ret
	}

	// 07.09.2022
	isProduction() {
		return Config.isProductionMode
	}

	public isStaging() {
		return Config.isStagingMode
	}

	public isDev() {
		return !Config.isStagingMode && !Config.isProductionMode
	}

	// ****************************************************************

	public requestDBCountrySettings(): Promise<DBCountrySettings[]> {
		this.countryDBSettings = []
		const promise = new Promise<DBCountrySettings[]>((resolve, reject) => {
			let request: any = this.buildBaseRequest()
			request.method = 'GET'
			request.url = Config.countriesEndPoint + '/features'

			this.myGet(request)
				.then((ris) => {
					if (ris.features && ris.features.length > 0) {
						for (let feature of ris.features) {
							this.countryDBSettings.push(new DBCountrySettings(feature))
						}
						resolve(this.countryDBSettings)
					} else {
						resolve(this.countryDBSettings)
					}
				})
				.catch((err) => {
					reject(err)
				})
		})

		return promise
	}

	public getDBCountrySettings(): DBCountrySettings[] {
		return this.countryDBSettings.slice()
	}

	public getDialCodeFromAlpha3(alpha3: string): string | boolean {
		let validDialCode = false
		for (let country of this.countries) {
			if (country.alpha3 == alpha3) {
				return country.dial_code
			}
		}

		return validDialCode
	}

	public getAlpha2FromAlpha3(alpha3: string, uppercase: boolean): string {
		let alpha2 = this.countries.find((c) => c.alpha3 == alpha3).alpha2
		if (uppercase) {
			alpha2 = alpha2.toUpperCase()
		}
		return alpha2
	}

	public isValidPrefixCode(dial_code: string): boolean {
		for (let country of this.countries) {
			if (country.dial_code == dial_code) {
				return true
			}
		}

		return false
	}

	// 06.09.2022
	public getSingleCountry(myAlpha: string) {
		let c: Country
		c = null
		if (this.countries != null && this.countries.length > 0) {
			for (let i = 0; i < this.countries.length; i++) {
				if (this.countries[i].alpha3 == myAlpha) {
					c = this.countries[i]
					break
				}
			}
		}
		return c
	}

	// 30.08.2022 return data from memory, if any
	public getDtCountries(): Country[] {
		return this.countries
	}

	// 08.06.2022 load from server DB
	private getCountries(): Promise<any> {
		//console.log("(getCountries) ");
		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.countriesEndPoint

		this.countries = [] // svuoto per ripartire

		return this.myGet(request)
			.then((ris) => {
				//Util.debug("(S getCountries) got: ");
				// console.log(ris)
				if (ris && ris && ris.countries) {
					var list = ris.countries
					Util.debug('S (getCountries) list: ' + list.length) // ok 246
					for (let i = 0; i < list.length; i++) {
						var plan = new Country(list[i])
						this.countries.push(plan)
					}
				} else {
					Util.debug('S (getCountries) bad response')
				}
				return this.countries
			})
			.catch((err) => {
				if (!this.isExpired(err)) {
					// rilancia la exception
					throw err
				}
			})
	}

	// 14.04.2023 utility function
	getCountryFullName(myAlphaC) {
		let ret = myAlphaC
		let c = this.getSingleCountry(myAlphaC)
		if (c) {
			//ret = c.name + ' (' + myAlphaC + ')';
			ret = '(' + myAlphaC + ') ' + c.name
		}
		return ret
	}

	// *************** agreements ********************

	// 25.05.2022 based on the country of the logged user, fa una nuova richiesta al server
	// loadAgreement(): Promise<Agreement> {
	// 	let currCountry = this.user.getCountry()
	// 	return this.data.loadUsersAgreement(this.buildBaseRequest(), currCountry)
	// }

	// 17.02.2023 refactoring, vd getAcceptedUserAgreement
	// the one the user accepted during activation process, fa una nuova richiesta al server
	/*loadUserAgreement() : Promise<Agreement> {
		let usrId = this.getUserId()
		return this.data.loadAgreement(this.buildBaseRequest(), null, usrId)
	}
  */

	// ritorna l'agreement disponibile predisposto per una country (da dati in memoria), se gia' richiesto al server
	getCountryAgreement(): Agreement {
		let currCountry = this.getUserCountry()
		let agr = this.data.getAgreement(currCountry)
		return agr
	}

	/*
  // dismettere, il nome confonde!
	private getUserAgreement() {
		let currCountry = this.getUserCountry()
		let agr = this.data.getAgreement(currCountry)
		return agr
	}
  */

	// 17.02.2023 ritorna l'agreement accettato dallo user
	// user loggato o da parametro, se richiesta by admins
	getAcceptedUserAgreement(userId?: number): Promise<Agreement[]> {
		// let agrId = 0
		// let agr = null
		// let agreements = []

		// if (this.isLevel1()) {
		// 	let info = this.user.agreementInfo
		// 	if (info && info.agreement_id.length > 0) {
		// 		let count = 0
		// 		for (let i = 0; i < info.agreement_id.length; i++) {
		// 			const elem = info.agreement_id[i]
		// 			count++
		// 			agrId = elem
		// 			agr = this.data.getAgreementById(agrId)
		// 			if (agr) {
		// 				agreements.push(agr)
		// 				Util.debug('S (getAcceptedUserAgreement) already available, id: ' + agrId)
		// 				if (count == info.agreement_id.length) {
		// 					return Promise.resolve(agreements)
		// 				}
		// 			}
		// 		}
		// 		agrId = info.agreement_id
		// 		agr = this.data.getAgreementById(agrId)
		// 		if (agr) {
		// 			Util.debug('S (getAcceptedUserAgreement) already available, id: ' + agrId)
		// 			return Promise.resolve(agr)
		// 		}
		// 	}
		// }

		// if (!userId) {
		// 	userId = this.user.user_id
		// }

		// nuova richiesta al server
		Util.debug('S (getAcceptedUserAgreement) going to request... ')
		return this.data.loadAcceptedAgreement(this.buildBaseRequest(), userId).then((agr) => {
			// agr é un array di agreement
			if (agr && agr.length > 0) {
				// if (this.isLevel1()) {
				// 	// salvo per prox volta
				// 	// let info = this.user.agreementInfo
				// 	// if (!info) {
				// 	// 	this.user.agreementInfo = new AgreementInfo()
				// 	// 	this.user.agreementInfo.setStatus('accepted')
				// 	// }

				// 	for (let i = 0; i < agr.length; i++) {
				// 		const elem = agr[i]
				// 		// this.user.agreementInfo.agreement_id[i] = elem.id
				// 		Util.debug('S (getAcceptedUserAgreement) OK id: ' + elem.id)
				// 	}
				// 	// this.user.agreementInfo.agreement_id = agr.id
				// 	// Util.debug('S (getAcceptedUserAgreement) OK id: ' + agr.id)
				// }
				return agr
			} else {
				return null
			}
		})
	}

	loadAgreementStatus(): Promise<AgreementsStatus[]> {
		const promise = new Promise<AgreementsStatus[]>((resolve, reject) => {
			let request: any = this.buildBaseRequest()
			request.method = 'GET'
			request.url = Config.agreementsEndpoint + '/' + this.user.user_id + '/status'

			this.myGet(request)
				.then((agrs) => {
					// console.log(agrs.agreements_status)
					this.user.agreements_status = agrs.agreements_status
					resolve(agrs.agreements_status)
				})
				.catch((err) => {
					console.log(err)
					reject(err)
				})
		})

		return promise
	}

	// senza filtro country, solo da admins
	loadAgreementsList(): Promise<Agreement[]> {
		return this.data.loadAgreementsList(this.buildBaseRequest())
	}

	// 27.06.2023 for the logged user
	loadAvailableAgreements(): Promise<Agreement[]> {
		let myCountry = this.user.getCountry()

		let myTarget = ''
		if (this.isSpecialist()) myTarget = 'grader'
		if (this.isClinicAdmin()) myTarget = 'clinic'
		else if (this.isLevel1()) {
			myTarget = 'optician'
		}

		return this.data.loadAgreementsList(this.buildBaseRequest(), myCountry, myTarget)
	}

	saveUserAgreement(agrIds: number[]) {
		let request: any = this.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.agreementsEndpoint + '/accepted'

		//let myData = {accepted: agrId};
		let myData = { accepted: { agreements: agrIds } }
		// let myData = { agreement_id: agrId }

		return this.myPost(request, myData)
	}

	// *************** patient agreements *********

	// 14.02.2023 ritorna l'agreement da far accettare ai pazienti (e' basato sulla country dell'optician owner)
	getOpticianAgreementForPatient(): Promise<Agreement[]> {
		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		let currCountry = this.getUserCountry()

		// facciamo fare al dataservice cosi' lo salva nell'array di tutti gli agreement, se servisse in futuro
		return this.data.loadOpticianAgreementForPatient(request, currCountry)
	}

	// getPatientsAgreement(): Promise<Agreement> {
	// 	let request: any = this.buildBaseRequest()
	// 	request.method = 'GET'
	// 	let currCountry = this.getUserCountry()

	// 	// facciamo fare al dataservice cosi' lo salva nell'array di tutti gli agreement, se servisse in futuro
	// 	return this.data.loadPatientsAgreement(request, currCountry)
	// }

	// ritorna l'agreement accettato dal paziente
	// getAcceptedPatientAgreement(patId: number): Promise<Agreement> {
	// 	// TODO - vedo se ce l'ho gia' in memoria

	// 	// nuova richiesta al server
	// 	return this.data.loadPatientAgreement(this.buildBaseRequest(), patId)
	// }

	// 16.02.2023
	savePatientAgreement(patId: number, agrId: number) {
		let request: any = this.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.agreementsEndpoint + '/patientaccepted'

		let myData = {
			agreement_id: agrId,
			patient_id: patId,
		}

		return this.myPost(request, myData)
	}

	// ************ change grader ********************

	public loadRequestList(): Promise<supportTask[]> {
		const promise = new Promise<supportTask[]>((resolve, reject) => {
			const request: any = this.buildBaseRequest()
			request.method = 'GET'
			request.url = Config.supportTaskEndpoint

			this.myGet(request)
				.then((res) => {
					let tasks = []

					for (let task of res.tasks) {
						tasks.push(new supportTask(task))
					}

					resolve(tasks)
				})
				.catch((err) => {
					reject(err)
				})
		})

		return promise
	}

	loadChangeGraderFormQuestions() {
		const lang = this.getLanguage()

		return this.data.loadChangeGraderForm(this.buildBaseRequest(), lang)
	}

	sendChangeGraderForm(repliesList: { question_id: number; reply_id: number }[]) {
		const request: any = this.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.surveysEndpoint
		const myData = {
			surveys: {
				reason: 'change_grader',
				questions: repliesList,
			},
		}

		return this.myPost(request, myData)
	}

	// ************* services *****************

	public pairingServiceToDevice(services: pairingDevices[]): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			let request: any = this.buildBaseRequest()
			request.method = 'PUT'
			request.url = Config.pairingEndpoint

			let data = {
				data: {
					services: services,
				},
			}

			this.myPut(request, data)
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
		return promise
	}

	public loadUserServices(): Promise<service[]> {
		const promise = new Promise<service[]>((resolve, reject) => {
			let request: any = this.buildBaseRequest()
			request.method = 'GET'
			request.url = Config.servicesEndpoint

			this.myGet(request)
				.then((res: { services: service[] }) => {
					// console.log(res)
					this.user.setServices(res.services)
					this.servicesUpdatedStatus.next(res.services)

					resolve(res.services)
				})
				.catch((err) => {
					// console.log(err)
					reject(err)
				})
		})

		return promise
	}

	// ************* devices *****************

	// vengono giá aggiunti alla lista dello user
	public loadUserDevices(): Promise<UserDevice[]> {
		const promise = new Promise<UserDevice[]>((resolve, reject) => {
			if (this.isLevel1()) {
				let request: any = this.buildBaseRequest()
				request.method = 'GET'
				request.url = Config.devicesEndpoint

				this.myGet(request)
					.then((res) => {
						// console.log(res)
						this.user.userDevices = res.devices
						resolve(res.devices)
					})
					.catch((err) => {
						reject(err)
					})
			} else {
				reject('not enabled ')
			}
		})

		return promise
	}

	// 09.03.2021
	public loadDevices() {
		// 29.12.2022 aggiunto level1, le API poi filtrano solo i suoi
		if (this.isGod() || this.isManager() || this.isSupport()) {
			Util.debug('(S loadDevices)')
			return this.data.loadDevices(this.buildBaseRequest())
		} else {
			return Promise.reject('not enabled yet') // 20.04.2023 non abilitato
		}
	}

	public loadDevice(id: number) {
		// 30.12.2022 aggiunto level1
		if (this.isGod() || this.isManager() || this.isLevel1() || this.isSupport()) {
			// 26.08.2021 esteso
			Util.debug('(S loadDevice) id:' + id)
			return this.data.loadDevice(this.buildBaseRequest(), id)
		} else {
			return Promise.reject('forbidden') // 20.04.2023 non abilitato
		}
	}

	// richiesta singola per ogni userId con device multipli
	public loadLocationsDevices(userId: number, deviceId: number[]): Promise<userLocation> {
		const promise = new Promise<userLocation>((resolve, reject) => {
			let request: any = this.buildBaseRequest()
			request.method = 'GET'

			request.url = Config.addressesEndpoint + Config.locations + '?user_id=' + userId
			// questa get é fatta per anche i graders, quindi risponde un array di locations
			// in questo caso ci prendiamo il primo
			this.myGet(request)
				.then((res) => {
					// console.log(res)
					let location: userLocation = new userLocation(res.locations[0])

					if (res.locations.length == 0) {
						location.isValid = false
					}

					this.data.userLocationsListMap.set(location.user_id, location)

					this.updateDevicesLocation(deviceId)

					resolve(location)
				})
				.catch((err) => {
					reject(err)
				})
		})

		return promise
	}

	public updateDevicesLocation(deviceId: number[]): Promise<userLocation[]> {
		const promise = new Promise<userLocation[]>((resolve, reject) => {
			let locations: userLocation[] = []
			// console.log(deviceId)
			for (let id of deviceId) {
				let device = this.data.getDevice(id)

				let location = this.data.userLocationsListMap.get(device.last_owner_id)
				// console.log(location)
				if (device && location) {
					device.location = location
					device.location.isValid = true
					locations.push(location)

					this.data.updateDeviceList(device)
				}
			}

			resolve(locations)
		})

		return promise
	}

	public loadUsersLocations(): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			let request: any = this.buildBaseRequest()
			request.method = 'GET'
			request.url = Config.addressesEndpoint + '/shop_locations'

			this.myGet(request)
				.then((res) => {
					// console.log(res.locations)

					for (let loc of res.locations) {
						let location: userLocation = new userLocation(loc)

						if (!location.latitude || location.latitude == 0 || !location.longitude || location.longitude == 0) {
							location.isValid = false
						}

						this.data.userLocationsListMap.set(location.user_id, location)
					}

					resolve(true)
				})
				.catch((err) => {
					reject(err)
				})
		})

		return promise
	}

	public getUsersLocations(): userLocation[] {
		return Array.from(this.data.userLocationsListMap.values())
	}

	getDevicesUpdatesStatus(deviceid: number): Promise<{ updt_status: deviceUpdateStatus }> {
		const promise = new Promise<{ updt_status: deviceUpdateStatus }>((resolve, reject) => {
			if (this.isLevel3() || this.isManager() || this.isSupport()) {
				let request: any = this.buildBaseRequest()
				request.url = Config.updatesEndpoint + '/status?device=' + deviceid

				this.myGet(request)
					.then((res) => {
						// console.log(res)
						resolve(res)
					})
					.catch((err) => {
						reject(err)
					})
			} else {
				reject('user not allowed')
			}
		})

		return promise
	}

	getDevicesUpdates(deviceId: number): Promise<SwUpdate[]> {
		const promise = new Promise<SwUpdate[]>((resolve, reject) => {
			if (this.isLevel3() || this.isManager()) {
				this.data
					.loadDeviceUpdates(this.buildBaseRequest(), deviceId)
					.then((res) => {
						// console.log(res)
						resolve(res)
					})
					.catch((err) => {
						reject(err)
					})
			} else {
				reject('user not allowed')
			}
		})

		return promise
	}

	getDtDevices(): Device[] {
		return this.data.getDevicesList()
	}

	getDtDevice(devId) {
		// return this.data.getDevice(devId)
		let currDev = this.data.device
		if (currDev && currDev.id == devId) {
			Util.debug('(S getDevice) ok ' + currDev.id)
			return currDev
		} else return null
	}

	isLoadingDevices() {
		return this.data.isLoadingDevices()
	}

	hasLoadedDevice() {
		return this.data.hasLoadedDevice()
	}

	// 22.02.2022 - 28.09.2022
	requireDeviceLog(userId: any, deviceId: number) {
		Util.debug('(S requireDeviceLog) user: ' + userId + ' device: ' + deviceId)

		let request: any = this.buildBaseRequest()
		request.method = 'PUT'
		request.url = Config.devicesEndpoint + '/' + deviceId + '/userlog'

		var dataReq = {
			log: {
				//device_id: deviceId,
				user_id: userId,
			},
		}
		//request.data = dataReq;

		return this.myPut(request, dataReq)
	}

	// SOLO PER TEST
	saveNewUserDevice(device) {
		let request: any = this.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.devicesEndpoint + '/'

		return this.myPost(request, device)
	}

	// 05.05.2021 get the list of available models, prende dalla tabella devices
	private requireModels(): Promise<{ models: UserDevices[] }> {
		let request: any = this.buildBaseRequest()
		//request.method = "GET";
		request.url = Config.devicesEndpoint + '/models/'

		return this.myGet(request).catch((err) => {
			// 14.07.2021
			throw err // rilancia la exception
		})
	}

	// ********** updates ********************

	// wizard //

	public loadAvailableDeviceBrands(model: DevicesType | string): Promise<any> {
		let request: any = this.buildBaseRequest()
		request.url = Config.devicesEndpoint + '/options?model=' + model + '&field=brand'

		return this.myGet(request).catch((err) => {
			throw err
		})
	}

	// public loadAvailableComponentDevices(model: DevicesType | string): Promise<any> {
	// 	let request: any = this.buildBaseRequest()
	// 	request.url = Config.devicesEndpoint + '/components?model=' + model

	// 	return this.myGet(request).catch((err) => {
	// 		throw err
	// 	})
	// }

	public loadAvailableOsVersions(model: DevicesType | string, build?: string): Promise<any> {
		let request: any = this.buildBaseRequest()
		request.url = Config.devicesEndpoint + '/options?model=' + model + '&field=os'

		if (build) {
			request.url += '&build=' + build
		}

		return this.myGet(request).catch((err) => {
			throw err
		})
	}

	public loadAvailableMinVersions(model: DevicesType | string, os?: string): Promise<any> {
		let request: any = this.buildBaseRequest()
		request.url = Config.devicesEndpoint + '/options?model=' + model + '&field=buildNum'

		if (os) {
			request.url += '&os=' + os
		}

		return this.myGet(request).catch((err) => {
			throw err
		})
	}

	// end wizard //

	loadUpdates() {
		if (this.isLevel3() || this.isManager()) {
			Util.debug('(S loadUpdates)  ')
			return this.data
				.loadUpdates(this.buildBaseRequest())
				.then((list) => {
					// console.log(list)
					return list
				})
				.catch((err) => {
					console.log('S (loadUpdates) KO!')
					console.log(err)
					throw err // la gestisce poi la chiamante --ls
				})
		} else {
			return Promise.reject('function not available yet')
		}
	}

	public loadCandidateDevices(updateId: number): Promise<CandidateDevices[]> {
		let request: any = this.buildBaseRequest()
		request.url = Config.updatesEndpoint + '/candidates?updt=' + updateId
		let candidate_devices: CandidateDevices[] = []

		return this.myGet(request)
			.then((list) => {
				for (let device of list.candidate_devices) {
					candidate_devices.push(new CandidateDevices(device))
				}

				return candidate_devices
			})
			.catch((err) => {
				throw err
			})
	}

	// 06.12.2022 - 22.03.2021
	createSwUpdate(swDraft: SwUpdate, binaryStream) {
		Util.debug('(createSwUpdate) inizio...')
		return this.insertOrUpdatePack(swDraft, binaryStream)
	}

	// modifica alcuni campi, non il binario
	updateSwPack(swDraft: SwUpdate) {
		//var currId = swDraft.id;
		//console.log("(updateSwPack) id "+currId);
		return this.insertOrUpdatePack(swDraft, null)
	}

	// binary solo per la create
	private insertOrUpdatePack(swDraft: SwUpdate, binaryStream?) {
		// 03.02.2022 aggiunto manager
		if (!(this.isGod() || this.isManager())) {
			return Promise.reject(false)
		}

		var request: any = this.buildBaseRequest()

		if (binaryStream) {
			request.method = 'POST' // lo crea nuovo
			request.url = Config.updatesEndpoint
		} else {
			var currId = swDraft.id
			request.method = 'PUT' // aggiorna esistente
			request.url = Config.updatesEndpoint + '/' + currId
		}

		// console.log(binaryStream)

		var dataReq = {
			pack: {
				dev_type: swDraft.dev_type,
				model: swDraft.model,
				build_ver: swDraft.build_ver,
				build_num: swDraft.build_num,
				component_name: swDraft.component_name,
				comp_model: swDraft.comp_model, // 04.03.2022
				required: swDraft.required,
				blocker: swDraft.blocker,
				package_name: swDraft.package_name,
				parameters: swDraft.parameters,
				os_ver: swDraft.os_ver, // 16.07.2024 fix
				branding: swDraft.branding,
				sn: swDraft.sn,
				url: swDraft.url,
				binary: binaryStream,
				description: swDraft.description,
			},
		}

		if (request.method == 'POST') {
			return this.myPost(request, dataReq, 600000).catch((err) => {
				// 14.07.2021
				if (!this.isExpired(err)) {
					// rilancia la exception
					throw err
				}
			})
		} else {
			// PUT
			return this.myPut(request, dataReq).catch((err) => {
				// 14.07.2021
				if (!this.isExpired(err)) {
					// rilancia la exception
					throw err
				}
			})
		}
	}

	pushSWUpdate(targets: deviceTargetUpdate): Promise<{ tot_sn_target: number }> {
		var request: any = this.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.updatesEndpoint + '/target'
		let data = { target_update: targets }
		return this.myPost(request, data)
	}

	deleteSwUpdate(swUpdtId) {
		// 03.02.2022 aggiunto manager
		if (!(this.isGod() || this.isManager())) return false

		var request: any = this.buildBaseRequest()
		request.method = 'DELETE'
		request.url = Config.updatesEndpoint + '/' + swUpdtId
		return this.myDelete(request).catch((err) => {
			// 14.07.2021
			if (!this.isExpired(err)) {
				// rilancia la exception
				throw err
			}
		})
	}

	// *************** statistics ********************

	public getStatistics(fromDay, toDay, usrType, opticians, cadence) {
		let request: any = this.buildBaseRequest()
		request.method = 'GET'

		let myUrl = Config.statisticsEndpoint

		if (usrType === Statistics.customers) {
			myUrl += '/patients_cadence' + '?from_date=' + fromDay + '&to_date=' + toDay + '&cadence=' + (cadence || 'yearly')
		} else if (usrType === Statistics.conversion_customers) {
			myUrl += '/pats_funnel_hg_cadence' + '?from_date=' + fromDay + '&to_date=' + toDay + '&cadence=' + (cadence || 'yearly')
		} else if (usrType === Statistics.conversion_ai) {
			myUrl += '/pats_funnel_ai_hg_cadence' + '?from_date=' + fromDay + '&to_date=' + toDay + '&cadence=' + (cadence || 'yearly')
		} else {
			// fromDay can be null, in this case fromDate params is not sent, and the query received is not the same, is a trend
			if (fromDay) {
				myUrl += '/' + usrType + '?from_date=' + fromDay + '&to_date=' + toDay
			} else {
				myUrl += '/' + usrType + '?to_date=' + toDay
			}

			// se statistica purchase history, potei passare gli id di solo alcuni optician
			if (opticians) {
				myUrl += '&users_list=' + opticians
			}
		}

		// console.log(myUrl)

		request.url = myUrl

		return this.myGet(request)
			.then((stats) => {
				// console.log(stats)

				return stats
			})
			.catch((err) => {
				console.log(err)
				if (!this.isExpired(err)) {
					// rilancia la exception
					throw err
				}
			})
	}

	//14-09-23
	// return only used countries on server
	public getUsedCountry(): Promise<Country[]> {
		let request: any = this.buildBaseRequest()

		let myUrl = Config.userscountries
		// console.log(myUrl)
		request.url = myUrl

		return this.myGet(request)
			.then((obj) => {
				let countriesRaw = obj.countries
				let countries: Country[] = []

				if (countriesRaw && countriesRaw.length > 0) {
					for (let i = 0; i < countriesRaw.length; i++) {
						const raw = countriesRaw[i]

						let country = new Country(raw)

						countries.push(country)
					}

					return countries
				}
			})
			.catch((err) => {
				throw err
			})
	}

	// 26.08.2022
	public getAiRepExport(fromDay, toDay) {
		return this.getExamsCount(fromDay, toDay, 'billableAI')
	}

	public getAiQualityExport(fromDay, toDay) {
		let request: any = this.buildBaseRequest()
		request.method = 'GET'

		let myUrl = Config.statisticsEndpoint + '/ai_quality?from_date=' + fromDay + '&to_date=' + toDay
		request.url = myUrl

		return this.myGet(request)
			.then((stats) => {
				return stats.ai_quality
			})
			.catch((err) => {
				if (!this.isExpired(err)) {
					// rilancia la exception
					throw err
				}
			})
	}

	getExamsCount(fromDay, toDay, usrType) {
		// : Promise<any[]>

		let request: any = this.buildBaseRequest()
		request.method = 'GET'

		let myUrl = Config.statisticsEndpoint // + "?type=examscount";

		/* 10.11.2021 casi previsti su usrType, vd costanti su CvsLine:
    value="operators"   
    value="graded"   // "specialists" 
    value="hg_reports"   
    value="ai_reports"  
    */
		let countType = 'examscount'

		if (usrType.indexOf('reports') > 0) {
			countType = 'reportscount'
		}

		// 26.08.2022 patch, sistemare, troppi parametri
		if (usrType == 'billableAI') {
			countType = 'aireportsfull'
		}

		myUrl += '?type=' + countType + '&from_date=' + fromDay + '&to_date=' + toDay + '&profile=' + usrType
		request.url = myUrl

		//console.log("(getExamsCount) req: "+myUrl);

		return this.myGet(request)
			.then((stats) => {
				// Sconsole.log(stats) // 27.01.2021
				let rows = []

				if (stats) {
					if (stats.statistics) {
						rows = stats.statistics

						//console.log(rows);  // 27.01.2021
						let len = rows.length
						Util.debug('(getExamsCount) tot rows: ' + len) // ok
					} else if (stats.aireports) {
						rows = stats.aireports

						//console.log(rows);  // 27.01.2021
						let len = rows.length
						Util.debug('(getAiRepExport) tot aireports: ' + len)
					} else {
						rows = stats
						console.log('(getExamsCount) unknown rows')
					}
				}

				return rows
			})
			.catch((err) => {
				if (!this.isExpired(err)) {
					// rilancia la exception
					throw err
				}
			})
	}

	getGradersReports(fromDay, toDay, usrType?) {
		let request: any = this.buildBaseRequest()
		request.method = 'GET'

		request.url = Config.statisticsEndpoint + '/graders_reports?from_date=' + fromDay + '&to_date=' + toDay
		return this.myGet(request)
			.then((stats) => {
				return stats.graders_reports
			})
			.catch((err) => {
				if (!this.isExpired(err)) {
					throw err
				}
			})
	}

	getPendingGradings(toDay, country) {
		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.statisticsEndpoint + '/pending_grading?at_date=' + toDay + '&country=' + country

		return this.myGet(request).catch((err) => {
			if (!this.isExpired(err)) {
				throw err
			}
		})
	}

	// 21.06.2021 conta quanti token validi (circa = utenti loggati)
	queryStatsTok() {
		if (!this.isGod() && !this.isStats())
			// solo per utenti NS, per ora  20.07.2021 aggiunto stats
			return

		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.statisticsEndpoint + '/validtokens/'

		return this.myGet(request).catch((err) => {
			// 14.07.2021
			if (!this.isExpired(err)) {
				// rilancia la exception
				throw err
			}
		})
	}

	// 19.01.2022 per ora ignora type, poi sara' HG o AI
	getLastReport(type?) {
		if (!this.isGod() && !this.isStats())
			// solo per utenti NS e stats
			return

		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.reportsEndpoint + '/last/'

		return this.myGet(request).catch((err) => {
			if (!this.isExpired(err)) {
				// rilancia la exception
				throw err
			}
		})
	}

	// 20.09.2021
	queryStatsLoggedUsers() {
		if (!this.isGod() && !this.isStats())
			// solo per utenti NS, per ora  20.07.2021 aggiunto stats
			return

		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.statisticsEndpoint + '/validtokens?act_type=list'

		return this.myGet(request).catch((err) => {
			// 14.07.2021
			if (!this.isExpired(err)) {
				// rilancia la exception
				throw err
			}
		})
	}

	// 08.07.2021 export di utenti che han fatto visite negli ultimi XX gg
	getStatisticsActivity(rangeDays, objType) {
		let request: any = this.buildBaseRequest()
		request.method = 'GET'

		let myUrl = Config.statisticsEndpoint + '/activity'
		myUrl += '?type=' + objType + '&days=' + rangeDays

		request.url = myUrl

		return this.myGet(request).then((stats) => {
			//console.log(stats);
			//let rows = stats.data.activity;
			let rows = stats.activity
			// if(rows){
			//   console.log(rows);
			//   let len = rows.length;
			//   console.log("(getStatsUsage) tot rows: "+len);  // ok
			// }
			return rows
		})
	}

	// 13.07.2021 unica richiesta, gestisce sia il count che il download vero e proprio
	getStatsImages(myConstrains) {
		let request: any = this.buildBaseRequest()

		request.method = 'POST'
		request.url = Config.statisticsEndpoint

		/* gestiti direttamente dalle api
    constrains['stat_type'] = "count_images";  
    constrains['exam_type'] = this.examTypeFilter;
    constrains['eye'] = this.eye;
    constrains['device'] = this.device;
    constrains['fromDay'] = this.fromDay;
    constrains['toDay'] = this.toDay;
    */

		let dataReq = {
			constrains: myConstrains,
		}

		//request.data = dataReq;

		return this.myPost(request, dataReq)
			.then((myResp) => {
				// 28.10.2021
				//Util.debug("S (getStatsImages)");
				//console.log(myResp);  // solo per TEST !  14.07.2021

				if (myConstrains.stat_type == 'images') {
					// qui sono ancora crittate
					return this.decryptStatImages(myResp)
				} else {
					// solo count
					return myResp
				}
			})
			.catch((err) => {
				// 14.07.2021
				if (!this.isExpired(err)) {
					// rilancia la exception
					throw err
				}
			})
	}

	private decryptStatImages(resp) {
		let crittImages = resp.images
		let imgNames = resp.tags // i nomi dei campi di tipo immagine: per dryeye e' image, per fundus e' "central", ecc.

		// console.log("(session.decryptStatImages) tags: "+imgNames);
		// tags: image_central, image_nasal, image_supero_nasal, image_supero_temporal, image_temporal, image_inferior, image_external, image_central_nasal

		// 29.09.2021 anche utente statistico
		// per utenti AdminGroup serve chiamare la funz.
		//let nsPwd = this.user.password;
		let nsPwd = this.getPwdForKeyPhoto()

		// 08.02.2018 fare qui ciclo di decrypt per dare feedback dei progress
		//this.cryptoUtils.resetCounter();

		let result = []

		Util.debug('(session.decryptStatImages) tot exams to decrypt: ' + crittImages.length)

		for (let i = 0; i < crittImages.length; i++) {
			let rawExam = crittImages[i] // la create sotto vuole tutto l'oggetto, nn solo il campo image

			//console.log("(session.decryptStatImages) "+i+" id: "+rawExam.id+" eye: "+rawExam.eye);

			let currKeyBox = crittImages[i].docKeyBoxPhoto

			// sempre 1
			//let cryptType = 1; // crittImages[i].cryptType;

			// 17.06.2020 per salare la pwd_key
			let currSalt = ''
			//if(crittImages[i].username)
			//  currSalt = crittImages[i].username.toLowerCase();
			//else
			if (crittImages[i].salt) currSalt = crittImages[i].salt.toLowerCase()

			// per le immagini dei miniB fallisce, dovrei avere qui il suo super
			// 21.06.2021 tolta
			//console.log("(session.queryStats) "+i+" doc "+mySalt);

			//let localKey = this.cryptoUtils.decryptBoxWithPwd(nsPwd, currKeyBox);
			let localKey = this.cryptoUtils.decryptBoxWithPwdS(nsPwd, currKeyBox, currSalt)

			// i campi images sono in base64, per essere esposti su web
			result.push(Exam.createExam(rawExam, this.cryptoUtils, localKey))
			//console.log("(session.queryStats) ok decrypt foto: "+i);  // ko causa promise
		}

		return Promise.all(result).then((imagesDec) => {
			Util.debug('(session.queryStats) ok, tot foto: ' + imagesDec.length)
			return [imagesDec, imgNames]
		})
	}

	// 21.07.2022
	decryptDataWithPem(pem2, myBase64Data) {
		let myData = forge.util.decode64(myBase64Data)
		let privKey = forge.pki.privateKeyFromPem(pem2)
		let decryptedData = privKey.decrypt(myData)

		return decryptedData
	}

	decodeBase64(myBase64Data: string): any {
		return forge.util.decode64(myBase64Data)
	}

	encodeBase64(myData: any): string {
		return forge.util.encode64(myData)
	}

	// 29.07.2022
	bytesToHex(myData: any): string {
		return forge.util.bytesToHex(myData)
	}

	private decryptData(myKey, myBase64Data) {
		return this.cryptoUtils.decryptDataWithKey(myKey, myBase64Data)
	}

	encryptData(myKey, myData) {
		return this.cryptoUtils.encryptDataWithMyKey(myKey, myData)
	}

	private decryptDataShort(myKey, myBase64Data) {
		return this.cryptoUtils.decryptDataWithPuk(myKey, myBase64Data)
	}

	// usata per comunicazione con Vistel
	public encryptDataShort(myKey, myData) {
		return this.cryptoUtils.encryptDataWithPuk(myKey, myData)
	}

	// NB: ok per gli optician, non per i graders, vd sotto
	// 26.07.2022
	private getUserRSAPrivateKey() {
		let priv_keybox: string // chiave privata crittata con la keyPrivacy
		let currUser = this.getCurrentUser() // prendo dall'utente gia' loggato
		let myKeyPrivacy = currUser.keyDoctor
		priv_keybox = currUser.privateKeybox
		Util.debug('(RSA_p_key) private keybox len: ' + priv_keybox.length)
		return this.decryptData(myKeyPrivacy, priv_keybox)
	}

	// 26.07.2022 for current user, lev1
	extractAiRedKey(aiKeybox) {
		return this.getUserRSAPrivateKey().then((ris) => {
			if (ris) {
				let pem2 = ris

				// open the aiKeyBox with my pem2
				let redKeyB = this.decryptDataWithPem(pem2, aiKeybox)
				//Util.debug('S (extractAiRedKey) red key in b64: ' + redKeyB) // SOLO per TEST !!!!!!

				// in base 64
				// red key: Yc/lwcjLugPm90xYZKnRAP96RSiW89NB1qEQPT5EMHI=
				let redKey = this.decodeBase64(redKeyB) // len 32 ?

				return redKey
			} else {
				Util.debug('S (extractAiRedKey) ko red key!')
			}
		})
	}

	decryptWithPrivateKey(myBase64Data) {
		return this.getUserRSAPrivateKey().then((res) => {
			if (res) {
				let pem2 = res
				let decryptedData = this.decryptDataWithPem(pem2, myBase64Data)

				return decryptedData
			} else {
				Util.debug('S (decryptWithPrivateKey) ko private key!')
			}
		})
	}

	// 08.06.2023 e' diversa da quella dell'optician, qui chiusa con la sua pwd
	private getGraderRSAPrivateKey() {
		let priv_keybox: string // chiave privata crittata con la sua pwd
		let currUser = this.getCurrentUser() // prendo dall'utente gia' loggato

		let myPwd = currUser.password

		let mySalt = currUser.username.toLowerCase()
		priv_keybox = currUser.privateKeybox
		//Util.debug('(getGraderRSAPrivateKey) private keybox len: ' + priv_keybox.length + ' using pwd: ' + myPwd + ' mySalt: ' + mySalt) // 2304
		return this.cryptoUtils.decryptDataWithPwdS(myPwd, priv_keybox, mySalt)
	}

	// 08.06.2023 for current user, grader
	extractAsymmetric(myKeybox) {
		return this.getGraderRSAPrivateKey().then((pemPriv) => {
			// e' un : ByteStringBuffer
			if (pemPriv) {
				//Util.debug('(extractAsymetric) ok my priv key: ')
				//console.log(pemPriv)
				// -----BEGIN RSA PRIVATE KEY-----\r\nMIIEowIBAAKCAQEAq…LPxTzpRYI3uD4WtB\r\n-----END RSA PRIVATE KEY-----

				console.log('(extractAsymetric) box len: ' + myKeybox.length) // 512

				// open the myKeybox with my pemPriv

				// usata con vistel, non va bene ?!
				//let redKeyB = this.decryptDataWithPem(pemPriv, myKeybox)

				let pKeyPem = pemPriv.data // e' un  ByteStringBuffer
				//let pKeyPem = pemPriv.toString()
				//let pKeyPem = ""+pemPriv
				let privKey = forge.pki.privateKeyFromPem(pKeyPem)

				//let myData = forge.util.decode64(myKeybox);
				//let myData = myKeybox   // e' gia' una stringa in base64 ? no, era toHex

				let myData = forge.util.hexToBytes(myKeybox) // .toString();

				let redKeyB = null

				try {
					redKeyB = privKey.decrypt(myData, 'RSAES-PKCS1-V1_5')

					//console.log('(extractAsymetric) ok 1 ')
					// NB: *** solo per stamparla, la ritorno in binario *****
					// hex
					//let redKey = forge.util.createBuffer(redKeyB).toHex()
					//Util.debug('S (extractAsymetric) keyPhoto :' + redKey) // SOLO per TEST !!!!!!
					//in hex: 280bda6327bf580737d3e90ddab447e1c257a6d3117adb090c951a60175e5467   // 64

					return redKeyB
				} catch (err) {
					console.log('(extractAsymetric) ko 1')
					console.log(err)
					throw err
				}

				/* in andata fatto cosi': 
        let input = this.cryptoUtils.applyableToString(docKeyPhoto);
        let encryptedData = graderPubKey.encrypt(input, 'RSAES-PKCS1-V1_5');
        keybox_public = forge.util.createBuffer(encryptedData).toHex();
        */
			} else {
				Util.debug('S (extractAsymetric) ko priv key!')
				return null
			}
		})
	}

	// ************************

	// 03.08.2022 per fare il download delle immagini
	getBlobFromBase64(base64Img: string): Promise<Blob> {
		return fetch(base64Img).then((res) => {
			return res.blob()
		})
	}

	// *********** LOGS *************************

	// 14.06.2021 richiede un estratto del log del server (api) con filtro su user
	getServerLog(userId?: string, username?: string) {
		// fromDay?: string, toDay?: string){
		let request: any = this.buildBaseRequest()
		request.method = 'GET'

		let myUrl = Config.statisticsEndpoint + '/serverlog'

		if (userId)
			// se ci sono entrambi, precedenza all'id, ignoro la mail
			myUrl += '?userId=' + userId
		else if (username) myUrl += '?username=' + this.parseEmail(username)

		request.url = myUrl

		return this.myGet(request)
			.then((srvLogs) => {
				let rows = srvLogs
				if (rows) {
					let len = rows.lines.length
					Util.debug('S (getServerLog) tot rows: ' + len)
				}
				return rows
			})
			.catch((err) => {
				// 14.07.2021
				if (!this.isExpired(err)) {
					// rilancia la exception
					throw err
				}
			})
		/* la gestisce la chiamante --ls
      .catch((err) =>  {                   
      });
      */
	}

	// 18.11.2022 richiede un estratto del log del server (api) con filtro su user
	getDbLog(myFilter: string, range: string, userId?: number) {
		let request: any = this.buildBaseRequest()
		request.method = 'GET'

		//let myUrl = Config.statisticsEndpoint + '/dblog?filter='+myFilter+"&range="+range;
		let myUrl = Config.logsEndpoint + '?filter=' + myFilter + '&range=' + range

		if (userId) {
			myUrl += '&user=' + userId
		}

		request.url = myUrl
		return this.myGet(request)
	}

	public getUserEvents(userId: number, isAdmin: boolean, afterDate?: Date): Promise<UserEvents[]> {
		// isAdmin if logs events is request on Admin user
		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.userEventsEndpoint

		const promise = new Promise<UserEvents[]>((resolve, reject) => {
			if (isAdmin) {
				request.url += '?created_by=' + userId
			} else {
				request.url += '/' + userId
			}

			if (afterDate) {
				request.url += '?after=' + afterDate.toISOString()
			}

			this.myGet(request)
				.then((resp) => {
					let events: Events[] = resp.events
					let userEventList: UserEvents[] = []

					for (let ev of events) {
						userEventList.push(new UserEvents(ev))
					}

					resolve(userEventList)
				})
				.catch((err) => {
					console.log(err)
					reject(err)
				})
		})
		return promise
	}

	// ******** AI reports *********************************

	// // 25.07.2022 carica solo la lista, non ci sono dati crittati
	// spostata su visitListService
	// loadAiReports(patId: number): Promise<AiReport[]> {
	// 	Util.debug('(loadReportsAi) for patient ' + patId)

	// 	// 01.08.2022
	// 	if (patId <= 0) {
	// 		Util.debug('(loadReportsAi) invalid patient! ' + patId)
	// 		return Promise.reject('invalid patient')
	// 	}

	// 	let request: any = this.buildBaseRequest()
	// 	return this.data.loadAiReportList(request, patId) // ritorna gia' l'array di obj
	// }

	// the obj in input is missing of the pdf stream
	// loadAiReport(reportAi: AiReport): Promise<AiReport> {
	// 	let reportId = reportAi.id
	// 	let myKeybox = reportAi.keybox

	// 	Util.debug('S (loadReportAi) id ' + reportId)
	// 	let request: any = this.buildBaseRequest()

	// 	return this.extractAiRedKey(myKeybox).then((myRedKey) => {
	// 		return this.data.loadAiReport(request, reportId, myRedKey)
	// 	})
	// }

	// ************ AI vistel ********************

	// public getAiQuality(fundusImage: ExamImage) {
	// 	let request: any = this.buildBaseRequest()
	// 	request.method = 'POST'
	// 	request.url = Config.aiReportsEndpoint + '/quality'

	// 	let myBase64 = fundusImage.image

	// 	// togliere "data:image/jpeg;base64," in testa
	// 	let ind = myBase64.indexOf(CryptoUtilsService.JPEG_BASE64)
	// 	let lenPatt = CryptoUtilsService.JPEG_BASE64.length

	// 	//Util.debug("S (getAiQuality) ind: "+ind+" "+CryptoUtilsService.JPEG_BASE64+" len:"+lenPatt);

	// 	let inizio = myBase64.substring(0, 30)
	// 	Util.debug('S (getAiQuality) inizio ' + inizio)

	// 	if (ind == 0) {
	// 		// inizia per...

	// 		myBase64 = myBase64.substring(lenPatt)
	// 		//let inizioTrim = myBase64.substring(0, 30);
	// 		//Util.debug("S (getAiQuality) inizio trimmed "+inizioTrim);
	// 	}

	// 	//imgId: fundusImage.imgId,
	// 	// 22.07.2022 fixation al posto di imgName

	// 	let dataReq = {
	// 		image: {
	// 			examId: fundusImage.examId,
	// 			fixation: fundusImage.descr,
	// 			imgBase64: myBase64,
	// 		},
	// 	}

	// 	let timeout = 360000 //5 minuti

	// 	return this.myPost(request, dataReq, timeout)
	// }

	// 21.07.2022 richiede la key per crittare i dati del patientInfo
	public getAiKey() {
		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.aiReportsEndpoint + '/keybox'

		return this.myGet(request)
	}

	// con patInfo gia' crittati
	// 15.07.2022
	// 	//getAiReport(myPat: Patient, myImages: ExamImage[], options ? : any){
	// 	public getAiReport(patId: number, myPatInfoCri: string, myBatch: string, myImages: ExamImage2[], options?: any) {
	// 		let request: any = this.buildBaseRequest()
	// 		request.method = 'POST'
	// 		request.url = Config.aiReportsEndpoint + '/grading'

	// 		let imgList: AiImage[] // array di json con immagine
	// 		//let myPatInfo : AiPatient;  // nested json

	// 		if (myImages && myImages.length) {
	// 			imgList = []
	// 		}

	// 		for (let i = 0; i < myImages.length; i++) {
	// 			let myImg = myImages[i]
	// 			let myBase64 = myImg.image

	// 			// togliere "data:image/jpeg;base64," in testa
	// 			let ind = myBase64.indexOf(CryptoUtilsService.JPEG_BASE64)
	// 			let lenPatt = CryptoUtilsService.JPEG_BASE64.length

	// 			//Util.debug("S (getAiQuality) ind: "+ind+" "+CryptoUtilsService.JPEG_BASE64+" len:"+lenPatt);

	// 			//let inizio = myBase64.substring(0, 30);
	// 			//Util.debug("S (getAiQuality) inizio "+inizio);

	// 			if (ind == 0) {
	// 				// inizia per...
	// 				myBase64 = myBase64.substring(lenPatt)
	// 				//let inizioTrim = myBase64.substring(0, 30);
	// 				//Util.debug("S (getAiQuality) inizio trimmed "+inizioTrim);
	// 			}

	// 			let aiImg: AiImage = new AiImage(myImg)
	// 			// aiImg = {
	// 			// 	name: 'fundus_' + myImg.imgId,
	// 			// 	base64: myBase64,
	// 			// 	extraData: {
	// 			// 		imageFixation: myImg.descr,
	// 			// 		laterality: myImg.eye,
	// 			// 	},
	// 			// }

	// 			imgList.push(aiImg)
	// 		}

	// 		/*
	//     myPatInfo = {
	//       name: myPat.getFullName(),
	//       gender: myPat.getGenderForAi(),
	//       birthday: myPat.getDobForAi(),  // YYYYMMDD,
	//       isDiabetes: null,
	//       isHypertension: null,
	//       isHighMyopia : null,
	//       laserPhotocoagulation : {
	//         exist: null,
	//         data: []
	//       },
	//       medicalHistory: null,
	//     };
	// */

	// 		// 22.07.2022 estraggo iv da myPatInfoCri
	// 		// stacco iv in testa dal resto, poi ri-forzo base64 sui due pezzi

	// 		Util.debug('S (getAiQuality) full myPatInfoCri: ' + myPatInfoCri)

	// 		let binary = this.decodeBase64(myPatInfoCri)

	// 		let input = forge.util.createBuffer(binary)

	// 		// read the iv and take it away from the input
	// 		let origIV = input.getBytes(16)
	// 		let origInfo = input.getBytes() // rimanente

	// 		// rimetto in base64 le due parti
	// 		let myIv = this.encodeBase64(origIV)
	// 		let myPatCri = this.encodeBase64(origInfo)

	// 		Util.debug('S (getAiQuality) iv ' + myIv)
	// 		Util.debug('S (getAiQuality) myPatCri ' + myPatCri)

	// 		if (!options) {
	// 			options = {
	// 				//channel: "dss",
	// 				language: this.getLanguage(),
	// 				timezone: '+0200',
	// 				device: 'VX610',
	// 			}
	// 		}

	// 		//channel: options.channel,  // 27.09.2022 tolto

	// 		let dataReq = {
	// 			grading: {
	// 				language: options.language,
	// 				timezone: options.timezone,
	// 				device: options.device,
	// 				batchId: myBatch,
	// 				images: imgList,
	// 				patientId: patId,
	// 				iv: myIv,
	// 				patientInfo: myPatCri,
	// 			},
	// 		}

	// 		let timeout = 360000 //5 minuti

	// 		return this.myPost(request, dataReq, timeout)
	// 	}

	// ******** from dataModel ******

	isLoadingPatients() {
		let ret = this.data.patientListStatus == DataStatus.LOADING
		//Util.debug("(S isLoadingPatients) "+ret);
		return ret
	}

	hasLoadedPatients() {
		return this.data.patientListStatus == DataStatus.LOADED
	}

	// 23.01.2023
	// isLoadingPatient() {
	// 	return this.data.isLoadingPatient()
	// }

	// 08.02.2022
	// hasLoadedPatient(patId?) {
	// 	return this.data.hasLoadedPatient(patId)
	// }

	// 20.10.2021
	unlockPatient(patId: string, repId: number) {
		Util.debug('(unlockPatient) sending request... ')
		// console.log(repId)
		return this.setLockPatient(patId, false, repId)
	}

	// 20.10.2021
	lockPatient(patId: string) {
		Util.debug('(lockPatient) sending req... ')
		return this.setLockPatient(patId, true)
	}

	// 20.10.2021 copiata da nexy - 03.09.2019
	private setLockPatient(patId: string, flagActivate: boolean, repId?: number) {
		// 25.03.2022 aggiunto doctor
		if (!this.isSpecialist() && !this.isDoctor()) {
			//return false;
			return Promise.reject('forbidden')
		}

		let request: any = this.buildBaseRequest()
		request.method = 'POST'

		if (flagActivate) {
			request.url = Config.distributorsEndpoint + '/lock'
		} else {
			request.url = Config.distributorsEndpoint + '/unlock'
		}

		let dataReq

		if (repId || repId == 0) {
			dataReq = { lock: { patient_id: patId, report_id: repId } }
		} else {
			dataReq = { lock: { patient_id: patId } }
		}

		//request.data = dataReq;

		//console.log("(lockPatient) going to call "+request.url);
		// console.log(dataReq)
		//return this.http(request)

		return this.myPost(request, dataReq)
	}

	/*
  // TODO, togliere
  private getData(): DataModelService {
    return this.data;
  }
  */

	public getTotGraders(country?: string) {
		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.distributorsEndpoint + '/count_available' + (country ? `'?country=${country}'` : '')
		return this.myGet(request)
	}

	// ********* Date & times *********

	// TODO trovare modo di fare chiamata static al filter e spostare da qui [ls]
	// 11.06.2020 portato qui per uniformare [ls]
	// anno da 4, per chiarezza [ls]
	formatDate(date: Date, lang?) {
		var myFormat = this.getDateFormat()
		return this.datepipe.transform(date, myFormat)
	}

	formatDateTime(date: Date, lang?) {
		/*
    if(lang == null)
      lang = this.lang;   
    */
		var dtFormat = this.getDateTimeFormat()
		return this.datepipe.transform(date, dtFormat)
	}

	// solo per data, senza time
	getDateFormat() {
		//var f = Util.getDateFormatByLang(this.lang);
		var f = this.user.getDateFormat() // 29.03.2022
		return f
	}

	getDateTimeFormat() {
		//var f = Util.getDateFormatByLang(this.lang) + " HH:mm";
		// 29.03.2022 prendiamo dai settings, non piu' associato alla lingua
		var f = this.user.getDateFormat() + ' HH:mm'
		return f
	}

	// 09.08.2022 TODO test sul parametro myFormat
	public formatDateAs(date: Date, myFormat: string) {
		return this.datepipe.transform(date, myFormat)
	}

	// 11.06.2020 unica data per i dati della tabella centrale (in teoria occhio dx e sin fatti nello stesso gg)
	// TODO migliorare, cfr solo il gg ignorando l'orario
	mergeExamDate(examR, examL, lang?) {
		var singleExamDate = ''
		var dtR = ''
		var dtL = ''

		if (lang == null) lang = this.lang

		if (examR) {
			dtR = this.formatDate(examR.exam_date, lang)
		}

		if (examL) {
			dtL = this.formatDate(examL.exam_date, lang)
		}

		// merge delle due date
		if (dtR == dtL) {
			singleExamDate = dtR
		} else if (dtR == '' && dtL != '') {
			singleExamDate = dtL
		} else if (dtL == '' && dtR != '') {
			singleExamDate = dtR
		} else {
			//singleExamDate = dtR + "/" + dtL;
			singleExamDate = dtR + '&' + dtL
		}

		if (singleExamDate != '') Util.debug('(mergeExamDate) ExamDate: ' + singleExamDate)

		return singleExamDate
	}

	// fare statica su Util ?
	// 07.09.2017 serve quando si passa nella url per le get
	private parseEmail(emailIn) {
		// ne sostituisce solo uno...
		var emailOut = emailIn.replace('.', '$')
		while (emailOut.indexOf('.') > 0) {
			emailOut = emailOut.replace('.', '$')
		}
		return emailOut
	}

	private logoutAuxFunc: () => void

	setLogoutAuxFunc(fn: () => void) {
		this.logoutAuxFunc = fn
	}

	private profileRedirectAuxFunc: () => void

	setProfileRedirectAuxFunc(fn: () => void) {
		this.profileRedirectAuxFunc = fn
	}

	private createdRelationAuxFunc: (isSwitchingGrader: boolean) => void

	setCreatedRelationAuxFunc(fn: (isSwitchingGrader: boolean) => void) {
		this.createdRelationAuxFunc = fn
	}

	// 09.10.2023 Vat check
	public async vatChecker(vatInfo: { state: string; vatNumber: string }) {
		let request: any = this.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.vatCheckerEndpoint

		Util.debug('(vatChecker) sending POST ...')

		return await this.myPost(request, vatInfo).catch((err) => console.log('error on vatChecker request:', err))
	}

	loadPendingPatients(uuids: string[]): Promise<string[]> {
		Util.debug('S (loadPendingPatients) START for: ' + uuids.length + ' uuids')
		// ritorna una lista di uuid correttamente gestiti
		const promise = new Promise<string[]>((resolve, reject) => {
			let managedUuids: string[] = []

			this.data
				.loadPendingPatients(this.buildBaseRequest())
				.then(async (res: PendingPatientsResponse) => {
					// console.log(res)
					if (res && res.patients.length > 0) {
						// gestisco solo quelli con gli uuid corretti
						const filteredPatient = res.patients.filter((el) => uuids.includes(el.uuid))

						if (filteredPatient.length > 0) {
							// per ogni paziente eseguo un ciclo di decrypt
							for (let patient of filteredPatient) {
								await this.managePendingPatients(patient).then((res) => {
									// console.log(res)
									if (res) {
										managedUuids.push(patient.uuid)
									}
								})
							}
						}
					}

					resolve(managedUuids)
				})
				.catch((err) => {
					console.log(err)
				})
		})

		return promise
	}

	private managePendingPatients(patient: PendingPatient): Promise<any> {
		const promise = new Promise((resolve, reject) => {
			// solo quando ho la patient list caricata
			this.getDtPatientList().then((patientList) => {
				this.getDtPatientListUnsubscribe()

				let currentPatientList = patientList

				this.decryptWithPrivateKey(patient.priv_data).then((res) => {
					const privDataDecrypted: privDataDecryptedT = JSON.parse(res)
					// console.log(privDataDecrypted)
					const possibleConflictingFullNames: string[] = []
					let exactMatchConflictPatient: Patient = null
					let samePatient: Patient = null //match by personal_id

					const patientDraft = new Patient()
					patientDraft.addresses[0] = new Address()
					patientDraft.addresses[0].country = patient.country
					patientDraft.addresses[0].ref_email = privDataDecrypted.email
					patientDraft.addresses[0].phone1 = privDataDecrypted.phonenumber
					patientDraft.firstName = privDataDecrypted.firstname
					patientDraft.lastName = privDataDecrypted.lastname
					patientDraft.birthDate = privDataDecrypted.birthdate
					patientDraft.birthYear = parseInt(privDataDecrypted.birthdate.split('-')[0])
					patientDraft.personal_id = privDataDecrypted.personalid
					patientDraft.subscription_time = DateParser.parseDate(patient.creation_date)
					patientDraft.pending_id = patient.id
					patientDraft.sex = patient.sex
					patientDraft.race = 0
					patientDraft.unique_hash = patient.unique_hash

					// check current patients for same birthdate as pending patient, then extract current patients that have similar/same full name
					const conflictingCurrentPatients = currentPatientList.filter((currentPatient) => {
						if (privDataDecrypted.personalid === currentPatient.personal_id) {
							console.log('personalid: ' + currentPatient.personal_id)
							samePatient = currentPatient
							return true
						}

						if (privDataDecrypted.birthdate === currentPatient.birthDate) {
							const currentPatientFullName = `${currentPatient.firstName} ${currentPatient.lastName}`
							const pendingPatientFullName = `${privDataDecrypted.firstname} ${privDataDecrypted.lastname}`
							const d = levenshteinEditDistance(currentPatientFullName, pendingPatientFullName, true)

							if (d <= 1) {
								// exact match -> store patient to serve to modal later
								if (d === 0) {
									exactMatchConflictPatient = currentPatient
								}
								// similar names are stored for later conflicting modal check
								possibleConflictingFullNames.push(currentPatientFullName)
								return true
							}

							return false
						}
					})

					// console.log(conflictingCurrentPatients)

					if (samePatient) {
						// se samePatient salvo direttamente perché stesso personal_id
						this.updateExistingPatient(patientDraft, samePatient.id, patientDraft.pending_id)
							.then((res) => {
								// console.log(res)
								resolve(true)
							})
							.catch((err) => {
								reject(false)
							})
					} else if (conflictingCurrentPatients.length === 0) {
						// if no conflicting current patients are found, create new patient using pending patient data
						this.createPatient(patientDraft)
							.then((res) => {
								Util.debug('(managePendingPatients) created patient with id: ' + res)
								// this.patientReloadListAuxFunc('created')
								this.data.patientListStatus = DataStatus.LOADING

								resolve(true)
							})
							.catch((err) => {
								console.log('(createPatient) ko!', err)
								this.data.patientListStatus = DataStatus.LOADED

								reject(false)
							})
					} else {
						// this.data.patientListStatus = DataStatus.LOADED
						const modalRef = this.modalService.open(PendingPatientConflictModalContent, {
							backdrop: 'static',
							keyboard: false,
							size: 'lg',
							windowClass: 'pendingPatientModalClass',
						})
						modalRef.componentInstance.pendingPatient = patientDraft
						modalRef.componentInstance.conflictingCurrentPatients = conflictingCurrentPatients
						modalRef.componentInstance.possibleConflictingFullNames = possibleConflictingFullNames
						modalRef.componentInstance.exactMatchConflictPatient = exactMatchConflictPatient ? exactMatchConflictPatient : null

						modalRef.result.then((result: { event: string; patient: Patient; id: number; pendingId: number }) => {
							// console.log(result)

							if (result.event == 'new') {
								this.createPatient(result.patient)
									.then((res) => {
										Util.debug('(managePendingPatients) created patient with id:' + result)
										// this.patientReloadListAuxFunc('created')
										this.data.patientListStatus = DataStatus.LOADING

										resolve(true)
									})
									.catch((err) => {
										console.log('(createPatient) ko!', err)
										this.data.patientListStatus = DataStatus.LOADED

										reject(false)
									})
							}

							if (result.event == 'update') {
								this.updateExistingPatient(result.patient, result.id, result.pendingId)
									.then(() => {
										this.data.patientListStatus = DataStatus.LOADING

										resolve(true)
									})
									.catch((err) => {
										this.data.patientListStatus = DataStatus.LOADED
										reject(false)
									})
							}

							// resolve(true)
						})
					}
				})
			})
		})

		return promise
	}

	public updateExistingPatient(patientOverwriteData: Patient, existingPatientId: number, pendingPatientId: number) {
		// this.data.patientListStatus = DataStatus.LOADING
		return this.mergePendingPatient(pendingPatientId, existingPatientId)
			.then(() => {
				patientOverwriteData.id = existingPatientId
				return this.updatePatient(patientOverwriteData)
				// .then(() => {
				// 	// setTimeout(() => {
				// 	// 	this.patientReloadListAuxFunc('updated')
				// 	// 	this.data.patientListStatus = DataStatus.LOADED
				// 	// }, 1000)
				// })
				// .catch((err) => {
				// 	console.log('(updatePatient) ko!', err)
				// 	throw new Error(err)
				// })
			})
			.catch((err) => {
				console.log('(updateExistingPatient) ko!', err)
				this.data.patientListStatus = DataStatus.LOADED
			})
	}

	public manageNewAutomaticRelation(designatedGraderId: number, isSwitchingGrader: boolean = false) {
		const request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = `${Config.distributorsEndpoint}/${designatedGraderId}`

		this.myGet(request)
			.then((res) => {
				// console.log(res)
				const distributor = res.distributor
				const graderUsername = distributor.user_access.username
				const graderPublicKey = distributor.user_access.public_key
				const currKeyPhoto = this.user.getKeyPhoto()
				const keybox_photo = this.buildKeyboxPhoto(graderUsername, graderPublicKey, currKeyPhoto)
				const dataR = {
					doctor_usrname: this.getUsername(),
					distrib_usrname: graderUsername,
					keybox_public: keybox_photo, // optician's keyPhoto closed with the graders public key
				}

				this.createRelInternal(dataR).then(() => {
					// this.user.addSpecialist(specialist)
					this.getRelationsByOpticians()
						.then((specs) => {
							let specToKeep = specs.filter((el) => el.distributor_id != distributor.user_id) //to string forse
							let newGrader = specs.find((el) => el.distributor_id == distributor.user_id)
							// console.log(specToKeep)
							// console.log(newGrader)

							if (specToKeep.length > 0) {
								//questa parte specToKeep.length > 0 da testare, per ora non c'é un caso in cui si capita
								let toRemove = this.user.specialists.filter((el) => {
									return !specToKeep.some((spec) => spec.distributor_id === el.distributor_id)
								})
								this.user.clearSpecialists(toRemove)
							} else {
								this.user.clearAllSpecialists()
							}

							Specialist.createSpecialist(newGrader, this.cryptoUtils, this.user.getKeyPhoto()).then((specialist) => {
								// console.log(specialist)
								this.user.addSpecialist([specialist])

								this.createdRelationAuxFunc(isSwitchingGrader)
							})
						})
						.then(() => {
							this.user.clearAllSpecialists()
						})
						.catch((err) => {
							console.log('(manageNewAutomaticRelation) err:' + err)
						})
				})
			})
			.catch((err) => {
				Util.debug('(manageNewAutomaticRelation) err:' + err)
				return
			})
	}

	refuseGraderSwitch(action: string, suggestedRelId: number) {
		const request: any = this.buildBaseRequest()
		request.method = 'PUT'
		request.url = `${Config.apiEndpoint}/nearest_relations/${suggestedRelId}`
		const reqData = {
			nearest_relation: {
				status: action,
			},
		}

		this.myPut(request, reqData)
	}

	public canStartQRCodeService(): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			const route = this.getRoute()

			if (!this.isLevel1()) {
				resolve(false)
			} else if (['agreement', 'activation', 'verifyPuk', 'trial'].includes(route)) {
				resolve(false)
			} else if (route === 'profile' && !this.isProfileComplete()) {
				resolve(false)
			} else {
				resolve(true)
			}
		})

		return promise
	}

	//trasformato in promise perché su signalR service, alla login torna null
	public canReceivePushNotifications(r?: string): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			const route = r ? r.replace('/', '') : this.getRoute()

			if (this.isManager()) {
				//!this.isOptician() && !this.isSupport()
				resolve(false)
			} else if (['agreement', 'activation', 'verifyPuk', 'trial'].includes(route)) {
				resolve(false)
			} else if (route === 'profile' && !this.isProfileComplete()) {
				resolve(false)
			} else {
				resolve(true)
			}
		})

		return promise
	}

	public async mergePendingPatient(pendingPatientId: number, existingPatientId: number) {
		const request: any = this.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.pendingPatientsEndpoint + '/merge'

		const dataReq = {
			merge: {
				nexus_patient_id: existingPatientId,
				pending_id: pendingPatientId,
			},
		}

		return this.myPost(request, dataReq)
	}

	public goLiveTelerefract(teleParams: {
		token: string
		name: string
		organization: string
		userId: number
		role: string
		groupId: number
		subtype: string
		targetOrganization: string
		targetGroupId: number
	}) {
		const target = '_blankTeleRefr'
		const mapForm = document.createElement('form')
		mapForm.target = target
		mapForm.method = 'post'
		mapForm.action = Config.telerefractUrl + '/Auth/TransferLoginFromNexus'

		const mapInput0 = document.createElement('input')
		mapInput0.type = 'text'
		mapInput0.setAttribute('type', 'text')
		mapInput0.name = 'token'
		mapInput0.value = teleParams.token
		mapForm.appendChild(mapInput0)

		const mapInput1 = document.createElement('input')
		mapInput1.type = 'text'
		mapInput1.name = 'name'
		mapInput1.value = teleParams.name
		mapForm.appendChild(mapInput1)

		const mapInput2 = document.createElement('input')
		mapInput2.type = 'text'
		mapInput2.name = 'organization'
		mapInput2.value = teleParams.organization
		mapForm.appendChild(mapInput2)

		const mapInput3 = document.createElement('input')
		mapInput3.type = 'text'
		mapInput3.name = 'userId'
		mapInput3.value = '' + teleParams.userId
		mapForm.appendChild(mapInput3)

		const mapInput4 = document.createElement('input')
		mapInput4.type = 'text'
		mapInput4.name = 'role'
		mapInput4.value = '' + teleParams.role
		mapForm.appendChild(mapInput4)

		const mapInput5 = document.createElement('input')
		mapInput5.type = 'text'
		mapInput5.name = 'groupId'
		mapInput5.value = '' + teleParams.groupId
		mapForm.appendChild(mapInput5)

		const mapInput6 = document.createElement('input')
		mapInput6.type = 'text'
		mapInput6.name = 'subtype'
		mapInput6.value = '' + teleParams.subtype
		mapForm.appendChild(mapInput6)

		const mapInput7 = document.createElement('input')
		mapInput7.type = 'text'
		mapInput7.name = 'targetOrganization'
		mapInput7.value = '' + teleParams.targetOrganization
		mapForm.appendChild(mapInput7)

		const mapInput8 = document.createElement('input')
		mapInput8.type = 'text'
		mapInput8.name = 'targetGroupId'
		mapInput8.value = '' + teleParams.targetGroupId
		mapForm.appendChild(mapInput8)

		document.body.appendChild(mapForm)
		window.open('', target).focus()
		mapForm.submit()
		document.body.removeChild(mapForm)

		// let request: any = this.buildBaseRequest()
		// request.method = 'POST'

		// request.url = Config.telerefractUrl + '/Auth/TransferLoginFromNexus'

		// let dataReq = {
		// 	data: {
		// 		token: teleParams.token,
		// 		name: teleParams.name,
		// 		organization: teleParams.organization,
		// 		userId: teleParams.userId,
		// 		role: teleParams.role,
		// 		groupId: teleParams.groupId,
		// 		subtype: teleParams.subtype,
		// 	},
		// }

		// window.open('', '_blankTeleRefr').focus()

		// return this.myPost(request, dataReq)
	}

	//RELEASE NOTES
	public getDocuments(): Promise<Documents[]> {
		const promise = new Promise<Documents[]>((resolve, reject) => {
			const request: any = this.buildBaseRequest()
			request.method = 'GET'
			request.url = Config.apiEndpoint + '/documents'

			this.myGet(request)
				.then((res) => {
					// console.log(res)
					let documents: Documents[] = []
					if (res.documents && res.documents.length > 0) {
						for (let doc of res.documents) {
							documents.push(new Documents(doc))
						}
					}

					resolve(documents)
				})
				.catch((error) => {
					console.log(error)
					reject(error)
				})
		})

		return promise
	}
}
