import { Injectable } from '@angular/core'
import { SessionService } from './session.service'
import { DataModelService } from './data-model.service'
import { ImpactReportData, ImpactDates, ImpactHistoryData, ImpactEyeData } from '../models/impact.model'
import { postMessageToWorker, workerFunctionType, dataType, CryptoUtilsService } from './crypto-utils.service'

@Injectable({
	providedIn: 'root',
})
export class ImpactService {
	userImpactReportMap: Map<string, ImpactReportData> // key: patientId + date
	userImpactDatesMap: Map<number, string[]> // key: patientId
	userImpactHistoryMap: Map<string, ImpactHistoryData> // key: patientId + topic + date

	constructor(private session: SessionService, private data: DataModelService, private cryptoUtils: CryptoUtilsService) {
		this.userImpactReportMap = new Map<string, ImpactReportData>()
		this.userImpactDatesMap = new Map<number, string[]>()
		this.userImpactHistoryMap = new Map<string, ImpactHistoryData>()
	}

	getImpactReport(patientId: number, day: string): Promise<ImpactReportData> {
		return new Promise((resolve, reject) => {
			if (this.userImpactReportMap.has(`${patientId}${day}`)) {
				resolve(this.userImpactReportMap.get(`${patientId}${day}`))
			} else {
				this.data
					.loadImpactReport(this.session.buildBaseRequest(), patientId, day)
					.then((res) => {
						const key = this.session.getExamKey()

						this.decryptImpactReportData(res, this.session.user.password, key).then((decrData) => {
							this.userImpactReportMap.set(`${patientId}${day}`, this.formatImpactReportData(decrData))
							resolve(this.formatImpactReportData(decrData))
						})
					})
					.catch((err) => {
						reject(err)
					})
			}
		})
	}

	getImpactAvailableDates(patientId: number): Promise<ImpactDates> {
		return new Promise((resolve, reject) => {
			if (this.userImpactDatesMap.has(patientId)) {
				resolve({ impact_available_dates: this.userImpactDatesMap.get(patientId) })
			} else {
				this.data
					.loadImpactAvailableDates(this.session.buildBaseRequest(), patientId)
					.then((res) => {
						this.userImpactDatesMap.set(patientId, res.impact_available_dates)
						resolve(res)
					})
					.catch((err) => {
						reject(err)
					})
			}
		})
	}

	getImpactTopicHistory(patientId: number, topic: string, day: string): Promise<ImpactHistoryData> {
		return new Promise((resolve, reject) => {
			if (this.userImpactHistoryMap.has(`${patientId}${topic}${day}`)) {
				resolve(this.userImpactHistoryMap.get(`${patientId}${topic}${day}`))
			} else {
				this.data
					.loadImpactTopicHistory(this.session.buildBaseRequest(), patientId, topic, day)
					.then((res) => {
						const key = this.session.getExamKey()

						this.decryptImpactHistoryData(res, this.session.user.password, key, this.session.user.username.toLowerCase()).then((decrData) => {
							this.userImpactHistoryMap.set(`${patientId}${topic}${day}`, this.formatImpactHistoryData(decrData))
							resolve(this.formatImpactHistoryData(decrData))
						})
					})
					.catch((err) => {
						reject(err)
					})
			}
		})
	}

	async decryptImpactReportData(encrData: ImpactReportData, password: string, key: any) {
		const decrData = encrData // Deep copy of the original data

		const postMessageToWorkerTemplate: postMessageToWorker = new postMessageToWorker()
		postMessageToWorkerTemplate.type = workerFunctionType.decryptDataAllInOne
		postMessageToWorkerTemplate.password = password
		postMessageToWorkerTemplate.key = key
		postMessageToWorkerTemplate.dataType = dataType.string

		for (const section of decrData.impact.sections) {
			for (const topic of section.topics) {
				for (const eye of topic.eyes) {
					for (const key in eye) {
						if (eye.hasOwnProperty(key) && !['eye', 'exam_date', 'exam_type'].includes(key)) {
							eye[key] = await this.cryptoUtils.decryptDataWithKeyWorker({ ...postMessageToWorkerTemplate, dataToDecrypt: eye[key] })
						}
					}
				}
			}
		}

		return decrData
	}

	async decryptImpactHistoryData(encrData: ImpactHistoryData, password: string, key: any, username: string) {
		const decrData = encrData // Deep copy of the original data

		const postMessageToWorkerTemplate: postMessageToWorker = new postMessageToWorker()
		postMessageToWorkerTemplate.type = workerFunctionType.decryptDataAllInOne
		postMessageToWorkerTemplate.password = password
		postMessageToWorkerTemplate.key = key
		postMessageToWorkerTemplate.dataType = dataType.string

		for (const eye of decrData.historical_topic.eyes) {
			for (const key in eye) {
				if (eye.hasOwnProperty(key) && !['eye', 'exam_date', 'exam_type'].includes(key)) {
					eye[key] = await this.cryptoUtils.decryptDataWithKeyWorker({ ...postMessageToWorkerTemplate, dataToDecrypt: eye[key] })
				}
			}
		}

		return decrData
	}

	formatImpactReportData(data: ImpactReportData) {
		const formattedImpactReportData = data

		for (const section of formattedImpactReportData.impact.sections) {
			for (const topic of section.topics) {
				if (topic.display_mode === 'direct') {
					for (const eye of topic.eyes) {
						if (eye.value && eye.value.replace) {
							eye.decrValue = parseFloat(eye.value.replace(',', '.'))
						}
					}
				}

				if (topic.display_mode === 'calculate')
					for (const eye of topic.eyes) {
						this.calculateTopicValue(topic.title, eye)
					}
			}
		}
		return formattedImpactReportData
	}

	formatImpactHistoryData(data: ImpactHistoryData) {
		const formattedImpactHistoryData = data

		if (formattedImpactHistoryData.historical_topic.display_mode === 'direct') {
			for (const eye of formattedImpactHistoryData.historical_topic.eyes) {
				if (eye.value && eye.value.replace) {
					eye.decrValue = parseFloat(eye.value.replace(',', '.'))
				}
			}
		}

		if (formattedImpactHistoryData.historical_topic.display_mode === 'calculate') {
			for (const eye of formattedImpactHistoryData.historical_topic.eyes) {
				this.calculateTopicValue(formattedImpactHistoryData.historical_topic.title, eye)
			}
		}

		return formattedImpactHistoryData
	}

	calculateTopicValue(topic: string, eye: ImpactEyeData) {
		switch (topic) {
			case 'daynight_delta':
				const sphere_dayParsed = parseFloat(eye.sphere_day)
				const sphere_nightParsed = parseFloat(eye.sphere_night)
				const cylinder_dayParsed = parseFloat(eye.cylinder_day)
				const cylinder_nightParsed = parseFloat(eye.cylinder_night)

				const x = sphere_dayParsed - Math.abs(cylinder_dayParsed) / 2
				const y = sphere_nightParsed - Math.abs(cylinder_nightParsed) / 2

				eye.decrValue = parseFloat((Math.abs(x) - Math.abs(y)).toFixed(2))
				break
		}
	}
}
