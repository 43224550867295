import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { DevicesType, SwUpdate } from '../../models/device.model'
import { DataModelService, DataStatus } from '../../service/data-model.service'
import { SessionService } from '../../service/session.service'

import { DeleteModalContent, itemType } from '../delete/delete.modal'

import { faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons'
import { faXmark, faSortDown, faCircleExclamation, faCaretDown, faCaretUp, faCircleRight, faFileCsv } from '@fortawesome/free-solid-svg-icons'

import { MatTable, MatTableDataSource } from '@angular/material/table' //10.10.22
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator' //10.10.22
import { MatSort, Sort } from '@angular/material/sort' //10.10.22
import { FormControl, FormGroup } from '@angular/forms'

import { Util } from '../../models/util.model'
import { NgbModal, NgbModalRef, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { Config } from 'src/config'
import { UpdatesModalContent } from './updates.modal'
import { userDeviceSubscription } from 'src/app/models/user.model'
import { WizardModal } from './wizard/wizard.modal'
import { TranslateService } from '@ngx-translate/core'
import { PushUpdateModal } from './push-update/push-update.modal'

@Component({
	selector: 'app-updates',
	templateUrl: './updates.component.html',
	styleUrls: ['./updates.component.scss'],
})
export class UpdatesComponent implements OnInit {
	updateList: MatTableDataSource<SwUpdate>
	updateListRaw: SwUpdate[]
	filteredList: SwUpdate[]

	@ViewChild(MatPaginator) paginator: MatPaginator
	@ViewChild(MatSort) sort: MatSort
	@ViewChild('filter') input: ElementRef

	expandedElement: userDeviceSubscription | null
	filterWord: string
	displayedColumns: string[]
	// filterDictionary = new Map<string, string>()

	deviceModels: string[]

	tabs: { label: string; updates: SwUpdate[]; alreadyLoaded: string[] }[]
	activeTab: { label: string; updates: SwUpdate[]; alreadyLoaded: string[] }

	showFilters: boolean
	filterSet: boolean

	filterForm: FormGroup

	availableOS: string[]
	// availableBrand: string[]
	availableDevType: string[]

	currentAction: string
	currentModal: NgbModalRef
	currentUpdt: SwUpdate

	faEdit = faEdit
	faTrashAlt = faTrashAlt
	faXmark = faXmark
	faSortDown = faSortDown
	faCircleExclamation = faCircleExclamation
	faCaretUp = faCaretUp
	faCaretDown = faCaretDown
	faCircleRight = faCircleRight
	faFileCsv = faFileCsv

	public devTypes = ['APP', 'FIRMWARE', 'PACKAGE', 'LAUNCHER'] // da levare viene usato nel updates.modal

	public compName = ['tablet', 'VX610', 'VX610App', 'twodots', 'VX610Launcher'] // da levare viene usato nel updates.modal

	constructor(public session: SessionService, public dataService: DataModelService, public modalService: NgbModal, private translator: TranslateService) {
		Util.debug('(UpdateList Comp) - Constructor')
		this.displayedColumns = []
		this.deviceModels = this.session.deviceModels
		// console.log(this.deviceModels)

		this.tabs = []

		if (this.deviceModels.length > 0) {
			for (let model of this.deviceModels) {
				this.tabs.push({ label: model, updates: [], alreadyLoaded: [] })
			}
		}
		this.activeTab = this.tabs[0]

		this.showFilters = false
		this.filterSet = false

		this.filterForm = new FormGroup({
			// os: new FormControl(null),
			// brand: new FormControl(null),
			dev_type: new FormControl(null),
		})

		this.filterForm.disable()

		this.availableOS = []
		// this.availableBrand = []
		this.availableDevType = []

		this.updateListRaw = []
		this.filteredList = []

		this.initColumns()

		this.loadUpdateList()

		this.clearCurrentUpdt()
	}

	ngOnInit(): void {
		Util.debug('(UpdateList Comp) - ngOnInit')
	}

	/* Inizializzazione colonne */

	initColumns() {
		this.displayedColumns = ['dev_type', 'os', 'min_ver', 'build_num', 'description', 'release_date', 'filename', 'filter', 'expand']

		let indx: number
		if (this.activeTab.label == DevicesType.VX610) {
			indx = this.displayedColumns.indexOf('description')
			this.displayedColumns.splice(indx, 0, 'package_name')
		} else {
			indx = this.displayedColumns.indexOf('package_name')
			this.displayedColumns.splice(indx, 1)
		}
	}

	/* Load the list of updates */

	loadUpdateList() {
		this.session.loadUpdates().then((data) => {
			// console.log(data)

			Util.debug('(LoadUpdate) Update the list....')

			this.updateListRaw = data

			this.initTabs(this.updateListRaw)

			if (this.activeTab.label == DevicesType.VX610) {
				this.initFilters(this.activeTab.updates)
			}

			this.initTable(this.activeTab.updates)

			this.initTargets(this.activeTab.updates)
		})
	}

	private initTabs(list: SwUpdate[]) {
		for (let tab of this.tabs) {
			tab.updates = list.filter((item) => item.model == tab.label)
			tab.alreadyLoaded = tab.updates.map((item) => item.url.split('/').pop())
		}
	}

	private initFilters(list: SwUpdate[]) {
		this.availableOS = [...new Set(list.map((item) => item.os_ver ?? this.translator.instant('SW_UPDT.FILTERS.NO_OS')))] // ?? '' rimpiazza i null con string a vuota
		// this.availableBrand = [...new Set(list.map((item) => item.branding ?? this.translator.instant('SW_UPDT.FILTERS.NO_BRAND')))]
		this.availableDevType = [...new Set(list.map((item) => item.dev_type))]

		this.filterForm.enable()
	}

	private initTable(list: SwUpdate[]) {
		this.updateList = new MatTableDataSource<SwUpdate>(list)

		this.updateList.sort = this.sort
		this.updateList.paginator = this.paginator

		this.sort.active = 'release_date'
		this.sort.direction = 'desc'
		this.sort.sortChange.emit()
	}

	private initTargets(list: SwUpdate[]) {
		for (let item of list) {
			if (item.targets.length > 0) {
				item.publishing = [...new Set(item.targets.map((target) => target.push_id))].length
				let dates = item.targets.map((target) => new Date(target.creation_date).getTime())
				item.last_publishing = new Date(Math.max(...dates))
				let isAll = item.targets.find((target) => target.sn == 'ALL')
				if (isAll) {
					item.isAll = true
				}
			}
		}
	}

	public onNavChange(event: NgbNavChangeEvent) {
		this.activeTab = event.nextId
		this.initTable(this.activeTab.updates)
		this.initTargets(this.activeTab.updates)
		this.filterForm.reset()
		this.filterSet = false

		if (this.activeTab.label == DevicesType.VX610) {
			this.initFilters(this.activeTab.updates)
		}

		this.initColumns()
	}

	/* Filters section */

	filterText() {
		this.filterWord = this.input.nativeElement.value

		this.filterWord = this.filterWord.trim().toLocaleLowerCase()

		this.updateList.filter = this.filterWord
	}

	public filterTableValue() {
		Util.debug('[Updates list] - filterValue')
		this.filterSet = false

		this.filteredList = this.activeTab.updates.slice()

		// os
		// let os = this.filterForm.get('os').value
		// if (os) {
		// 	this.filterSet = true
		// 	// console.log(os)

		// 	if (os == this.translator.instant('SW_UPDT.FILTERS.NO_OS')) {
		// 		this.filteredList = this.filteredList.filter((el) => el.os_ver == '' || el.os_ver == null)
		// 	} else {
		// 		this.filteredList = this.filteredList.filter((el) => el.os_ver == os)
		// 	}
		// }

		let dev_type = this.filterForm.get('dev_type').value
		if (dev_type) {
			this.filterSet = true
			// console.log(os)
			this.filteredList = this.filteredList.filter((el) => el.dev_type == dev_type)
		}

		// brand
		// let brand = this.filterForm.get('brand').value
		// if (brand) {
		// 	this.filterSet = true
		// 	// console.log(brand)

		// 	if (brand == this.translator.instant('SW_UPDT.FILTERS.NO_BRAND')) {
		// 		this.filteredList = this.filteredList.filter((el) => el.branding == '' || el.branding == null)
		// 	} else {
		// 		this.filteredList = this.filteredList.filter((el) => el.branding == brand)
		// 	}
		// }

		this.initTable(this.filteredList)
	}

	public openDetailRow(row) {
		let canOpen = row.targets.length > 0

		if (canOpen) {
			this.expandedElement = this.expandedElement

			if (this.expandedElement === row && canOpen) {
				this.expandedElement = null
			} else {
				this.expandedElement = row
			}
		}
	}

	// push update

	public pushUpdate(updt: SwUpdate) {
		// console.log(updt)
		this.setCurrentUpdt(updt)
		this.currentModal = this.modalService.open(PushUpdateModal, { size: 'xl', keyboard: false, backdrop: 'static' })
		this.currentModal.componentInstance.update = this.currentUpdt

		this.currentModal.result
			.then(() => {
				// console.log(setUpdt)
				this.loadUpdateList()
			})
			.catch(() => {})
	}

	/* Delete modal*/

	openDeleteModal(updt: SwUpdate) {
		// Util.debug('delete');
		this.setCurrentUpdt(updt)
		this.currentAction = 'delete'

		this.currentModal = this.modalService.open(DeleteModalContent, { size: 'lg' })

		this.currentModal.componentInstance.itemType = itemType.UPDATES
		this.currentModal.componentInstance.itemDescription = 'Update: ' + updt.dev_type + ' - ' + updt.component_name

		// arriva qui facendo la close del modal
		this.currentModal.result.then(
			(confirmed) => {
				Util.debug('(delUpdate) After modal closed: ' + confirmed) // confirmed = true/false
				if (confirmed) {
					this.deleteUpdt(updt)
					this.loadUpdateList() // ricarico la lista dopo la delete
				}
			},
			(reason) => {
				let ris = Util.getDismissReason(reason)
				Util.debug('(Upd) Dismissed ' + ris)
			}
		)
	}

	public download(swUpdate: SwUpdate) {
		// console.log(swUpdate)

		let filename = swUpdate.description + '.csv'
		let listToExport = []

		for (let target of swUpdate.targets) {
			let el = {
				device_sn: target.sn,
				publish_date: this.session.formatDateTime(new Date(target.creation_date)),
				publish_by_user: target.created_by,
			}

			listToExport.push(el)
		}

		Util.downloadCSV(listToExport, filename)
	}

	public downloadLsit() {
		let filename = this.activeTab.label + '_Update_List.csv'
		let listToExport = []
		let array = []
		if (this.filterSet) {
			array = this.filteredList
		} else {
			array = this.activeTab.updates
		}

		for (let swUpdate of array) {
			let el = {
				type: swUpdate.dev_type,
				os: swUpdate.os_ver,
				min_version: swUpdate.min_build_num,
				update_description: swUpdate.description,
				build: swUpdate.build_ver,
				build_num: swUpdate.build_num,
				update_date: this.session.formatDateTime(swUpdate.release_date),
				filename: swUpdate.url,
			}

			listToExport.push(el)
		}
		Util.downloadCSV(listToExport, filename)
	}

	private deleteUpdt(updt) {
		if (!(this.session.isGod() || this.session.isManager())) {
			Util.debug('DL Utente non abilitato, act: ' + this.currentAction)
			return
		}

		this.session.deleteSwUpdate(updt.id)
		alert('OK, SWupdate deleted!') // 23.03.2022
	}

	openModalOld(updt: SwUpdate) {
		if (!(this.session.isGod() || this.session.isManager())) {
			Util.debug('DL Utente non abilitato, act: ' + this.currentAction)
			return
		}
		if (updt) {
			this.currentAction = Config.ACT_EDIT
		} else {
			this.currentAction = Config.ACT_CREATE
		}

		this.setCurrentUpdt(updt)
		Util.debug('COMP (UpdateModal) going to open it... action: ' + this.currentAction)

		this.currentModal = this.modalService.open(UpdatesModalContent, { size: 'xl' })
		this.currentModal.componentInstance.parent = this // 24.11.2021 per poi chiamare session o altre globali
		this.currentModal.componentInstance.currUpdate = this.currentUpdt

		this.currentModal.result.then(
			(setUpdt) => {
				Util.debug('COMP - After modal closed: ' + setUpdt)

				if (setUpdt) {
					// 07.10.2022
					// this.submitForm(setUpdt);
					Util.debug('submit')
					this.loadUpdateList()
				}
			},
			(reason) => {}
		)
	}

	openModal() {
		this.currentModal = this.modalService.open(WizardModal, { size: 'lg', keyboard: false, backdrop: 'static' })
		this.currentModal.componentInstance.device = this.activeTab.label
		this.currentModal.componentInstance.alreadyLoadedFiles = this.activeTab.alreadyLoaded

		this.currentModal.result
			.then((setUpdt) => {
				// console.log(setUpdt)
				this.loadUpdateList()
			})
			.catch((err) => {
				// console.log(err)
			})
	}

	setCurrentUpdt(elem) {
		if (elem) {
			this.currentUpdt = elem
		} else {
			this.clearCurrentUpdt()
		}
	}

	clearCurrentUpdt() {
		this.currentUpdt = new SwUpdate(null)
	}
}
