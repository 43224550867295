<!-- <div class="loading-container" [hidden]="!session.isLogging()">
	<div class="tail-spin">&nbsp;</div>
</div>

<div class="login">
	<div class="topview container-fluid" [hidden]="session.isLogged() && session.isLogging()">
		<div class="row justify-content-center">
			<div class="login-container">
				<div class="panel panel-default">
					<div class="login-title-container">
						<h3>{{ 'LOGIN.PAGE_TITLE' | translate }}</h3>
					</div>

					<div class="panel-body">
						loginForm.$valid &&  -->
<!-- <form role="form" #loginForm="ngForm" name="lForm" (ngSubmit)="login(loginForm)" novalidate>
							<div class="form-group" *ngIf="customer != ''">
								<span>Welcome {{ customer }} user!</span>
							</div>

							<div class="form-group">
								<label for="username">{{ 'LOGIN.USERNAME' | translate }}</label>
								<input type="text" class="form-control" name="username" id="username" placeholder="{{ 'LOGIN.USERNAME_HINT' | translate }}" [(ngModel)]="guestUser.username" #username="ngModel" required /> -->
<!-- ="login.username" -->
<!-- <div class="error-wrapper" [hidden]="!(username.touched && username.invalid)">
									<p class="error-message">{{ 'MESSAGE.REQUIRED' | translate }}</p>
								</div>
							</div> -->
<!-- <div class="form-group">
								<label for="password">{{ 'LOGIN.PASSWORD' | translate }}</label>
								<fa-icon [icon]="showPwd ? faEyeOpen : faEyeClosed" (click)="clickTogglePassword()" aria-hidden="true" class="show-psw-icon"></fa-icon>
								<input [type]="showPwd ? 'text' : 'password'" class="form-control" name="password" id="password" placeholder="{{ 'LOGIN.PASSWORD_HINT' | translate }}" [(ngModel)]="guestUser.password" #password="ngModel" required />

								<div class="error-wrapper" [hidden]="!(password.touched && password.invalid)">
									<p class="error-message ng-hide">{{ 'MESSAGE.REQUIRED' | translate }}</p>
								</div>
							</div>
							<a class="float_right forgotpwd" (click)="openRecoverPwdModal()" ngbTooltip="{{ 'LOGIN.LINK_RECOVER_PWD_HINT' | translate }}" tooltip-placement="bottom"> {{ 'LOGIN.LINK_RECOVER_PWD' | translate }} </a>
							<div class="button-container">
								<button type="submit" class="btn btn-primary" [disabled]="loginForm.invalid || warning">{{ 'LOGIN.LOGIN_BUTTON' | translate }}</button>
							</div>
						</form>
						<div class="error-login-container">
							<p class="error-login ng-hide">{{ 'SESSION_STATUS.' + session.getStatus() | translate }}</p> -->
<!-- [hidden]="session.getStatus()<3" -->
<!-- </div>
					</div>
				</div>
				<div [hidden]="!oldFeBuild">
					<div class="alert alert-warning d-flex align-items-center warn-message" role="alert">
						<p style="white-space: pre-line">
							<fa-icon [icon]="faTriangleExclamation" aria-hidden="true"></fa-icon>
							{{ oldFeMessage }}
						</p>
					</div>
				</div>
			</div>
		</div>

	</div>
</div> -->

<div class="login container-fluid" [hidden]="session.isLogged() && session.isLogging()">
	<div class="row d-flex justify-content-center align-items-center" style="min-height: 90vh">
		<div class="col-md-6 col-xl-5 col-xxl-3 col-12 position-relative p-xs-0">
			<div class="modal-container text-center shadow d-flex flex-column justify-content-center position-relative">
				<!-- <div style="width: 100%"> -->
				<div class="logo mb-5"></div>
				<div class="mb-5">
					<p class="fw-bold uppercase">{{ "LOGIN.PAGE_TITLE" | translate }}</p>
				</div>

				<div class="error-login-container" *ngIf="loginStatus > 2">
					<p class="error-login">{{ 'SESSION_STATUS.' + session.getStatus() | translate }}</p>
				</div>

				<loader [activateSpinner]="loginStatus == 1"></loader>

				<form class="login-form" role="form" [formGroup]="loginForm" (ngSubmit)="login()" novalidate [ngClass]="loginStatus == 1 ? '' : 'show' ">
					<!-- USERNAME -->
					<div class="form-group mt-3 position-relative">
						<!-- <label for="username">{{ "LOGIN.USERNAME" | translate }}</label> -->
						<input type="text" class="form-control" name="username" id="username" #username placeholder="{{ 'LOGIN.USERNAME_HINT' | translate }}" formControlName="username" />
						<span class="error-message" [hidden]="!(loginForm.get('username').touched && loginForm.get('username').invalid)">{{ "MESSAGE.REQUIRED" | translate }}</span>
					</div>

					<!-- PASSWORD -->
					<div class="form-group position-relative mt-4">
						<fa-icon [icon]="showPwd ? faEyeOpen : faEyeClosed" (click)="clickTogglePassword()" aria-hidden="true" class="show-psw-icon"></fa-icon>
						<input [type]="showPwd ? 'text' : 'password'" class="form-control" name="password" id="password" [ngClass]="showPwd ? '' : 'hide' " placeholder="{{ 'LOGIN.PASSWORD_HINT' | translate }}" formControlName="password" />
						<span class="error-message ng-hide" [hidden]="!(loginForm.get('password').touched && loginForm.get('password').invalid)"> {{ "MESSAGE.REQUIRED" | translate }} </span>
					</div>

					<div class="button-container mt-5">
						<button type="submit" class="btn btn-danger" [disabled]="loginForm.invalid || loginStatus == 2">{{ "LOGIN.LOGIN_BUTTON" | translate }}</button>
					</div>
				</form>

				<div class="mt-5" *ngIf="loginStatus != 1">
					<a class="forgotpwd" (click)="openRecoverPwdModal()" ngbTooltip="{{ 'LOGIN.LINK_RECOVER_PWD_HINT' | translate }}" placement="bottom"> {{ 'LOGIN.LINK_RECOVER_PWD' | translate }} </a>
				</div>

				<div class="mt-4" [hidden]="!oldFeBuild">
					<div class="alert alert-warning warn-message" role="alert">
						<p style="white-space: pre-line" class="text-start">
							<fa-icon [icon]="faTriangleExclamation" aria-hidden="true"></fa-icon>
							{{ oldFeMessage }}
						</p>
					</div>
				</div>
				<!-- </div> -->
			</div>
		</div>
	</div>
</div>
