<div class="panel panel-modal panel-default ng-cloak position-relative">
	<!-- modal-panel -->
	<div class="panel-heading">
		<label [hidden]="currentAction != 'support'" class="panel-title">{{"MISC.VIEW_TITLE" | translate}} - {{currentDoctor.username}}</label>
		<label [hidden]="currentAction != 'modify'" class="panel-title">{{"DOCTOR.MODAL_TITLE_UPDATE" | translate}} - {{currentDoctor.username}}</label>
		<div class="btn-close-container-pos">
			<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
		</div>
	</div>

	<div class="topview container-fluid" [hidden]="!session.isLoadingDoctor()"><mat-spinner></mat-spinner>{{"MISC.LOADING" | translate}}</div>

	<div class="panel-body" *ngIf="session.hasLoadedDoctor(currentDoctor.id)">
		<form #doctorForm="ngForm" name="doctorForm" novalidate>
			<div class="row">
				<div class="twothird-container fields-container">
					<!-- poi ci sono i settings -->

					<div class="row">
						<div class="form-group col-4">
							<label for="first-name">{{"DOCTOR.MODAL_FIRST_NAME" | translate}}</label>
							<input type="text" class="form-control" readonly name="firstName" id="first-name" [(ngModel)]="currentDoctor.firstName" />
						</div>

						<div class="form-group col-4">
							<label for="last-name">{{"DOCTOR.MODAL_LAST_NAME" | translate}}</label>
							<input type="text" class="form-control" readonly name="lastName" id="last-name" [(ngModel)]="currentDoctor.lastName" />
						</div>

						<div class="form-group col-4">
							<label for="code">{{"DOCTOR.MODAL_CODE" | translate}}</label>
							<input type="text" class="form-control" readonly name="code" id="code" [(ngModel)]="currentDoctor.code" />
						</div>
					</div>

					<div class="row">
						<div class="form-group col-4">
							<label for="email">{{"DOCTOR.MODAL_EMAIL" | translate}}</label>
							<input type="text" class="form-control" readonly name="email" id="email" [(ngModel)]="currentDoctor.mainAddress.ref_email" />
						</div>
						<div class="form-group col-4">
							<label for="phone">{{"DOCTOR.PHONE" | translate}}</label>
							<input type="text" class="form-control" readonly name="phone" id="phone" [(ngModel)]="currentDoctor.mainAddress.phone1" />
						</div>

						<div class="form-group col-4" *ngIf="isCountryReadonly()">
							<label for="country">{{"DOCTOR.MODAL_COUNTRY" | translate}}</label>
							<input type="text" class="form-control" readonly name="country" id="country" [value]="getReadOnlyCountry()" />
						</div>

						<div class="form-group col-4" *ngIf="!isCountryReadonly()" [hidden]="session.isSupport()">
							<label for="country">{{"DOCTOR.MODAL_COUNTRY" | translate}}</label>
							<searchdropdown *ngIf="countries != null && countries.length > 0" class="form-control normalcase" style="padding: 0" [countries]="countries" (outcountry)="selCountry($event)" aria-required="true" required> </searchdropdown>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-4" *ngIf="session.isLevel3()">
							<label for="subtype">{{"DOCTOR.SUBTYPE" | translate}}</label>
							<input type="text" class="form-control" *ngIf="currentDoctor.isMini()" name="subtype" readonly [(ngModel)]="currentDoctor.user_subtype " />
							<select class="form-control" *ngIf="!currentDoctor.isMini()" [(ngModel)]="currentDoctor.user_subtype" name="subtype" [disabled]="session.isSupport()">
								<option *ngFor="let opt of getCurrentSubtypes()" [ngValue]="opt">{{opt}}</option>
							</select>
							<!-- session.usrSubtypes -->
						</div>

						<div class="form-group col-4">
							<label for="organization">{{"CREATE_USER.ORGANIZATION" | translate}}</label>
							<input type="text" class="form-control" id="organization" name="organization" [(ngModel)]="currentDoctor.mainAddress.organization" readonly />
						</div>
						<div class="form-group col-4" *ngIf="currentDoctor.isOptician() || currentDoctor.isDoctor()">
							<label for="vat">{{"CREATE_USER.VAT" | translate}}</label>
							<div class="vat-input-container">
								<div class="country-code">
									<p *ngIf="currentDoctor.mainAddress.vat && currentDoctor.mainAddress.vat !== ''">{{alpha2Country}}</p>
								</div>
								<input type="text" class="form-control vat" id="vat" name="vat" [(ngModel)]="currentDoctor.mainAddress.vat" readonly />
							</div>
						</div>
						<div class="form-group col-4" *ngIf="currentDoctor.isDoctor()">
							<!-- (currentDoctor.user_type == 'Doctor') -->
							<label for="order">{{"PROFILE.LICENCE_NUM" | translate}}</label>
							<input type="text" class="form-control" maxlength="50" name="order" id="order" [(ngModel)]="currentDoctor.order_reg_num " [readonly]="session.isSupport()" />
						</div>

						<div class="form-group col-2" *ngIf="(currentDoctor.user_type != 'Doctor' && currentDoctor.user_type != 'Optician') ">&nbsp;</div>

						<div class="form-group col-2" [hidden]="!session.isGod()">
							<label for="owner">{{"DOCTOR.MODAL_OWNER" | translate}}</label>
							<input type="text" class="form-control" readonly name="owner" id="owner" [(ngModel)]="currentDoctor.created_by" />
							<!-- [readonly]="!session.isGod() || session.isSupport() || currentDoctor.isMini()"-->
						</div>

						<div class="form-group col-4" [hidden]="!currentDoctor.superSalt">
							<label for="ownerP">{{"DOCTOR.C_HEAD" | translate}}</label>
							<input type="text" class="form-control" readonly name="ownerP" id="ownerP" [value]="currentDoctor.superSalt" />
						</div>

						<div class="form-group col-2" *ngIf="!this.session.isSuperB()">
							<label for="test">{{"CREATE_USER.TEST" | translate}}</label>
							<select class="form-control" name="test" id="test" [(ngModel)]="currentDoctor.is_test" [disabled]="session.isSupport()">
								<!--  ng-options="opt as opt for opt in ['Y', 'N']" -->
								<option *ngFor="let opt of session.yesNoOptions" [ngValue]="opt">{{opt}}</option>
							</select>
						</div>
					</div>

					<div class="row">
						<div class="form-group twothird-container">
							<label for="logo">{{"DOCTOR.MODAL_LOGO" | translate}}</label>
							<br />
							<i> Loaded ? {{currentDoctor.hasLogo}} {{currentDoctor.logo_name}} </i> [only *.png 65 x 25 ]
							<!-- 20.08.2018 add png -->
							<input type="file" name="logo" id="logo" accept=".png" [hidden]="session.isSupport()" />
							<p *ngIf="currentDoctor.hasLogo=='Y'" [hidden]="session.isSupport()"><input type="checkbox" id="delete_logo" name="delete_logo" /> delete Existing Logo</p>
						</div>
					</div>

					<div class="row">&nbsp;</div>
					<!-- spazio vuoto -->

					<div class="row">
						<div *ngIf="!currentDoctor.isActive" class="form-group col-4">
							<span>Created: {{session.formatDateTime(currentDoctor.creationDate)}}</span>
						</div>
						<div class="form-group half-container">
							<span *ngIf="currentDoctor.isActive">{{"CREDITS.ACTIVATED" | translate}}: {{session.formatDateTime(currentDoctor.subscriptionTime)}}</span>
							<span *ngIf="!currentDoctor.isActive"> INACTIVE</span>
						</div>
						<!-- empty 
            <div class="form-group col-4">
              <span>Last Visit: {{session.formatDateTime(currentDoctor.lastExamDate)}}</span>
            </div> -->
					</div>

					<!-- solo display info -->
					<div class="row">
						<div class="form-group col-4">
							<!-- User Type: -->
							<label>{{"DOCTOR_LIST.TYPE" | translate}}</label>
							<br /><span>{{currentDoctor.user_type}}</span>
						</div>
						<div class="form-group col-4">
							<label>{{"DOCTOR_LIST.SPACE_DB" | translate}}:</label>
							<br /><span>{{currentDoctor.getUsedSpace()}}</span>
						</div>

						<!-- 
              <div class="form-group col-4">
                <label>{{"CREATE_USER.SALE_PLAN" | translate}}:</label>                        
                <br><span>{{session.getSalePlan(currentDoctor.sale_plan_id).name}}</span>     
              </div>
              -->
					</div>

					<div class="row" *ngIf="this.session.isAdmin() || this.session.isSupport()">
						<div class="col-12">
							<label>LAST SEEN</label>

							<table class="table lastseen-table">
								<thead class="header-table">
									<tr>
										@for(val of lastSeen; track val){
										<th scope="col">
											<span ngbTooltip="{{'DOCTOR.LAST_SEEN.TOOLTIP.' + val.label | translate}}"> {{'DOCTOR.LAST_SEEN.TITLE.' + val.label | translate}} </span>
										</th>
										}
									</tr>
								</thead>
								<tbody>
									<tr>
										@for(val of lastSeen; track val){
										<td>@if(val.value){ {{session.formatDateTime(val.value)}} } @else { - }</td>
										}
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div class="row" *ngIf="this.session.isAdmin() || this.session.isSupport()">
						<!-- *ngIf="(!currentDoctor.isDoctor() && !currentDoctor.isMini()) || currentDoctor.isSuperB()" -->

						<div class="list-group" *ngIf="currentDoctor.isOptician() && !currentDoctor.isMini()" [hidden]="currentDoctor.specialists.length <= 0" style="max-height: 300px; overflow-y: scroll">
							<label class="list-group-item active">{{'DOCTOR.MODAL_RELATIONS' | translate}}:</label>
							<div *ngFor="let spec of currentDoctor.specialists" class="list-group-item" [hidden]="spec.user_type=='Distributor' || (spec.user_type === 'Specialist' && spec.user_subtype === 'Mini' )">
								<b>ID: </b>
								<span class="pe-5">{{spec.distributor_id}} [{{spec.user_type}}] </span>
								<b>Name: </b>
								<span>{{spec.display_name}}</span> ({{spec.getSubType()}})
							</div>
						</div>

						<div class="list-group" *ngIf="currentDoctor.isGroupB()" [hidden]="doctorsFilterEdit.length <= 0" style="max-height: 300px; overflow-y: scroll">
							<label class="list-group-item active">{{'NAVBAR.COLLEAGUES' | translate}}:</label>
							<div *ngFor="let docF of doctorsFilterEdit" class="list-group-item">
								<b>ID: </b>
								<span class="pe-5">{{docF.id}} [{{docF.user_type}}]</span>
								<b>username: </b>
								<span>{{docF.username}}</span>
							</div>
						</div>
					</div>

					<!-- vedi anche modal dedicato  03.10.2022 -->
					<div class="row pt-2" *ngIf="this.session.isAdmin()" [hidden]="currentDoctor.userDevices.length <= 0">
						<div class="list-group" style="max-height: 300px; overflow-y: scroll">
							<label class="list-group-item text-white bg-dark">{{"NAVBAR.DEVICES" | translate}}:</label>
							<div *ngFor="let dev of currentDoctor.userDevices" class="list-group-item">
								<b>Device id: </b>
								<span class="pe-5">{{dev.device_id}} </span>
								<b>From ip: </b>
								<span class="pe-5">{{dev.caller_ip}}</span>
								<b>Last used: </b>
								<span>{{session.formatDateTime(dev.last_use)}} </span>
							</div>
						</div>
					</div>
				</div>

				<!-- chiude twothird container con edit doct -->

				<div class="col-4 fields-container">
					<!-- style="float:right;" -->
					<div class="row" *ngIf="!this.session.isSuperB()">
						<div class="settings">
							<p>{{"SETTINGS.SETTINGS_LABEL" | translate}}</p>
							<p></p>

							<div class="row">
								<div class="half-container">
									<label for="upload">{{"SETTINGS.UPL_EXAMS" | translate}}</label>
								</div>
								<div class="half-container">
									<select class="form-control" name="upload" [(ngModel)]="currentDoctor.settings.upload_exams" [disabled]="session.isSupport() && !session.isSuperSupport()">
										<!-- ng-options="opt as opt for opt in ['Y', 'N', 'D']" -->
										<option *ngFor="let opt of session.yesNoOptions" [ngValue]="opt">{{opt}}</option>
										<option value="D">D</option>
									</select>
								</div>
							</div>

							<div class="row">
								<div class="half-container">
									<label for="download">{{"SETTINGS.SYNCR_PATS" | translate}}</label>
								</div>
								<div class="half-container">
									<select class="form-control" name="download" [(ngModel)]="currentDoctor.settings.syncr_patients" [disabled]="session.isSupport() && !session.isSuperSupport()">
										<option *ngFor="let opt of session.yesNoOptions" [ngValue]="opt">{{opt}}</option>
									</select>
								</div>
							</div>

							<div class="row">
								<div class="half-container">
									<label for="updt">{{"SETTINGS.UPDATES" | translate}}</label>
								</div>
								<div class="half-container">
									<select class="form-control" name="updt" [(ngModel)]="currentDoctor.settings.updates_enabled" [disabled]="session.isSupport() && !session.isSuperSupport()">
										<option *ngFor="let opt of session.yesNoOptions" [ngValue]="opt">{{opt}}</option>
									</select>
								</div>
							</div>

							<div class="row">
								<div class="col-6">
									<label for="group">{{ 'DOCTOR.ANAMN_GROUP' | translate }}</label>
								</div>
								<div class="col-6">
									<input type="number" maxlength="5" class="form-control text-right" name="group" id="group" min="0" [(ngModel)]="currentDoctor.settings.anamnesis_group" [readonly]="session.isSupport()" />
								</div>
							</div>

							<div class="row">
								<div class="col-6">
									<label for="impactgroup">{{ 'DOCTOR.IMPACT_ANAM_GROUP' | translate }}</label>
								</div>
								<div class="col-6">
									<input type="number" pattern="^0|[1-9][0-9]{3}$" maxlength="5" class="form-control text-right" name="impactgroup" id="impactgroup" min="0" [(ngModel)]="currentDoctor.settings.impact_anamn_group" [readonly]="session.isSupport()" />
								</div>
							</div>

							<div class="row">
								<div class="half-container">
									<label for="days">{{"DOCTOR.MODAL_SETT_DAYS" | translate}}</label>
								</div>
								<div class="half-container">
									<input type="number" maxlength="10" class="form-control text-right" name="days" id="days" min="0" [(ngModel)]="currentDoctor.settings.offline_days" [readonly]="session.isSupport()" required />
								</div>
							</div>

							<div class="row">
								<div class="half-container">
									<label for="shared_folder">{{"DOCTOR.MODAL_SETT_SHARE_FOLDER" | translate}}</label>
								</div>
								<div class="half-container">
									<select class="form-control" name="shared_folder" [(ngModel)]="currentDoctor.settings.shared_folder" [disabled]="session.isSupport() && !session.isSuperSupport()">
										<option *ngFor="let opt of session.yesNoOptions" [ngValue]="opt">{{opt}}</option>
									</select>
								</div>
							</div>

							<div class="row">
								<div class="half-container">
									<label for="remote">{{"SETTINGS.REMOTE_SUPPORT" | translate}}</label>
								</div>
								<div class="half-container">
									<select class="form-control" name="remote" [(ngModel)]="currentDoctor.settings.remote_support" [disabled]="session.isSupport() && !session.isSuperSupport()">
										<option *ngFor="let opt of session.yesNoOptions" [ngValue]="opt">{{opt}}</option>
									</select>
								</div>
							</div>

							<div class="row" *ngIf="!session.isSuperSupport()">
								<div class="half-container">
									<label for="telerefr">{{"SETTINGS.TELEREFRACT" | translate}}</label>
								</div>
								<div class="half-container">
									<select class="form-control" name="telerefr" [(ngModel)]="currentDoctor.settings.telerefract" [disabled]="session.isSupport()">
										<option *ngFor="let opt of session.yesNoOptions" [ngValue]="opt">{{opt}}</option>
									</select>
								</div>
							</div>

							<div class="row">
								<div class="half-container">
									<label for="dicom_level">{{"SETTINGS.DICOM_LEVEL" | translate}}</label>
								</div>
								<div class="half-container">
									<select class="form-control" name="dicom_level" [(ngModel)]="currentDoctor.settings.dicom_level" [disabled]="session.isSupport() && !session.isSuperSupport()">
										<option *ngFor="let opt of session.dicomLevels" [ngValue]="opt.val">{{opt.descr}}</option>
									</select>
								</div>
							</div>

							<div class="row">
								<div class="half-container">
									<label for="recovery">{{"SETTINGS.RECOVERY" | translate}}</label>
								</div>
								<div class="half-container">
									<!-- <input type="text" class="form-control" readonly name="recovery" [(ngModel)]="currentDoctor.settings.recovery" /> -->
									<select class="form-control" name="recovery" [(ngModel)]="currentDoctor.settings.recovery" [disabled]="session.isSupport() && !session.isSuperSupport()">
										<option *ngFor="let opt of session.yesNoOptions" [ngValue]="opt">{{ opt }}</option>
									</select>
								</div>
							</div>

							<div class="row">
								<div class="half-container">
									<label for="ai_type">AI Type</label>
								</div>
								<div class="half-container">
									<select class="form-control" name="ai_type" [(ngModel)]="currentDoctor.settings.ai_type" [disabled]="session.isSupport()">
										<option *ngFor="let opt of session.aiTypes" [ngValue]="opt.val">{{opt.descr}}</option>
									</select>
								</div>
							</div>

							<!-- 12.04.2023 not used ? 
							<div class="row">
								<div class="half-container">
									<label for="mosaic">{{"SETTINGS.MOSAIC" | translate}}</label>
								</div>
								<div class="half-container">
									<input type="text" class="form-control" readonly name="mosaic" [(ngModel)]="currentDoctor.settings.mosaic" />
								</div>
							</div>

							<div class="row">
								<div class="half-container">
									<label for="cupToDisc">{{"SETTINGS.CUP_TO_DISC" | translate}}</label>
								</div>
								<div class="half-container">
									<input type="text" class="form-control" readonly name="cupToDisc" [(ngModel)]="currentDoctor.settings.cup_to_disc" />
								</div>
							</div>
            -->

							<div class="row">
								<div class="half-container">
									<label for="diagn_grp">{{"SETTINGS.DIAGNOSIS_GRP" | translate}}</label>
								</div>
								<div class="half-container" *ngIf="canEditDiagnGroup()">
									<select class="form-control" name="diagn_grp" [(ngModel)]="currentDoctor.settings.diagnosis_group" [disabled]="session.isSupport()">
										<option *ngFor="let opt of session.diagnosisGroups" [ngValue]="opt.val">{{opt.descr}}</option>
									</select>
								</div>
								<div class="half-container" *ngIf="!canEditDiagnGroup()">
									<input type="text" readonly name="diagn_grp" class="form-control" [(ngModel)]="myDiagnGroup" />
									<!-- currentDoctor.getSzDiagnosisGrp() -->
								</div>
							</div>

							<div class="row">
								<div class="half-container">
									<label for="brand">{{"SETTINGS.BRAND" | translate}}</label>
								</div>
								<div class="half-container">
									<select class="form-control" name="brand" [(ngModel)]="currentDoctor.settings.brand" [disabled]="session.isSupport()">
										<option *ngFor="let opt of session.brands" [ngValue]="opt">{{opt}}</option>
									</select>
								</div>
							</div>

							<div class="row" *ngIf="!session.isSuperSupport()">
								<div class="half-container">
									<label for="ecommerce">{{ 'SETTINGS.ECOMMERCE' | translate }}</label>
								</div>
								<div class="half-container">
									<select class="form-control" name="ecommerce" [(ngModel)]="currentDoctor.settings.ecommerce" [disabled]="session.isSupport()">
										<option *ngFor="let opt of session.yesNoOptions" [ngValue]="opt">{{opt}}</option>
									</select>
								</div>
							</div>

							<div class="row" *ngIf="currentDoctor.isOptician() && !session.isSuperSupport()">
								<div class="col-6">
									<label for="free_trial_available">{{ 'SETTINGS.FREE_TRIAL_ABAILABLE' | translate }}</label>
								</div>
								<div class="col-6">
									<select name="free_trial_available" [(ngModel)]="currentDoctor.settings.free_trial_available" class="form-control" [disabled]="session.isSupport()">
										<option *ngFor="let opt of session.freeTrialOptions" [ngValue]="opt">{{ opt }}</option>
									</select>
								</div>

								<div class="col-6">
									<label>{{ 'SETTINGS.SELF_REGISTRATION' | translate }}</label>
								</div>
								<div class="col-6">
									<select name="sel_reg_available" [(ngModel)]="currentDoctor.settings.p_self_reg" class="form-control" [disabled]="session.isSupport()">
										<option *ngFor="let opt of session.yesNoOptions" [ngValue]="opt">{{ opt }}</option>
									</select>
								</div>
							</div>

							<div class="row" [hidden]="currentDoctor.user_subtype != 'Super' ">
								<div class="half-container">
									<label for="maxB">{{"DOCTOR.MODAL_SETT_MAX_COLLEAGUES" | translate}}</label>
								</div>
								<div class="half-container">
									<input type="text" class="form-control text-right" maxlength="10" name="maxB" id="maxB" placeholder="{{'DOCTOR.MODAL_SETT_MAX_COLLEAGUES' | translate}}" [(ngModel)]="currentDoctor.settings.max_colleagues" [readonly]="session.isSupport()" />
								</div>
							</div>
						</div>
					</div>

					<div class="row">&nbsp;</div>
				</div>
				<!-- chiude settings -->
			</div>

			<div class="spacer"></div>

			<div class="row fields-container">
				<!-- <div class="twothird-container">
					<div class="row">
						// password check
						<div class="col-4" *ngIf="!session.isSuperB() && !session.isSupport()">
							<input type="password" class="form-control" name="password" id="password" #fn="ngModel" placeholder="{{'MESSAGE.MNGR_PWD_HINT' | translate}}" [(ngModel)]="dispPassword" required />
							<div class="error-wrapper error-message-container">
								<p class="error-message" [hidden]="!(fn.touched && fn.invalid)">{{'MESSAGE.REQUIRED' | translate}}</p>
								&nbsp;
								<p class="error-message text-danger" [hidden]="!(wrongPwd)">{{'MESSAGE.WRONG_PWD' | translate}}</p>
							</div>
						</div>
					</div>
				</div> -->

				<div class="col-4">
					<div class="row">
						<div class="half-container" *ngIf="!session.isSupport() || session.isSuperSupport()">
							<button type="submit" [disabled]="doctorForm.invalid" (click)="editCurrentDoct()" class="btn btn-primary">{{'BUTTONS.SAVE' | translate}}</button>
						</div>
						<div class="half-container">
							<button type="clear" (click)="activeModal.dismiss('undo');" class="btn btn-secondary">{{"BUTTONS.CLOSE" | translate}}</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
