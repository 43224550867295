import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { ImpactReportData, ImpactEyeData, ImpactTopic, ImpactSection } from '../../models/impact.model'
import { SessionService } from 'src/app/service/session.service'
import { ImpactService } from 'src/app/service/impact.service'
import { CryptoUtilsService } from 'src/app/service/crypto-utils.service'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { TopicHistoryModal } from './topic-history.modal/topic-history.modal'
import { Patient } from 'src/app/models/patient.model'

@Component({
	selector: 'impact-modal',
	templateUrl: './impact.modal.html',
	styleUrls: ['./impact.modal.scss'],
})
export class ImpactModal implements OnInit {
	@Input() patient: Patient

	impactReportData: ImpactReportData
	selectedDate: string
	oldestAvailableDate: string
	availableDates: string[]
	leftRefractionEyeData: ImpactEyeData
	rightRefractionEyeData: ImpactEyeData
	showRefraction: boolean
	isLoading: boolean
	topicHistoryModal: NgbModalRef

	faChevronDown = faChevronDown

	constructor(
		public session: SessionService,
		public cryptoUtils: CryptoUtilsService,
		public impactService: ImpactService,
		public activeModal: NgbActiveModal,
		public modalService: NgbModal
	) {
		this.impactReportData = null
		this.availableDates = []
		this.selectedDate = null
		this.oldestAvailableDate = null
		this.leftRefractionEyeData = null
		this.rightRefractionEyeData = null
		this.showRefraction = false
		this.isLoading = false
	}

	ngOnInit() {
		this.isLoading = true
		this.impactService.getImpactAvailableDates(this.patient.id).then((res) => {
			this.availableDates = res.impact_available_dates
			this.selectedDate = this.availableDates[0]
			this.oldestAvailableDate = new Date(Math.min(...this.availableDates.map((date) => new Date(date).getTime()))).toISOString()
			this.impactService.getImpactReport(this.patient.id, this.selectedDate).then((data) => {
				this.handleImpactReportData(data)
				this.isLoading = false
			})
		})
	}

	onChangeDate(event: Event) {
		this.isLoading = true
		this.selectedDate = (event.target as HTMLInputElement).value
		this.impactService.getImpactReport(this.patient.id, this.selectedDate).then((data) => {
			this.handleImpactReportData(data)
			this.isLoading = false
		})
	}

	handleImpactReportData(data: ImpactReportData) {
		this.impactReportData = data
		const refractionData = this.impactReportData.impact.sections.find((el) => el.title === 'refraction')
		if (refractionData) {
			this.leftRefractionEyeData = refractionData.topics[0].eyes.find((el) => el.eye === 'left')
			this.rightRefractionEyeData = refractionData.topics[0].eyes.find((el) => el.eye === 'right')
		}
	}

	formatDateString(s: string): string {
		return this.session.formatDate(new Date(s))
	}

	getMaxDecimals(a: number, b: number, c: number): number {
		// count decimal digits
		const countDecimals = (num: number): number => {
			const numString = num.toString()
			if (numString.includes('.')) {
				return numString.split('.')[1].length
			}
			return 0 // no decimals
		}

		const decimalsA = countDecimals(a)
		const decimalsB = countDecimals(b)
		const decimalsC = countDecimals(c)

		return Math.max(decimalsA, decimalsB, decimalsC)
	}

	openTopicHistory(section: ImpactSection, topic: ImpactTopic) {
		this.topicHistoryModal = this.modalService.open(TopicHistoryModal, {
			size: 'lg',
			windowClass: 'impactModalClass',
		})

		this.topicHistoryModal.componentInstance.section = section.title
		this.topicHistoryModal.componentInstance.topic = topic.title
		this.topicHistoryModal.componentInstance.normRange = { min: topic.range_low, max: topic.range_high }
		this.topicHistoryModal.componentInstance.patientId = this.patient.id
		this.topicHistoryModal.componentInstance.day = this.selectedDate
	}

	close() {
		this.activeModal.dismiss()
	}
}
