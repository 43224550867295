import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { ActivatedRoute, Router } from '@angular/router'
import { FormControl, FormGroup } from '@angular/forms'

//import { Observable } from 'rxjs'
//import { map, startWith } from 'rxjs/operators'

import { faTrashAlt, faEdit, faQuestionCircle, faFolderOpen, faFileLines, faClock, faClipboard } from '@fortawesome/free-regular-svg-icons'
import {
	faRotate,
	faTriangleExclamation,
	faCalendar,
	faCheck,
	faXmark,
	faCircleExclamation,
	faChevronDown,
	faCaretDown,
	faCircleCheck,
	faSortDown,
	faPen,
} from '@fortawesome/free-solid-svg-icons'

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
import { DataModelService, DataStatus } from '../../service/data-model.service'

import { PatientModalContent } from './patient.modal'
import { DeleteModalContent, itemType } from '../delete/delete.modal'

import { Util } from '../../models/util.model'
import { Patient } from '../../models/patient.model'
import { Address } from '../../models/address.model'
import { Doctor } from '../../models/doctor.model'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator'

import { MatSort, Sort } from '@angular/material/sort'
import { TablePrefs } from '../../models/settings.model'
import { AgrStatus, Agreement } from 'src/app/models/agreement.model'

import { SaleInfo } from 'src/app/models/salePlan.model'

import { User } from 'src/app/models/user.model'
import { AppToastService } from 'src/app/service/toast.service'
import { Distrib, Relation } from 'src/app/models/specialist.model'

import { Observable, Subscription } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

import { MatDatepicker } from '@angular/material/datepicker'

import { ToastOptions } from 'src/app/models/toast.model'
import { QRCode } from 'src/app/elements/qrCode/qrCode.modal'
import { AnamnesisService } from 'src/app/service/anamnesis.service'
import { VisitListComponent } from '../visits/visitList.component'
import { PatientReportModal } from './reports.modal'
import { ImpactModal } from '../impact/impact.modal'

// add moment for translate the value of datepicker obj
import * as _moment from 'moment'
import { AnamnesisModal, AnamnesisMode } from '../anamnesis/anamesis.modal'
import { ConfirmModal } from 'src/app/elements/confirm/confirm.modal'
const moment = _moment

@Component({
	selector: 'patients-list',
	templateUrl: './patientList.component.html',
	styleUrls: ['./patientList.component.scss'],
})
// @Injectable({ providedIn: 'root' })
export class PatientListComponent implements OnInit, OnDestroy {
	patientList: MatTableDataSource<Patient> //14.10.22
	patientListRaw: Patient[] // Array di patients che arriva dal server18.10.22
	patList: Patient[] // mi serve per il filtro
	newPatient: Patient
	// newPatientId: any // from modal

	patientListStatusSubscribe: Subscription
	patientListChanged: Subscription

	@ViewChild(MatTable) table: MatTable<any> // 17.10.22 serve per poter rinfrescare la tabella dopo averla modificata
	@ViewChild(MatPaginator) paginator: MatPaginator //14.10.22
	@ViewChild(MatSort) sort: MatSort //14.10.22
	@ViewChild('filter') input: ElementRef //14.10.22

	//these viewChild are necessary to set default value if necesary. at the moment not used
	@ViewChild('picker1') RequestDatePicker: MatDatepicker<any>
	@ViewChild('picker2') DueDatePicker: MatDatepicker<any>
	@ViewChild('picker3') ReportDatePicker: MatDatepicker<any>

	displayedColumns: string[]

	visitListModal: NgbModalRef
	reportListModal: NgbModalRef
	impactModal: NgbModalRef
	anamnesisModal: NgbModalRef

	medicalAnamnesi: boolean
	impactEnable: boolean

	sortStatus: Sort
	pageStatus: PageEvent
	patientPref: TablePrefs
	localStorageName: string // in realtá é la sessionStorage e localStortageName

	birthYear = null

	itemsPerPage: number
	totalPages = 1 // per paginazione tabella
	currentPage = 1
	usrItemsPerPage
	pages: number[]
	totPat: number

	// currentPatient: Patient
	currUser: User
	currUserId: number
	isMiniC: boolean

	currDoct: Doctor
	// patientMainAddr: Address // 23.03.2020
	currentAction: string
	currentModal

	showQrTab: boolean
	patAgrStatus: AgrStatus

	//currentDoctor;  // non serve?
	patientId: number
	doctorId: number
	patStatus: string

	doctorUsername: string

	filterList: string // 06.05.2022 for admins only, filters the deleted

	filterSet: boolean // per capire se é settato un filtro e cambiare la scritta dinamicamente

	isFilterTextEmpty: boolean

	password: string
	wrongPwd: boolean

	dicom_title: string = ''
	dicom_label: string = ''
	internalCodeRequired: boolean // 25.11.2019 per Zeiss vale true

	canEditPat: boolean // 20.08.2019

	// 06.12.2021 used on the html to enable the column
	canSeeStatus: boolean // 21.10.2020
	aiEnabled: boolean // 29.03.2022
	// diagonalEnabled: boolean // solo per Clalit

	//patList: Patient[];   // 24.03.2020 sul directive
	patNameTitle: string // 10.07.2020 differenziato per livelli
	closeResult: string // message after closing the modal

	reloadEnable: boolean

	today: Date

	// setFull: boolean
	telerefractEnable: boolean

	// currPatAnamesis: Anamnesis[]

	saleInfo: SaleInfo

	relationList: Relation[]

	gradersNames: string[]
	operatorsNames: string[] //per il filtro
	gradingStatus: string[]
	reportState: string[]

	myGradersControl: FormControl
	filteredGraders: Observable<string[]>

	myOperatorsControl: FormControl
	filteredOperators: Observable<string[]>

	dateFilter1: any

	showFilters: boolean
	filterForm1: FormGroup
	filterForm2: FormGroup

	newReportCount: number
	pendingCount: number

	// RDS PARAMETERS URL
	targetPatientId: number
	editPat: boolean
	targetHgReportId: number
	targetAiReportId: number

	// ICONS
	faEdit = faEdit
	faTrashAlt = faTrashAlt
	faQuestion = faQuestionCircle
	faRotate = faRotate
	faTriangleExclamation = faTriangleExclamation
	faCalendar = faCalendar
	faCheck = faCheck
	faXmark = faXmark
	faCircleExclamation = faCircleExclamation
	faChevronDown = faChevronDown
	faCaretDown = faCaretDown
	faFolderOpen = faFolderOpen
	faFileLines = faFileLines
	faCircleCheck = faCircleCheck
	faClock = faClock
	faSortDown = faSortDown
	faPen = faPen
	faClipboard = faClipboard

	color = 'primary'

	activateSpinner: boolean // 22.06.2023
	isLoadingPatientModal: boolean

	constructor(
		public session: SessionService,
		public anamnesisService: AnamnesisService,
		private translator: TranslateService,
		public modalService: NgbModal, // ,  NgbActiveModal   ko
		private dataService: DataModelService,
		public myMatPagIntl: MatPaginatorIntl,
		private activatedRoute: ActivatedRoute,
		private toastService: AppToastService,
		private router: Router
	) {
		Util.debug('(PatientListComponent) - costruttore')

		this.patientListRaw = []

		this.patList = this.patientListRaw

		this.currUser = this.session.user

		this.medicalAnamnesi = false
		this.impactEnable = false
		// console.log(this.currUser)

		this.currUserId = this.currUser.user_id
		this.isMiniC = this.session.isMiniC()

		this.activateSpinner = true
		this.isLoadingPatientModal = false

		this.myGradersControl = new FormControl('')
		this.myOperatorsControl = new FormControl('')

		this.initGradersAutocomplete()
		this.initOperatorsAutocomplete()

		this.myGradersControl.disable()
		this.myOperatorsControl.disable()

		//this.patientList = new MatTableDataSource<Patient>()  // serve, vuota?

		this.session.checkRoute() // 09.06.2022 anticipato qui

		this.patNameTitle = 'PATIENT'
		//this.patList = [];

		this.doctorId = 0
		this.patientId = 0

		this.loadPatientList() //14.10.22

		this.patStatus = ''
		this.filterList = 'A' // only the Active

		this.newReportCount = 0
		this.pendingCount = 0

		this.doctorUsername = ''

		this.reloadEnable = false
		this.today = new Date()

		this.filterSet = false

		this.isFilterTextEmpty = false

		this.gradingStatus = [this.translator.instant('PATIENT_LIST.FOR_LV2.ALL'), this.translator.instant('PATIENT_LIST.FOR_LV2.PENDING')]
		this.reportState = [
			this.translator.instant('PATIENT_LIST.FOR_LV2.ALL'),
			this.translator.instant('PATIENT_LIST.FOR_LV1.NEW_REPORT'),
			this.translator.instant('PATIENT_LIST.FOR_LV1.PATIENTS_WITH_REPORTS'),
		]
		//all, pending

		if (this.session.isSpecialist() || this.session.isClinicAdmin()) {
			this.gradingStatus.push(this.translator.instant('PATIENT_LIST.FOR_LV2.GRADED')) // graded
		}

		this.relationList = []
		this.operatorsNames = []
		this.gradersNames = []

		this.targetPatientId = 0
		this.editPat = false
		this.targetHgReportId = 0
		this.targetAiReportId = 0
		this.showFilters = true
		// this.setFull = this.session.isTelerefractEnabled() && this.session.isMini()

		// this.telerefractEnable = this.session.isTelerefractEnabled();

		if (this.session.isLevel1()) {
			this.patAgrStatus = this.session.getPatientAgreementStatus()
			this.showQrTab = this.patAgrStatus == AgrStatus.AVAILABLE

			this.doctorId = this.session.getUserId() // 14.12.2021
			// 10.07.2020  // "Name and surname";
			this.patNameTitle = this.translator.instant('PATIENT_LIST.NAME')
			this.loadUrlParameters()
			if (this.session.isGroupB()) {
				// 11.02.2021

				// valorizza this.doctorId, serve x esporre il name sul titolo

				// 20.09.2022 verifico che siano stati caricati i colleagues, per il created_by e i report
				let myColleagues = this.session.getDtDoctorList()
				if (!myColleagues || myColleagues.length == 0) {
					Util.debug('(PatList) - groupB - going to load colleagues...')
					this.session.loadDoctors()
				}
			}

			// 15.11.2022 esteso anche support
			// 20.05.2022 esteso anche per stats
			// lev3 = god or vice
		} else if (this.session.isLevel2() || this.session.isLevel3() || this.session.isStats() || this.session.isSupport()) {
			this.patNameTitle = this.translator.instant('PATIENT_LIST.PATIENT') // 10.07.2020 "Patient"
			//this.hideExamStatus = false;

			this.doctorId = this.session.getCurrentDoctorId() // 14.01.2022

			// console.log(this.doctorId)

			if (this.doctorId > 0) {
				// 20.05.2022

				if (this.dataService.hasLoadedDoctor(this.doctorId)) {
					Util.debug('(PatientListComponent) - doct ' + this.doctorId + ' already loaded')
					this.initCurrDoctor()
				} else {
					if (this.dataService.isLoadingDoctor()) {
						Util.debug('(PatientListComponent) - doct loading - TODO avoid 2 requests ?!')
					}

					// 15.03.2021 se arrivo qui passando da lista distrib -> relazione (doc name) -> pazienti, non ho il doctFromList
					// qui doctor si intente un level1
					this.session
						.loadDoctor(this.doctorId)
						.then(() => {
							this.initCurrDoctor()
						})
						.catch((err) => {
							// 30.05.2022
							Util.debug('(PatientListComponent) err: ')
							Util.debug(err)
						})
				}
			} else {
				Util.debug('(PatientListComponent) flat list without doctorId!')
				//alert("TMP TEST - ERROR");
			}
		} else {
			//this.managePatList();
			Util.debug('(pats) - flat list, user profile ?' + this.session.getCurrentUser().role)
		}

		//this.buildNavSteps()
		// this.clearCurrentPatient() // lo inizializza vuoto

		//this.session.setCurrentWindow("patientlist");  // TEMP TODO 18.11.2021
		//this.session.clearSavedPages(1);

		this.wrongPwd = false
		//this.showProgressBar = false;

		this.canEditPat = this.session.userCanEditPatient() // usi futuri

		this.internalCodeRequired = false // 08.02.2021 dismettere

		//this.aiEnabled = this.session.userCanRequestAIReport();
		this.aiEnabled = this.session.userCanSeeAiReportStatus() // 26.08.2022
		// this.diagonalEnabled = this.session.userCanSeeDiagReportStatus()

		// 13.09.2022 lo nascondo se presente diagonal, per Clalit
		// 21.10.2020 abilita il pallino di status su esami da refertare
		this.canSeeStatus = this.session.userCanSeeReportStatus()
		//this.canSeeStatus = this.session.userCanSeeReportStatus();
		if (this.session.isLevel1()) {
			this.impactEnable = this.currUser.isImpactEnabled()
			this.medicalAnamnesi = this.currUser.getAnamnesisGroup() > 0
		}
		this.initColumns()

		this.filterForm1 = new FormGroup({
			requestDate: new FormControl(null),
			dueDate: new FormControl(null),
			reportDate: new FormControl(null),
			grading: new FormControl(null),
			reports: new FormControl(null),
			// graders: new FormControl(null),
			// operators: new FormControl(null),
		})

		this.manageTablePreferences()

		// this.isSetFull = false //05-04-2023

		this.saleInfo = new SaleInfo(null)
	}

	ngOnInit() {
		Util.debug('(PatientListComponent) - onInit')

		this.patientListChanged = this.dataService.patientListChanged.subscribe((patientList: Patient[]) => {
			Util.debug('(PatientListComponent) patientListChanged')
			// console.log(patientList)

			this.initTable(patientList)

			this.loadUrlParametersRDS()

			if (this.session.isSpecialist() || this.session.isClinicAdmin() || this.session.isMiniC()) {
				this.operatorNameAssignment(this.relationList)
				this.initOperatorsAutocomplete()
			}
		})

		this.patientListStatusSubscribe = this.dataService.patientListStatusObservable.subscribe((status: DataStatus) => {
			if (status == DataStatus.LOADING) {
				this.activateSpinner = true
			}

			if (status == DataStatus.LOADED) {
				this.activateSpinner = false
			}

			if (this.session.isSpecialist() || this.session.isClinicAdmin() || this.session.isMiniC()) {
				if (this.activateSpinner) {
					this.filterForm1.disable()
				} else {
					this.filterForm1.enable()
				}
			}
		})

		if (this.session.isLevel1()) {
			if (this.session.user.hasValidSaleInfo()) {
				this.saleInfo = this.session.user.saleInfo
			} else {
				this.session.loadUserPlan(this.session.getUserId()).then((res) => {
					this.saleInfo = res
				})
			}
		}

		// translation for the mat-paginator
		this.myMatPagIntl.itemsPerPageLabel = this.translator.instant('PAGING.ITEMS_PER_PG')

		// for page destroying
		window.onbeforeunload = () => this.ngOnDestroy()

		this.session.setLogoutAuxFunc(this.resetCurrentPage.bind(this))
	}

	private manageTablePreferences() {
		Util.debug('(PatientList) - manage Table preferences')
		this.localStorageName = this.session.getUserId() + ' - patientPref'
		let saveLocal = sessionStorage.getItem(this.localStorageName)
		if (saveLocal) {
			this.patientPref = JSON.parse(saveLocal)
		} else {
			// first time
			this.patientPref = new TablePrefs()
			// this.patientPref.empty ??

			if (this.session.isSpecialist()) {
				if (this.session.isClalit()) {
					this.patientPref.sort = 'statusDP' // 25.11.2022
				} else {
					this.patientPref.sort = 'request_date' // ex 'tot_new_report' // 27.06.23 ex statusHG userStory 18661
					this.patientPref.dir = 'asc'

					this.patientPref.gradingStatus = 'PATIENT_LIST.FOR_LV2.PENDING'
				}
			} else if (this.session.isClinicAdmin()) {
				this.patientPref.gradingStatus = 'PATIENT_LIST.FOR_LV2.PENDING'
			} else {
				this.patientPref.sort = 'subscription_time' // default data for lev1
			}
			sessionStorage.setItem(this.localStorageName, JSON.stringify(this.patientPref))
		}
	}

	private initReportsCounter(patients: Patient[]) {
		this.newReportCount = patients.reduce((somma, patient) => somma + patient.tot_new_report + patient.tot_new_aireports, 0)

		this.pendingCount = patients.filter((patient) => patient.exams_to_review > 0).length
	}

	loadPatientList() {
		// console.log(this.doctorId + ' and ' + this.patStatus)
		this.session
			.loadPatientList()
			.then((patients) => {
				// console.log(patients)

				// this.initTable(this.patientListRaw) viene dalla subscribe

				this.toastService.checkNotifications()

				if (this.session.isLoadingRels()) {
					Util.debug('(loadPatientList) already loading relations... ')
				} else {
					if (this.session.isSpecialist() || this.session.isClinicAdmin()) {
						//Util.debug('(loadPatientList) going to load relations... ');
						this.loadDistribDoc(this.currUser.user_id)
					}
				}

				if (this.session.isClinicAdmin()) {
					this.loadDistribList()
				}

				this.myGradersControl.enable()
				this.myOperatorsControl.enable()
			})
			.catch((myErr) => {
				console.log(myErr)
				// let msg = myErr.data ? myErr.data.error : JSON.stringify(myErr)

				let msg = this.session.parseErrorMessage(myErr)
				Util.debug('(loadPatientList) KO ' + msg)
			})
	}

	private initTable(patients: Patient[]) {
		// this.valorizeReportDots(patients)

		this.initReportsCounter(patients)
		// console.log(patients)

		this.patientListRaw = patients // puó essere utile avere l'array che arriva dal server

		this.patList = this.patientListRaw

		this.patientList = new MatTableDataSource<Patient>(patients)

		this.patientList.paginator = this.paginator
		this.patientList.sort = this.sort

		this.patientList.filterPredicate = function (data, filter: string): boolean {
			return data.code.toLowerCase().includes(filter) || data.name.toLowerCase().includes(filter) || data.internal_code.toLowerCase().includes(filter)
		}

		setTimeout(() => {
			this.reloadEnable = true
		}, 5000)

		// automatic sort
		this.applySettings(this.patientPref, this.patientList)
	}

	initColumns() {
		this.displayedColumns = []

		if (this.session.isLevel1() || this.session.isAdmin() || this.session.isSupport()) {
			this.displayedColumns.push('code')
		}

		if (this.session.userCanEditPatient()) {
			this.displayedColumns.push('edit')
		}

		this.displayedColumns.push('name') // per tutti

		if (this.session.isLevel1()) {
			this.displayedColumns.push('internal_code')
		}

		if (!this.session.isClinicAdmin() && !this.session.isSpecialist()) {
			this.displayedColumns.push('birthYear')
		}

		if (this.session.isOptician()) {
			this.displayedColumns.push('subscription_time')
		}

		// per tutti
		this.displayedColumns.push('lastExamDate')

		if (this.session.isLevel1() || this.session.isAdmin() || this.session.isSupport()) {
			this.displayedColumns.push('last_device')
		}

		if (this.canSeeStatus || this.aiEnabled) {
			if (!this.session.isSpecialist() && !this.session.isClinicAdmin()) {
				this.displayedColumns.push('reports')
			}

			this.displayedColumns.push('pending')
		}

		if (this.session.isOptician() || (this.session.isAdmin() && (this.session.isStaging() || this.session.isDev()))) {
			this.displayedColumns.push('impact')
		}
		// if (this.aiEnabled) {
		// 	this.displayedColumns.push('statusAI') // ex tot_new_aireports
		// }
		// if (this.diagonalEnabled) {
		// 	this.displayedColumns.push('statusDP') // ex tot_diagonal_reports
		// }

		if (!this.session.isSpecialist() && !this.session.isClinicAdmin()) {
			this.displayedColumns.push('actions')
		}

		if (this.session.isSpecialist() || this.session.isClinicAdmin()) {
			this.displayedColumns.push('request_date', 'due_date', 'report_date', 'reports')
		}

		if (this.session.isClinicAdmin()) {
			this.displayedColumns.push('grader')
		}

		// if (this.session.isGroupB() || this.session.isSpecialist() || this.session.isClinicAdmin()) {
		// 	this.displayedColumns.push('created_by')
		// }
		if (this.session.isSpecialist() || this.session.isClinicAdmin()) {
			this.displayedColumns.push('created_by')
		}
	}

	loadDistribDoc(id) {
		Util.debug('(PatientList) - loadDistribDoc start')

		// giá caricata nell'init afterr login
		this.relationList = this.session.getRelationsList()

		this.operatorsNames = []

		for (let i = 0; i < this.relationList.length; i++) {
			const el = this.relationList[i]

			this.operatorsNames.push(el.username)
		}

		// console.log(this.operatorsNames)

		this.operatorNameAssignment(this.relationList)
		this.initOperatorsAutocomplete()
	}

	loadDistribList() {
		Util.debug('(PatientList) - loadDistribList start')
		var distrList = this.session.getDtDistribList()

		if (distrList && distrList.length > 0) {
			this.graderNameAssignment(distrList)
		} else {
			let ignoreCritt = false

			this.session
				.loadRemotes(ignoreCritt)
				.then((list) => {
					// console.log(list)

					this.graderNameAssignment(list)
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}

	// Assegno i nomi degli operatori invece di avere l'id
	operatorNameAssignment(resp: Relation[]) {
		Util.debug('(PatientList) - operatorNameAssignment start')
		let patList = this.patientListRaw

		for (let i = 0; i < patList.length; i++) {
			const pat = patList[i]

			for (let n = 0; n < resp.length; n++) {
				const oper = resp[n]

				if (pat.created_by == +oper.doctor_id) {
					pat.operator = oper.username
				}
			}
		}
		this.applyOperatorFilter()
		Util.debug('(PatientList) - operatorNameAssignment ends')
		// console.log(this.patientListRaw)
	}

	private applyOperatorFilter() {
		if (this.patientPref.operators) {
			this.myOperatorsControl.setValue(this.patientPref.operators)
			this.myOperatorsControl.disable()
			this.filterValue()
		}
	}

	graderNameAssignment(distrList: Distrib[]) {
		// console.log(distrList)

		this.gradersNames = []

		Util.debug('(PatientList) - graderNameAssignment start')
		let patList = this.patientListRaw

		// aggiorno la lista distrib della tabella
		for (let i = 0; i < patList.length; i++) {
			const pat = patList[i]

			pat.grader = this.getGraderName(pat.grader_id)
		}

		// aggiorno la lista del filtro dei graders
		for (let i = 0; i < distrList.length; i++) {
			const distr = distrList[i]

			if (distr.subscriptionTime && distr.is_deleted == 'N') {
				this.gradersNames.push(distr.username)
			}
		}

		Util.debug('(PatientList) - graderNameAssignment ends')
		// va richiamata quando completa la lista, altrimenti al primo click non campare la tendina
		this.initGradersAutocomplete()

		// senza timeout non riesce a disabilitarlo, setta il valore ma non lo disabilita
		setTimeout(() => {
			this.applyGraderFilter()
		}, 50)
	}

	private applyGraderFilter() {
		if (this.patientPref.graders) {
			this.myGradersControl.setValue(this.patientPref.graders)
			this.myGradersControl.disable()
			this.filterValue()
		}
	}

	private initGradersAutocomplete() {
		this.filteredGraders = this.myGradersControl.valueChanges.pipe(
			startWith(''),
			map((value) => {
				const descr = typeof value === 'string' ? value : value?.descr

				let filt = this.gradersNames.filter((list) => list.toLowerCase().includes(value))

				return descr ? filt : this.gradersNames.slice()
			})
		)
	}

	private initOperatorsAutocomplete() {
		this.filteredOperators = this.myOperatorsControl.valueChanges.pipe(
			startWith(''),
			map((value) => {
				const descr = typeof value === 'string' ? value : value?.descr

				let filt = this.operatorsNames.filter((list) => list.toLowerCase().includes(value))

				return descr ? filt : this.operatorsNames.slice()
			})
		)
	}

	private applySettings(pref: TablePrefs, list) {
		Util.debug('(PatientList) - applySettings')
		// print data sort
		this.sort.active = pref.sort
		this.sort.direction = pref.dir
		this.sort.sortChange.emit()
		// print data paginator
		this.paginator.pageIndex = pref.currentPage
		this.paginator.pageSize = pref.itemsPerPage
		list.paginator.page.emit()

		// search
		if (pref.filter.length > 0) {
			this.filterSet = true
			list.filter = pref.filter

			if (this.input) {
				// fix per specialist e clinicAdmin xk input forse non viene usato
				this.input.nativeElement.value = pref.filter
			}
		}

		// listen sort
		list.sort.sortChange.subscribe(() => {
			// save variables
			pref.sort = this.sort.active
			pref.dir = this.sort.direction
		})
		// listen paginator
		list.paginator.page.subscribe(() => {
			pref.itemsPerPage = this.paginator.pageSize
			pref.currentPage = this.paginator.pageIndex
		})
		if (pref.gradingStatus) {
			//grading status
			this.filterForm1.get('grading').setValue(this.translator.instant(pref.gradingStatus))
			this.filterValue()
		}

		// Apply filter dates
		// console.log(this.patientPref.req_date)
		if (this.patientPref.req_date) {
			let date = moment.utc(new Date(this.patientPref.req_date))

			this.RequestDatePicker.select(date)
		}

		if (this.patientPref.due_date) {
			let date = moment.utc(new Date(this.patientPref.due_date))

			this.DueDatePicker.select(date)
		}

		if (this.patientPref.rep_date) {
			let date = moment.utc(new Date(this.patientPref.rep_date))

			this.ReportDatePicker.select(date)
		}
	}

	// 23.03.2022
	// Solo per superB o mini
	private loadUrlParameters() {
		Util.debug('(PatientList) - loadUrlParameters')
		if (this.activatedRoute != null) {
			this.activatedRoute.queryParams.subscribe((params) => {
				let myParam = params['doctor']

				if (myParam != null && parseInt(myParam) > 0) {
					this.doctorId = myParam
				}
			})

			let patid = this.activatedRoute.snapshot.queryParams['patient']
			let editpat = this.activatedRoute.snapshot.queryParams['edit']
			let report = this.activatedRoute.snapshot.queryParams['report']
			let aireport = this.activatedRoute.snapshot.queryParams['aireport']
			// manca anamnesi

			if (patid != null && parseInt(patid) > 0) {
				this.targetPatientId = parseInt(patid)
				Util.debug('(PatientList) - targetPatientId: ' + this.targetPatientId)
			}
			if (editpat != null && editpat === 'true') {
				this.editPat = true
				Util.debug('(PatientList) - editpat: ' + this.editPat)
			}
			if (report != null && parseInt(report) > 0) {
				this.targetHgReportId = parseInt(report)
				Util.debug('(PatientList) - targetHgReportId: ' + this.targetHgReportId)
			}
			if (aireport != null && parseInt(aireport) > 0) {
				this.targetAiReportId = parseInt(aireport)
				Util.debug('(PatientList) - targetAiReportId: ' + this.targetAiReportId)
			}
		}
	}

	private loadUrlParametersRDS() {
		// console.log(this.targetPatientId)
		let pat: Patient = this.patientListRaw.find((pat) => pat.id == this.targetPatientId)
		// console.log(pat)
		if (pat) {
			if (this.editPat) {
				this.openPatientModal(pat)
				return
			}
			if (this.targetHgReportId || this.targetAiReportId) {
				this.openReportsModal(pat)
				return
			}
		}
	}

	// 09.02.2022 da richiamare per i lev2 e 3
	private initCurrDoctor() {
		this.doctorUsername = this.session.getDoctorUsername(this.doctorId)

		this.currDoct = this.session.getDtDoctor(this.doctorId)
		if (this.currDoct != null) {
			this.impactEnable = this.currDoct.isImpactEnabled()
			this.medicalAnamnesi = this.currDoct.getAnamnesisGroup() > 0
			// 04.11.2022 patch, solo se sono specialist.
			if (this.session.isSpecialist()) {
				//this.hideExamStatus = (currDoct.user_type == Config.PR_DOCTOR);
				Util.debug('(initCurrDoctor) asking for relation...')
				// 24.01.2022 richiedo la relazione per vedere displ name e firma
				this.session
					.getCurrentRelation(this.doctorId)
					.then((currRelation) => {
						if (currRelation != null) {
							Util.debug('(PatientList) - displ_name: ' + currRelation.display_name)
						} else {
							Util.debug('(PatientList) - null relation!')
						}
					})
					.catch((err) => {
						// 04.11.2022 ok, ignorare
						//Util.debug('(PatientList) rel err: ')
						//Util.debug(err) // null
					})
			}
		}
	}

	// 31.03.2020
	public showVisits(myPat: Patient) {
		if (this.session.isClinicAdmin()) {
			Util.debug('(showVisits) ClinicAdmin not enabled!')
			return // not enabled to see the visits and exams
		}

		if (this.isMiniC && myPat.assigned_to != this.currUserId && myPat.exams_to_review > 0) {
			Util.debug('(showVisits) this user is not asigned yet!')

			let header = this.translator.instant('TOAST.HEADER.WARNING')
			let body = this.translator.instant('TOAST.NOTIFICATIONS.USERNOTASSIGNED')
			let options = new ToastOptions('notification_s')

			this.toastService.show(header, body, false, options, 'center')

			return
		}

		// 16.09.2022 se non ne ha, inutile cambiare pg
		if (!myPat.lastExamDate && !this.session.isLocalNS()) {
			// || myPat.lastExamDate == 0
			let msg = 'no visits to show.' // TODO tradurre su json
			alert(msg)
			return
		} else {
			//alert("show visit list for user "+id);

			// 05.07.2023 FIX facciamo sempre!
			//if (this.doctorId == 0) {
			//31.05.2023 modifica per i clinic grader
			this.doctorId = myPat.created_by
			//}

			this.visitListModal = this.modalService.open(VisitListComponent, { size: 'xl', backdrop: 'static' })
			let queryParams = {}
			if (this.session.isOptician()) {
				queryParams = { patient: myPat.id }
			} else {
				queryParams = { doctor: this.doctorId, patient: myPat.id }
			}
			this.addUrlParameters(queryParams, 'visits')

			this.visitListModal.componentInstance.currentPatient = myPat
			this.visitListModal.componentInstance.doctorId = this.doctorId

			this.visitListModal.result.finally(() => {
				this.eraseUrlParameters()
			})

			// this.session.loadVisitsPage(this.doctorId, myPat.id)
		}
	}

	private addUrlParameters(queryParams, fragment: string) {
		this.router.navigate([], {
			fragment: fragment,
			relativeTo: this.activatedRoute,
			queryParams: queryParams,
			queryParamsHandling: 'merge',
		})
	}

	private eraseUrlParameters() {
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: {},
			queryParamsHandling: '',
		})
	}

	reloadList() {
		Util.debug('(PatientListComponent) - reload')
		this.reloadEnable = false
		this.loadPatientList()
	}

	public newPatientModal() {
		// console.log(Config.QRCodeEnabled)
		//prima apro il modale con il Qr code se posso showQrTab, dal modale posso decidere se passare al manuale
		if (Config.QRCodeEnabled && this.showQrTab && this.currUser.settings.p_self_reg == 'Y' && this.session.isOptician()) {
			this.currentModal = this.modalService.open(QRCode, { size: 'l' })

			this.currentModal.result
				.then((result) => {
					// console.log('then ' + result)

					if (result == 'manual') {
						this.openPatientModal(null)
					}
				})
				.catch(() => {
					// console.log('catch ')
				})
		} else {
			this.openPatientModal(null)
		}
	}

	public openAnamnesisModal(patient: Patient) {
		let impactEnable: boolean = false

		if (this.session.isLevel1()) {
			impactEnable = this.currUser.isImpactEnabled()
		} else {
			impactEnable = this.currDoct.isImpactEnabled()
		}

		this.anamnesisModal = this.modalService.open(AnamnesisModal, { size: 'xl', keyboard: false, backdrop: 'static' })
		this.anamnesisModal.componentInstance.currPatient = patient
		this.anamnesisModal.componentInstance.impactEnable = impactEnable
		if (this.session.isLevel1()) {
			this.anamnesisModal.componentInstance.currentAction = AnamnesisMode.EDIT
		} else {
			this.anamnesisModal.componentInstance.currentAction = AnamnesisMode.VIEW
		}
	}

	// passa via create and edit
	public openPatientModal(patient: Patient) {
		if (this.isLoadingPatientModal) {
			return
		}
		let patId = 0
		if (patient) {
			this.currentAction = Config.ACT_EDIT // "modify";
			patId = patient.id
		} else {
			this.currentAction = Config.ACT_CREATE // "create";
		}

		// 31.01.2023 verifico il token per evitare che poi la sessione scada al submit
		this.session.verifyUserToken()

		Util.debug('COMP (patientModal) going to open it... action: ' + this.currentAction)

		// Aggiunta promise per caricare i dati paziente al click su edit, il then se edit ritorna dopo aver caricato il paziente, se new semplicemente imposta un nuovo paziente
		this.isLoadingPatientModal = true

		this.getSinglePat(patId)
			.then((pat) => {
				// console.log(pat)

				this.currentModal = this.modalService.open(PatientModalContent, { size: 'lg', keyboard: false, backdrop: 'static' })

				// this.currentModal.componentInstance.thisAnamnesis = this.currPatAnamesis
				// this.currentModal.componentInstance.parent = this // per poi chiamare session o altre globali
				this.currentModal.componentInstance.currPatient = pat
				this.currentModal.componentInstance.currentAction = this.currentAction

				// let patAddr = this.currentPatient.getMainAddress()
				// if (patAddr == null) {
				// 	patAddr = new Address()
				// }
				// this.currentModal.componentInstance.mainAddr = patAddr

				// arriva qui facendo la close del modal
				this.currentModal.result.then(
					(patid: number) => {
						Util.debug('COMP - After modal closed: ' + patid)

						// this.isSetFull = false
						// here for modify case
						// this.reloadList()
						if (patid) {
							// 07.10.2022
							this.submitForm(patid)
						}
					},
					(reason) => {
						Util.debug('Dismissed ' + reason)

						if (reason) {
							let ris = Util.getDismissReason(reason)
							Util.debug('Dismissed ' + ris)

							// 25.02.2022 abandoned because of duplicated patient
							if (reason.indexOf('goto:') == 0) {
								let oldPat = reason.substring(5)

								// console.log(oldPat);

								let existingPat = this.getPatientById(oldPat)

								// console.log(existingPat);

								if (!existingPat) {
									Util.debug('pat ' + oldPat + ' not found in list')
									return
								}

								//23-01-23 anche se non telefract é utile venga mostrato l'edit del patient
								this.openPatientModal(existingPat)
							}
						}
					}
				)
			})
			.catch((exc) => {
				let msg = this.session.parseErrorMessage(exc, 'trace')
				Util.debug('VL (loadPatient) ex ' + msg)
			})
			.finally(() => {
				this.isLoadingPatientModal = false
			})

		//END
	}

	getSinglePat(patId: number): Promise<Patient> {
		// console.log(patId)

		if (patId == 0) {
			return Promise.resolve(new Patient())
		} else {
			return this.session.getDtPatient(patId, true).then((pat) => {
				return pat
			})
		}
	}

	public openDeleteModal(patient: Patient) {
		// this.setCurrentPatient(patient)
		this.currentAction = Config.ACT_DELETE // "delete";

		this.currentModal = this.modalService.open(DeleteModalContent, { size: 'l' })

		let patLabel = this.translator.instant('PATIENT_LIST.PATIENT') + ': ' // 17.06.2022
		this.currentModal.componentInstance.itemDescription = patLabel + patient.getFullName()
		this.currentModal.componentInstance.itemType = itemType.PATIENT
		this.currentModal.componentInstance.requirePsw = true

		// arriva qui facendo la close del modal
		this.currentModal.result.then(
			(confirmed) => {
				Util.debug('(delPat) After modal closed: ' + confirmed)

				if (confirmed) {
					this.submitForm(patient.id)
				} else {
					Util.debug('(delPat) - UNDO.')
				}
			},
			(reason) => {
				Util.debug('(delPat) Dismissed ' + Util.getDismissReason(reason))
			}
		)
	}

	// 19.12.2022
	private getPatientById(myId) {
		let pat = null
		for (let i = 0; i < this.patientListRaw.length; i++) {
			if (this.patientListRaw[i].id == myId) {
				pat = this.patientListRaw[i]
				break
			}
		}
		return pat
	}

	public openReportsModal(patient: Patient) {
		// console.log(patient)
		if (!this.session.isLevel1()) {
			this.session.loadDoctorKey('' + patient.created_by)
		}

		if (!this.session.isSpecialist() && patient.totalReports == 0) {
			return
		}

		if ((this.session.isClinicAdmin() || this.session.isSpecialist()) && patient.tot_reports_done == 0) {
			return
		}

		Util.debug('openReportsModal')
		let queryParams: any
		if (this.session.isOptician()) {
			queryParams = { patient: patient.id }
		} else {
			this.doctorId = patient.created_by
			queryParams = { doctor: this.doctorId, patient: patient.id }
		}
		this.addUrlParameters(queryParams, 'reports')

		this.reportListModal = this.modalService.open(PatientReportModal, { size: 'lg' })
		this.reportListModal.componentInstance.patient = patient

		this.reportListModal.result.finally(() => {
			this.eraseUrlParameters()
		})
	}

	public showImpact(patient: Patient) {
		if (!patient.impact_enabled) {
			return
		}

		this.session.loadDoctorKey(this.doctorId.toString())

		this.impactModal = this.modalService.open(ImpactModal, {
			size: 'lg',
			windowClass: 'impactModalClass',
		})
		this.impactModal.componentInstance.patient = patient
	}

	private openConfirmModal(text: string): NgbModalRef {
		this.currentModal = this.modalService.open(ConfirmModal, { size: 'l', keyboard: false, backdrop: 'static' }) //backdrop evita che cliccando al di fuori si chiuda automaticamente il modal
		this.currentModal.componentInstance.isExit = false
		this.currentModal.componentInstance.isQuest = true
		this.currentModal.componentInstance.warnText = text

		return this.currentModal
	}

	// 29.11.2021
	// arriva qui dopo la close del modal con il form
	//submit(form ? ) {
	private submitForm(patid: number) {
		if (this.currentAction == Config.ACT_CREATE) {
			if (patid > 0) {
				Util.debug('(patientForm) patient created')

				if (this.currUser.isImpactEnabled() || this.currUser.getAnamnesisGroup() > 0) {
					let patient = this.patientListRaw.find((p) => p.id == patid)

					// console.log(patient)

					if (patient) {
						let msg = 'Would you like to add the anamnesis details for this customer now?'
						let confirmModal = this.openConfirmModal(msg)
						confirmModal.result.then((resp) => {
							this.anamnesisModal = this.modalService.open(AnamnesisModal, { size: 'xl', keyboard: false, backdrop: 'static' })
							this.anamnesisModal.componentInstance.currPatient = patient
							this.anamnesisModal.componentInstance.impactEnable = this.currUser.isImpactEnabled()
							this.anamnesisModal.componentInstance.currentAction = AnamnesisMode.EDIT
						})
					}
				}
			} else {
				Util.debug('(patientForm) closed without action')
			}
		} else if (this.currentAction == Config.ACT_EDIT) {
			Util.debug('(patientForm) going to modify patient ' + patid)

			// this.session
			// 	.updatePatient(changedPat)
			// 	.then((resp) => {
			// 		Util.debug(resp)

			// 		//Importante necessaria in quanto loggetto paziente passato al modale con this.session.getDtPatient(patId) e ritornato non ha le info del grading, per cui dopo un edit vengono perse

			// 	})
			// 	.catch((err) => {
			// 		console.log(err)

			// 		//toast service per ora disabilitato
			// 		// let header = this.translator.instant('TOAST.HEADER.SUCCESS')
			// 		// let body = this.translator.instant('TOAST.PATIENT') + ' cod: ' + changedPat.id + '. ' + this.translator.instant('TOAST.BODYERR.EDIT')

			// 		// this.toastService.show(header, body, { type: 'error', delay: 10000, autohide: true }, 'bottom-right')
			// 	})
		} else if (this.currentAction == Config.ACT_DELETE) {
			if (this.session.isGod()) {
				// 23.03.2020 admin ?!
				Util.debug('COMP (patientForm) KO, admin does not delete patients!')
			} else if (this.session.isLevel1()) {
				Util.debug('COMP (patientForm) delete by lev1, pat ' + patid)

				this.session
					.deletePatient(patid)
					.then((resp) => {
						Util.debug(resp)

						//02-03-23 moved here
						//Elimino il paziente dalla lista senza ricaricarla
						for (var i = 0; i < this.patientList.data.length; i++) {
							if (this.patientList.data[i].id === patid) {
								this.patientList.data.splice(i, 1) //Elimino il paziene dall'array

								this.table.renderRows() // qui il renderRows serve
								this.patientList._updateChangeSubscription()
							}
						}
						//toast service per ora disabilitato
						// let header = this.translator.instant('TOAST.HEADER.SUCCESS')
						// let body = this.translator.instant('TOAST.PATIENT') + ' cod: ' + changedPat.id + '. ' + this.translator.instant('TOAST.BODY.DELETED')

						// this.toastService.show(header, body, { type: 'success', delay: 5000, autohide: true }, 'bottom-right')

						// this.reloadList();  //non serve, la commento in caso servisse rispristinarlo
					})
					.catch((err) => {
						console.log(err)

						//toast service per ora disabilitato
						// let header = this.translator.instant('TOAST.HEADER.ERROR')
						// let body =
						// 	this.translator.instant('TOAST.PATIENT') + ' cod: ' + changedPat.id + '. ' + this.translator.instant('TOAST.BODYERR.DELETED')

						// this.toastService.show(header, body, { type: 'error', delay: 10000, autohide: true }, 'bottom-right')
					})
			} else {
				Util.debug('(patientForm) KO, richiesta delete da ' + this.session.getUsername())
			}
		}
	}

	// 02-03-23
	// public forceListReload() {
	// 	Util.debug('(forceListReload) - refresh...');
	// 	//this.refreshFlag = '' + new Date().getTime(); // per farlo sempre cambiare, usando la directive
	// 	this.loadPatientList(); // 19.12.2022 non c'e' piu' la directive
	// }

	// 09.09.2021 vd simile su session
	// 26.08.2021 metto la username del superB al posto del created by, per i mini
	getStrCreator(usrId) {
		var ret = '' + usrId

		if (this.session.isGroupB()) {
			if (this.session.getUserId() == usrId) {
				ret = 'myself'
			} //else {  // 18.04.2023 tolta visibilita'
			//ret = this.session.getDoctorName(usrId)
			//}
		}
		return ret
	}

	// 16.06.2023 valutare se rallenta la pg, analogam alla funz sopra, getStrCreator
	public getGraderName(graderId) {
		let ret = '-'
		if (graderId && graderId > 0) {
			let grader = this.session.geDistribFromList(graderId)
			if (grader) {
				ret = grader.username
			} else {
				ret = '' + graderId
			}
		}
		return ret
	}

	public filterText() {
		this.patientPref.filter = this.input.nativeElement.value
		this.patientPref.filter = this.patientPref.filter.trim().toLocaleLowerCase()
		this.patientList.filter = this.patientPref.filter

		if (this.input.nativeElement.value.length > 0) {
			this.filterSet = true
		}

		if (this.patientList.filteredData.length == 0) {
			this.isFilterTextEmpty = true
		} else {
			this.isFilterTextEmpty = false
		}
	}

	// func to filter the list
	filterValue() {
		Util.debug('(PatientList) - filterValue start')
		this.filterSet = false
		// console.log(this.filterForm1)
		// console.log(form)

		this.patList = this.patientListRaw //la raw sará sempre la completa, ogni volta parto dalla completa

		Util.debug('PL (filterValue) tot patients: ' + this.patList.length)
		// console.log(this.filterForm1)
		//Grading
		let gradingFilter = this.filterForm1.get('grading').value

		if (gradingFilter) {
			if (gradingFilter == this.translator.instant('PATIENT_LIST.FOR_LV2.ALL')) {
				this.patientPref.gradingStatus = 'PATIENT_LIST.FOR_LV2.ALL' // per il grading status devo salvare la stringa del json in modo da essere compatibile nel caso di cambio lingua
			} else {
				this.filterSet = true

				if (gradingFilter == this.translator.instant('PATIENT_LIST.FOR_LV2.PENDING')) {
					this.patList = this.patList.filter((d) => d.exams_to_review > 0)

					this.patientPref.gradingStatus = 'PATIENT_LIST.FOR_LV2.PENDING'
				}

				if (gradingFilter == this.translator.instant('PATIENT_LIST.FOR_LV2.GRADED')) {
					this.patList = this.patList.filter((d) => d.exams_to_review == 0)

					this.patientPref.gradingStatus = 'PATIENT_LIST.FOR_LV2.GRADED'
				}
			}
		}

		//reports

		let reportFilter = this.filterForm1.get('reports').value

		if (reportFilter) {
			if (reportFilter == this.translator.instant('PATIENT_LIST.FOR_LV1.NEW_REPORT')) {
				this.filterSet = true
				this.patList = this.patList.filter((d) => d.tot_new_report > 0 || d.tot_new_aireports > 0)
			}

			if (reportFilter == this.translator.instant('PATIENT_LIST.FOR_LV1.PATIENTS_WITH_REPORTS')) {
				this.filterSet = true
				this.patList = this.patList.filter((d) => d.tot_reports > 0 || d.tot_aireports > 0)
			}
		}

		// Operators

		// let operatorFilter = this.filterForm1.get('operators').value

		let operatorFilter = this.myOperatorsControl.value

		if (operatorFilter && operatorFilter != '') {
			this.filterSet = true

			this.patList = this.patList.filter((d) => d.operator == operatorFilter)

			this.patientPref.operators = operatorFilter
		}

		// Grader

		// let graderFilter = this.filterForm1.get('graders').value

		let graderFilter = this.myGradersControl.value

		if (graderFilter && graderFilter != '') {
			this.filterSet = true

			this.patList = this.patList.filter((d) => d.grader == graderFilter)

			this.patientPref.graders = graderFilter
		}

		// Request Date

		let reqD = this.filterForm1.get('requestDate').value

		if (reqD) {
			this.filterSet = true

			let reqDateFilter = new Date(moment(reqD).format()) // bisogna usare la libreria moment, il datepicker material ha come valore un oggetto moment per gestire tutte le lingue e formati

			this.patList = this.patList.filter((d) => this.session.formatDate(d.request_date) == this.session.formatDate(reqDateFilter))

			this.patientPref.req_date = new Date(Date.UTC(reqDateFilter.getFullYear(), reqDateFilter.getMonth(), reqDateFilter.getDate())) // se salvo anche l'ora poi trasformandolo con JSON.stringify mi cambia la data perche viene portato a GMT-ZULU
		}

		// Due Date

		let dDate = this.filterForm1.get('dueDate').value

		if (dDate) {
			this.filterSet = true

			let dueDateFilter = new Date(moment(dDate).format())

			this.patList = this.patList.filter((d) => this.session.formatDate(d.due_date) == this.session.formatDate(dueDateFilter))

			this.patientPref.due_date = new Date(Date.UTC(dueDateFilter.getFullYear(), dueDateFilter.getMonth(), dueDateFilter.getDate()))
		}

		// Report Date

		let rDate = this.filterForm1.get('reportDate').value

		if (rDate) {
			this.filterSet = true

			let reportDateFilter = new Date(moment(rDate).format())

			this.patList = this.patList.filter((d) => this.session.formatDate(d.lastReportDate) == this.session.formatDate(reportDateFilter))

			this.patientPref.rep_date = new Date(Date.UTC(reportDateFilter.getFullYear(), reportDateFilter.getMonth(), reportDateFilter.getDate()))
		}

		this.initReportsCounter(this.patList)

		this.patientList = new MatTableDataSource<Patient>(this.patList)
		this.patientList.paginator = this.paginator
		this.patientList.sort = this.sort

		// se prima filtro con il testo e dopo con filterValue, bisogna alla fine richiamare il filtro solo testo
		if (this.patientPref.filter != '' || this.input.nativeElement.value.length > 0) {
			this.filterText()
		}
	}

	ngOnDestroy() {
		sessionStorage.setItem(this.localStorageName, JSON.stringify(this.patientPref))

		this.patientListStatusSubscribe.unsubscribe()
		this.patientListChanged.unsubscribe()
	}

	resetCurrentPage() {
		this.patientPref.currentPage = 0
	}
}
